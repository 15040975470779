import React from 'react'
//import './ImportMessages.scss'

const ImportMessages = ({ messages }) => {
    if (messages) {
        return (
            <div>{messages.map((m, i) => <div key={i}>{m.row >= 0 ? 'Row ' + m.row + ': ' : ''}{m.msg}</div>)}</div>
        )
    }
    else return null
}

export default ImportMessages
