import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import axiosMiddleware from 'middlewares/axiosMiddleware'
import reportWebVitals from './reportWebVitals'
import index from "./js/index"; // eslint-disable-line
import { Provider } from 'react-redux'
import store from './js/store/index'
import { CookiesProvider } from 'react-cookie'

axiosMiddleware()

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
)

reportWebVitals()
