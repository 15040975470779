const { INFOMATIONAL_PROP, WIDGET, KEY, OTHER_PROP, LOW_PROP, MEDIUM_PROP, HIGH_PROP, CRITICAL_PROP, IS_EDITABLE, CATEGORY } = require('../metrics/common-metrics')

const metricModel = {
    [CATEGORY]: '',
    [WIDGET]: '',
    [KEY]: '',
    [OTHER_PROP]: '',
    [INFOMATIONAL_PROP]: '',
    [LOW_PROP]: '',
    [MEDIUM_PROP]: '',
    [HIGH_PROP]: '',
    [CRITICAL_PROP]: '',
    [IS_EDITABLE]: true
}

export {
    metricModel
}
