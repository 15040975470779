import React, { useState, useEffect } from 'react'
import { InputRow, PasswordStrength, helpers, genericTexts, Loader, Popup } from 'hub-web-lib'
import UserApi from 'services/UserApi'
import { userModel } from 'js/constants/models/user'
import { USER } from 'js/constants/vocabulary'
import { roles, otpStatuses } from 'js/constants/enums/user'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT, UPDATE_CURRENT_USER } from 'js/constants/action-types'
import { emptyJsonValues } from 'middlewares/ApiHelpers'
import store from 'js/store'
import { connect } from 'react-redux'
import { getUserRole } from 'js/ui-helpers/roles'
import 'scss/UpdateAdminItem/UpdateAdminItem.scss'
import './UpdateUser.scss'
import OrgApi from 'services/OrgApi'
import { useNavigate } from 'react-router'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

const fieldsErrorMsgDefault = emptyJsonValues({ ...userModel, role_id: '' })

const UpdateUserComponent = ({ 
    userDetails, 
    isNewUser,
    isPopup, 
    isOrgCreation,
    closePopupUser = () => {}, 
    updateUserDetails = () => {},
    updateUserProp = () => {},
    fieldsErrorMsgExternal,
    updatePasswordIsValid,
    currentUser,
    currentUserOrgs
}) => {
    const navigate = useNavigate()

    const [userOrgs, setUserOrgs] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [userToEdit, setUserToEdit] = useState({})
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...(fieldsErrorMsgExternal || fieldsErrorMsgDefault) })
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [isOpenChangeRolePopup, setIsOpenChangeRolePopup] = useState(false)
    const isDataChanged = !helpers.isSameObject(userToEdit, userDetails)
    const isMe = userDetails?.id === currentUser?.id
    const isDowngrading = isMe && getUserRole(currentUser) === 1 && userToEdit.role_id && (userToEdit.role_id[userToEdit.org_id] || 0) > 1
    const getUserToEdit = async () => {
        const user = userDetails ? helpers.createNewInstance(userDetails) : helpers.createNewInstance(userModel)
        if (isOrgCreation) {
            setUserToEdit(user)
            return
        }
        const orgs = isMe ? currentUserOrgs : await OrgApi.getChildOrgs(currentUser?.current_org)
        if (!orgs || orgs.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: orgs.message, notificationType: 'error' }))
            return
        }
        const activeOrgs = orgs.filter(o => o.status === 1)
        activeOrgs.forEach(org => {
            if (!user.role_id[org.id]) user.role_id[org.id] = user.role_id[user.org_id] || ''
        })
        setUserToEdit(user)
        setUserOrgs(activeOrgs)
    }

    const updateUser = async () => {
        const userUpdated = await updateCreateUserAction()
        if (!userUpdated || userUpdated.error) {
            if (Array.isArray(userUpdated?.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsg)
                userUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else if (userUpdated?.message) {
                const message = typeof userUpdated.message === 'string' ? userUpdated.message : userUpdated.message.join(', ')
                setErrorMsg(message)
            }
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: getSuccessMessage() }))
        closePopup()
        await updateUserDetails()
        if (isDowngrading) {
            navigate('/')
            return
        }
        if (userUpdated.id === currentUser?.id && !isNewUser) await updateCurrentUser()
    }

    const updateCurrentUser = async () => {
        const currentUserDetails = await UserApi.getCurrentUser()
        if (currentUserDetails?.id) store.dispatch(action(UPDATE_CURRENT_USER, currentUserDetails))
    }

    const closePopup = () => {
        setFieldsErrorMsg({ ...fieldsErrorMsg })
        setErrorMsg('')
        setIsPasswordValid(false)
        closePopupUser()
    }

    const updateCreateUserAction = async () => {
        let updated
        setIsButtonLoading(true)
        if (isNewUser) {
            if (!isPasswordValid) {
                setFieldsErrorMsg({ ...fieldsErrorMsg, password: 'Please type strong password' })
                setIsButtonLoading(false)
                return null
            }
            updated = await UserApi.createUser(userToEdit)
        } else {
            updated = await UserApi.updateUser(userToEdit.id, userToEdit)
            if (userToEdit.password) {
                const isPasswordChanged = await UserApi.updateUserPasswordAdmin(userToEdit.id, userToEdit.password)
                if (!isPasswordChanged || isPasswordChanged.error || isPasswordChanged.data?.error) {
                    store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: isPasswordChanged.error, notificationType: 'error' }))
                }
            }

        }
        setIsButtonLoading(false)
        return updated
    }

    const getSuccessMessage = () => {
        return isNewUser ? genericTexts.defaultText.createdSuccess(USER) : genericTexts.defaultText.updatedSuccess(USER)
    }

    const changeValueHandler = (key, val) => {
        setUserToEdit({ ...userToEdit, [key]: val })
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
        updateUserProp(key, val)
    }

    const updateUserRole = (val, orgId) => {
        let roleId = { ...userToEdit.role_id, [orgId]: val }
        if (isNewUser && userOrgs.findIndex(o => o.id === orgId) === 0) {
            Object.keys(roleId).forEach(org => {
                if (!roleId[org]) roleId[org] = val
            })
        }
        setUserToEdit({ ...userToEdit, role_id: roleId })
        setFieldsErrorMsg({ ...fieldsErrorMsg, role_id: '' })
        updateUserProp('role_id', val)
    }

    useEffect(async () => {
        if (!helpers.isSameObject(userDetails, userToEdit)) await getUserToEdit()
        setIsLoading(false)
    }, [userDetails])

    useEffect(() => {
        if (!helpers.isSameObject(fieldsErrorMsg, fieldsErrorMsgExternal) && fieldsErrorMsgExternal) setFieldsErrorMsg(fieldsErrorMsgExternal)
    }, [fieldsErrorMsgExternal])

    const isDisableAllControls = !isNewUser && getUserRole(userToEdit) !== 1 && !isPopup

    return (
        <>
            <div className='form-fields'>
                <fieldset>
                    <h3 id='user-details'>{isMe ? 'My' : 'User'} Details</h3>
                    <InputRow 
                        inputId='name' 
                        title='Full Name' 
                        changeValueHandler={(val) => changeValueHandler('name', val)} 
                        defaultValue={ userToEdit.name || '' }
                        disabled={isDisableAllControls}
                        errorMsg={fieldsErrorMsg.name} />

                    <InputRow 
                        inputId='user_name' 
                        title='Username' 
                        changeValueHandler={(val) => changeValueHandler('user_name', val)} 
                        defaultValue={ userToEdit.user_name || '' }
                        disabled={!isNewUser}
                        errorMsg={fieldsErrorMsg.user_name} />
                    
                    <InputRow 
                        inputId='email' 
                        title='Email' 
                        changeValueHandler={(val) => changeValueHandler('email', val)} 
                        defaultValue={ userToEdit.email || '' }
                        disabled={isDisableAllControls}
                        errorMsg={fieldsErrorMsg.email} />

                    <InputRow
                        inputId='otp_status'
                        title='Two-factor auth'
                        inputTag='select'
                        placeholder='Select'
                        options={otpStatuses.map(r => ({ ...r, name: helpers.capitalizeString(r.name) }))}
                        changeValueHandler={(val) => changeValueHandler('otp_status', val)}
                        defaultValue={userToEdit.otp_status}
                        disabled={isDisableAllControls}
                        errorMsg={fieldsErrorMsg.otp_status} />
                    {(isNewUser || (!isNewUser && getUserRole(currentUser) === 1 && isPopup)) &&
                        <PasswordStrength
                            details={{ title: (!isNewUser && getUserRole(currentUser) === 1) ? 'Change User Password' : 'Password', inputId: 'newUserPassword', layout: 'horizontal' }}
                            password={userToEdit.password || ''}
                            updatePassword={(val) => changeValueHandler('password', val)}
                            updatePasswordIsValid={isOrgCreation ? updatePasswordIsValid : setIsPasswordValid}
                            errorMsg={fieldsErrorMsg.password} />
                    }
    
                </fieldset>
                {!isOrgCreation && <fieldset className='scroll user-roles'>
                    <h3 id='user-permissions'>Permissions</h3>
                    {isLoading ? <Loader /> : userOrgs.map((org, i) => <InputRow 
                        key={org.id}
                        inputId={`role-id-${org.id}`} 
                        title={org.name}
                        inputTag='select'
                        placeholder='Select'
                        layout='vertical'
                        options={ roles.map(r => ({ ...r, name: helpers.capitalizeString(r.name) })) }
                        changeValueHandler={(val) => updateUserRole(val, org.id)}
                        defaultValue={ userToEdit.role_id[org.id] || '' }
                        disabled={isDisableAllControls}
                        errorMsg={i === 0 ? fieldsErrorMsg.role_id : ''} />)}
                </fieldset>}
            </div>
            {!isOrgCreation && <>

                <p className='error-msg' id='update-user-error'>{errorMsg}</p>

                <div className={isPopup ? 'popup-buttons' : 'form-buttons'}>
                    {(isPopup || getUserRole(currentUser) === 1) && <button className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} onClick={isDowngrading ? () => setIsOpenChangeRolePopup(true) : () => updateUser()} disabled={!isDataChanged && !isNewUser} id='update-user'>{genericTexts.defaultText.createNewButton(USER, !isNewUser)}</button>}
                    {isPopup && <button onClick={closePopup} className='btn-cancel' id='update-user-cancel'>{genericTexts.defaultText.cancel}</button>}
                </div>
            </>
            }
            <Popup
                onClose={() => setIsOpenChangeRolePopup(false)}
                isOpen={isOpenChangeRolePopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p><i className='icon-warning status error'></i> Downdrading you role from Admin is irreversible change. You will not be able to manage your orgs and users and change you role back</p>
                <div className='popup-buttons'>
                    <button className='btn-delete' onClick={updateUser} id='change-user-status'>Agree</button>
                    <button onClick={() => setIsOpenChangeRolePopup(false)} className='btn-cancel' id='change-user-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
        </>)
}

const UpdateUser = connect(mapStateToProps)(UpdateUserComponent)
export default UpdateUser