import React from 'react'
import { MatrixTable } from 'hub-web-lib/dist/index-export'
import { IMPACT, LIKELIHOOD } from '../metrics/risk-assessment-metrics'
import { CRITICAL_PROP,  HIGH, HIGH_PROP, INFOMATIONAL_PROP, LOW, LOW_PROP, MEDIUM, MEDIUM_PROP, VERY_LOW } from '../metrics/common-metrics'
import { RISK_ASSESSMENT_MODULE } from '../app-modules'
import { getMetricsValuesPropsAllDimensions } from 'js/ui-helpers/metrics'
import { IMPACT_DIMENSION, LIKELIHOOD_DIMENSION, RISK_DIMENSION, SEVERITY_DIMENSION } from '../dashboard-settings'

const template2Aliases = {
    [SEVERITY_DIMENSION]: {
        [INFOMATIONAL_PROP]: 'Extremely Low',
        [LOW_PROP]: 'Minor',
        [HIGH_PROP]: 'Major',
        [CRITICAL_PROP]: 'Extreme',
    },
    [RISK_DIMENSION]: {
        [INFOMATIONAL_PROP]: 'Extremely Low',
        [LOW_PROP]: 'Minor',
        [HIGH_PROP]: 'Major',
        [CRITICAL_PROP]: 'Extreme',
    },
    [IMPACT_DIMENSION]: {
        [INFOMATIONAL_PROP]: 'Very Low',
    },
    [LIKELIHOOD_DIMENSION]: {
        [INFOMATIONAL_PROP]: 'None',
        [LOW_PROP]: 'Rare',
        [MEDIUM_PROP]: 'Occasional',
        [HIGH_PROP]: 'Likely',
        [CRITICAL_PROP]: 'Very likely',
    },
}

const getMatrixTableTemplate = (colors) => {
    return {
        1: [{
            name: {
                value: LOW
            },
            [LOW]: {
                color: colors[LOW_PROP],
                value: 6
            },
            [MEDIUM]: {
                color: colors[LOW_PROP],
                value: 1
            },
            [HIGH]: {
                color: colors[MEDIUM_PROP],
                value: 4
            }
        }, {
            name: {
                value: MEDIUM
            },
            [LOW]: {
                color: colors[LOW_PROP],
                value: 7
            },
            [MEDIUM]: {
                color: colors[MEDIUM_PROP],
                value: 7
            },
            [HIGH]: {
                color: colors[HIGH_PROP],
                value: 2
            }
        }, {
            name: {
                value: HIGH
            },
            [LOW]: {
                color: colors[MEDIUM_PROP],
                value: 8
            },
            [MEDIUM]: {
                color: colors[HIGH_PROP],
                value: 5
            },
            [HIGH]: {
                color: colors[HIGH_PROP],
                value: 2,
                secondValue: 4,
                secondColor: colors[CRITICAL_PROP]
            }
        }],
        2: [{
            name: {
                value: template2Aliases[LIKELIHOOD_DIMENSION][CRITICAL_PROP]
            },
            [VERY_LOW]: {    
                color: colors[LOW_PROP],
                value: 2
            },
            [LOW]: {
                color: colors[MEDIUM_PROP],
                value: 6
            },
            [MEDIUM]: {
                color: colors[HIGH_PROP],
                value: 1
            },
            [HIGH]: {
                color: colors[HIGH_PROP],
                value: 4
            }
        }, {
            name: {
                value: template2Aliases[LIKELIHOOD_DIMENSION][HIGH_PROP]
            },
            [VERY_LOW]: {
                color: colors[LOW_PROP],
                value: 5
            },
            [LOW]: {
                color: colors[LOW_PROP],
                value: 7
            },
            [MEDIUM]: {
                color: colors[MEDIUM_PROP],
                value: 7
            },
            [HIGH]: {
                color: colors[HIGH_PROP],
                value: 2
            }
        }, {
            name: {
                value: template2Aliases[LIKELIHOOD_DIMENSION][MEDIUM_PROP]
            },
            [VERY_LOW]: {
                color: colors[INFOMATIONAL_PROP],
                value: 3
            },
            [LOW]: {
                color: colors[LOW_PROP],
                value: 8
            },
            [MEDIUM]: {
                color: colors[MEDIUM_PROP],
                value: 5
            },
            [HIGH]: {
                color: colors[MEDIUM_PROP],
                value: 2
            }
        }, {
            name: {
                value: template2Aliases[LIKELIHOOD_DIMENSION][LOW_PROP]
            },
            [VERY_LOW]: {
                color: colors[INFOMATIONAL_PROP],
                value: 7
            },
            [LOW]: {
                color: colors[INFOMATIONAL_PROP],
                value: 1
            },
            [MEDIUM]: {
                color: colors[LOW_PROP],
                value: 4
            },
            [HIGH]: {
                color: colors[MEDIUM_PROP],
                value: 6
            }
        }],
    }
}

const matrixTableHeadersTemplate2 = {
    columns: IMPACT,
    rows: LIKELIHOOD
}

const matrixTableHeadersTemplate1 = {
    columns: LIKELIHOOD,
    rows: IMPACT
}

const riskAssessmentActivityTemplateOuterHeaders = {
    1: matrixTableHeadersTemplate1,
    2: matrixTableHeadersTemplate2
}

const riskAssessmentActivityTemplateTypes = [{
    id: 1,
    name: 'Template 1'
}, {
    id: 2,
    name: 'Template 2'
}]

const getRiskAssessmentActivityTemplateTypes = (dashboardSettings) => {

    const metricsPropsOrdered = getMetricsValuesPropsAllDimensions(dashboardSettings, RISK_ASSESSMENT_MODULE)
    const colors = {}
    metricsPropsOrdered[RISK_DIMENSION].forEach(s => {
        colors[s.id] = s.color
    })
    const matrixTableTemplate = getMatrixTableTemplate(colors)
    return riskAssessmentActivityTemplateTypes.map(t => ({
        ...t,
        content: <MatrixTable tableData={matrixTableTemplate[t.id]} outerHeaders={riskAssessmentActivityTemplateOuterHeaders[t.id]} />
    }))
} 

const matrixTableColorOrder = {
    1: {
        [LOW_PROP]: [LOW_PROP, LOW_PROP, MEDIUM_PROP],
        [MEDIUM_PROP]: [LOW_PROP, MEDIUM_PROP, HIGH_PROP],
        [HIGH_PROP]: [MEDIUM_PROP, HIGH_PROP, HIGH_PROP],
        [CRITICAL_PROP]: [CRITICAL_PROP]
    },
    2: {
        [INFOMATIONAL_PROP]: [LOW_PROP, LOW_PROP, INFOMATIONAL_PROP, INFOMATIONAL_PROP],
        [LOW_PROP]: [MEDIUM_PROP, LOW_PROP, LOW_PROP, INFOMATIONAL_PROP],
        [MEDIUM_PROP]: [HIGH_PROP, MEDIUM_PROP, MEDIUM_PROP, LOW_PROP],
        [HIGH_PROP]: [HIGH_PROP, HIGH_PROP, MEDIUM_PROP, MEDIUM_PROP]
    }
}

const matrixTableColors = {
    1: {
        [LOW_PROP]: {
            [LOW_PROP]: LOW_PROP,
            [MEDIUM_PROP]: LOW_PROP,
            [HIGH_PROP]: MEDIUM_PROP,
        },
        [MEDIUM_PROP]: {
            [LOW_PROP]: LOW_PROP,
            [MEDIUM_PROP]: MEDIUM_PROP,
            [HIGH_PROP]: HIGH_PROP,
        },
        [HIGH_PROP]: {
            [LOW_PROP]: MEDIUM_PROP,
            [MEDIUM_PROP]: HIGH_PROP,
            [HIGH_PROP]: HIGH_PROP,
        },
        [CRITICAL_PROP]: {
            [HIGH_PROP]: CRITICAL_PROP,
        },
    },
    2: {
        [INFOMATIONAL_PROP]: {
            [LOW_PROP]: INFOMATIONAL_PROP,
            [MEDIUM_PROP]: INFOMATIONAL_PROP,
            [HIGH_PROP]: LOW_PROP,
            [CRITICAL_PROP]: LOW_PROP,
        },
        [LOW_PROP]: {
            [LOW_PROP]: INFOMATIONAL_PROP,
            [MEDIUM_PROP]: LOW_PROP,
            [HIGH_PROP]: LOW_PROP,
            [CRITICAL_PROP]: MEDIUM_PROP,
        },
        [MEDIUM_PROP]: {
            [LOW_PROP]: LOW_PROP,
            [MEDIUM_PROP]: MEDIUM_PROP,
            [HIGH_PROP]: MEDIUM_PROP,
            [CRITICAL_PROP]: HIGH_PROP,
        },
        [HIGH_PROP]: {
            [LOW_PROP]: MEDIUM_PROP,
            [MEDIUM_PROP]: MEDIUM_PROP,
            [HIGH_PROP]: HIGH_PROP,
            [CRITICAL_PROP]: HIGH_PROP,
        }
    }
}

export {
    riskAssessmentActivityTemplateTypes,
    getRiskAssessmentActivityTemplateTypes,
    riskAssessmentActivityTemplateOuterHeaders,
    matrixTableColorOrder,
    template2Aliases,
    matrixTableColors
}