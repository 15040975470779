import React from 'react'
import { InputRow } from 'hub-web-lib'

const RedTeamGeneralInformation = ({ data }) => {
    const {
        currentOrg,
        orgUsers,
        activityToUpdate,
        fieldsErrorMsg,
        changeValueHandler,
        errorMsg,
    } = data

    const dateTimeDetails = {
        showTimeSelect: false,
        dateFormat: 'dd MMMM yy',
    }
    const changeValue = (key, val, isAuto) => {
        if (isAuto) return
        const updatedActivity = { ...activityToUpdate, [key]: val }
        if (key === 'start_date' && new Date(val) > (activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : new Date())) updatedActivity.end_date = val
        changeValueHandler(key, updatedActivity)
    }


    return (
        <div className='activity-general-information'>
            <h3 id='easm-general-information'>General Information</h3>
            <InputRow
                inputId='org_id'
                title='Organization'
                layout='vertical'
                disabled={true}
                defaultValue={currentOrg?.name || currentOrg?.id || ''}
                errorMsg={fieldsErrorMsg?.org_id} />
            <InputRow
                inputId='title'
                title='Title'
                layout='vertical'
                required={true}
                defaultValue={activityToUpdate?.title || ''}
                changeValueHandler={(val) => changeValue('title', val)}
                errorMsg={fieldsErrorMsg.title} />
            <InputRow
                inputId='description'
                title='Description'
                inputTag='textarea'
                layout='vertical'
                required={true}
                changeValueHandler={(val) => changeValue('description', val)}
                defaultValue={activityToUpdate?.description || ''}
                errorMsg={fieldsErrorMsg?.description} />
            <InputRow
                inputId='start_date'
                title='Start Date'
                inputType='dateTime'
                layout='vertical'
                defaultValue={activityToUpdate?.start_date ? new Date(activityToUpdate.start_date) : ''}
                dateTimeDetails={dateTimeDetails}
                changeValueHandler={(val, isAuto) => changeValue('start_date', val, isAuto)}
                errorMsg={fieldsErrorMsg.start_date} />
            <InputRow
                inputId='end_date'
                title='End Date'
                inputType='dateTime'
                layout='vertical'
                dateTimeDetails={{ ...dateTimeDetails, minDate: new Date(activityToUpdate?.start_date) || new Date() }}
                defaultValue={activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : ''}
                changeValueHandler={(val, isAuto) => changeValue('end_date', val, isAuto)}
                errorMsg={fieldsErrorMsg?.end_date} />
            <InputRow
                inputId='reported_by'
                title='Created By'
                layout='vertical'
                inputTag='select'
                placeholder='Select'
                options={orgUsers}
                defaultValue={activityToUpdate?.reported_by}
                changeValueHandler={val => changeValue('reported_by', +val)}
                errorMsg={''} />
            <InputRow
                inputId='is_complete'
                title='Completed'
                inputType='checkbox'
                defaultValue={activityToUpdate?.is_complete}
                changeValueHandler={val => changeValue('is_complete', val)}
                errorMsg={fieldsErrorMsg?.is_complete} />
            <p className='error-msg' id='easm-activity-general-info-error'>{errorMsg}</p>


        </div>)
}

export default RedTeamGeneralInformation