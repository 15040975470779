import React, { useState } from 'react'
import './ChangePassword.scss'
import UserApi from 'services/UserApi'
import { PasswordStrength, ToolTip } from 'hub-web-lib'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { getErrorMessage } from 'middlewares/ApiHelpers'

const ChangePassword = ({ userToken = '', updatePassword = () => { }, logOut = () => {} }) => {

    const [newPassword, setNewPassword] = useState('')
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    // const [confirmedPassword, setConfirmedPassword] = useState('')
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    // const isConfirmedPasswordMatch = newPassword === confirmedPassword

    const changePassword = async () => {
        setErrorMsg('')
        setIsButtonLoading(true)
        const isPasswordChanged = await UserApi.changeUserPassword({ new_pwd: newPassword, user_token: userToken })
        if (!isPasswordChanged || isPasswordChanged.error || isPasswordChanged.data?.error) {
            const message = getErrorMessage({ response: isPasswordChanged })
            setErrorMsg(message)
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Your password has been changed successfully! You are now being redirected to the login page...' }))
        setTimeout(() => {
            // UserApi.clearCurrentUserCookies()
            updatePassword()
            setIsButtonLoading(false)
            logOut()
        }, 2000)
    }

    const tooltipDetails = {
        title: 'How to make a strong password:',
        description: <><p>At least 12 characters long but 14 or more is better.</p>
            <p>A combination of uppercase letters, lowercase letters, numbers, and symbols.</p>
            <p>Not a word that can be found in a dictionary or the name of a person, character, product, or organization.</p>
            <p>No repeating characters and sequential keyboard patterns.</p></>,
        isHiddenNextButton: true
    }

    return (
        <section className='change-password'>
            <div className='header-label'>
                <h3 id={`${userToken ? 'choose-new' : 'change'}-password`}>{userToken ? 'Choose a new' : 'Change'} Password</h3>
                <div className='guidelines'>
                    <i className='icon-information-outline' id='show-password-guidelines'></i>
                    <ToolTip tooltipDetails={tooltipDetails} />
                </div>
            </div>
            <div className='form'>
                <PasswordStrength
                    details={{ title: 'New Password', inputId: 'newPassword' }}
                    password={newPassword}
                    updatePassword={setNewPassword}
                    updatePasswordIsValid={setIsPasswordValid}
                    errorMsg={errorMsg} />
                {/* <InputRow 
                    title='Confirm Password'
                    inputId='confirmedPassword'
                    inputType='password'
                    layout='vertical'
                    defaultValue={confirmedPassword}
                    changeValueHandler={setConfirmedPassword} /> */}
                {/* <p className='password-match'>
                    {newPassword && confirmedPassword ? isConfirmedPasswordMatch ? <><i className='icon-check'></i> Passwords Match</> : <><i className='icon-remove'></i> Passwords Mismatch</> : <></>}
                </p> */}
                <div className='form-buttons'>
                    <button className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} onClick={changePassword} disabled={!isPasswordValid} id='save-new-password'>Save New Password</button>
                </div>
            </div>
        </section>)
}

export default ChangePassword