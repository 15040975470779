const redTeamModel = {
    id: 0,
    org_id: 0,
    title: '', 
    start_date: '',
    end_date: '',
    is_complete: false,
    description: '',
    reported_by: 0,
    archived: false,
    starred: false,
    has_findings: false
}

export { 
    redTeamModel
}