import React from 'react'
import './NoDataPlaceholder.scss'

const NoDataPlaceholder = () => {
    return (
        <div className='no-data-message'>No Data</div>
    )
}

export default NoDataPlaceholder
