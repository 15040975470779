import { OnboardingTour } from 'hub-web-lib/dist/index-export'
import { tenantDashboardOnboardingDetails, tenantDashboardOnboardingTourSteps } from 'js/constants/guest-onboarding'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import './TenantOnboarding.scss'
import UserApi from 'services/UserApi'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const TenantOnboardingComponent = ({ currentUser }) => {
    const [isOpenTour, setIsOpenTour] = useState(false)
    const isTenantOnboardingFinished = UserApi.getUserProp('isTenantOnboardingFinished', currentUser?.id)
    const [isTourFinished, setIsTourFinished] = useState(isTenantOnboardingFinished)

    const onCloseTour = () => {
        setIsOpenTour(false)
        setIsTourFinished(true)
        UserApi.setUserPropCookie('isTenantOnboardingFinished', true, currentUser.id, true)
    }
    return (
        isTourFinished || <div className='tanent-dashboard-onboarding'>
            <div className='start-onboarding'>
                Ready to start your cyber assessment?
                <button className='btn-link' onClick={() => setIsOpenTour(true)} id='start-tour'>Start Tour</button>
            </div>
            {isOpenTour &&<OnboardingTour
                steps={tenantDashboardOnboardingTourSteps}
                parentStepDetails={tenantDashboardOnboardingDetails}
                onCloseTour={onCloseTour} />}
        </div>
    )
}

const TenantOnboarding = connect(mapStateToProps)(TenantOnboardingComponent)
export default TenantOnboarding
