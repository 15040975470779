import { DOMAIN_RESPONSE, DOMAIN_RISK_BY_CATEGORY, DOMAIN_RISK_LEVEL, LIKELIHOOD, RESPONSE, RISK_BY_CATEGORY, RISK_LEVEL, RISK_MATRIX, RISK_RESPONSE, RISK_STATUS } from 'js/constants/metrics/risk-assessment-metrics'
import { getMetricByProps, getMetricsByProps } from './metrics'
import { BY_CATEGORY, CRITICAL_PROP, INFOMATIONAL_PROP, KEY, OTHER_PROP, PIE, WIDGET } from 'js/constants/metrics/common-metrics'
import { matrixTableColors, riskAssessmentActivityTemplateOuterHeaders } from 'js/constants/enums/risk-assessment'
import { helpers } from 'hub-web-lib/dist/index-export'
import { IMPACT_DIMENSION, LIKELIHOOD_DIMENSION, RISK_DIMENSION, dashboardSettingsDefault } from 'js/constants/dashboard-settings'
import { getFilteredMetricsProps } from './filter'

const riskLevelChartDetailsDefault = {
    chartId: 'risk-level',
    direction: 'vertical',
    size: 'large',
    isDonat: true,
    isShownLabelsValues: true,
}

const riskStatusValues = [{
    id: 'in process',
    color: '#A975D6'
}, {
    id: 'pending',
    color: '#9852d9'
}, {
    id: 'approval',
    color: '#8739c9'
}, {
    id: 'denied',
    color: '#E02A2B'
}]

const riskStatusChartDataDefault = {
    details: {
        chartId: 'risk-status-chart',
        colorValues: [
            '#A975D6', 
            '#9852d9',
            '#8739c9', 
            '#E02A2B'
        ]
    },
    columns: [],
    rows: []
}

const riskByCategoryChartDetailsDefault = {
    direction: 'vertical',
    size: 'large',
    isDonat: true,
    isShownLabelsValues: true,
    colorValues: [
        '#E055E2',
        '#893DE7',
        '#686DF4',
        '#3892F3',
        '#34C5E8',
        '#4aebe2',
        '#4ae881',
        '#bced68',
        '#eae54b',
        '#ffc823',
        '#ff8223',
    ]
}

const riskResponseValues = [{
    id: 'transfer',
    color: '#65D283'
}, {
    id: 'accept',
    color: '#ffb637'
}, {
    id: 'mitigate',
    color: '#FF8B37'
}, {
    id: 'avoid',
    color: '#FF4A4A'
}]

const getRiskLevelChartData = (metricsData, metricsProps, filter, widget) => {
    const riskLevel = getMetricByProps(metricsData, widget ? DOMAIN_RISK_LEVEL : RISK_LEVEL, widget ? widget : PIE)
    if (!riskLevel) return { riskLevelChartData: [], riskLevelChartDetails: {} }
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const riskLevelChartData = filteredProps.map(v => ({ 
        name: v.name, 
        value: (riskLevel[v.id] || 0) * 1, 
        color: v.color 
    }))
    const highestLevelWithValue = riskLevelChartData.find(row => row.value) || riskLevelChartData[0]
    const centeredValue = riskLevelChartData?.length ? {
        ...highestLevelWithValue,
        title: highestLevelWithValue.name
    } : {}

    const riskLevelChartDetails = {
        ...riskLevelChartDetailsDefault,
        chartId: `${riskLevelChartDetailsDefault.chartId}-${widget ? helpers.transformToKebab(widget) : 'total'}`,
        size: widget ? 'medium' : 'large',
        colorValues: riskLevelChartData.map(s => s.color),
        centeredValue
    }
    return { riskLevelChartData, riskLevelChartDetails }
}

const sortByNameOrder = (a, b, settingsDefault) => {
    const orderA = settingsDefault.find(item => helpers.locaseString(item.name) === helpers.locaseString(a.name))?.order || 0
    const orderB = settingsDefault.find(item => helpers.locaseString(item.name) === helpers.locaseString(b.name))?.order || 0
    return orderA - orderB
}

const getRiskMatrixTableData = (metricsData, metricsProps, tempateType) => {
    const riskMatrix = getMetricsByProps(metricsData, RISK_MATRIX, LIKELIHOOD)

    const settingsDefault = dashboardSettingsDefault.map((s, i) => ({ name: s.key, order: i + 1 }))

    const riskMatrixSorted = riskMatrix.map(m => ({ ...m, name: m[KEY] })).sort((a, b) => sortByNameOrder(a, b, settingsDefault))
    let riskMatrixTableData = []
    if (tempateType === 1) {
        const columnsPropsImpact =  metricsProps[IMPACT_DIMENSION].filter(p => ![INFOMATIONAL_PROP, CRITICAL_PROP].includes(p.id))
        const criticalColor = metricsProps[RISK_DIMENSION].find(s => s.id === CRITICAL_PROP)?.color
        const criticalValue = riskMatrixSorted.length && riskMatrixSorted[riskMatrixSorted.length - 1][CRITICAL_PROP]
        riskMatrixTableData = columnsPropsImpact.map((p) => { 
            const columnsData = {}
            riskMatrixSorted.forEach((m, i) => {
                const colorKey = matrixTableColors[1][p.id][m[KEY]]
                const color = metricsProps[RISK_DIMENSION].find(s => s.id === colorKey)?.color
                if (i < 3) { 
                    const columnName = metricsProps[LIKELIHOOD_DIMENSION].find(s => s.id === m[KEY])?.name || m[KEY]
                    columnsData[columnName] = {
                        color,
                        value: m[p.id]
                    } 
                }
            })
            return {
                name: {
                    value: p.name
                },
                ...columnsData
            }
        })

        // NOTE: for critical prop
        if (criticalValue) {
            const lastRow = riskMatrixTableData[riskMatrixTableData.length - 1]
            const highKey = riskMatrixSorted[2][KEY]
            riskMatrixTableData[riskMatrixTableData.length - 1][highKey] = {
                ...lastRow[highKey],
                secondValue: criticalValue,
                secondColor: criticalColor
            }
        }

    }

    if (tempateType === 2) {
        const columnsPropsImpact =  metricsProps[IMPACT_DIMENSION].filter(p => p.id !== CRITICAL_PROP)
        riskMatrixTableData = [...riskMatrixSorted].reverse().map(m => {
            const columnsData = {}
            columnsPropsImpact.forEach(p => {
                const colorKey = matrixTableColors[2][p.id][m[KEY]]
                const color = metricsProps[RISK_DIMENSION].find(s => s.id === colorKey)?.color
                columnsData[p.name] = {
                    color,
                    value: m[p.id]
                }
            })
            const rowName = metricsProps[LIKELIHOOD_DIMENSION].find(s => s.id === m[KEY])?.name || m[KEY]
            return { 
                name: {
                    value: rowName
                },
                ...columnsData
            }
        })
    }
    const riskMatrixOuterHeaders = riskAssessmentActivityTemplateOuterHeaders[tempateType]
    return { riskMatrixTableData, riskMatrixOuterHeaders }

}

const getRiskStatusChartData = (metricsData) => {

    const riskStatus = getMetricsByProps(metricsData, RISK_STATUS, RISK_STATUS)
    const settingsDefault = riskStatusValues.map((v, i) => ({ name: v.id, order: i + 1 }))
    const riskStatusSorted = riskStatus.map(m => ({ ...m, name: m[KEY] })).sort((a, b) => sortByNameOrder(a, b, settingsDefault))
    const row = {
        label: RISK_STATUS
    }
    // special condition for denied
    if (riskStatusSorted?.length && helpers.locaseString(riskStatusSorted[riskStatusSorted.length - 1][KEY] || '') !== 'denied') riskStatusSorted.push({ ...riskStatusSorted[riskStatusSorted.length - 1], key: 'denied', other: 0, name: 'denied' })
    riskStatusSorted.forEach(m => {
        row[m[KEY]] = m[OTHER_PROP]
    })
    const riskStatusChartData = {
        ...riskStatusChartDataDefault,
        details: {
            ...riskStatusChartDataDefault.details,
            colorValues: riskStatusSorted.map(m => riskStatusValues.find(v => v.id === helpers.locaseString(m[KEY]))?.color)
        },
        columns: riskStatusSorted.map(m => ({ label: m[KEY] })),
        rows: riskStatusSorted?.length ? [row] : []
    }

    return riskStatusChartData
}

const getRiskByCategoryChartData = (metricsData, widget) => {
    const riskByCategory = getMetricsByProps(metricsData, widget ? DOMAIN_RISK_BY_CATEGORY : RISK_BY_CATEGORY, widget ? widget : BY_CATEGORY)
    const riskByCategoryChartData = riskByCategory.map(m => ({
        name: m[KEY],
        value: m[OTHER_PROP]
    }))
    const riskByCategoryChartDetails = {
        ...riskByCategoryChartDetailsDefault,
        chartId: `risk-by-category-${widget ? helpers.transformToKebab(widget) : 'total'}`,
        size: widget ? 'medium' : 'large',
        centeredValue: {
            title: 'Risks',
            value: riskByCategoryChartData?.length ? riskByCategoryChartData.map(r => r.value).reduce((a, b) => a + b) : 0
        }
    }
    return { riskByCategoryChartData, riskByCategoryChartDetails }
}

const getRiskResponseChartData = (metricsData, widget) => {
    const riskByCategory = getMetricsByProps(metricsData, widget ? DOMAIN_RESPONSE : RISK_RESPONSE, widget ? widget : RESPONSE)
    const settingsDefault = riskResponseValues.map((v, i) => ({ name: v.id, order: i + 1 }))
    const riskResponseChartData = riskByCategory
        .map(m => ({
            name: helpers.capitalizeString(m[KEY]),
            value: m[OTHER_PROP]
        }))
        .sort((a, b) => sortByNameOrder(a, b, settingsDefault))
    const colorValues = riskResponseChartData.map(m => riskResponseValues.find(v => v.id === helpers.locaseString(m.name))?.color)
    const riskResponseChartDetails = {
        ...riskByCategoryChartDetailsDefault,
        colorValues,
        chartId: `risk-response-${widget ? helpers.transformToKebab(widget) : 'total'}`,
        size: widget ? 'medium' : 'large',
        centeredValue: {
            title: 'Total',
            value: riskResponseChartData?.length ? riskResponseChartData.map(r => r.value).reduce((a, b) => a + b) : 0
        }
    }
    return { riskResponseChartData, riskResponseChartDetails }
}

const getRiskAssessmentDomains = (metricsData) => {
    const domainRiskLevel = getMetricsByProps(metricsData, DOMAIN_RISK_LEVEL)
    return domainRiskLevel.map(m => m[WIDGET])
}

const getRiskLevelChartDataImportant = (metricsData, metricsProps) => {
    const riskLevel = getMetricByProps(metricsData, RISK_LEVEL, PIE)
    if (!riskLevel) return []
    const riskLevelValues = metricsProps[RISK_DIMENSION].map(v => ({ 
        label: v.name, 
        value: (riskLevel[v.id] || 0) * 1, 
        color: v.color 
    })).filter(r => r.value)
    return riskLevelValues.slice(0, 3)
}

export {
    getRiskLevelChartData,
    getRiskMatrixTableData,
    getRiskStatusChartData,
    getRiskByCategoryChartData,
    getRiskResponseChartData,
    getRiskAssessmentDomains,
    getRiskLevelChartDataImportant
}