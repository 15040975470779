import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, InfoCard, Loader, SortableTable } from 'hub-web-lib'
import { ASSESSMENT } from 'js/constants/vocabulary'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import QuestionnaireApi from 'services/QuestionnaireApi'
import moment from 'moment'
import QAApi from 'services/QAApi'
import { assessmentDomainsColumns } from 'js/constants/table-columns'
import { getFilteredQuestionnaire, isAnsweredQuestion } from 'js/ui-helpers/questionnaire'
import { connect } from 'react-redux'
import AssessmentApi from 'services/AssessmentApi'
import AssessmentQuestionAndAnswer from 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentQuestionAndAnswer'
import './AssessmentInfo.scss'
import AssessmentQuestionsCategoryName from 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentQuestionsCategoryName'
import { assessmentStatuses } from 'js/constants/enums/questionnaire'
import BackButton from 'components/Assets/BackButton/BackButton'

const assessmentInfoDefault = {
    id: {
        title: `${ASSESSMENT} ID`
    },
    date: {
        title: 'Created Date'
    },
    questionnaire: {
        title: 'Questionnaire Template'
    },
    questionnaireVersion: {
        title: 'Questionnaire Version'
    },
    activity: {
        title: 'Activity'
    },
    totalQuestions: {
        title: 'Total Questions'
    },
    answeredQuestions: {
        title: 'Total Answered Questions'
    },
    status: {
        title: 'Status'
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}


const AssessmentInfoComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentAssessment, setCurrentAssessment] = useState({})
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState({})
    const [currentActivity, setCurrentActivity] = useState({})
    const currentAssessmentTitle = `${currentAssessment.id || ''} ${currentAssessment.description || ''}`
    const answers = currentAssessment.assessment_results || []

    const getAssessmentInfo = async () => {
        const assessmentId = (location?.pathname || '').split('/')[3]*1
        const assessment = await AssessmentApi.getAssessmentResults(assessmentId, currentOrgId)
        if (!assessment || assessment.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: assessment.error, notificationType: 'error' }))
            return
        }  
        const questionnaire = await QuestionnaireApi.getQuestionnaireTemplateFull(assessment.template_id, currentOrgId)
        if (!questionnaire || questionnaire.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: questionnaire.error, notificationType: 'error' }))
            return
        }  else {
            const filteredQuestionnaire = getFilteredQuestionnaire(questionnaire)
            setCurrentQuestionnaire(filteredQuestionnaire)
        } 
        const activity = await QAApi.getActivity(assessment.qa_activity_id)
        if (!activity || activity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: activity.error, notificationType: 'error' }))
            return
        }  else {
            setCurrentActivity(activity)
        }
        setCurrentAssessment(assessment)
    }

    const getCardData = () => {
        const data = { ...assessmentInfoDefault }
        data.id.value = currentAssessment.id || '-'
        data.date.value = moment(currentAssessment.creation_date).format('DD/MM/YY')
        data.questionnaire.value = currentQuestionnaire.name || '-'
        data.questionnaireVersion.value = currentQuestionnaire.version || '-'
        data.activity.value = currentActivity.description
        const allQuestions = (currentQuestionnaire.domains || [])
            .map(d => (d.categories || []))
            .flat()
            .map(c => (c.questions || []))
            .flat()
            .map(q => ({ ...q, ...isAnsweredQuestion(answers, q.id) }))
        data.totalQuestions.value = allQuestions.length
        data.answeredQuestions.value = allQuestions.filter(q => q.isAnswered).length
        const statusObject = assessmentStatuses.find(s => s.id === currentAssessment.status) || {}
        data.status.value = statusObject.statusValue
        return data
    }

    const getDomainsTableData = () => {
        const data = (currentQuestionnaire.domains || []).map((d => {
            const categories = d.categories || []
            const allQuestions = categories
                .map(c => (c.questions || []))
                .flat()
                .map(q => ({ ...q, ...isAnsweredQuestion(answers, q.id) }))
            const totalQuestions = allQuestions.length
            const answeredQuestions = allQuestions.filter(q => q.isAnswered).length
            const totalRequiredQuestions = allQuestions.filter(q => q.is_required).length
            const answeredRequiredQuestions = allQuestions.filter(q => q.is_required && q.isAnswered).length
            return {
                id: d.id,
                name: d.name,
                categoriesCount: categories.length,
                totalQuestions,
                answeredQuestions,
                totalRequiredQuestions,
                answeredRequiredQuestions
            }
        }))
        return data
    }

    const cardData = getCardData()
    const domainsTableData = getDomainsTableData()


    useEffect( async () => {
        await getAssessmentInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content'>
            <div className='page-header'>
                <BackButton />
                <h1 id='qa-assessment-info'>{ASSESSMENT} {currentAssessmentTitle} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentAssessment.id ? <div className='qa-assessment-info-con info-card-con'>
                <InfoCard cardData={cardData} />
                <h2 id='assessment-domains'>Domains</h2>
                <SortableTable columns={assessmentDomainsColumns} tableData={domainsTableData} tableId='assessment-info-domains-table' />
                <h2 id='assessment-answers'>Answers</h2>
                <ul className='assessment-answers'>
                    {(currentQuestionnaire.domains || []).map(d => <li key={d.id}>
                        <h3 className='domain-name'>{d.name}</h3>
                        <ul>
                            {(d.categories || []).map(c => <li key={c.id}>
                                <AssessmentQuestionsCategoryName category={c} />
                                <ul>
                                    {(c.questions || []).map((q, qi) => 
                                        <AssessmentQuestionAndAnswer key={q.id}
                                            question={q} 
                                            answers={answers}
                                            questionNumber={qi + 1} />
                                    )}
                                </ul>
                            </li>)}
                        </ul>
                    </li>)}
                </ul>
            </div> : <Alert message={`No data for this ${ASSESSMENT}`} />}
        </div>
    )
}


const AssessmentInfo = connect(mapStateToProps)(AssessmentInfoComponent)

export default AssessmentInfo