import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, InfoCard, Loader, genericTexts, SortableTable } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import { connect } from 'react-redux'
import './RiskAssessmentActivityInfo.scss'
import RiskAssessmentApi from 'services/RiskAssessmentApi'
import { getActivityInfoMetricsColumns, getActivityInfoMetricsTableData, getActivityMetricsValuesProps } from 'js/ui-helpers/metrics'
import { activityCardInfoDefault } from 'js/constants/enums/activity'
import { getActivityInfoCardData, getSelectedActivityTitle } from 'js/ui-helpers/activity'
import { RISK_ASSESSMENT_MODULE } from 'js/constants/app-modules'
import BackButton from 'components/Assets/BackButton/BackButton'
import { LIKELIHOOD_DIMENSION, RISK_DIMENSION } from 'js/constants/dashboard-settings'
import { KEY, WIDGET } from 'js/constants/metrics/common-metrics'
import { LIKELIHOOD } from 'js/constants/metrics/risk-assessment-metrics'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        dashboardSettings: state.dashboardSettings,
        currentUserOrgs: state.currentUserOrgs
    }
}

const RiskAssessmentActivityInfoComponent = ({ currentUser, dashboardSettings, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentActivityInfo, setCurrentActivityInfo] = useState({})
    const currentActivityTitle = getSelectedActivityTitle(currentActivityInfo)
    const riskAssessmentValuesProps = getActivityMetricsValuesProps(dashboardSettings, RISK_ASSESSMENT_MODULE, RISK_DIMENSION)
    const riskAssessmentLikelihoodProps = getActivityMetricsValuesProps(dashboardSettings, RISK_ASSESSMENT_MODULE, LIKELIHOOD_DIMENSION)

    const getActivityInfo = async () => {
        let info = location?.state?.activity
        if (info) {
            setCurrentActivityInfo(info)
            return
        }
        const activityId = (location?.pathname || '').split('/')[3]*1
        const activity = await RiskAssessmentApi.getActivity(activityId)
        if (!activity || activity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(ACTIVITY), notificationType: 'error' }))
            return
        }  
        activity.metrics = await RiskAssessmentApi.getActivityMetrics(activityId)
        if (!activity.metrics || activity.metrics.error) activity.metrics = []
        const org = currentUserOrgs.find(o => o.id === currentOrgId) || { id: currentOrgId }
        activity.organization = org?.name || currentOrgId
        setCurrentActivityInfo(activity)
    }

    const getCardData = () => {
        const data = getActivityInfoCardData(activityCardInfoDefault, currentActivityInfo)
        return data
    }

    const getTableData = () => {
        let data = getActivityInfoMetricsTableData(currentActivityInfo, riskAssessmentValuesProps)
        data = data.map(metric => ({
            ...metric,
            [KEY]: metric[WIDGET] === LIKELIHOOD ? riskAssessmentLikelihoodProps.find(p => p.id === metric[KEY])?.name || metric[KEY] : metric[KEY]
        }))
        return data
    }

    const cardData = getCardData()
    const metricsTableData = getTableData()
    const metricsTableColumns = getActivityInfoMetricsColumns(riskAssessmentValuesProps)


    useEffect( async () => {
        await getActivityInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content ra-info'>
            <div className='page-header'>
                <BackButton />
                <h1 id='risk-assessment-activity-info'>{ACTIVITY} {currentActivityTitle} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentActivityInfo.id ? <div className='report-info-con info-card-con'>
                <InfoCard cardData={cardData} />
                <h2 id='risk-assessment-mertics'>Metrics</h2>
                <SortableTable columns={metricsTableColumns} tableData={metricsTableData} itemsPerPage={0} tableId='risk-assessment-activity-info-metrics-table' />
            </div> : <Alert message={`No data for this ${ACTIVITY}`} />}
        </div>
    )
}
const RiskAssessmentActivityInfo = connect(mapStateToProps)(RiskAssessmentActivityInfoComponent)
export default RiskAssessmentActivityInfo