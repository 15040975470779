import { ArrowBarChart, MatrixTable, PieChartCustom, Tabs, WidgetBlock } from 'hub-web-lib'
import React, { useEffect, useState } from 'react'
import './RiskAssessment.scss'
import { connect } from 'react-redux'
import { getRiskAssessmentDomains, getRiskByCategoryChartData, getRiskLevelChartData, getRiskMatrixTableData, getRiskResponseChartData, getRiskStatusChartData } from 'js/ui-helpers/risk-assessment-dashboard'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { RISK_BY_CATEGORY, RISK_LEVEL, RISK_MATRIX, RISK_RESPONSE, RISK_STATUS } from 'js/constants/metrics/risk-assessment-metrics'


const mapStateToProps = state => {
    return {
        riskMatrixTemplate: state.riskMatrixTemplate
    }
}

const RiskAssessmentComponent = ({ metricsData, metricsProps, filter, metricsPropsOrdered, riskMatrixTemplate, isAnimationActive }) => {

    const [activeTab, setActiveTab] = useState(0)
    const [charts, setCharts] = useState({})

    const selectedActivityTemplateType = riskMatrixTemplate?.value || 1
    
    useEffect(() => {
        const { riskLevelChartData, riskLevelChartDetails } = getRiskLevelChartData(metricsData, metricsProps, filter)
        const { riskMatrixTableData, riskMatrixOuterHeaders } = getRiskMatrixTableData(metricsData, metricsPropsOrdered, selectedActivityTemplateType)
        const riskStatusChartData = getRiskStatusChartData(metricsData)
        const { riskByCategoryChartData, riskByCategoryChartDetails } = getRiskByCategoryChartData(metricsData)
        const { riskResponseChartData, riskResponseChartDetails } = getRiskResponseChartData(metricsData)
        const domains = getRiskAssessmentDomains(metricsData)
        const domainsData = {}
        domains.forEach(widget => {
            const { riskLevelChartData, riskLevelChartDetails } = getRiskLevelChartData(metricsData, metricsProps, filter, widget)
            const { riskByCategoryChartData, riskByCategoryChartDetails } = getRiskByCategoryChartData(metricsData, widget)
            const { riskResponseChartData, riskResponseChartDetails } = getRiskResponseChartData(metricsData, widget)
            domainsData[widget] = {
                riskLevelChartData,
                riskLevelChartDetails,
                riskByCategoryChartData,
                riskByCategoryChartDetails,
                riskResponseChartData,
                riskResponseChartDetails
            }
        })
        setCharts({
            riskLevelChartData,
            riskLevelChartDetails,
            riskMatrixTableData,
            riskMatrixOuterHeaders,
            riskStatusChartData,
            riskByCategoryChartData,
            riskByCategoryChartDetails,
            riskResponseChartData,
            riskResponseChartDetails,
            domains,
            ...domainsData
        })
    }, [])

    const riskAssessmentTabs = (charts?.domains || []).map(label => ({ label, contentComponent: () => <></> }))

    const RiskAssessmentDomain = ({ data }) => {
        const { widget } = data
        return <div className='risk-assessment-domain'>
            <div className='module-widgets-row'>
                <WidgetBlock title={`${RISK_LEVEL}`} content={charts?.[widget]?.riskLevelChartData?.length ? 
                    <PieChartCustom chartData={charts[widget].riskLevelChartData} chartDetails={{ ...charts[widget].riskLevelChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
                <WidgetBlock title={`${RISK_BY_CATEGORY}`} content={charts?.[widget]?.riskByCategoryChartData?.length ? 
                    <PieChartCustom chartData={charts[widget].riskByCategoryChartData} chartDetails={{ ...charts[widget].riskByCategoryChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
                <WidgetBlock title={`${RISK_RESPONSE}`} content={charts?.[widget]?.riskResponseChartData?.length ? 
                    <PieChartCustom chartData={charts[widget].riskResponseChartData} chartDetails={{ ...charts[widget].riskResponseChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
            </div>
        </div>
    }

    return (
        <>
            <div className='module-widgets-row risk-charts'>
                <WidgetBlock title={`${RISK_LEVEL}`} content={charts.riskLevelChartData?.length ? 
                    <PieChartCustom chartData={charts.riskLevelChartData} chartDetails={{ ...charts.riskLevelChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
                <WidgetBlock title={`${RISK_MATRIX}`} content={charts.riskMatrixTableData?.length ? 
                    <MatrixTable tableData={charts.riskMatrixTableData} outerHeaders={charts.riskMatrixOuterHeaders} tableId='risk-matrix' /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row risk-status'>
                <WidgetBlock title={`${RISK_STATUS}`} content={charts.riskStatusChartData?.rows?.length ? 
                    <ArrowBarChart chartData={charts.riskStatusChartData} /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row pie-charts'>
                <WidgetBlock title={`${RISK_BY_CATEGORY}`} content={charts.riskByCategoryChartData?.length ? 
                    <PieChartCustom chartData={charts.riskByCategoryChartData} chartDetails={{ ...charts.riskByCategoryChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
                <WidgetBlock title={`${RISK_RESPONSE}`} content={charts.riskResponseChartData?.length ? 
                    <PieChartCustom chartData={charts.riskResponseChartData} chartDetails={{ ...charts.riskResponseChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='domains'>
                <Tabs tabs={riskAssessmentTabs}
                    handleActiveTab={setActiveTab} />
                {(charts.domains || []).map((widget, i) => <div className={`tab-content-custom ${i === activeTab ? 'show' : ''}`} key={i}>
                    <h3 className='print-title'>{widget}</h3>
                    <RiskAssessmentDomain data={{ widget }} />
                </div>)}
            </div>
        </>
    )
}

const RiskAssessment = connect(mapStateToProps)(RiskAssessmentComponent)

export default RiskAssessment