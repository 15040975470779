const roles = [{
    id: 1,
    name: 'admin'
}, {
    id: 2,
    name: 'analyst'
}, {
    id: 3,
    name: 'assignee'
}
, {
    id: 4,
    name: 'viewer'
}]

const statuses = [{
    id: 1,
    name: 'active'
}, {
    id: 2,
    name: 'inactive'
}, {
    id: 3,
    name: 'locked'
}]

const otpStatuses = [{
    id: 1,
    name: 'initial'
}, {
    id: 2,
    name: 'enabled'
}, {
    id: 3,
    name: 'locked'
}, {
    id: 4,
    name: 'ignored'
}]

const loginModes = {
    login: 0,
    getOtp: 1,
    setupOtp: 2,
    resetPassword: 3,
    afterResetPassword: 4
}

export {
    roles,
    statuses,
    otpStatuses,
    loginModes
}