import React from 'react'
import '../Settings.scss'
import { Link } from 'react-router-dom'

const ApiAccessPoint = () => {

    const accessPoint = process.env.REACT_APP_API_ACCESS_POINT
    return (
        <section className='access-point'>
            <h3 id='api-access-point-url'>API Access Point URL</h3>
            <p><Link to={accessPoint} id='open-access-point'>{accessPoint}</Link></p>
        </section>)
}

export default ApiAccessPoint