import React from 'react'
import { helpers } from 'hub-web-lib/dist/index-export'
import { CYBER_READINESS_MODULE, RISK_ASSESSMENT_MODULE, disabledModulesDefault } from 'js/constants/app-modules'
import { ALIAS_ERROR, ALL_DIMENSIONS, ALL_MODULES, IMPACT_DIMENSION, LIKELIHOOD_DIMENSION, RISK_DIMENSION, SEVERITY_DIMENSION, dashboardAllModules, dashboardDimensions, dashboardSettingsDefault } from 'js/constants/dashboard-settings'
import { KEY } from 'js/constants/metrics/common-metrics'
import { template2Aliases } from 'js/constants/enums/risk-assessment'

const getEnabledSettingItems = (settings) => {
    const settingsObject = {}
    settings.map(v => settingsObject[v.key] = v.value)
    return settingsObject
}

const getSortedFeatures = (settings) => {
    const features = settings.map(a => ({ id: a.key, name: a.label, isShown: a.value_type === 'boolean' ? a.value : false, order: a.id }))
    features.sort((a, b) => (a.isShown === b.isShown) ? a.order - b.order : a.isShown ? -1 : 1)
    return features
}

const getAllModulesDashboard = visibleModules => {
    const all = visibleModules.map(a => ({ id: a.key, name: a.label }))
        .filter(m => m.id !== 'pt' && m.id !== CYBER_READINESS_MODULE)
        .sort((a, b) => a.order - b.order)

    const existing = all.filter(m => !disabledModulesDefault.includes(m.id))
    const future = all.filter(m => disabledModulesDefault.includes(m.id))
    return [dashboardAllModules, ...existing, ...future]
}

const checkIsDashboardSettingsChanged = (currentDashboardSettings, dashboardSettings) => {
    let isChanged = false
    currentDashboardSettings.forEach(row => {
        const defaultRow = dashboardSettings.find(r => r.key === row.key)
        if (!helpers.isSameObject(row.value, defaultRow.value)) isChanged = true
    })
    return isChanged
}

const checkIsDashboardSettingsAllDefault = (currentDashboardSettings) => {
    const changedItems = []
    currentDashboardSettings.forEach(s => {
        const value = s.value
        const defaultRow = dashboardSettingsDefault.find(r => r.key === s.key)
        Object.keys(value).forEach(module => {
            Object.keys(value[module]).forEach(dimension => {
                if (!helpers.isSameObject(value[module][dimension], defaultRow.value[ALL_MODULES][ALL_DIMENSIONS])) {
                    changedItems.push({
                        label: s.label,
                        dimension,
                        module
                    })
                }
            })
        })
    })
    if (!changedItems.length) return null
    const changedItemsObject = {}
    changedItems.forEach(item => {
        changedItemsObject[item.module] = changedItemsObject[item.module] || {}
        changedItemsObject[item.module][item.dimension] = changedItemsObject[item.module][item.dimension] || []
        if (!changedItemsObject[item.module][item.dimension].includes(item.label)) changedItemsObject[item.module][item.dimension].push(item.label)
    })
    return changedItemsObject
}

const checkIsDashboardSettingsModuleDefault = (currentModule, currentDashboardSettings) => {
    if (currentModule === ALL_MODULES) return true
    let isDefault = true
    currentDashboardSettings.forEach(row => {
        if (row.value[currentModule]) isDefault = false
    })
    return isDefault
}

const validateAlias = (alias) => {
    return alias && alias.length <= 20 ? '' : ALIAS_ERROR
}

const getAliasError = (currentDashboardSettings, allModules) => {
    let error = ''
    const aliases = []
    currentDashboardSettings.forEach(s => {
        const value = s.value
        Object.keys(value).forEach(module => {
            Object.keys(value[module]).forEach(dimension => {
                aliases.push({
                    label: s.label,
                    alias: value[module][dimension].alias,
                    dimension,
                    module
                })
            })
        })
    })
    const aleasesErrors = aliases.filter(a => validateAlias(a.alias))
    if (aleasesErrors.length) {
        return <>
            <p>{ALIAS_ERROR}. Wrong aliases are in:</p>
            {aleasesErrors.map((e, i) => {
                const moduleName = getModuleName(e.module, allModules)
                const dimensionName = getDimensionName(e.dimension)
                return (
                    <p key={i}>- Module: {moduleName}, Dimension: {dimensionName}, Key: {e.label}</p>
                )
            })}
        </>
    }
    return error
}

const getModuleName = (module, allModules) => {
    return allModules.find(m => m.id === module)?.name || ''
}

const getDimensionName = (dimension) => {
    return dashboardDimensions.find(d => d.id === dimension)?.name || ''
}

const getCurrentPropValue = (key, prop, currentData) => {
    const { currentDashboardSettings, currentModule, currentDimension } = currentData
    const row = currentDashboardSettings.find(p => p.key === key)
    if (!row) return ''
    const rowModule = row.value[currentModule] || row.value[ALL_MODULES]
    const rowModuleDimension = rowModule[currentDimension] || rowModule[ALL_DIMENSIONS] || row.value[ALL_MODULES][ALL_DIMENSIONS]
    const value = rowModuleDimension[prop]
    const moduleToCompare = currentDimension === ALL_DIMENSIONS ? row.value[ALL_MODULES] : rowModule
    const dimensionToCompare = moduleToCompare[ALL_DIMENSIONS] || row.value[ALL_MODULES][ALL_DIMENSIONS]
    const isChanged = value !== dimensionToCompare[prop]
    return { value, isChanged }
}

const getSettingForResetKey = (key, currentData) => {
    const { currentDashboardSettings, currentModule, currentDimension } = currentData
    const updatedDashboardSettings = [...currentDashboardSettings]
    const rowIndex = updatedDashboardSettings.findIndex(r => r.key === key)
    delete updatedDashboardSettings[rowIndex].value[currentModule][currentDimension]
    if (!Object.keys(updatedDashboardSettings[rowIndex].value[currentModule])?.length) delete updatedDashboardSettings[rowIndex].value[currentModule]
    return updatedDashboardSettings
}

const getSettingsForResetModule = (currentData) => {
    const { currentDashboardSettings, currentModule } = currentData
    const updatedDashboardSettings = [...currentDashboardSettings]
    updatedDashboardSettings.forEach(row => {
        delete row.value[currentModule]
    })
    return updatedDashboardSettings
}

const getSettingsForResetAll = (currentData) => {
    const { currentDashboardSettings } = currentData
    const updatedDashboardSettings = [...currentDashboardSettings]
    updatedDashboardSettings.forEach(row => {
        const defaultRow = dashboardSettingsDefault.find(r => r.key === row.key)
        row.value = helpers.createNewInstance(defaultRow.value)
    })
    return updatedDashboardSettings
}

const getSettingsForChangedValue = (val, key, prop, currentData) => {
    const { currentDashboardSettings, currentModule, currentDimension } = currentData
    const updatedDashboardSettings = [...currentDashboardSettings]
    const defaultRow = dashboardSettingsDefault.find(r => r.key === key)
    const rowIndex = updatedDashboardSettings.findIndex(r => r.key === key)
    updatedDashboardSettings[rowIndex].value[currentModule] = updatedDashboardSettings[rowIndex].value[currentModule] || helpers.createNewInstance(defaultRow.value[ALL_MODULES])
    updatedDashboardSettings[rowIndex].value[currentModule][currentDimension] = updatedDashboardSettings[rowIndex].value[currentModule][currentDimension] || helpers.createNewInstance(defaultRow.value[ALL_MODULES][ALL_DIMENSIONS])
    updatedDashboardSettings[rowIndex].value[currentModule][currentDimension][prop] = val
    return updatedDashboardSettings
}

const changePropsTemplate1 = (s, dimension) => {
    if (s.value[RISK_ASSESSMENT_MODULE]?.[dimension]) {
        if (s.value[RISK_ASSESSMENT_MODULE][dimension].color === s.value[RISK_ASSESSMENT_MODULE][ALL_DIMENSIONS]?.color) {
            delete s.value[RISK_ASSESSMENT_MODULE][dimension]
        } else {
            s.value[RISK_ASSESSMENT_MODULE][dimension] = {
                ...s.value[RISK_ASSESSMENT_MODULE][dimension],
                alias: s.value[RISK_ASSESSMENT_MODULE][ALL_DIMENSIONS].alias
            }
        }
        if (s.value[RISK_ASSESSMENT_MODULE] && !Object.keys(s.value[RISK_ASSESSMENT_MODULE]).length) {
            delete s.value[RISK_ASSESSMENT_MODULE]
        }
    }
}

const changePropsTemplate2 = (s, dimension) => {
    if (template2Aliases[dimension][s[KEY]]) {
        const color = s.value[RISK_ASSESSMENT_MODULE]?.[dimension]?.color || s.value[ALL_MODULES]?.[dimension]?.color || s.value[ALL_MODULES][ALL_DIMENSIONS].color
        s.value[RISK_ASSESSMENT_MODULE][dimension] = {
            alias: template2Aliases[dimension][s[KEY]],
            color
        }
    }
}

const changeDashboardSettingByTemplate = (dashboardSettings, templateType) => {
    const updatedDashboardSettings = [...dashboardSettings]
    const dimensions = [SEVERITY_DIMENSION, RISK_DIMENSION, IMPACT_DIMENSION, LIKELIHOOD_DIMENSION]
    updatedDashboardSettings.forEach(s => {
        if (templateType === 2) {
            s.value[RISK_ASSESSMENT_MODULE] = s.value[RISK_ASSESSMENT_MODULE] || helpers.createNewInstance(s.value[ALL_MODULES])
            dimensions.forEach(d => changePropsTemplate2(s, d))
        } else {
            dimensions.forEach(d => changePropsTemplate1(s, d))
        }
    })
    return updatedDashboardSettings
}

export {
    getEnabledSettingItems,
    getSortedFeatures,
    getAllModulesDashboard,
    checkIsDashboardSettingsChanged,
    checkIsDashboardSettingsAllDefault,
    checkIsDashboardSettingsModuleDefault,
    validateAlias,
    getAliasError,
    getCurrentPropValue,
    getModuleName,
    getDimensionName,
    getSettingForResetKey,
    getSettingsForResetModule,
    getSettingsForResetAll,
    getSettingsForChangedValue,
    changeDashboardSettingByTemplate
}