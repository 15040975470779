const { 
    GENERAL,
    ACTIVE,
    BY_AGE,
    NEW_ALERTS,
    FINDINGS,
    YEARLY_MEAN_TIME,
    OPEN,
    ACKNOWLEDGE,
    MEAN_TIME_TO_ACKNOWLEDGE,
    MEAN_TIME_TO_RESOLVE,
    BY_SEVERITY,
    BY_TYPE,
    BY_MONTH,
    ALERTS_BY_SEVERITY,
    RESOLVED,
} = require('./common-metrics')

const easmMetricsCategories = [
    GENERAL,
    ACTIVE,
    BY_AGE,
    NEW_ALERTS,
    FINDINGS,
    YEARLY_MEAN_TIME
]

const easmMetricsWidgets = [
    ACTIVE,
    OPEN,
    ACKNOWLEDGE,
    MEAN_TIME_TO_ACKNOWLEDGE,
    MEAN_TIME_TO_RESOLVE,
    BY_SEVERITY,
    BY_TYPE,
    BY_AGE,
    BY_MONTH,
    ALERTS_BY_SEVERITY,
    RESOLVED
]

const easmMetricsKeys = [
    ACTIVE,
    OPEN,
    ACKNOWLEDGE,
    MEAN_TIME_TO_ACKNOWLEDGE,
    MEAN_TIME_TO_RESOLVE,
    BY_SEVERITY,
    'Email Secuity issue',
    'Exploitable Ports',
    'Other',
    '0-7 d',
    '8-14 d',
    '15-31 d',
    '31 d <',
    'Q2 23',
    'Q1 23',
    'Q1 22'
]

export {
    easmMetricsCategories,
    easmMetricsWidgets,
    easmMetricsKeys,
}