const questionnaireAssessmentModel = {
    org_id: 0,
    template_id: 0, 
    qa_activity_id: 0,
    filled_by: '',
    status: 0,
    mail_to: '',
    remarks: ''
}

const questionnaireAssessmentResultModel = {
    possible_answers_id: null,
    text: '',
    number: null,
    multi_choice: []
}

export {
    questionnaireAssessmentModel,
    questionnaireAssessmentResultModel
}