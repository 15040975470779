const industriesList = [{
    id: 1,
    name: 'Aerospace'
}, {
    id: 2,
    name: 'Transportation'
}, {
    id: 3,
    name: 'Computer'
}, {
    id: 4,
    name: 'Telecommunication'
}, {
    id: 5,
    name: 'Agriculture'
}, {
    id: 6,
    name: 'Construction'
}, {
    id: 7,
    name: 'Education'
}, {
    id: 8,
    name: 'Pharmaceutical'
}, {
    id: 9,
    name: 'Finance'
}, {
    id: 10,
    name: 'Insurance'
}, {
    id: 11,
    name: 'Food & Hospitality'
}, {
    id: 12,
    name: 'Real Estate & Housing'
}, {
    id: 13,
    name: 'Legal'
}, {
    id: 14,
    name: 'Motor Vehicle'
}, {
    id: 15,
    name: 'Environmental'
}, {
    id: 16,
    name: 'Gaming'
}, {
    id: 17,
    name: 'Health care'
}, {
    id: 18,
    name: 'Entertainment'
}, {
    id: 19,
    name: 'News media'
}, {
    id: 20,
    name: 'Energy'
}, {
    id: 21,
    name: 'Manufacturing'
}, {
    id: 22,
    name: 'Music'
}, {
    id: 23,
    name: 'Mining'
}, {
    id: 24,
    name: 'Retail'
}, {
    id: 25,
    name: 'Other'
}, {
    id: 26,
    name: 'Personal services'
}, {
    id: 27,
    name: 'Aviation'
}, {
    id: 28,
    name: 'Cybersecurity'
}]

const licenseTypes = [{
    id: 1,
    name: 'basic'
}, {
    id: 2,
    name: 'advanced'
}, {
    id: 3,
    name: 'premium'
}, {
    id: 4,
    name: 'unlimited'
}]

const sizes = [{
    id: 1,
    name: 'small'
}, {
    id: 2,
    name: 'medium'
}, {
    id: 3,
    name: 'big'
}, {
    id: 4,
    name: 'large'
}]

const orgTypes = [{
    id: 1,
    name: 'production'
}, {
    id: 2,
    name: 'poc'
}, {
    id: 3,
    name: 'demo'
}, {
    id: 4,
    name: 'internal'
}]

export {
    industriesList,
    licenseTypes,
    sizes,
    orgTypes
}