import React, { useEffect, useState } from 'react'
import './DashboardActivityGeneric.scss'
import 'scss/Dashboards/DashboardPageHeader.scss'
import 'scss/Dashboards/DashboardPlaceholder.scss'
import { Alert, InputRow, Loader, helpers } from 'hub-web-lib/dist/index-export'
import { getSelectedActivityDetails } from 'js/ui-helpers/activity'
import { START_FILLING_TEXT } from 'js/constants/enums/home-dashboard-demo'
import { DASHBOARD, DASHBOARD_PATH, appModulesNames, appModulesPaths } from 'js/constants/app-modules'
import ActivityGantt from '../ActivityGantt/ActivityGantt'
import { getSelectedActivityYear } from 'js/ui-helpers/activity-gantt'
import Filter from '../Filter/Filter'
import { connect } from 'react-redux'
import { getMetricsValuesPropsAllDimensions, getNumerizedMetrics } from 'js/ui-helpers/metrics'
import { useNavigate } from 'react-router'
import { appModulesProps } from 'js/constants/app-modules-props'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import { useSearchParams } from 'react-router-dom'
import { getLatestActivity, getSortedFilteredActivities } from 'js/ui-helpers/home-dashboard'
import { getActivityFilter, getChangedFiltersCount, getDefaultFilter, getFilterLevelDimensionsUi } from 'js/ui-helpers/filter'
import { filterPinDefault } from 'js/constants/filter'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        dashboardSettings: state.dashboardSettings
    }
}

let timeoutId

const DashboardActivityGenericComponent = ({
    currentUser,
    dashboardSettings,
    module,
    modulePropKey,
    modulePropValue
}) => {
    const currentOrgId = currentUser?.current_org
    const [activities, setActivities] = useState([])
    const [allActivities, setAllActivities] = useState([])
    const [selectedActivityId, setSelectedActivityId] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [metricsData, setMetricsData] = useState([])
    const [isPlaceholderMode, setIsPlaceholderMode] = useState(false)
    const [findings, setFindings] = useState([])
    const [selectedActivityFilter, setSelectedActivityFilter] = useState(null)
    const [defaultFilter, setDefaultFilter] = useState({})
    const [filterPin, setFilterPin] = useState(filterPinDefault)
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [isAnimationActive, setIsAnimationActive] = useState(true)
    const [searchParams] = useSearchParams()
    const providedId = searchParams.get('id')
    const navigate = useNavigate()

    const metricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, module, true)
    const metricsPropsOrdered = getMetricsValuesPropsAllDimensions(dashboardSettings, module)
    const levelDimenstionsUI = getFilterLevelDimensionsUi(metricsPropsOrdered, appModulesProps[module].levelDimensions, findings, module)
    const isNoResults = !!(findings?.length && !metricsData?.length)
    const selectedActivity = (activities || []).find(a => a.id === selectedActivityId)
    const selectedActivityYear = getSelectedActivityYear(selectedActivityId, activities)
    const ModuleDashboard = appModulesProps[module].component 
    const filtersCount = getChangedFiltersCount(selectedActivityFilter, defaultFilter)

    const activitiesData = [{
        activities: allActivities,
        module,
        link: appModulesPaths[module],
        name: '',
        modulePropValue
    }]

    const emptyStates = () => {
        setSelectedActivityFilter({})
        setDefaultFilter({})
        setFilterPin(filterPinDefault)
        setFindings([])
        setActivities([])
        setAllActivities([])
        setIsAnimationActive(true)
    }

    const getActivityData = async () => {
        emptyStates()
        const activities = await appModulesProps[module].api.getAllActivities(currentOrgId)
        if (!activities || activities.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: activities.message, notificationType: 'error' }))
            setPlaceholderMode()
            return
        }
        setAllActivities(getSortedFilteredActivities(activities, modulePropKey, modulePropValue))
        const sortedActivities = getSortedFilteredActivities(activities, modulePropKey, modulePropValue, true)
        if (!sortedActivities?.length) {
            setPlaceholderMode()
            return
        }
        setActivities(sortedActivities)
        await switchActivity(providedId, sortedActivities, {}, filterPinDefault)
    }

    const setPlaceholderMode = () => {
        setIsPlaceholderMode(true)
        setMetricsData(appModulesProps[module].metricsPlaceholder)
        setIsAnimationActive(false)
        setIsLoading(false)
    }

    const getMetricsData = async (activityId, filter = defaultFilter) => {
        if (!activityId) return
        setIsLoading(true)
        setIsPlaceholderMode(false)
        const metrics = await appModulesProps[module].api.getActivityMetrics(activityId, filter)
        if (!metrics || metrics.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: metrics.message, notificationType: 'error' }))
            setPlaceholderMode()
            return
        }
        if (metrics.length) {
            setMetricsData(getNumerizedMetrics(metrics, false, appModulesProps[module].valuesProps, appModulesProps[module].isOtherString))
        } else {
            const count = getChangedFiltersCount(selectedActivityFilter, filter)
            count ? setMetricsData([]) : setPlaceholderMode()
        }
        setIsLoading(false)
    }

    const getFilters = async (activityId, filter, pin) => {
        let activityFilter = {}
        if (appModulesProps[module].hasFilter) {
            const findingsData = await appModulesProps[module].api.getActivityFindings(activityId)
            if (findingsData?.length) {
                activityFilter = getActivityFilter(findingsData, appModulesProps[module].dynamicDimensions)
                setSelectedActivityFilter(activityFilter)
                filter = getDefaultFilter(activityFilter, filter, pin)
                setDefaultFilter(filter)
                setFindings(findingsData)
            } else {
                filter = getDefaultFilter(filter, filter, pin)
                setDefaultFilter(filter)
                setSelectedActivityFilter({})
                setFindings([])
            }
        }
        return filter
    }

    const switchActivity = async (activityId, completedActivities = activities, filter = defaultFilter, pin = filterPin) => {
        activityId = (activityId || 0) * 1
        if (activityId === selectedActivityId) return
        setIsLoading(true)
        const activity = (completedActivities || []).find(a => a.id === (activityId || 0) * 1)
        if (!activity) {
            const latestActivity = getLatestActivity(completedActivities, modulePropKey, modulePropValue, true)
            activityId = latestActivity?.id
        }
        setSelectedActivityId(activityId)
        filter = await getFilters(activityId, filter, pin)
        await getMetricsData(activityId, filter)
        navigate(`/${appModulesPaths[module]}/${DASHBOARD_PATH}${modulePropValue ? `-${modulePropValue}` : ''}${activityId ? `?id=${activityId}` : ''}`)
        setIsLoading(false)
    }

    const applyFilter = async (filter) => {
        setIsOpenFilter(false)
        setDefaultFilter(filter)
        await getMetricsData(selectedActivityId, filter)
    }

    useEffect(async () => {
        if (providedId && selectedActivityId && providedId * 1 !== selectedActivityId) {
            await switchActivity(providedId)
        }
    }, [providedId])

    useEffect(async () => {
        if (!modulePropValue && !module) return
        setIsLoading(true)
        await getActivityData()
        timeoutId = setTimeout(() => {
            setIsAnimationActive(false)
        }, [1500])
        setIsLoading(false)
    }, [modulePropValue, module])

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId)
        }
    }, [modulePropValue, module])

    return (
        <>
            {isLoading ? <Loader /> : <div className={`${appModulesPaths[module]} page-content ${isPlaceholderMode ? 'placeholder-mode' : ''}`}>
                <div className='page-header dashboard-page-header'>
                    <div>
                        <div>
                            <h1 id={`${appModulesPaths[module]}${modulePropValue ? `-${modulePropValue}` : ''}-${DASHBOARD_PATH}`}>{appModulesNames[module]} {modulePropValue ? helpers.capitalizeString(modulePropValue) : ''} {DASHBOARD}</h1>
                            {!!activities?.length && <InputRow
                                inputId='qa-activity-selector'
                                inputTag='select'
                                placeholder='Select'
                                defaultValue={selectedActivityId || ''}
                                options={activities.map(a => ({ id: a.id, name: a.title || a.description }))}
                                changeValueHandler={switchActivity} />}
                        </div>
                        {isPlaceholderMode && !isNoResults && <p>{START_FILLING_TEXT}</p>}
                        <h4 className='activivty-details'>{getSelectedActivityDetails(selectedActivity)}</h4>
                    </div>
                    <div className='dashboard-buttons'>
                        {!isPlaceholderMode && <button id='print-button' onClick={() => window.print()}><i className='icon-printer'></i><span>Print</span></button>}
                        {appModulesProps[module].hasFilter && (!isPlaceholderMode || isNoResults) && 
                            <button 
                                id='filter-button' 
                                className={filtersCount ? 'is-filtered': 'no-filter'} 
                                onClick={() => setIsOpenFilter(true)}>
                                <i className='icon-filter'></i><span>Filter {filtersCount ? ` (${filtersCount})` : ''}</span>
                            </button>}
                    </div>
                </div>
                <ActivityGantt activitiesData={activitiesData} defaultYear={selectedActivityYear} />

            

                <div className='module-widgets dashboard-content'>
                    {isPlaceholderMode && <div className='placeholder-mode-overlay'></div>}
                    {isNoResults ? 
                        <Alert message='No Results for this filter' alertType='neutral' /> : 
                        <ModuleDashboard 
                            metricsData={metricsData} 
                            metricsProps={metricsProps} 
                            metricsPropsOrdered={metricsPropsOrdered}
                            filter={defaultFilter}
                            isAnimationActive={isAnimationActive} />}
                    
                </div>
            </div>}
            <Filter
                isOpenFilter={isOpenFilter}
                setIsOpenFilter={setIsOpenFilter}
                defaultFilter={defaultFilter}
                setDefaultFilter={setDefaultFilter}
                applyFilter={applyFilter}
                activityFilter={selectedActivityFilter}
                levelDimenstionsUI={levelDimenstionsUI}
                dynamicDimensions={appModulesProps[module].dynamicDimensions}
                filterPin={filterPin}
                setFilterPin={setFilterPin}
                module={module} />
        </>
    )
}


const DashboardActivityGeneric = connect(mapStateToProps)(DashboardActivityGenericComponent)
export default DashboardActivityGeneric
