const questionnaireModel = {
    id: 0,
    folder_id: 0,
    code: '',
    name: '',
    description: '',
    version: '',
    creation_date: null,
    created_by: 0,
    approved: false,
    approved_by: 0,
    remarks: '',
    domains: [{
        id: 0,
        template_id: 0,
        name: '',
        order: 0,
        categories: [{
            id: 0,
            name: '',
            description: '',
            order: 0,
            questions: [{
                id: 0,
                text: '',
                description: '',
                score: 0,
                weight: 0,
                answer_type_id: 0,
                is_cyber_defense: false,
                order: 0,
                is_required: false,
                possible_answers: [{
                    id: 0,
                    name: '',
                    score: 0,
                    order: 0
                }]
            }]
        }]
    }]
}

const questionnaireTemplateModel = {
    // id: 0,
    folder_id: 1,
    code: '',
    name: '',
    description: '',
    version: '',
    creation_date: null,
    created_by: '',
    approved: false,
    approved_by: '',
    remarks: ''
}

const questionnaireDomainModel = {
    // id: 0,
    // template_id: 0,
    name: '',
    // order: 0,
}

const questionnaireCategoryModel = {
    // id: 0,
    name: '',
    description: '',
    order: 0,
}

const questionnaireQuestionModel = {
    // id: 0,
    text: '',
    description: '',
    score: 0,
    weight: null,
    answer_type_id: 4,
    is_cyber_defense: false,
    order: 0,
    is_required: false,
    possible_answers: []
}

const questionnairePossibleAnswerModel = {
    // id: 0,
    name: '',
    score: '',
    // order: 0
}

export {
    questionnaireModel,
    questionnaireTemplateModel,
    questionnaireDomainModel,
    questionnaireCategoryModel,
    questionnaireQuestionModel,
    questionnairePossibleAnswerModel,
}