import React, { useState } from 'react'
import './ImportTemplate.scss'
import envelope from 'assets/illustrations/questionnaire-envelope.svg'
import { InputRow, Popup } from 'hub-web-lib'
import { connect } from 'react-redux'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { action } from 'js/actions'
import QAApi from 'services/QAApi'
import 'components/Assets/BackButton/BackButton.scss'
import { QUESTIONNAIRE } from 'js/constants/vocabulary'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'
import ImportMessages from 'components/Assets/ImportMessages/ImportMessages'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        questionnareFeaturesList: state.questionnareFeaturesList
    }
}
export const ImportTemplateComponent = ({
    currentUser,
    questionnareFeaturesList,
    setIsImportState = () => { },
    startCreateTemplate = () => { },
    updateTemplates = () => { }
}) => {
    const currentOrgId = currentUser?.current_org

    const [templateFile, setTemplateFile] = useState()
    const [isLoadingButton, setIsLoadingButton] = useState(false)
    const [importResultMessages, setImportResultMessages] = useState(null)

    const clickHiddenRow = () => {
        const importMetricsRow = document.getElementById('fileUpload')
        importMetricsRow.click()
    }
    const changeFile = (file) => {
        setTemplateFile(file)
        uploadFile(file)
    }
    const visibleQuestionnaireFeatures = getEnabledSettingItems(questionnareFeaturesList)
    const uploadFile = async (file = templateFile) => {
        if (file) {
            setIsLoadingButton(true)
            const formData = new FormData()
            formData.append('file', file)
            let res = await QAApi.uploadFile(currentOrgId, formData)
            if (res.storage_name) {
                setIsLoadingButton(false)
                setIsImportState(false)
                store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                    message: `${QUESTIONNAIRE} imported successfully`,
                }))
                updateTemplates()
            }
            else {
                setIsLoadingButton(false)
                store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                    message: res.error,
                    notificationType: 'error'
                }))
                setImportResultMessages(res.details)
            }
        }

    }


    return (
        <>
            <div className='import-content'>
                <button className='back-link roundLink' onClick={() => setIsImportState(false)} id='back-to-templates-list'></button>
                <div className='import-questionnaire'>
                    <img src={envelope} />
                    <p className='description'>Get started and create your very first questionnaire to gather valuable insights with just a few clicks!</p>
                    <div className='import-buttons'>
                        <button className={`btn-set ${isLoadingButton ? 'loader' : ''}`} onClick={clickHiddenRow} id='import-template'><i className='icon-upload-cloud'></i>Import Template</button>
                        {visibleQuestionnaireFeatures?.create_questionnaire && <button className='btn-set' onClick={startCreateTemplate} id='create-from-scratch'><i className='icon-plus'></i>Create Questionnaire</button>
                        }                    </div>
                </div>

            </div>
            <div className='hidden-row'><InputRow
                inputId='fileUpload'
                title='Import Metrics'
                inputType='file'
                changeValueHandler={changeFile}
                defaultValue={templateFile} /></div>

            <Popup
                onClose={() => setImportResultMessages(null)}
                isOpen={importResultMessages != null && importResultMessages.length > 0}
                title={'Import result messages'}>
                <div>
                    <ImportMessages messages={importResultMessages}></ImportMessages>
                </div>
            </Popup>

        </>

    )
}

const ImportTemplate = connect(mapStateToProps)(ImportTemplateComponent)

export default ImportTemplate