import React, { createRef, useEffect, useRef } from 'react'
import AssessmentQuestionAndAnswer from './AssessmentQuestionAndAnswer'
import AssessmentQuestion from './AssessmentQuestion'

const QuestionnaireQuestionsForm = ({
    questions,
    answers,
    activeIds,
    totalUnansweredQuestionsCount,
    isReview,
    isViewOnly,
    goNext = () => {},
    updateActiveQuestionId = () => {},
    saveAnswer = () => {}
}) => {
    const activeQuestionId = questions.find(q => q.id === activeIds[1])?.id || ''
    const componentRefs = useRef([])
    componentRefs.current = questions.map(q => `category-question-${q.id}`).map((_, i) => componentRefs.current[i] ?? createRef())
    useEffect(() => {
        const refIndex = questions.findIndex(q => q.id === activeIds[1])
        if (refIndex > -1 && componentRefs.current && componentRefs.current[refIndex]?.current) {
            componentRefs.current[refIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true })
        }
    }, [activeIds])
    
    return (
        <div className='questions-form-wrapper scroll'>
            <div className='form questions-form'>
                {questions.length ? questions.map((question, i) => {
                    const answer = answers.find(a => a.question_id === question.id)?.answer
                    const questionNumber = i + 1
                    return (<div key={question.id} ref={componentRefs.current[i]} className={isViewOnly ? 'view-only' : 'question-form'}>
                        {isViewOnly ? <AssessmentQuestionAndAnswer
                            question={question} 
                            answers={answers}
                            questionNumber={questionNumber}  /> 
                            : <AssessmentQuestion 
                                question={question} 
                                answer={answer}
                                questionNumber={questionNumber} 
                                isActive={question.id === activeQuestionId} 
                                isLast={!totalUnansweredQuestionsCount}
                                isReview={isReview}
                                isViewOnly={isViewOnly}
                                goNext={goNext}
                                updateActiveQuestionId={updateActiveQuestionId}
                                saveAnswer={saveAnswer} />}
                    </div>) }
                ) : <div className='no-data-message'>No questions</div>}
            </div>
        </div>
    )
}

export default QuestionnaireQuestionsForm
