import React from 'react'
import questionnaireNoData from 'assets/illustrations/questionnaire-no-data.svg'
import './NoDataMessage.scss'

function NoDataMessage({ itemType, addNewAction = () => {} }) {
    return (
        <div className='questionnaire-no-data'>
            <img src={questionnaireNoData} />
            <p>Uh-oh! It seems like your {itemType} folder is feeling a bit empty.<br />              
            Add your {itemType} today.</p>
            <button className='btn-set' id={`empty-${itemType}-add-new`} onClick={addNewAction}>Add {itemType}</button>
        </div>
    )
}

export default NoDataMessage