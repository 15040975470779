import moment from 'moment'
import { getMetricsByProps, getValueByProps, getMetricByProps } from './metrics'
import { helpers } from 'hub-web-lib'
import { sortByYearQuarter } from './home-dashboard'
import { SEVERITY_DIMENSION } from 'js/constants/dashboard-settings'
import { getFilteredMetricsProps } from './filter'

const { ACTIVE, OPEN, ACKNOWLEDGE, OTHER_PROP, GENERAL, BY_AGE, FINDINGS, ALERTS_BY_SEVERITY, KEY, NEW_ALERTS, BY_MONTH, YEARLY_MEAN_TIME, RESOLVED, BY_SEVERITY, LOW, MEDIUM, HIGH, VERY_HIGHT, BY_TYPE } = require('js/constants/metrics/common-metrics')

const easmColors = {
    lightGreen: '#7BFF77',
    lightBlue: '#69FFF0',
    blue: '#00B9FE',
    violet: '#866DFF'
}

const easmVioletColors = ['#8054DA', '#A079EE', '#B695F5', '#EAD9FD']

const ACKNOWLEDGED = 'Acknowledged'

const getDisplayLabel = (string) => {
    const regex = new RegExp('\\b' + ACKNOWLEDGE + '\\b', 'g')
    return string.replace(regex, ACKNOWLEDGED)
}

const findingsAlertsValuesTemplate = [ACTIVE, OPEN, ACKNOWLEDGE].map(label => ({
    label: `${getDisplayLabel(label)} Alerts`,
    status: 'light',
    widget: label
}))

const findingsAlertsBarDetailsDefault = {
    size: 'small',
    direction: 'horizontal',
    chartId: 'findings-alerts-chart'
}

const meanTimeChartDetails = {
    isPercentage: false,
    direction: 'horizontal',
    size: 'medium',
    isShownLabels: false,
    isDonat: true,
    colorValues: Object.values(easmColors),
}

const activeAlertsBySeverityChartDetailsDefault = {
    isPercentage: false,
    direction: 'vertical',
    size: 'regular',
    isShownLabels: true,
    isShownLabelsValues: true,
    chartId: 'alerts-by-severity-chart'
}

const activeAlertsByTypeChartDetails = {
    isPercentage: false,
    direction: 'vertical',
    size: 'regular',
    isShownLabels: true,
    isShownLabelsValues: true,
    colorValues: easmVioletColors,
    chartId: 'alerts-by-type-chart'
}

const byAgeChartDataDetails = {
    colorValues: [easmColors.lightBlue, easmColors.blue],
    size: 'small',
    chartId: 'by-age-chart'
}

const newAlertsChartDetails = {
    isGradient: true,
    colorScheme: 'secondary',
    isPercentage: false,
    size: 250,
    isShownLabels: false,
    chartId: 'new-alerts-chart'
}

const yearlyMeanTimeChartDetails = {
    isGradient: false,
    isPercentage: false,
    colorValues: [easmColors.blue, easmColors.lightGreen],
    size: 250,
    isShownLabels: true,
    chartId: 'yearly-mean-time-chart'
}

const getDayHoursString = (number) => {
    if (!number?.length) return ''
    let string = `${number[0] || 0}d`
    if (number[1] * 1) string = `${string} ${number[1] || 0}h`
    return string
}

const getFindingsAlertsValuesList = (metricsData) => {
    return findingsAlertsValuesTemplate.map(v => ({ 
        label: v.label,
        status: v.status,
        value: getValueByProps(metricsData, GENERAL, v.widget, null, OTHER_PROP) 
    }))
}

const getFindingsAlertsBarChartData = (metricsData, metricsProps, filter) => {
    const findingsAlertsMetrics = getMetricsByProps(metricsData, FINDINGS, ALERTS_BY_SEVERITY)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    let findingsAlertsBarChartData = (findingsAlertsMetrics || []).map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(column => {
            row[column.name] = metric[column.id] || 0
        })
        return row
    })
    findingsAlertsBarChartData = sortByYearQuarter(findingsAlertsBarChartData, 'name')

    const findingsAlertsBarDetails = {
        ...findingsAlertsBarDetailsDefault,
        colorValues: filteredProps.map(l => l.color)
    }
    return { findingsAlertsBarChartData, findingsAlertsBarDetails }
}

const getByAgeChartData = (metricsData) => {
    const byAgeOpenMetrics = getMetricsByProps(metricsData, BY_AGE, OPEN)
    const byAgeChartData = (byAgeOpenMetrics || []).map(m => ({
        name: m[KEY],
        [OPEN]: m.other,
        [ACKNOWLEDGED]: getValueByProps(metricsData, BY_AGE, ACKNOWLEDGE, m[KEY], OTHER_PROP)
    }))
    return { byAgeChartData, byAgeChartDataDetails }
}

const getNewAlertsChartData = (metricsData) => {
    const newAlertsChartData = getMetricsByProps(metricsData, NEW_ALERTS, BY_MONTH)
        .sort((a, b) => new Date(a[KEY]) - new Date(b[KEY]))
        .map(m => ({
            name: moment(m[KEY]).format('MMM YY'),
            'Data': m[OTHER_PROP] * 1 || 0,
        }))
    return { newAlertsChartData, newAlertsChartDetails }
}

const getYearlyMeanTimeChartData = (metricsData) => {
    const yearlyMeanTimeChartData = getMetricsByProps(metricsData, YEARLY_MEAN_TIME, ACKNOWLEDGE)
        .sort((a, b) => new Date(a[KEY]) - new Date(b[KEY]))
        .map(m => ({
            name: moment(m[KEY]).format('MMM YY'),
            [ACKNOWLEDGED]: m[OTHER_PROP] * 1 || 0,
            [RESOLVED]: getValueByProps(metricsData, YEARLY_MEAN_TIME, RESOLVED, m[KEY], OTHER_PROP) * 1
        }))
    return { yearlyMeanTimeChartData, yearlyMeanTimeChartDetails }
}

const getMeanTimeChartProps = (metricsData, category) => {
    const metric = getMetricByProps(metricsData, GENERAL, category)
    const numberArray = metric ? `${metric[OTHER_PROP]}`.split(',') : []
    const number = (numberArray[0] || 0) * 1 + (((numberArray[1] || 0) / 24)).toFixed(1) * 1
    const chartData = [{
        name: LOW,
        value: 7
    }, {
        name: MEDIUM,
        value: 7
    }, {
        name: HIGH,
        value: 17
    }, {
        name: VERY_HIGHT,
        value: Math.max(31, Math.ceil(number) - 31)
    }]
    const chartId = helpers.transformPascalToKebab(category)
    const chartDetails = { ...meanTimeChartDetails, chartId: `${chartId}-chart` }
    const gaugeDetails = { gaugeValue: number, isShownRange: true }
    const valuesList = [{
        label: getDisplayLabel(category),
        value: metric ? getDayHoursString(numberArray) : 'No Data',
        status: 'light'
    }]
    return { metric, chartData, chartDetails, gaugeDetails, valuesList }
}

const getActiveAlertsBySeverityChartData = (metricsData, metricsProps, filter) => {
    const activeAlertsBySeverity = getMetricByProps(metricsData, ACTIVE, BY_SEVERITY) || {}
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    const activeAlertsBySeverityChartData = filteredProps.map(v => ({ 
        name: v.name, 
        value: (activeAlertsBySeverity[v.id] || 0) * 1, 
        color: v.color 
    }))
    const activeAlertsBySeverityChartDetails = { 
        ...activeAlertsBySeverityChartDetailsDefault, 
        colorValues: activeAlertsBySeverityChartData.map(r => r.color) 
    }
    return { activeAlertsBySeverityChartData, activeAlertsBySeverityChartDetails }
}

const getActiveAlertsBySeverityValues = (metricsData, metricsProps) => {
    const activeAlertsBySeverity = getMetricByProps(metricsData, ACTIVE, BY_SEVERITY) || {}
    const activeAlertsBySeverityValues = metricsProps[SEVERITY_DIMENSION].map(v => ({ 
        label: v.name, 
        value: (activeAlertsBySeverity[v.id] || 0) * 1, 
        color: v.color 
    })).filter(r => r.value)
    return activeAlertsBySeverityValues
}

const getActiveAlertsBySeverityValuesImportant = (metricsData, metricsProps) => {
    const activeAlertsBySeverityValuesImportant = getActiveAlertsBySeverityValues(metricsData, metricsProps).slice(0, 3)
    return activeAlertsBySeverityValuesImportant
}

const getActiveAlertsByTypeChartData = (metricsData) => {
    const activeAlertsByType = getMetricsByProps(metricsData, ACTIVE, BY_TYPE) || []
    const activeAlertsByTypeChartData = activeAlertsByType.map(v => ({ name: v.key, value: (v.other || 0) * 1 }))
    return { activeAlertsByTypeChartData, activeAlertsByTypeChartDetails }
}

export {
    getFindingsAlertsValuesList,
    getFindingsAlertsBarChartData,
    getByAgeChartData,
    getNewAlertsChartData,
    getYearlyMeanTimeChartData,
    getMeanTimeChartProps,
    getActiveAlertsBySeverityChartData,
    getActiveAlertsBySeverityValues,
    getActiveAlertsBySeverityValuesImportant,
    getActiveAlertsByTypeChartData
}