import axios from 'axios'
import { helpers } from 'hub-web-lib'
import { dispatchRequest } from 'middlewares/ApiHelpers'


const EasmApi = {

    createActivity: async (newActivity) => {
        const body = helpers.cleanEmptyProps(newActivity)
        body.starred = false
        body.archived = false
        body.is_complete = !!newActivity.is_complete
        body.has_findings = !!newActivity.has_findings
        if (!body.start_date) body.start_date = new Date()
        if (!body.end_date) body.end_date = new Date(body.start_date)
        return dispatchRequest(axios.post('/easm/activity', body)).then(res => res.data)
    },

    updateActivity: async (updatedActivity) => {
        const body = helpers.cleanEmptyProps(updatedActivity)
        body.starred = !!updatedActivity.starred
        body.archived = !!updatedActivity.archived
        body.is_complete = !!updatedActivity.is_complete
        body.has_findings = !!updatedActivity.has_findings
        return dispatchRequest(axios.put('/easm/activity', body)).then(res => res.data)
    },

    deleteActivity: async (activityId) => {
        return dispatchRequest(axios.delete(`/easm/activity/${activityId}`)).then(res => res.data)
    },

    getActivity: async (activityId) => {
        return dispatchRequest(axios.get(`/easm/activity/${activityId}`)).then(res => res.data)
    },

    getAllActivities: async (orgId) => {
        return dispatchRequest(axios.get(`/easm/org/${orgId}/activity`)).then(res => res.data)
    },

    getPredefinedMetrics: async () => {
        return dispatchRequest(axios.get('/easm/predefined')).then(res => res.data)
    },

    getActivityMetrics: async (activityId, filter) => {
        if (!filter) filter = {}
        return dispatchRequest(axios.post(`/easm/activity/${activityId}/metrics_calc`, filter)).then(res => res.data)
    },

    addActivityMetrics: async (activityId, metrics) => {
        return dispatchRequest(axios.post(`/easm/activity/${activityId}/metrics`, metrics)).then(res => res.data)
    },

    updateActivityMetrics: async (activityId, metrics) => {
        return dispatchRequest(axios.put(`/easm/activity/${activityId}/metrics`, metrics)).then(res => res.data)
    },

    deleteActivityMetrics: async (activityId) => {
        return dispatchRequest(axios.delete(`/easm/activity/${activityId}/metrics`)).then(res => res.data)
    },

    deleteActivityMetricsList: async (activityId, metricsList) => {
        return dispatchRequest(axios.delete(`/easm/activity/${activityId}/metrics`, { data: metricsList })).then(res => res.data)
    },
    getLatestMetrics: async (orgId) => {
        return dispatchRequest(axios.get(`/easm/org/${orgId}/last_metrics`)).then(res => res.data)
    },
    postMetricsFile: async (currentOrgId, file, sheetName) => {
        const params = { hard_scan: false }
        if (sheetName) params.sheet = sheetName
        return dispatchRequest(axios.post(`/files/org/${currentOrgId}/easm_metrics/upload-file`, file, { params })).then(res => res.data)
    },
    getActivityFindings: async (activityId) => {
        return dispatchRequest(axios.get(`/easm/activity/${activityId}/findings`)).then(res => res.data)
    },
    updateActivityFindings: async (activityId, findings) => {
        return dispatchRequest(axios.put(`/easm/activity/${activityId}/findings`, findings)).then(res => res.data)
    },
    deleteActivityFindingsList: async (activityId, findingsList) => {
        return dispatchRequest(axios.delete(`/easm/activity/${activityId}/findings`, { data: findingsList })).then(res => res.data)
    },
    downloadMetricsTemplate: async () => {
        return dispatchRequest(axios.get('/files/easm_metrics/template')).then(res => res.data)
    },
    importMetricsFromCyberInt: async (fromDate, toDate) => {
        const dates = { 'from_date': fromDate, 'to_date': toDate }
        return dispatchRequest(axios.get('/easm/easm_synchronize', { params: dates })).then(res => res.data)
    },
}
export default EasmApi
