import React from 'react'
import { InputRow, InputList } from 'hub-web-lib'
import moment from 'moment'
import './AssessmentGeneralInfo.scss'
const AssessmentGeneralInfo = ({ 
    selectedQuestionnaire, 
    selectedActivity, 
    isInfoOpenedFromClient,
    closePopup = () => { }, 
    displayAssessment = () => { }
}) => {
    return (
        <div className='assessment-general-information'>
            <div className='general-body'>
                <div className='assessment-general-information-questionnaire'>
                    <h3>Questionnaire</h3>
                    <InputRow inputId='submit-template-name'
                        title='Name'
                        layout='vertical'
                        defaultValue={selectedQuestionnaire?.name}
                        disabled={true} />
                    <InputRow
                        inputTag='textarea'
                        title='Description'
                        inputId='description'
                        layout='vertical'
                        defaultValue={selectedQuestionnaire?.description || ''}
                        disabled={true}
                    />
                    <InputList list={(selectedQuestionnaire?.domains || []).map(d => d.name)} disabled={true} isTagsView={true} details={{ inputId: 'domains-list', title: 'Domains' }} />
                    <InputRow inputId='submit-template-version'
                        title='Version'
                        layout='vertical'
                        defaultValue={selectedQuestionnaire?.version}
                        disabled={true} />
                    <InputRow inputId='submit-template-creation_date'
                        title='Creation Date'
                        layout='vertical'
                        defaultValue={selectedQuestionnaire?.creation_date ? moment(selectedQuestionnaire.creation_date).format('DD/MM/YY') : ''}
                        disabled={true} />



                </div>
                <div className='assessment-general-information-activity'>
                    <h3>Activity</h3>
                    <InputRow inputId='submit-template-creation_date'
                        title='Creation Date'
                        layout='vertical'
                        defaultValue={selectedActivity?.end_date ? moment(selectedActivity.end_date).format('DD/MM/YY') : ''}
                        disabled={true} />
                    <InputRow
                        inputTag='textarea'
                        title='Description'
                        inputId='description'
                        layout='vertical'
                        defaultValue={selectedActivity?.description || ''}
                        disabled={true}
                    />
                </div>
            </div>
            <div className='general-buttons'>
                <button className='btn-cancel' onClick={isInfoOpenedFromClient ? displayAssessment : closePopup} id='start-assessment'>Close</button>
                {!isInfoOpenedFromClient && <button className='btn-submit' onClick={displayAssessment} id='cancel-preview'>Start Assessment</button>}

            </div>

        </div>

    )
}

export default AssessmentGeneralInfo