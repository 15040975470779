import { BarChartCustom, WidgetBlock } from 'hub-web-lib'
import { READINESS_BY_CATEGORY } from 'js/constants/metrics/common-metrics'
import { getReadinessChartData } from 'js/ui-helpers/qa-dashboard'
import React, { useEffect, useState } from 'react'
import CyberReadinessProgress from './CyberReadinessProgress'
const CyberReadinessBottomWidgets = ({ data }) => {
    const { metricsData, category, isShown, isAnimationActive } = data
    const [charts, setCharts] = useState({})
    useEffect(() => {
        const { chartData, chartDetails } = getReadinessChartData(metricsData, category)
        setCharts({
            chartData,
            chartDetails
        })
    }, [])

    return (
        <div className={`dashboard category-content ${isShown ? 'show' : 'hidden'}`}>
            <div className='dashboard-center'>
                <WidgetBlock title={`${category} ${READINESS_BY_CATEGORY}`} content={
                    charts.chartData?.length ? <BarChartCustom chartData={charts.chartData} chartDetails={{ ...charts.chartDetails, isAnimationActive }} /> : 
                        <div className='no-data-message'>No Data</div>} />

            </div>
            <div className='dashboard-right'>
                <CyberReadinessProgress data={ data } />
            </div>

        </div>
    )

}

export default CyberReadinessBottomWidgets
