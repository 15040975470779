import axios from 'axios'
import { helpers } from 'hub-web-lib'
import { dispatchRequest } from 'middlewares/ApiHelpers'
import { activityStatusesEnum } from 'js/constants/enums/activity'

const QAApi = {

    createActivity: async (newActivity) => {
        const body = helpers.cleanEmptyProps(newActivity)
        if (!body.status) body.status = activityStatusesEnum.started
        body.starred = false
        body.archived = false
        if (!body.start_date) body.start_date = new Date()
        if (!body.end_date) body.end_date = new Date(body.start_date)
        return dispatchRequest(axios.post('/qa/activity', body)).then(res => res.data)
    },

    updateActivity: async (updatedActivity) => {
        const body = helpers.cleanEmptyProps(updatedActivity)
        if (!body.status) body.status = activityStatusesEnum.started
        body.starred = !!updatedActivity.starred
        body.archived = !!updatedActivity.archived
        if (!body.start_date) body.start_date = new Date()
        if (!body.end_date) body.end_date = new Date(body.start_date)
        return dispatchRequest(axios.put('/qa/activity', body)).then(res => res.data)
    },

    deleteActivity: async (activityId) => {
        return dispatchRequest(axios.delete(`/qa/activity/${activityId}`)).then(res => res.data)
    },

    cancelActivity: async (activityId) => {
        return dispatchRequest(axios.put(`/qa/activity/${activityId}/cancel`)).then(res => res.data)
    },

    getActivity: async (activityId) => {
        return dispatchRequest(axios.get(`/qa/activity/${activityId}`)).then(res => res.data)
    },

    getAllActivities: async (orgId) => {
        return dispatchRequest(axios.get(`/qa/org/${orgId}/activity`)).then(res => res.data)
    },

    getPredefinedMetrics: async () => {
        return dispatchRequest(axios.get('/qa/predefined')).then(res => res.data)
    },

    getActivityMetrics: async (activityId) => {
        return dispatchRequest(axios.get(`/qa/activity/${activityId}/metrics`)).then(res => res.data)
    },

    addActivityMetrics: async (activityId, metrics) => {
        return dispatchRequest(axios.post(`/qa/activity/${activityId}/metrics`, metrics)).then(res => res.data)
    },

    updateActivityMetrics: async (activityId, metrics) => {
        return dispatchRequest(axios.put(`/qa/activity/${activityId}/metrics`, metrics)).then(res => res.data)
    },

    deleteActivityMetrics: async (activityId) => {
        return dispatchRequest(axios.delete(`/qa/activity/${activityId}/metrics`)).then(res => res.data)
    },

    deleteActivityMetricsList: async (activityId, metricsList) => {
        return dispatchRequest(axios.delete(`/qa/activity/${activityId}/metrics`, { data: metricsList })).then(res => res.data)
    },
    getLatestMetrics: async (orgId) => {
        return dispatchRequest(axios.get(`/qa/org/${orgId}/last_metrics`)).then(res => res.data)
    },
    postMetricsFile: async (currentOrgId, file, sheetName) => {
        const params = { hard_scan: false }
        if (sheetName) params.sheet = sheetName
        return dispatchRequest(axios.post(`/files/org/${currentOrgId}/qa_metrics/upload-file`, file, { params })).then(res => res.data)
    },
    downloadMetricsTemplate: async () => {
        return dispatchRequest(axios.get('/files/qa_metrics/template')).then(res => res.data)
    },
    uploadFile: async (orgId, file) => {
        return dispatchRequest(axios.post(`/files/org/${orgId}/questionnaire/upload-file`, file)).then(res => res.data)
    },
    calculateMetrics: async (orgId, activityId) => {
        return dispatchRequest(axios.get(`/qa/org/${orgId}/activity/${activityId}/calculate`)).then(res => res.data)
    },
    getTenantMetrics: async () => {
        return dispatchRequest(axios.get('/qa/guest-metrics')).then(res => res.data)
    },
}
export default QAApi
