import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, InfoCard, Loader, SortableTable } from 'hub-web-lib'
import { QUESTIONNAIRE } from 'js/constants/vocabulary'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import QuestionnaireApi from 'services/QuestionnaireApi'
import moment from 'moment'
import { assessmentDomainsColumns } from 'js/constants/table-columns'
import { connect } from 'react-redux'
import { questionnaireAnswerTypes } from 'js/constants/enums/questionnaire'
import './TemplateInfo.scss'
import BackButton from 'components/Assets/BackButton/BackButton'

const templateInfoDefault = {
    id: {
        title: `${QUESTIONNAIRE} ID`
    },
    name: {
        title: 'Name'
    },
    date: {
        title: 'Created Date'
    },
    version: {
        title: 'Version'
    },
    createdBy: {
        title: 'Created By'
    },
    approvedBy: {
        title: 'Manager'
    },
    status: {
        title: 'Status'
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const TemplateInfoComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState({})
    const currentTemplateTitle = currentQuestionnaire.name || ''

    const getTemplateInfo = async () => {
        const templateId = (location?.pathname || '').split('/')[3]*1
        const questionnaire = await QuestionnaireApi.getQuestionnaireTemplateFull(templateId, currentOrgId)
        if (!questionnaire || questionnaire.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: questionnaire.error, notificationType: 'error' }))
            return
        }  

        questionnaire.createdBy = questionnaire.created_by_user?.name
        questionnaire.approvedBy = questionnaire.approved_by_user?.name
        setCurrentQuestionnaire(questionnaire)
    }

    const getCardData = () => {
        const data = { ...templateInfoDefault }
        for (const key of Object.keys(data)) {
            data[key].value = currentQuestionnaire[key] || '-'
        }
        data.id.value = currentQuestionnaire.id || '-'
        data.date.value = moment(currentQuestionnaire.creation_date).format('DD/MM/YY')
        data.status.value = currentQuestionnaire.approved ? 'Approved' : 'Ready to approve'
        return data
    }

    const templateDomainsColumns = assessmentDomainsColumns.slice(0, 3)

    const getDomainsTableData = () => {
        const data = (currentQuestionnaire.domains || []).map((d => {
            const categories = d.categories || []
            const allQuestions = categories
                .map(c => (c.questions || []))
                .flat()
            return {
                id: d.id,
                name: d.name,
                categoriesCount: categories.length,
                totalQuestions: allQuestions.length
            }
        }))
        return data
    }

    const cardData = getCardData()
    const domainsTableData = getDomainsTableData()


    useEffect( async () => {
        await getTemplateInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content'>
            <div className='page-header'>
                <BackButton />
                <h1 id='qa-template-info'>{QUESTIONNAIRE} {currentTemplateTitle} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentQuestionnaire.id ? <div className='qa-template-info-con info-card-con'>
                <InfoCard cardData={cardData} />
                <h2 id='assessment-domains'>Domains</h2>
                <SortableTable columns={templateDomainsColumns} tableData={domainsTableData} tableId='template-info-domains-table' />
                <h2 id='assessment-answers'>Questions</h2>
                <ul className='assessment-answers'>
                    {(currentQuestionnaire.domains || []).map(d => <li key={d.id}>
                        <h3>{d.name}</h3>
                        {d.categories?.length ? <ul>
                            {(d.categories || []).map(c => <li key={c.id}>
                                <h4>{c.name}</h4>
                                {c.questions?.length ? <ul>
                                    {(c.questions || []).map((q, qi) => <li key={q.id}>
                                        <p>{q.is_required ? <span className='error-msg'>*</span> : ''}{qi + 1}. {q.text} ({questionnaireAnswerTypes.find(t => t.id === q.answer_type_id)?.name})</p>
                                        <ul>
                                            {(q.possible_answers || []).map((a, i) => <li key={i}>
                                                {a.name} ({a.score || 0})
                                            </li>)}
                                        </ul>
                                    </li>)}
                                </ul> : <div className='no-data-message'>No questions</div>}
                            </li>)}
                        </ul> : <div className='no-data-message'>No categories</div>}
                    </li>)}
                </ul>
            </div> : <Alert message={`No data for this ${QUESTIONNAIRE}`} />}
        </div>
    )
}

const TemplateInfo = connect(mapStateToProps)(TemplateInfoComponent)

export default TemplateInfo