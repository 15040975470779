const setIdsToAssessment = () => {
    const assessmentTitle = document.querySelector('.tabs-title.inline.has-click')
    if (assessmentTitle) assessmentTitle.id = 'assessment-title'
    const assessmentDomains = document.querySelector('.tabs-nav')
    if (assessmentDomains) assessmentDomains.id = 'assessment-domains'
    const assessmentDomain = document.querySelector('.questionnaire-domain.show')
    if (assessmentDomain) {
        const assessmentCategories = assessmentDomain.querySelector('.questionnaire-categories-section')
        if (assessmentCategories) assessmentCategories.id = 'assessment-categories'
        const assessmentQuestions = assessmentDomain.querySelector('.questions-form-wrapper')
        if (assessmentQuestions) assessmentQuestions.id = 'assessment-questions'
    }
}

export {
    setIdsToAssessment
}