import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { PieChartCustom, ValuesList, WidgetBlock, ProgressBar, HomeMenu, ProgressBarCircular, helpers } from 'hub-web-lib'
import { SCORING_BY_CATEGORY, SCORING_BY_DOMAIN } from 'js/constants/metrics/common-metrics'
import { getScoringByCategoryMenu, getSecurePercent, getSecurityControlsChartData, getSecurityControlsValues } from 'js/ui-helpers/qa-dashboard'
import React, { useEffect, useState } from 'react'

const CyberReadinessTopWidgets = ({ metricsData, isHome = false }) => {

    const [charts, setCharts] = useState({})

    useEffect(() => {
        const securePercent = getSecurePercent(metricsData)
        const scoringByCategoryMenu = getScoringByCategoryMenu(metricsData)
        const securityControlsValues = getSecurityControlsValues(metricsData)
        const { securityControlsChartData, securityControlsChartDetails } = getSecurityControlsChartData(metricsData)
        setCharts({
            securePercent,
            scoringByCategoryMenu,
            securityControlsValues,
            securityControlsChartData,
            securityControlsChartDetails
        })
    }, [])

    return (
        <div className='dashboard'>
            <div className='dashboard-center'>
                <WidgetBlock title={isHome ? SCORING_BY_CATEGORY : SCORING_BY_DOMAIN} content={
                    <>
                        {isHome ? <>
                            <div className='home-center-top-progress'>
                                {charts.securePercent ? <ProgressBarCircular percent={charts.securePercent} title='Total Scoring' size='large' chartId='home-secure-percent' /> : <NoDataPlaceholder />}
                            </div>
                            {charts.scoringByCategoryMenu?.length ? <HomeMenu menuItems={charts.scoringByCategoryMenu} noLink={true} /> : <NoDataPlaceholder />}
                        </>

                            :
                            <div className='main-dashboard'>
                                {charts.scoringByCategoryMenu?.length ? charts.scoringByCategoryMenu.map(s => 
                                    <ProgressBar id={s.name} key={s.id} percent={s.percent} title={s.name} chartId={`scoring-category-${helpers.transformToKebab(s.name)}`} />) : <NoDataPlaceholder />
                                }
                            </div>

                        }
                    </>}
                />
            </div>
            <div className='dashboard-right'>
                <WidgetBlock
                    title='Implementation Security Controls'
                    content={charts.securityControlsValues?.length && charts.securityControlsChartData?.length ?
                        <div className='cyber-authority'>
                            <ValuesList list={charts.securityControlsValues} listId='security-control-values' />
                            <div className='dashboard-right-pie-chart'>
                                <PieChartCustom chartData={charts.securityControlsChartData} chartDetails={charts.securityControlsChartDetails} />
                            </div>
                        </div> : <NoDataPlaceholder />}
                />
            </div>
        </div>
    )
}

export default CyberReadinessTopWidgets
