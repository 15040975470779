import { IMPACT_DIMENSION, LIKELIHOOD_DIMENSION, RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION } from './dashboard-settings'
import { CRITICAL_PROP, HIGH_PROP, INFOMATIONAL_PROP, LOW_PROP, MEDIUM_PROP } from './metrics/common-metrics'

const levelDimensionFilter = [INFOMATIONAL_PROP, LOW_PROP, MEDIUM_PROP, HIGH_PROP, CRITICAL_PROP]

const START_DATE = 'start_date'
const END_DATE = 'end_date'
const FINDING_CATEGORY = 'finding_category'
const STATUS = 'status'
const RISK_ASSESSMENT_DOMAIN = 'risk_assessment_domain'
const RESPONSE = 'response'
const LAYER = 'layer'
const ATTACK_NAME = 'attack_name'
const ATTACK_TYPE = 'attack_type'
const MITIGATION = 'mitigation'
const TYPE = 'type'
const EXPOSURE = 'exposure'
const TARGETS = 'targets'

const filterDates = {
    [START_DATE]: '',
    [END_DATE]: '',
}

const levelDimensionsDefault = [RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION, IMPACT_DIMENSION, LIKELIHOOD_DIMENSION]

const filterPinDefault = {
    [START_DATE]: false,
    [END_DATE]: false,
    [RISK_LEVEL_DIMENSION]: false,
    [SEVERITY_DIMENSION]: false,
    [IMPACT_DIMENSION]: false,
    [LIKELIHOOD_DIMENSION]: false
}

export {
    START_DATE,
    END_DATE,
    FINDING_CATEGORY,
    STATUS,
    RISK_ASSESSMENT_DOMAIN,
    RESPONSE,
    LAYER,
    ATTACK_NAME,
    ATTACK_TYPE,
    MITIGATION,
    TYPE,
    EXPOSURE,
    TARGETS,
    levelDimensionFilter,
    filterDates,
    levelDimensionsDefault,
    filterPinDefault
}