const MINOR = 'minor'
const MAJOR = 'major'
const EXTREME = 'extreme'

const findingSeverityTypes = [{
    id: 1,
    name: MINOR
}, {
    id: 2,
    name: MAJOR
}, {
    id: 3,
    name: EXTREME
}]

const findingSeverityTypesFull = [{
    id: 1,
    name: 'informational'
}, {
    id: 2,
    name: 'low'
}, {
    id: 3,
    name: 'medium'
}, {
    id: 2,
    name: 'hight'
}, {
    id: 3,
    name: 'critical'
}]

const allSeverityStatuses = findingSeverityTypes.sort((a, b) => b.id - a.id).map(t => t.name)


const assetTypes = [{
    id: 1,
    name: 'logical'
}, {
    id: 2,
    name: 'technical'
}]

export {
    MINOR,
    MAJOR,
    EXTREME,
    findingSeverityTypes,
    findingSeverityTypesFull,
    allSeverityStatuses,
    assetTypes
}