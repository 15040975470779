import React, { useEffect, useState } from 'react'
import './Scheduler.scss'
import { WidgetBlock } from 'hub-web-lib'
import { WidgetList } from 'hub-web-lib'
import { Popup } from 'hub-web-lib'
import LocalStorageApi from 'services/LocalStorageApi'
import { genericTexts } from 'hub-web-lib'
import { InputRow } from 'hub-web-lib'
import { ptAssetsList, scheduleActivityTypes, phishingAssetsList } from 'js/constants/lorem-ipsum'
import { ACTIVITY } from 'js/constants/vocabulary'

const newActivityDefault = {
    date: new Date(),
    title: '',
    type: 'PT',
    asset: ''
}
const defaultText = genericTexts.defaultText

export const Scheduler = () => {
    const [scheduledActivities, setScheduledActivities] = useState([])
    const [newActivity, setNewActivity] = useState(newActivityDefault)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const [selectedActivityId, setSelectedActivityId] = useState('')

    const assetList = (newActivity.type === 'Phishing campaigns' ? phishingAssetsList : ptAssetsList).map(a => ({ id: a.id, name: a.title }))

    const activities = scheduledActivities.map(activity => ({ 
        ...activity, 
        message: `${activity?.asset || ''} (${activity.type})`,
        icon: 'delete'
    }))

    useEffect(async () => {
        const scheduled = await LocalStorageApi.getScheduled()
        setScheduledActivities(scheduled)
    }, [])

    const clearForm = () => {
        setErrorMsg('')
        setNewActivity(newActivityDefault)
    }

    const addActivity = async () => {
        if (validateForm()) return
        setIsButtonLoading(true)
        const activityToAdd = { ...newActivity }
        const selectedAsset = assetList.find(a => a.id === newActivity.asset)
        activityToAdd.asset = selectedAsset?.name || ''
        const scheduled = await LocalStorageApi.updateScheduled(activityToAdd)
        setScheduledActivities(scheduled)
        clearForm()
        setIsButtonLoading(false)
    }

    const validateForm = () => {
        let error = ''
        const dateNow = new Date()
        if (newActivity.date < dateNow) {
            error = `The ${ACTIVITY} Date must be in the future`
        }
        if (!newActivity.title) {
            error = `Please provide an ${ACTIVITY} Title`
        }
        if (!newActivity.type) {
            error = `Please provide an ${ACTIVITY} Type`
        }
        if (!newActivity.asset) {
            error = 'Please provide an Asset Name'
        }
        setErrorMsg(error)
        return error
    }

    const removeActivity = async (id) => {
        setIsOpenPopup(true)
        setSelectedActivityId(id)
    }

    const deleteActivity = async () => {
        const scheduled = await LocalStorageApi.deleteScheduled(selectedActivityId)
        setScheduledActivities(scheduled)
    }

    const newActivityForm = <div className='form new-activity-form'>
        <InputRow
            inputId='date'
            title='Date'
            inputType='dateTime'
            defaultValue={newActivity.date}
            changeValueHandler={val => setNewActivity({ ...newActivity, date: val })} />
        
        <InputRow
            inputId='title'
            title='Title'
            defaultValue={newActivity.title}
            changeValueHandler={val => setNewActivity({ ...newActivity, title: val })} />
        
        <InputRow
            inputId='type'
            title='Type'
            inputTag='select'
            defaultValue={newActivity.type}
            options={scheduleActivityTypes}
            changeValueHandler={val => setNewActivity({ ...newActivity, type: val })} />
        
        <InputRow
            inputId='asset'
            title='Asset'
            inputTag='select'
            placeholder='Select'
            defaultValue={newActivity.asset}
            options={assetList}
            changeValueHandler={val => setNewActivity({ ...newActivity, asset: val })} />
        <p className='error-msg'>{errorMsg}</p>

        <div className='form-buttons'>
            <button onClick={addActivity} className={`btn-submit ${isButtonLoading ? 'loader' : ''}`}>{defaultText.createNewButton(ACTIVITY)}</button>
        </div>
    </div>

    return (
        <div className='scheduler page-content'>
            <div className='page-header'>
                <h1>Scheduler</h1>
            </div>
            <div className='scheduler-con'>
                <div className='scheduler-widgets'>
                    <WidgetBlock 
                        title='Scheduled Activities' 
                        content={ <WidgetList list={activities}
                            limit={100}
                            handleItemClick={removeActivity} /> } />
                    
                    <WidgetBlock 
                        title={defaultText.addNewButton(ACTIVITY)} 
                        content={ newActivityForm } />
                </div>
                <Popup
                    onClose={() => setIsOpenPopup(false)}
                    isOpen={isOpenPopup}
                    title={defaultText.deletePopupTitle}>
                    <p>{defaultText.deletePopupText(ACTIVITY)}</p>
                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={() => {
                            deleteActivity()
                            setIsOpenPopup(false)
                        }}>{defaultText.yes}</button>
                        <button onClick={() => setIsOpenPopup(false)} className='btn-cancel'>{defaultText.no}</button>
                    </div>
                </Popup>
            </div>
        </div>
    )
}

export default Scheduler