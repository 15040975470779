import { action } from 'js/actions'
import { REQUEST } from 'js/constants/action-types'
import store from 'js/store'
import { helpers } from 'hub-web-lib'
import { genericTexts } from 'hub-web-lib'
import { userFriendlyErrors } from 'js/constants/common-errors'
import axios from 'axios'

// dispatch request listens to the state of the promise request and if rejected, the error handler is called
const dispatchRequest = async (req, metaData = { 'errorStrategy': 'warning' }, type = REQUEST) => {
    store.dispatch(action(type, req, metaData))
    return await req
}

const jsonToFormData = (object) => {
    const formData = new FormData()
    if (!helpers.isObject(object)) return formData
    for (const key of Object.keys(object)) {
        formData.append(key, object[key])
    }
    return formData
}

const numericJsonValues = (object) => {
    if (!helpers.isObject(object)) return object
    for (const key of Object.keys(object)) {
        if (!helpers.isObject(object[key])) {
            const value = object[key]
            object[key] = !isNaN(value * 1) && typeof value === 'string' && value !== '' ? value * 1 : value
        } else {
            numericJsonValues(object[key])
        }
    }
    return object
}

const emptyJsonValues = (object) => {
    if (!helpers.isObject(object)) return object
    for (const key of Object.keys(object)) {
        if (!helpers.isObject(object[key])) {
            object[key] = ''
        } else {
            emptyJsonValues(object[key])
        }
    }
    return object
}

const isEmptyObject = (object) => {
    let isEmpty = true
    for (const key of Object.keys(object)) {
        if (object[key]) isEmpty = false
    }
    return isEmpty
}

const objectHasValuesForKeys = (object, keys) => {
    let hasAllValues = true
    for (const key of keys) {
        if (!object[key]) hasAllValues = false
    }
    return hasAllValues
}

const getErrorMessage = (err) => {
    let errDetails = err?.response?.data?.details
    if (!errDetails) {
        if (err?.response?.data?.message) {
            if (err.response.data.message.message && !err.response.data.message.success) return err.response.data.message.message
            return err.response.data.message
        }
        if (err?.response?.status && err?.response?.statusText) return `${err.response.status} ${err.response.statusText}`
        return genericTexts.defaultText.genericErrorMsg
    }
    errDetails = Array.isArray(errDetails) ? errDetails[0][Object.keys(errDetails[0])[0]] : errDetails
    if (typeof errDetails !== 'string') errDetails = genericTexts.defaultText.genericErrorMsg
    return errDetails
}

const getErrorDetail = (err, isDetailed) => {
    let errDetails = err?.response?.data?.detail
    if (errDetails) {
        if (typeof errDetails === 'string') {
            return errDetails
        } else if (Array.isArray(errDetails)) {
            return isDetailed ? 
                errDetails.filter(e => e.msg).map(e => ({ ...e, msg: userFriendlyErrors[e.msg] || helpers.capitalizeString(e.msg || '') })) : 
                errDetails.filter(e => e.loc).map(e => `${helpers.capitalizeString(e.loc[1] || '')} ${helpers.capitalizeString(e.loc[2] || '')} ${e.msg}`)
        }
    }
    return getErrorMessage(err)
}

const getLastSecondOfCurrentDay = () => {
    const now = new Date()
    const lastSecond = new Date(now)
    lastSecond.setHours(23, 59, 59, 999)
    return lastSecond
}

const getYearAgoDate = () => {
    const now = new Date()
    const oneYearAgo = new Date(now)
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    return oneYearAgo
}

function findObjectDifference(obj1, obj2, parentKey) {
    const differences = {}
    if (!obj1) return 'obj1 doesn\'t exist'
    if (!obj2) return 'obj2 doesn\'t exist'
    for (const key of Object.keys(obj1)) {
        if (obj1[key]) {
            if (!obj2[key]) {
                differences[key] = { oldValue: obj1[key], newValue: undefined }
            } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                const nestedDiff = findObjectDifference(obj1[key], obj2[key], key)
                if (Object.keys(nestedDiff).length > 0) {
                    differences[key] = nestedDiff
                }
            } else if (obj1[key] !== obj2[key]) {
                differences[key] = { oldValue: obj1[key], newValue: obj2[key], parentKey }
            }
        }
    }

    for (const key of Object.keys(obj2)) {
        if (obj2[key] && !obj1[key]) {
            differences[key] = { oldValue: undefined, newValue: obj2[key] }
        }
    }

    return differences
}

function downloadFile(path, defaultFileName) {
    axios.get(path, { responseType: 'blob' }).then((response) => {
        let fileName = response.headers['content-disposition']?.split('filename=')[1] || defaultFileName
        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileName)
        link.click()
        URL.revokeObjectURL(href)
    })
}

export {
    dispatchRequest,
    jsonToFormData,
    getErrorMessage,
    getErrorDetail,
    numericJsonValues,
    emptyJsonValues,
    isEmptyObject,
    objectHasValuesForKeys,
    getLastSecondOfCurrentDay,
    getYearAgoDate,
    findObjectDifference,
    downloadFile
}