import React, { useEffect, useState } from 'react'
import 'scss/UpdateActivity/UpdateActivity.scss'
import { genericTexts, helpers, Loader, Tabs } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { qaMetricsModel, qaActivityModel } from 'js/constants/models/qa'
import { questionnaireAssessmentModel } from 'js/constants/models/questionnaire-assessment'
import QAApi from 'services/QAApi'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { emptyJsonValues } from 'middlewares/ApiHelpers'
import QAGeneralInformation from './UpdateActivityTabs/QAGeneralInformation'
import QAActivityMetrics from './UpdateActivityTabs/QAActivityMetrics'
import QuestionnaireTemplateSelect from 'components/Modules/Questionnaire/ActivityAssessmentTabs/TemplateSelect'
import ReviewAssessment from 'components/Modules/Questionnaire/ActivityAssessmentTabs/ReviewAssessment'
import QuestionnaireApi from 'services/QuestionnaireApi'
import AssessmentApi from 'services/AssessmentApi'
import { getStepsErrors } from 'js/ui-helpers/qa-activity'
import { findDeletedMetrics, getAllCurrentMetrics, getNumerizedMetrics } from 'js/ui-helpers/metrics'
import { qaValueProps } from 'js/constants/metrics/qa-metrics'
import { getFilteredQuestionnaire } from 'js/ui-helpers/questionnaire'
import { assessmentStatusesEnum } from 'js/constants/enums/questionnaire'


const newActivityModel = helpers.createNewInstance(qaActivityModel)
delete newActivityModel.id
delete newActivityModel.org_id
const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(newActivityModel))

const newMetricModel = helpers.createNewInstance(qaMetricsModel)
delete newMetricModel.id
delete newMetricModel.org_id


export const UpdateQAActivity = ({
    currentUser,
    predefinedMetrics,
    activityDetails = {},
    isNewActivity,
    currentOrg,
    orgUsers,
    closeUpdateActivityPopup,
    templateUrl,
    approvedTemplates,
    currentUserOrgs
}) => {
    const newActivity = {
        ...newActivityModel,
        org_id: currentOrg?.id,
        reported_by: currentOrg?.id === currentUser?.org_id ? currentUser?.id : ''
    }
    const [activityToUpdate, setActivityToUpdate] = useState(isNewActivity || !activityDetails?.id ? newActivity : activityDetails)
    const [isLoading, setIsLoading] = useState(false)
    const [currentAssessment, setCurrentAssessment] = useState(null)
    const [currentTemplates, setCurrentTemplates] = useState(approvedTemplates)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [customMetrics, setCustomMetrics] = useState([{ ...newMetricModel }])
    const [originalMetrics, setOriginalMetrics] = useState([])
    const [predefinedMetricsToEdit, setPredefinedMetricsToEdit] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })
    const [activeTab, setActiveTab] = useState(0)
    const [isMetricsTabOpened, setIsMetricsTabOpened] = useState(false)
    const [recipientList, setRecipientList] = useState([])
    const [isEmailListChanged, setIsEmailListChanged] = useState(false)
    const orgs = currentUserOrgs.filter(o => o.status === 1)

    const isReview = currentAssessment?.email_date
    const metrics = getAllCurrentMetrics(predefinedMetricsToEdit, customMetrics)

    const stepsErrors = getStepsErrors(activityToUpdate, currentAssessment, selectedTemplate, metrics, isReview)

    const deletedMetrics = findDeletedMetrics(originalMetrics, predefinedMetricsToEdit)


    const checkReadyToSubmit = (isSubmit) => {
        if (!isSubmit) return true
        const errors = Object.values(stepsErrors).filter(e => e).map(e => e.message)
        if (errors.length) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: errors.join('. '), notificationType: 'error' }))
            if (stepsErrors[0]) {
                const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
                setFieldsErrorMsg(errorDetails)
            }
            return false
        }
        return true
    }

    const saveActivity = async () => {
        if (stepsErrors[0]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[0].message, notificationType: 'error' }))
            const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
            setFieldsErrorMsg(errorDetails)
            setActiveTab(0)
            return false
        }
        const activityUpdated = activityToUpdate?.id ?
            helpers.isSameObject(activityToUpdate, activityDetails) ? true : await QAApi.updateActivity(activityToUpdate) :
            await QAApi.createActivity(activityToUpdate)
        if (!activityUpdated || activityUpdated.error) {
            if (Array.isArray(activityUpdated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                activityUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                setErrorMsg(activityUpdated.message)
            }
            setActiveTab(0)
            return false
        }
        const activityId = activityToUpdate?.id || activityUpdated.id
        if (!activityToUpdate?.id) setActivityToUpdate({ ...activityToUpdate, id: activityId })
        return activityId
    }

    const saveMetrics = async (activityId) => {
        if (stepsErrors[2]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[2].message, notificationType: 'error' }))
            setActiveTab(2)
            return false
        }
        const metricsWithValues = getNumerizedMetrics(metrics, !activityToUpdate?.id, qaValueProps)
        const metricsSaved = await QAApi.updateActivityMetrics(activityId, metricsWithValues)
        if (!metricsSaved?.message?.success) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Metrics saving failed. Please check the missed data', notificationType: 'error' }))
            setActiveTab(1)
            return false
        }
        if (deletedMetrics.length && activityToUpdate?.id) await QAApi.deleteActivityMetricsList(activityId, deletedMetrics)
        return true
    }

    const saveAssessment = async (activityId) => {
        if ((!isReview && stepsErrors[1])) return true
        if (recipientList !== currentAssessment.mail_to) setIsEmailListChanged(true)
        const assessmentToUpdate = {
            ...currentAssessment,
            template_id: selectedTemplate.id,
            mail_to: currentAssessment.mail_to || null,
            filled_by_list: currentAssessment.mail_to || null,
            filled_by: null,
            qa_activity_id: activityId,
        }
        const assessmentUpdated = assessmentToUpdate.id ?
            await AssessmentApi.updateAssessment(assessmentToUpdate, currentOrg?.id) :
            await AssessmentApi.createAssessment(assessmentToUpdate)
        if (!assessmentUpdated || assessmentUpdated.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: assessmentUpdated.error, notificationType: 'error' }))
            setActiveTab(3)
            return false
        }
        const updatedAssessment = { ...assessmentToUpdate, id: currentAssessment.id || assessmentUpdated.id }
        setCurrentAssessment(updatedAssessment)
        return updatedAssessment
    }

    const submitAssessment = async (assessment, isSubmit) => {
        if (!isSubmit) return true
        if (!isReview && stepsErrors[1]) return true
        const assessmentSubmitted = await AssessmentApi.submitActivityWithAssessment(assessment, isEmailListChanged, recipientList)
        if (!assessmentSubmitted || assessmentSubmitted.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: assessmentSubmitted?.message || genericTexts.defaultText.genericErrorMsg, notificationType: 'error' }))
            setActiveTab(3)
            return null
        }
        return true
    }
    const updateActivity = async (isSubmit) => {
        // check if all the steps are validated - if submit action
        const readyToSubmit = checkReadyToSubmit(isSubmit)
        if (!readyToSubmit) return
        // save activity
        const activityId = await saveActivity()
        if (!activityId) return

        // save metrics
        const metricsSaved = await saveMetrics(activityId)
        if (!metricsSaved) return

        // save assessment
        const assessmentSaved = await saveAssessment(activityId)
        if (!assessmentSaved) return

        // submit assessment
        const assessmentSubmitted = await submitAssessment(assessmentSaved, isSubmit)
        if (!assessmentSubmitted) return

        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: !activityToUpdate?.id ? genericTexts.defaultText.createdSuccess(ACTIVITY) : genericTexts.defaultText.updatedSuccess(ACTIVITY) }))
        if (isSubmit) closeUpdateActivityPopup()
    }

    const changeMetricsTab = (isNext) => {
        let newTabIndex = isNext ? activeTab + 1 : activeTab - 1
        if (newTabIndex < 0) newTabIndex = 0
        if (newTabIndex > formTabs.length - 1) newTabIndex = formTabs.length - 1
        setActiveTab(newTabIndex)
        if (newTabIndex === 2) setIsMetricsTabOpened(true)
    }

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex)
        if (tabIndex === 2) setIsMetricsTabOpened(true)
    }

    const changeValueHandler = (key, updatedActivity) => {
        setActivityToUpdate(updatedActivity)
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
    }

    const updateMetrics = (updatedMetrics, isPredefined) => {
        isPredefined ? setPredefinedMetricsToEdit(updatedMetrics) : setCustomMetrics(updatedMetrics)
    }

    const loadActivityMetrics = async (isSaved) => {
        let activityMetrics = activityToUpdate.id ? await QAApi.getActivityMetrics(activityToUpdate.id) : predefinedMetrics
        activityMetrics = activityMetrics && !activityMetrics.error ? helpers.createNewInstance(activityMetrics || []) : []
        if (isSaved) setOriginalMetrics(activityMetrics)
        setPredefinedMetricsToEdit(activityMetrics)
    }

    const loadActivityAssessment = async () => {
        const emptyAssessment = {
            ...helpers.createNewInstance(questionnaireAssessmentModel),
            org_id: activityToUpdate.org_id,
            qa_activity_id: activityToUpdate.id,
            template_id: selectedTemplate?.id,
            status: assessmentStatusesEnum.pending,
        }
        let activityAssessment = activityToUpdate.id ? await AssessmentApi.getAssessmentByActivityId(activityToUpdate.id) : emptyAssessment
        if (!activityAssessment || activityAssessment.error || activityAssessment.message ) activityAssessment = emptyAssessment
        setCurrentAssessment(activityAssessment)
        let recipientList = activityAssessment.id ? await AssessmentApi.getEmailRecipientList(activityAssessment.id) : null
        if (!recipientList || recipientList.error) recipientList = []
        setRecipientList(recipientList)
        return activityAssessment
    }


    const loadActivityTemplate = async (templateId = currentAssessment?.template_id) => {
        let activityTemplate = templateId ? await QuestionnaireApi.getQuestionnaireTemplateFull(templateId, activityToUpdate.org_id) : null
        if (!activityTemplate || activityTemplate.error) activityTemplate = null
        const filteredTemplate = getFilteredQuestionnaire(activityTemplate)
        setSelectedTemplate(filteredTemplate)
    }

    const updateActivityAndAssets = async (isSaved) => {
        setIsLoading(true)

        // update metrics
        await loadActivityMetrics(isSaved)

        // update assessment
        const activityAssessment = await loadActivityAssessment()

        // update template
        await loadActivityTemplate(activityAssessment?.template_id)
        setIsLoading(false)
    }

    const generalInformationTab = {
        label: 'General Information',
        contentComponent: QAGeneralInformation
    }

    const reviewAssessmentTab = {
        label: isReview ? 'Review Assessment' : 'Review & Submit',
        contentComponent: ReviewAssessment
    }

    const activityMetricsTab = {
        label: 'Activity Metrics',
        contentComponent: QAActivityMetrics
    }

    const templateSelectTab = {
        label: 'Select Questionnaire',
        contentComponent: QuestionnaireTemplateSelect
    }

    const formTabs = isReview ?
        [generalInformationTab, reviewAssessmentTab, activityMetricsTab] :
        [generalInformationTab, templateSelectTab, activityMetricsTab, reviewAssessmentTab]


    const calculateCompletedSteps = () => {
        let steps = Object.keys(stepsErrors).filter(step => !stepsErrors[step]).map(step => step * 1)
        if (steps.includes(2) && isNewActivity && !isMetricsTabOpened) steps = steps.filter(s => s !== 2)
        return steps
    }

    const completedSteps = calculateCompletedSteps()

    const data = {
        activeTab,
        changeValueHandler,
        setActivityToUpdate,
        setFieldsErrorMsg,
        activityToUpdate,
        fieldsErrorMsg,
        errorMsg,
        currentOrg,
        orgUsers,
        currentUser,
        customMetrics,
        predefinedMetricsToEdit,
        updateMetrics,
        newMetricModel,
        isNewActivity,
        templateUrl,
        approvedTemplates,
        setCurrentTemplates,
        currentTemplates,
        isReview,
        currentAssessment,
        setCurrentAssessment,
        selectedTemplate,
        loadActivityTemplate,
        orgs,
        setIsEmailListChanged
    }

    useEffect(() => {
        const tabsContent = document.getElementsByClassName('tab-content')
        for (let i = 0; i < tabsContent.length; i++) {
            tabsContent[i].classList.add('scroll')
        }
    }, [activeTab])

    useEffect(async () => {
        await updateActivityAndAssets(true)
    }, [])


    return (
        isLoading ? <Loader /> : <div className='update-activity-wrapper'>
            <Tabs
                layout='vertical'
                data={data}
                tabs={formTabs}
                activeIndex={activeTab}
                handleActiveTab={handleTabClick}
                isSteps={true}
                completedSteps={completedSteps} />
            <div className='form-buttons'>
                <div className='steps-buttons'>
                    {(activeTab < formTabs.length - 1) ? <button className='btn-submit' disabled={activeTab === 2 && isReview} onClick={() => changeMetricsTab(true)} id='create-qa-activity-next-step'>Next</button>
                        : <button
                            className='btn-submit'
                            onClick={() => updateActivity(true)}
                            id='save-qa-activity'
                            disabled={completedSteps.length < formTabs.length}
                        >
                            {isReview ? genericTexts.defaultText.saveItem(ACTIVITY) : <><i className='icon-send'></i>{genericTexts.defaultText.submit}</>}
                        </button>
                    }
                    <button className='btn-link' disabled={activeTab === 0} onClick={() => changeMetricsTab(false)} id='create-qa-activity-previous-step'>Back</button>
                </div>
                <button
                    className='btn-set'
                    onClick={() => updateActivity(false)}
                    id='save-activity-progress'>
                    Save Progress
                </button>
            </div>

        </div>
    )
}

export default UpdateQAActivity