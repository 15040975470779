import { Popup, InputRow, helpers, genericTexts } from 'hub-web-lib'
import { orgTypes, sizes } from 'js/constants/enums/org'
import UpdateUser from '../UserManagement/UpdateUser'
import { numericJsonValues } from 'middlewares/ApiHelpers'
import React, { useEffect } from 'react'
import OrgApi from 'services/OrgApi'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { industriesList, licenseTypes } from 'js/constants/enums/org'
import { ORG } from 'js/constants/vocabulary'
import { useState } from 'react'
import { orgToEditModel } from 'js/constants/models/org'
import { emptyJsonValues } from 'middlewares/ApiHelpers'
import 'scss/UpdateAdminItem/UpdateAdminItem.scss'

const UpdateOrg = ({
    isNewOrg,
    isOpenEditPopup,
    orgToEdit,
    updateOrgs = () => { },
    setIsOpenEditPopup = () => { },
    orgs 
}) => {
    const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(orgToEditModel))

    const [orgToEditCurrent, setOrgToEditCurrent] = useState(helpers.createNewInstance(orgToEdit))
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const addOrgToEdit = async () => {
        const numerizedOrg = numericJsonValues(orgToEditCurrent)
        if (!isPasswordValid && isNewOrg) {
            setFieldsErrorMsg({ ...fieldsErrorMsg, new_user: { ...fieldsErrorMsg.new_user, password: 'Please type strong password' } })
            return
        }
        const orgCreated = isNewOrg ? await OrgApi.createOrg(numerizedOrg) : await OrgApi.editOrg(numerizedOrg.new_org)
        if (!orgCreated || orgCreated.error) {

            if (Array.isArray(orgCreated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                orgCreated.details.forEach(err => {
                    isNewOrg ? errorDetails[err.loc[1]][err.loc[2]] = err.msg : errorDetails.new_org[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                const message = typeof orgCreated.message === 'string' ? orgCreated.message : orgCreated.message.join(', ')
                setErrorMsg(message)
            }
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: isNewOrg ? genericTexts.defaultText.createdSuccess(ORG) : genericTexts.defaultText.updatedSuccess(ORG) }))
        closePopupCreate()
        updateOrgs()
    }
    const changeValueHandler = (key, subkey, val) => {
        setOrgToEditCurrent({ ...orgToEditCurrent, [key]: { ...orgToEditCurrent[key], [subkey]: val } })
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: { ...fieldsErrorMsg[key], [subkey]: '' } })

    }

    const closePopupCreate = () => {
        setOrgToEditCurrent(helpers.createNewInstance(orgToEditModel))
        setIsOpenEditPopup(false)
        setErrorMsg('')
        setFieldsErrorMsg({ ...fieldsErrorMsgDefault })
        setIsPasswordValid(false)
    }
    useEffect(() => {
        setOrgToEditCurrent(orgToEdit)
    }, [orgToEdit])
    return (
        <Popup
            onClose={closePopupCreate}
            isOpen={isOpenEditPopup}
            title={genericTexts.defaultText.addNewPopupTitle(ORG, !isNewOrg)}
            isDisabledClickOutside={true}
            customClass={isNewOrg ? 'update-admin-item-popup extra-large' : ''}>

            <div className='form-fields'>
                <fieldset>
                    <h3 id='organization-details'>Organization Details</h3>
                    <InputRow
                        inputId='orgName'
                        title='Name'
                        changeValueHandler={(val) => changeValueHandler('new_org', 'name', val)}
                        defaultValue={orgToEditCurrent.new_org.name || ''}
                        errorMsg={fieldsErrorMsg.new_org.name} />

                    <InputRow
                        inputId='parent_id'
                        title='Parent'
                        inputTag='select'
                        placeholder='Select'
                        options={orgs}
                        changeValueHandler={(val) => changeValueHandler('new_org', 'parent_id', val)}
                        defaultValue={orgToEditCurrent.new_org.parent_id || ''}
                        errorMsg={fieldsErrorMsg.new_org.parent_id}
                        disabled={!isNewOrg} />


                    <InputRow
                        inputId='industry_id'
                        title='Industry'
                        inputTag='select'
                        placeholder='Select'
                        options={industriesList.sort((a, b) => a.name.localeCompare(b.name))}
                        changeValueHandler={(val) => changeValueHandler('new_org', 'industry_id', val)}
                        defaultValue={orgToEditCurrent.new_org.industry_id || ''}
                        errorMsg={fieldsErrorMsg.new_org.industry_id} />

                    <InputRow
                        inputId='license_id'
                        title='License'
                        inputTag='select'
                        placeholder='Select'
                        options={licenseTypes.map(l => ({ ...l, name: helpers.capitalizeString(l.name) }))}
                        changeValueHandler={(val) => changeValueHandler('new_org', 'license_id', val)}
                        defaultValue={orgToEditCurrent.new_org.license_id}
                        errorMsg={fieldsErrorMsg.new_org.license_id} />

                    <InputRow
                        inputId='domain'
                        title='Domain'
                        changeValueHandler={(val) => changeValueHandler('new_org', 'domain', val)}
                        defaultValue={orgToEditCurrent.new_org.domain || ''}
                        errorMsg={fieldsErrorMsg.new_org.domain} />

                    <InputRow
                        inputId='country'
                        title='Country'
                        inputType='country'
                        changeValueHandler={(val) => changeValueHandler('new_org', 'country', val)}
                        defaultValue={orgToEditCurrent.new_org.country || ''}
                        errorMsg={fieldsErrorMsg.new_org.country} />

                    <InputRow
                        inputId='orgType'
                        title='Type'
                        inputTag='select'
                        options={orgTypes.map(t => ({ ...t, name: helpers.capitalizeString(t.name) }))}
                        changeValueHandler={(val) => changeValueHandler('new_org', 'org_type', val)}
                        defaultValue={orgToEditCurrent.new_org.org_type}
                        errorMsg={fieldsErrorMsg.new_org.org_type} />

                    <InputRow
                        inputId='size'
                        title='Size'
                        inputTag='select'
                        options={sizes.map(s => ({ ...s, name: helpers.capitalizeString(s.name) }))}
                        changeValueHandler={(val) => changeValueHandler('new_org', 'size', val)}
                        defaultValue={orgToEditCurrent.new_org.size}
                        errorMsg={fieldsErrorMsg.new_org.size} />

                    <InputRow
                        inputId='mfa'
                        title='MFA'
                        inputType='checkbox'
                        changeValueHandler={(val) => changeValueHandler('new_org', 'mfa', val)}
                        defaultValue={orgToEditCurrent.new_org.mfa}
                        errorMsg={fieldsErrorMsg.new_org.mfa} />
                </fieldset>
                {isNewOrg && <fieldset>
                    <h3 id='admin-details'>Admin Details</h3>
                    <UpdateUser
                        userDetails={orgToEditCurrent.new_user}
                        isOrgCreation={true}
                        isNewUser={true}
                        updateUserProp={(subkey, val) => changeValueHandler('new_user', subkey, val)}
                        fieldsErrorMsgExternal={fieldsErrorMsg.new_user}
                        updatePasswordIsValid={setIsPasswordValid} />

                </fieldset>}
            </div>
            <p className='error-msg' id='create-organization-error'>{errorMsg}</p>

            <div className='popup-buttons'>
                <button className='btn-submit' onClick={addOrgToEdit} id='create-new-org'>{genericTexts.defaultText.createNewButton(ORG, !isNewOrg)}</button>
                <button onClick={closePopupCreate} className='btn-cancel' id='create-new-org-cancel'>{genericTexts.defaultText.cancel}</button>
            </div>
        </Popup>

    )
}


export default UpdateOrg