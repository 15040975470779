import { InputRow, MenuPopup, genericTexts } from 'hub-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import './TemplateCategoryContent.scss'
import { categoryNameDefault, questionTextDefault } from 'js/ui-helpers/questionnaire'
import { connect } from 'react-redux'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'

const mapStateToProps = state => {
    return {
        questionnareFeaturesList: state.questionnareFeaturesList
    }
}

const TemplateCategoryContentComponent = ({
    category,
    question,
    updateName = () => { },
    isSimpleMode,
    data = {},
    questionnareFeaturesList
}) => {
    const {
        isApproved,
        actions,
        questionActions,
        activeCategoryId,
        activeQuestionId,
        isShown,
        addQuestion = () => { },
        categoryMenuClickHandler = () => { },
        updateActiveCategory = () => { },
        updateActiveQuestion = () => { },
        updateCategoryDescription = () => { },
        expandCategory = () => { }
    } = data
    const contentRef = useRef(null)
    const isQuestion = !!question
    const name = isQuestion ? question.text : category.name
    const [isEditMode, setIsEditMode] = useState(false)
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [contentTop, setContentTop] = useState(0)
    const id = `content-${category.id}-${question?.id || (isSimpleMode ? 'category-summary' : 'category')}`
    const isShownDetails = !isQuestion && !isSimpleMode
    const visibleQuestionnaireFeatures = getEnabledSettingItems(questionnareFeaturesList)
    const menuClickHandler = (action) => {
        if (action === genericTexts.actionsText.edit) {
            setIsEditMode(true)
        } else {
            categoryMenuClickHandler(action, category.id, question?.id)
        }
    }
    const deleteActionsSettings = (act) => {
        delete act.delete
        delete act.duplicate
    }
    visibleQuestionnaireFeatures?.categories_add_delete ? actions : deleteActionsSettings(actions)
    visibleQuestionnaireFeatures?.questions_add_delete ? questionActions : deleteActionsSettings(questionActions)

    const updateItemName = (val) => {
        updateName(val, category.id, question?.id, isQuestion ? 'text' : null)
    }

    const onBlur = () => {
        if (!name) updateItemName(isQuestion ? `${questionTextDefault} ${question.id}` : `${categoryNameDefault} ${category.id}`)
        setIsEditMode(false)
    }

    const nameClick = () => {
        if (isSimpleMode && !isApproved) {
            setIsEditMode(true)
        } else {
            updateActiveCategory(category.id)
            expandCategory(category.id)
            if (question?.id) updateActiveQuestion(question.id)
        }
    }

    const getMenuTop = (contentRef, dragAndDrop, popup) => {
        const menuTop = contentRef.current?.offsetTop && dragAndDrop && popup.offsetTop ? contentRef.current?.offsetTop - dragAndDrop.scrollTop + popup.offsetTop + 10 : null
        return menuTop
    }

    useEffect(() => {
        const dragAndDrop = document.getElementById('drag-and-drop-template-categories')
        const popup = document.getElementsByClassName('questionnaire-popup')[0]
        const menuTop = getMenuTop(contentRef, dragAndDrop, popup)
        setContentTop(menuTop)
        const handleScroll = () => {
            const menuTop = getMenuTop(contentRef, dragAndDrop, popup)
            setContentTop(menuTop)
        }
        dragAndDrop.addEventListener('scroll', handleScroll)
        return () => {
            setIsOpenMenu(false)
            dragAndDrop.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const dragAndDrop = document.getElementById('drag-and-drop-template-categories')
        const popup = document.getElementsByClassName('questionnaire-popup')[0]
        const menuTop = getMenuTop(contentRef, dragAndDrop, popup)
        setContentTop(menuTop)
    }, [activeCategoryId, activeQuestionId])

    return (<>
        <div
            className={`template-category-wrapper ${isApproved ? 'approved' : ''} ${isSimpleMode ? 'template-category-summary' : `template-category-content ${!isQuestion && activeCategoryId === category.id ? 'active' : ''}`}`}
            id={id} onMouseLeave={isSimpleMode ? null : () => setIsOpenMenu(false)}
            title={name}
            ref={contentRef}>
            {isEditMode ? <InputRow
                inputId={`category-name-${category.id}`}
                defaultValue={name}
                changeValueHandler={updateItemName}
                autoFocus={true}
                selectOnFocus={true}
                handleBlur={onBlur}
                handleSubmit={onBlur} /> :
                <p onClick={nameClick}>
                    {isShownDetails && <i className='icon-folder'></i>}
                    <span>{name}</span>
                </p>}
            {isShownDetails && <span className='questions-count'>{(category.questions || []).length}</span>}
            {!isApproved && <div className={`category-actions ${question ? 'category-actions-question' : 'category-actions-category'}`}>
                {(!isQuestion && visibleQuestionnaireFeatures?.questions_add_delete) && <button className='btn-icon' title='Add Question' id='add-question' onClick={() => addQuestion(category.id)}><i className='icon-plus'></i></button>}
                <div className='menu-popup-container' style={contentTop ? { top: contentTop } : null}>
                    <MenuPopup
                        items={question?.id ? questionActions : actions}
                        id={question?.id || category.id}
                        menuClickHandler={menuClickHandler}
                        isOpen={isOpenMenu}
                        updateIsOpen={setIsOpenMenu} />
                </div>
            </div>}
        </div>
        {isSimpleMode && (category.description && isApproved || !isApproved) && isShown && <div className='category-description'>
            <InputRow
                inputId={`category-description-${id}`}
                inputTag='textarea'
                placeholder='+ Add Category Description'
                autoHeight={true}
                defaultValue={category.description}
                disabled={isApproved}
                changeValueHandler={(val) => updateCategoryDescription(val, category.id)} />
        </div>}
    </>)
}

const TemplateCategoryContent = connect(mapStateToProps)(TemplateCategoryContentComponent)

export default TemplateCategoryContent