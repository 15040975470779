import { OnboardingTour } from 'hub-web-lib/dist/index-export'
import { tenantAssessmentOnboardingDetails, tenantAssessmentOnboardingTourSteps } from 'js/constants/guest-onboarding'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import './TenantOnboarding.scss'
import UserApi from 'services/UserApi'
import { setIdsToAssessment } from 'js/ui-helpers/guest-assessment'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const TenantOnboardingAssessmentComponent = ({ currentUser }) => {
    const [isOpenTour, setIsOpenTour] = useState(false)
    const isTenantOnboardingFinished = UserApi.getUserProp('isTenantAssessmentOnboardingFinished', currentUser?.id)
    const [isTourFinished, setIsTourFinished] = useState(isTenantOnboardingFinished)
    
    const openTour = () => {
        setIdsToAssessment()
        setIsOpenTour(true)
    }

    const onCloseTour = () => {
        setIsOpenTour(false)
        setIsTourFinished(true)
        UserApi.setUserPropCookie('isTenantAssessmentOnboardingFinished', true, currentUser.id, true)
    }

    return (
        isTourFinished || <div className='tanent-assessment-onboarding'>
            <div className='start-onboarding'>
                Ready to start your cyber assessment?
                <button className='btn-link' onClick={openTour} id='start-tour'>Start Tour</button>
            </div>
            {isOpenTour &&<OnboardingTour
                steps={tenantAssessmentOnboardingTourSteps}
                parentStepDetails={tenantAssessmentOnboardingDetails}
                onCloseTour={onCloseTour} />}
        </div>
    )
}

const TenantOnboardingAssessment = connect(mapStateToProps)(TenantOnboardingAssessmentComponent)
export default TenantOnboardingAssessment
