import { questionnaireAnswerTypes } from 'js/constants/enums/questionnaire'
import { isAnsweredQuestion } from 'js/ui-helpers/questionnaire'
import React from 'react'
import './AssessmentQuestionAndAnswer.scss'

const AssessmentQuestionAndAnswer = ({
    question = {}, 
    questionNumber,
    answers,
}) => {
    
    const id = `question-answer-${question?.id}`
    const { answer, isAnswered } = isAnsweredQuestion((answers || []), question.id)
    const selectedType = questionnaireAnswerTypes.find(t => t.id === question?.answer_type_id) 
    const answersArray = (answer && Array.isArray(answer) ? answer : [answer]).filter(a => a)
    const answeredValues = isAnswered ? answersArray.map(a => 
        selectedType?.hasOptions ? 
            ((question?.possible_answers || []).find(p => p.id === a)?.name || '')
            : a) : ['-- Not answered --']

    return (
        <li className='question-and-answer' id={id}>
            <div id={`${id}-text`} className='question-name'>
                {question.is_required ? <span className='error-msg'>*</span> : ''}
                <span className='question-number'>{questionNumber}.</span>
                <span className='question-text'>{question.text}</span>
            </div>
            <div className='question-description-assessment'>{question?.description}</div>
            <ul className={answersArray.length ? '' : 'no-answer'}>
                {answeredValues.map((a, i) => <li key={i} id={`${id}-value-${i}`}>{a}</li>)}
            </ul>
        </li>
    )
}

export default AssessmentQuestionAndAnswer
