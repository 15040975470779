import React, { useEffect, useState } from 'react'
import AssessmentCategories from './AssessmentCategories'
import AssessmentQuestions from './AssessmentQuestions'
import { getFirstUnansweredQuestion } from 'js/ui-helpers/questionnaire'
import './AssessmentDomain.scss'

function AssessmentDomain({ data = {} }) {

    const {
        domain = {}, 
        answers = [], 
        allQuestions,
        isReview,
        isViewOnly,
        saveAnswers = () => {},
        isShown,
        goNextDomain
    } = data

    const categories = domain.categories
    const [activeIds, setActiveIds] = useState([])
    const updateActiveIds = (ids) => {
        if (!ids.length) return
        if (ids.length === 1) ids = getFirstUnansweredQuestion(categories, false, ids[0])
        setActiveIds(ids)
    }

    const setActiveIdsUnanswered = () => {
        const firstUnansweredIds = getFirstUnansweredQuestion(categories)
        if (firstUnansweredIds?.length) setActiveIds(firstUnansweredIds)
        return firstUnansweredIds?.length
    }

    const updateProgress = (updatedActiveIds) => {
        if (JSON.stringify(updatedActiveIds) === JSON.stringify(activeIds)) {
            if (!setActiveIdsUnanswered()) goNextDomain()
        } else {
            updateActiveIds(updatedActiveIds)
        }
    }

    useEffect(() => {
        if (isShown) {
            const firstAnswered = setActiveIdsUnanswered()
            if (!firstAnswered.length) setActiveIds(domain.categories.length ? [domain.categories[0].id, domain.categories[0].questions[0].id] : [0, 0])
        }
    }, [isShown])

    return (<div className={`questionnaire-domain ${isShown ? 'show' : ''}`}>
        <div className='categories-questions'>
            <AssessmentCategories 
                categories={categories} 
                activeIds={activeIds} 
                updateActiveIds={updateActiveIds} 
                isViewOnly={isViewOnly} />
            <AssessmentQuestions 
                categories={categories} 
                activeIds={activeIds} 
                isReview={isReview} 
                isViewOnly={isViewOnly}
                answers={answers}
                updateActiveIds={updateProgress}
                saveAnswers={saveAnswers}
                allQuestions={allQuestions} />
        </div>
    </div>)
}

export default AssessmentDomain