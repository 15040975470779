import { ACKNOWLEDGE, BY_CATEGORY, BY_QUARTER, BY_SEVERITY, CATEGORY_BY_SEVERITY, OPEN, RESOLVED, CARDS, PIE, AREA, BY_RISK } from './common-metrics'

const VULNERABILITIES_TOTALS_BY_SEVERITY = 'Vulnerabilities Totals by Severity'
const VULNERABILITIES_BY_RISK_LEVEL = 'Vulnerabilities by Risk Level'
const TOP_5_VULNERABILITIES_CATEGORIES = 'Top 5 Vulnerabilities Categories'
const VULNERABILITIES_BY_STATUS = 'Vulnerabilities by Status'
const VULNERABILITIES_BY_SEVERITY = 'Vulnerabilities by Severity'
const RISK_LEVEL_TREND = 'Risk Level Trend'
const VULNERABILITIES_CATEGORY_BY_SEVERITY = 'Vulnerabilities Category by Severity'

const NETWORK_DISCOVERY = 'Network Discovery'
const APPLICATION_SECURITY_TESTING = 'Application Security Testing'
const DR_CONFIGURATION = 'DR Configuration'
const OPERATING_SYSTEM_TESTING = 'Operating System Testing'
const MISCONFIGURATIONS = 'Misconfigurations'
const FALSE_POSITIVE = 'False Positive'

const penTestMetricsKeys = [
    BY_SEVERITY,
    BY_RISK,
    APPLICATION_SECURITY_TESTING,
    DR_CONFIGURATION,
    OPERATING_SYSTEM_TESTING,
    MISCONFIGURATIONS,
    OPEN,
    RESOLVED,
    ACKNOWLEDGE,
    FALSE_POSITIVE,
]

const penTestMetricsCategories = [
    VULNERABILITIES_TOTALS_BY_SEVERITY, 
    VULNERABILITIES_BY_RISK_LEVEL,
    TOP_5_VULNERABILITIES_CATEGORIES,
    VULNERABILITIES_BY_STATUS,
    VULNERABILITIES_BY_SEVERITY,
    RISK_LEVEL_TREND,
    VULNERABILITIES_CATEGORY_BY_SEVERITY
]

const penTestMetricsWidgets = [
    CARDS,
    PIE,
    AREA,
    BY_CATEGORY,
    OPEN,
    RESOLVED,
    ACKNOWLEDGE,
    FALSE_POSITIVE,
    BY_QUARTER,
    CATEGORY_BY_SEVERITY
]

export {
    penTestMetricsKeys,
    penTestMetricsCategories,
    penTestMetricsWidgets,

    VULNERABILITIES_TOTALS_BY_SEVERITY, 
    VULNERABILITIES_BY_RISK_LEVEL,
    TOP_5_VULNERABILITIES_CATEGORIES,
    VULNERABILITIES_BY_STATUS,
    VULNERABILITIES_BY_SEVERITY,
    RISK_LEVEL_TREND,
    VULNERABILITIES_CATEGORY_BY_SEVERITY,
    FALSE_POSITIVE,
    NETWORK_DISCOVERY,
    APPLICATION_SECURITY_TESTING,
    DR_CONFIGURATION,
    OPERATING_SYSTEM_TESTING,
    MISCONFIGURATIONS
}