import { CheckBoxGroup, InputRow } from 'hub-web-lib'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT, UPDATE_VISIBLE_MODULES } from 'js/constants/action-types'
import store from 'js/store'
import React, { useState } from 'react'
import './ModulesVisibility.scss'
import { connect } from 'react-redux'
import SettingsApi from 'services/SettingsApi'
import UserApi from 'services/UserApi'
import { getSortedFeatures } from 'js/ui-helpers/settings'
import { disabledModulesDefault } from 'js/constants/app-modules'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        visibleModules: state.visibleModules

    }
}

const ModulesVisibilityComponent = ({ currentUser, visibleModules }) => {
    const currentOrgId = currentUser?.current_org

    // temporary solution for future modules
    const getAllModules = (visibleModules) => {
        const all = getSortedFeatures(visibleModules).filter(m => m.id !== 'pt')
        const existing = all.filter(m => !disabledModulesDefault.includes(m.id))
        const future = all.filter(m => disabledModulesDefault.includes(m.id))
        return [...existing, ...future]
    }

    const allModules = currentUser ? getAllModules(visibleModules) : []
    const checkedModulesDefault = allModules.filter(m => m.isShown).map(m => m.id)
    const allModulesIds = allModules.map(m => m.id)
    const [checkedModules, setCheckedModules] = useState(checkedModulesDefault)
    const [isChanged, setIsChanged] = useState(false)
    const isUserDemoMode = UserApi.getUserProp('isDemoMode', currentUser?.id)
    const [isDemoMode, setIsDemoMode] = useState(JSON.parse(isUserDemoMode))
    const disabledModules = isDemoMode ? [] : disabledModulesDefault


    const updateItems = (newItems) => {
        setCheckedModules(newItems)
        setIsChanged(true)
    }

    const switchDemoMode = (val) => {
        setIsDemoMode(val)
        if (val) {
            setCheckedModules([...allModulesIds])
        } else {
            setCheckedModules(allModulesIds.filter(id => !disabledModulesDefault.includes(id)))
        }
        setIsChanged(true)
    }

    const saveVisibleModules = async () => {
        const updatedModules = visibleModules.map(item => ({
            ...item,
            org_id: currentOrgId,
            value: checkedModules.includes(item.key),
        }))
        const settingsUpdated = await SettingsApi.updateSetting(updatedModules)
        if (!settingsUpdated || settingsUpdated.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: settingsUpdated.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_VISIBLE_MODULES, updatedModules))
        UserApi.setUserPropCookie('isDemoMode', isDemoMode, currentUser?.id, true)
        setIsChanged(false)
    }

    return (
        <section className='visible-modules'>
            <InputRow
                inputId='demoMode'
                title='Demo Mode'
                inputType='checkbox'
                defaultValue={isDemoMode}
                changeValueHandler={switchDemoMode}
                isCompact={true} />
            <h3 id='modules-visibility'>Module Activation</h3>
            <CheckBoxGroup
                allItems={allModules}
                checkedIds={checkedModules}
                columns={1}
                isInputRow={true}
                disabledIds={disabledModules}
                updateItems={updateItems} />
            <button className='btn-submit' id='save-visible-modules' onClick={saveVisibleModules} disabled={!isChanged}>Save</button>
        </section>
    )
}


const ModulesVisibility = connect(mapStateToProps)(ModulesVisibilityComponent)

export default ModulesVisibility

