const userModel = {
    name: '',
    org_id: 0,
    user_name: null,
    email: '',
    role_id: {},
    password: '',
    avatar_url: null
}

export { userModel }