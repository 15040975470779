import { VULNERABILITIES_BY_RISK_LEVEL, TOP_5_VULNERABILITIES_CATEGORIES, VULNERABILITIES_BY_SEVERITY, VULNERABILITIES_BY_STATUS, VULNERABILITIES_CATEGORY_BY_SEVERITY, VULNERABILITIES_TOTALS_BY_SEVERITY, FALSE_POSITIVE } from 'js/constants/metrics/pen-test-metrics'
import { getMetricByProps, getMetricsByProps } from './metrics'
import { ACKNOWLEDGE, BY_CATEGORY, BY_QUARTER, CATEGORY_BY_SEVERITY, KEY, OPEN, OTHER_PROP, RESOLVED, RISK_LEVEL_TREND, PIE, CARDS, AREA, FIXED, MITIGATED, PARTIALLY_FIXED, NOT_FIXED, NOT_RETESTED } from 'js/constants/metrics/common-metrics'
import { sortByYearQuarter } from './home-dashboard'
import { RISK_DIMENSION, SEVERITY_DIMENSION } from 'js/constants/dashboard-settings'
import { getFilteredMetricsProps } from './filter'
import { helpers } from 'hub-web-lib/dist/index-export'

const vulnerabilitiesByRiskChartDetailsDefault = {
    isPercentage: false,
    direction: 'horizontal',
    size: 'large',
    isShownLabels: true,
    chartId: 'vulnerabilities-by-risk'
}

const riskLevelByCategoryChartDetailsDefault = {
    height: 400,
    chartId: 'risk-level-by-category'
}

const vulnerabilitiesCategoriesColors = [
    '#E055E2',
    '#893DE7',
    '#686DF4',
    '#3892F3',
    '#34C5E8',
    '#4aebe2',
    '#4ae881',
    '#bced68',
    '#eae54b',
    '#ffc823',
    '#ff8223',
]

const topVulnerabilitiesCategoriesChartDetails = {
    direction: 'vertical',
    size: 'small',
    colorValues: vulnerabilitiesCategoriesColors,
    chartId: 'top-vulnerabilities-by-category',
    isStacked: true,
    isValueInside: true,
    hasToolTip: false
}

const vulnerabilitiesByStatusChartColors = {
    [FIXED]: '#00e000',
    [MITIGATED]: '#00a000',
    [PARTIALLY_FIXED]: '#ffff00',
    [NOT_FIXED]: '#e00000',
    [OPEN]: '#a00000', // '#409ff7',
    [NOT_RETESTED]: '#0000ff',
    [ACKNOWLEDGE]: '#FF8B37',
    [RESOLVED]: '#65D283',
    [FALSE_POSITIVE]: '#E055E2',
}

const vulnerabilitiesByStatusChartDetailsDefault = {
    isPercentage: false,
    direction: 'vertical',
    size: 'large',
    isShownLabels: true,
    isShownLabelsValues: true,
    isDonat: true,
    chartId: 'vulnerabilities-by-status-chart',
    colorValues: [
        '#FF8B37',
        '#409ff7',
        '#65D283',
        '#E055E2'
    ],
    centeredValue: {
        title: 'Total',
        value: 0
    }
}

const bySeverityQuarterlyChartDetailsDefault = {
    direction: 'horizontal',
    chartId: 'vulnerabilities-by-severity-quarterly-chart',
    size: 'small',
}

const riskLevelTrendChartDetailsDefault = {
    isGradient: false,
    colorScheme: 'primary',
    height: 250,
    isShownLabels: true,
    chartId: 'risk-level-trend-pt-chart',
    isShownDots: true
}

const vulnerabilitiesSeverityByCategoryChartDetailsDefault = {
    direction: 'horizontal',
    isStacked: true,
    chartId: 'vulnerabilities-category-by-severity-chart',
    isValueInside: true,
}

const getVulnerabilitiesTotalsBySeverityValues = (metricsData, metricsProps, filter) => {
    const bySeverity = getMetricByProps(metricsData, VULNERABILITIES_TOTALS_BY_SEVERITY, CARDS)
    if (!bySeverity) return []
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    const vulnerabilitiesTotalsBySeverityValues = filteredProps.map(v => ({
        label: v.name,
        value: (bySeverity[v.id] || 0) * 1,
        color: v.color
    }))
    return vulnerabilitiesTotalsBySeverityValues
}

const getVulnerabilitiesTotalsBySeverityValuesImportant = (metricsData, metricsProps) => {
    const vulnerabilitiesTotalsBySeverityValues = getVulnerabilitiesTotalsBySeverityValues(metricsData, metricsProps)
    return vulnerabilitiesTotalsBySeverityValues.slice(0, 3)
}

const getVulnerabilitiesByRiskLevelChart = (metricsData, metricsProps, filter) => {
    const vulnerabilitiesByRisk = getMetricByProps(metricsData, VULNERABILITIES_BY_RISK_LEVEL, PIE) || {}
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const vulnerabilitiesByRiskChartData = filteredProps.map(v => ({
        name: v.name,
        value: (vulnerabilitiesByRisk[v.id] || 0) * 1,
        color: v.color
    }))
    const vulnerabilitiesByRiskChartDetails = {
        ...vulnerabilitiesByRiskChartDetailsDefault,
        colorValues: vulnerabilitiesByRiskChartData.map(r => r.color)
    }
    return { vulnerabilitiesByRiskChartData, vulnerabilitiesByRiskChartDetails }
}

const getRiskLevelByCategoryChart = (metricsData, metricsProps) => {
    const riskLevelByCategory = getMetricsByProps(metricsData, VULNERABILITIES_BY_RISK_LEVEL, AREA) || []
    const riskLevelByCategoryChartData = metricsProps[RISK_DIMENSION].map(v => {
        const children = riskLevelByCategory.map(metric => ({
            name: metric[KEY],
            size: metric[v.id]
        })).filter(m => m.size)
        return {
            name: v.name,
            children,
            color: v.color
        }
    }).filter(r => r.children.length)
    const riskLevelByCategoryChartDetails = {
        ...riskLevelByCategoryChartDetailsDefault,
        colorValues: riskLevelByCategoryChartData.map(r => r.color)
    }
    return { riskLevelByCategoryChartData, riskLevelByCategoryChartDetails }
}

const getTopVulnerabilitiesCategoriesData = (metricsData) => {
    const topVulnerabilitiesCategories = getMetricsByProps(metricsData, TOP_5_VULNERABILITIES_CATEGORIES, BY_CATEGORY)
    const topVulnerabilitiesCategoriesChartData = (topVulnerabilitiesCategories || []).map(metric => {
        const row = { name: `${metric[KEY]}` }
        topVulnerabilitiesCategories.forEach(m => {
            row[m[KEY]] = m[KEY] === metric[KEY] ? m[OTHER_PROP] : 0
        })
        return row
    })
    return { topVulnerabilitiesCategoriesChartData, topVulnerabilitiesCategoriesChartDetails }
}

const cleanName = (name) => {
    return (name || '').split(' ').map(n => helpers.capitalizeString(n.replace(/[^a-zA-Z]/g, ''))).join(' ') || 'N/A'
}

const getVulnerabilitiesByStatusChart = (metricsData) => {
    const byStatus = getMetricsByProps(metricsData, VULNERABILITIES_BY_STATUS)
    const vulnerabilitiesByStatusChartData = byStatus.map((metric, i) => ({
        name: cleanName(metric[KEY]),
        value: (metric[OTHER_PROP] || 0) * 1,
        color: vulnerabilitiesByStatusChartColors[metric[KEY]] || vulnerabilitiesCategoriesColors[i]
    }))
    const valuesArray = vulnerabilitiesByStatusChartData.map(i => i.value).filter(v => v)
    const vulnerabilitiesByStatusChartDetails = {
        ...vulnerabilitiesByStatusChartDetailsDefault,
        colorValues: vulnerabilitiesByStatusChartData.map(i => i.color),
        centeredValue: {
            ...vulnerabilitiesByStatusChartDetailsDefault.centeredValue,
            value: valuesArray.length ? valuesArray.reduce((a, b) => a + b) : 0
        }
    }
    return { vulnerabilitiesByStatusChartData, vulnerabilitiesByStatusChartDetails }
}

const getVulnerabilitiesBySeverityChartData = (metricsData, metricsProps, filter) => {
    const vulnerabilitiesBySeverity = getMetricsByProps(metricsData, VULNERABILITIES_BY_SEVERITY, BY_QUARTER)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    const vulnerabilitiesBySeverityChartData = (vulnerabilitiesBySeverity || []).map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    })
    const vulnerabilitiesBySeverityChartDetails = {
        ...bySeverityQuarterlyChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { vulnerabilitiesBySeverityChartData, vulnerabilitiesBySeverityChartDetails }
}

const getRiskLevelTrendChart = (metricsData, metricsProps, filter) => {
    let riskLevelTrend = getMetricsByProps(metricsData, RISK_LEVEL_TREND, BY_QUARTER)
    riskLevelTrend = sortByYearQuarter(riskLevelTrend, KEY)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const riskLevelTrendChartData = riskLevelTrend.map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    })
    const riskLevelTrendChartDetails = {
        ...riskLevelTrendChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { riskLevelTrendChartData, riskLevelTrendChartDetails }
}

const getRowSum = (row) => {
    return Object.keys(row).filter(key => key !== 'name').map(key => row[key]).reduce((acc, value) => acc + value, 0)
}

const sortRowBySum = (a, b) => {
    return getRowSum(b) - getRowSum(a)
}

const getVulnerabilitiesSeverityByCategoryChartData = (metricsData, metricsProps, filter) => {
    const vulnerabilitiesSeverityByCategory = getMetricsByProps(metricsData, VULNERABILITIES_CATEGORY_BY_SEVERITY, CATEGORY_BY_SEVERITY)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    
    const vulnerabilitiesSeverityByCategoryChartData = (vulnerabilitiesSeverityByCategory || []).map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    }).sort(sortRowBySum)

    const vulnerabilitiesSeverityByCategoryChartDetails = {
        ...vulnerabilitiesSeverityByCategoryChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    
    return { vulnerabilitiesSeverityByCategoryChartData, vulnerabilitiesSeverityByCategoryChartDetails }
}

export {
    getVulnerabilitiesTotalsBySeverityValues,
    getVulnerabilitiesTotalsBySeverityValuesImportant,
    getVulnerabilitiesByRiskLevelChart,
    getRiskLevelByCategoryChart,
    getTopVulnerabilitiesCategoriesData,
    getVulnerabilitiesByStatusChart,
    getVulnerabilitiesBySeverityChartData,
    getRiskLevelTrendChart,
    getVulnerabilitiesSeverityByCategoryChartData
}