import React, { useState } from 'react'
import { getUnansweredQuestions, getUpdatedAnswers } from 'js/ui-helpers/questionnaire'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import './AssessmentQuestions.scss'
import AssessmentQuestionsCategoryName from './AssessmentQuestionsCategoryName'
import AssessmentQuestionsHeader from './AssessmentQuestionsHeader'
import QuestionnaireQuestionsForm from './AssessmentQuestionsForm'

function AssessmentQuestions({ 
    categories = [], 
    activeIds = [], 
    answers = [],
    allQuestions,
    isReview,
    isViewOnly,
    updateActiveIds = () => {},
    saveAnswers = () => {}
}) {

    const activeCategory = categories.find(c => c.id === activeIds[0])
    const activeCategoryQuestions = activeCategory?.questions || []
    const totalUnansweredQuestionsCount = allQuestions.filter(q => !q.isAnswered).length

    const [isButtonLoading, setIsButtonLoading] = useState(false)

    const getNextActiveIds = (questionId) => {
        let nextCategoryId = activeIds[0]
        let nextQuestionId = questionId
        const questionIndex = activeCategoryQuestions.findIndex(q => q.id === questionId)
        if (questionIndex !== -1 ) {
            if (questionIndex < activeCategoryQuestions.length - 1) {
                // if not last go to next question in the same category
                return [nextCategoryId, activeCategoryQuestions[questionIndex + 1].id]
            } else {
                const unansweredRequiredQuestions = getUnansweredQuestions(activeCategory, true)
                const count = unansweredRequiredQuestions?.length || 0
                if (count) {
                    // if last and has unanswered required questions - go to the first of them
                    store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { 
                        message: <><i className='icon-warning'></i> {`${count} required question${count > 1 ? 's are' : ' is'} missed`}</>, 
                        notificationType: 'warning' 
                    }))
                    return [nextCategoryId, unansweredRequiredQuestions[0].id]
                }
            }
        } 
        const categoryIndex = categories.findIndex(c => c.id === activeCategory?.id)
        if (categoryIndex !== -1 && categoryIndex < categories.length - 1) {
            // if last question and not last category go to the first question of the next category
            const nextCategory = categories[categoryIndex + 1]
            if (nextCategory.questions?.length) {
                const nextUnansweredQuestion = nextCategory.questions[0]
                if (nextUnansweredQuestion) {
                    nextCategoryId = nextCategory.id
                    nextQuestionId = nextCategory.questions[0].id
                }
            }
        } 
        // returns next category or same values (if last category)
        return [nextCategoryId, nextQuestionId]
    }

    const goNext = (questionId) => {
        updateActiveIds(getNextActiveIds(questionId))
    }

    const saveAnswer = async (answer, questionId) => {
        setIsButtonLoading(true)
        const progressSaved = await saveAnswers(getUpdatedAnswers(answers, answer, questionId))
        if (!progressSaved || !progressSaved.error) {
            setIsButtonLoading(false)
            return
        }
        setIsButtonLoading(false)
    }

    const updateActiveQuestionId = (questionId) => {
        updateActiveIds([activeIds[0], questionId])
    }
    
    return (
        <div className='questionnaire-questions-section'>
            {isViewOnly ? 
                <AssessmentQuestionsCategoryName 
                    category={activeCategory} /> : 
                <AssessmentQuestionsHeader
                    category={activeCategory}
                    questions={activeCategoryQuestions}
                    isButtonLoading={isButtonLoading}
                    isReview={isReview} />}
            <QuestionnaireQuestionsForm
                questions={activeCategoryQuestions}
                answers={answers}
                activeIds={activeIds}
                totalUnansweredQuestionsCount={totalUnansweredQuestionsCount}
                isReview={isReview}
                isViewOnly={isViewOnly}
                goNext={goNext}
                updateActiveQuestionId={updateActiveQuestionId}
                saveAnswer={saveAnswer} />
        </div>
    )
}

export default AssessmentQuestions