import { APPLICATION } from '../enums/pen-test'

const penTestActivityModel = {
    id: 0,
    org_id: 0,
    title: '',
    start_date: '',
    end_date: '',
    test_type: APPLICATION,
    is_complete: false,
    description: '',
    attachment_url: '',
    external_reporter: '',
    reported_by: 0,
    archived: false,
    starred: false
}
export {
    penTestActivityModel
}