
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { ProgressBarCircular, WidgetBlock, helpers } from 'hub-web-lib'
import { qaImplementationValues } from 'js/constants/metrics/qa-metrics'
import { getReadinessCirclesData } from 'js/ui-helpers/qa-dashboard'
import React from 'react'

const CyberReadinessProgress = ({ data }) => {
    const { metricsData, category } = data
    const readinessCirclesData = getReadinessCirclesData(metricsData, category)
    return (
        <WidgetBlock
            title={`${category} Readiness`}
            content={readinessCirclesData ? <div className='readiness-circles'>
                {qaImplementationValues.map(value => {
                    return <ProgressBarCircular 
                        percent={readinessCirclesData[value.id]} 
                        key={value.id} 
                        title={value.name} 
                        size='regular' 
                        type={value.status}
                        chartId={`${helpers.transformToKebab(category)}-readiness-${helpers.transformToKebab(value.name)}`} />
                })}
            </div> : <NoDataPlaceholder />}
        />
    )
}

export default CyberReadinessProgress
