import { CRITICAL_PROP, HIGH_PROP, INFOMATIONAL_PROP, LOW_PROP, MEDIUM_PROP } from './metrics/common-metrics'

const dashboardSettingsDefault = [{
    value: {
        allModules: {
            allDimensions: {
                alias: 'Informational',
                color: '#409ff7'
            }
        }
    },
    value_type: 'object',
    label: 'Informational',
    key: INFOMATIONAL_PROP,
    category_id: 3
}, {
    value: {
        allModules: {
            allDimensions: {
                alias: 'Low',
                color: '#65D283'
            }
        }
    },
    value_type: 'object',
    label: 'Low',
    key: LOW_PROP,
    category_id: 3
}, {
    value: {
        allModules: {
            allDimensions: {
                alias: 'Medium',
                color: '#FF8B37'
            }
        }
    },
    value_type: 'object',
    label: 'Medium',
    key: MEDIUM_PROP,
    category_id: 3
}, {
    value: {
        allModules: {
            allDimensions: {
                alias: 'High',
                color: '#FF4A4A'
            }
        }
    },
    value_type: 'object',
    label: 'High',
    key: HIGH_PROP,
    category_id: 3
}, {
    value: {
        allModules: {
            allDimensions: {
                alias: 'Critical',
                color: '#df172b'
            }
        }
    },
    value_type: 'object',
    label: 'Critical',
    key: CRITICAL_PROP,
    category_id: 3
}]

const ALL_MODULES = 'allModules'
const ALL_DIMENSIONS = 'allDimensions'

const dashboardAllModules = {
    id: ALL_MODULES,
    name: 'All Modules'
}

const SEVERITY_DIMENSION = 'severity'
const RISK_DIMENSION = 'risk'
const RISK_LEVEL_DIMENSION = 'risk_level'
const IMPACT_DIMENSION = 'impact'
const LIKELIHOOD_DIMENSION = 'likelihood'

const standardDimensions = [{ 
    id: SEVERITY_DIMENSION,
    name: 'Severity'
}, { 
    id: RISK_DIMENSION,
    name: 'Risk'
}, { 
    id: IMPACT_DIMENSION,
    name: 'Impact'
}, { 
    id: LIKELIHOOD_DIMENSION,
    name: 'Likelihood'
}]

const dashboardDimensions = [{ 
    id: ALL_DIMENSIONS,
    name: 'All Dimensions'
}, 
...standardDimensions
]

const ALIAS_ERROR = 'Alias cannot be empty ot longer than 20 characters'

export { 
    dashboardSettingsDefault,
    ALL_MODULES,
    ALL_DIMENSIONS,
    SEVERITY_DIMENSION,
    RISK_DIMENSION,
    RISK_LEVEL_DIMENSION,
    IMPACT_DIMENSION,
    LIKELIHOOD_DIMENSION,
    dashboardAllModules,
    standardDimensions,
    dashboardDimensions,
    ALIAS_ERROR
}