import React from 'react'
const EXTERNAL = 'external'
const EXISTING = 'existing'

const participantTypes = [{
    id: EXISTING,
    name: 'Existing Participant'
}, {
    id: EXTERNAL,
    name: 'External Reporter'
}]

const downloadMetricsTemplateBtn = { id: 1, content: <><i className='icon-download-cloud'></i> Download Metrics Template</> }
const downloadFindingsTemplateBtn = { id: 2, content: <><i className='icon-download-cloud'></i> Download Findings Template</> }
const importMetricsBtn = { id: 3, content: <><i className='icon-upload-cloud'></i> Import Metrics</> }
const importFindingsBtn = { id: 4, content: <><i className='icon-upload-cloud'></i> Import Findings</> }
const calculateMetricsBtn = { id: 5, content: <><i className='icon-netfilter'></i> Calculate Metrics</> }
const metricsButtonsList = [downloadMetricsTemplateBtn, downloadFindingsTemplateBtn, importMetricsBtn, importFindingsBtn]

const DOWNLOAD_METRICS_TEMPLATE = 'Download Metrics Template'
const DOWNLOAD_FINDINGS_TEMPLATE = 'Download Findings Template'
const SYNC_FROM_CLOUD = 'Sync From Cloud'
const IMPORT_METRICS = 'Import Metrics'
const IMPORT_FINDINGS = 'Import Findings'
const UPLOAD_CLOUD = 'upload-cloud'
const DOWNLOAD_CLOUD = 'download-cloud'
const NETFILTER= 'netfilter'
export {
    EXTERNAL,
    EXISTING,
    participantTypes,
    downloadMetricsTemplateBtn,
    downloadFindingsTemplateBtn,
    importMetricsBtn,
    importFindingsBtn,
    calculateMetricsBtn,
    metricsButtonsList,
    DOWNLOAD_METRICS_TEMPLATE,
    DOWNLOAD_FINDINGS_TEMPLATE,
    IMPORT_METRICS,
    IMPORT_FINDINGS,
    UPLOAD_CLOUD,
    DOWNLOAD_CLOUD,
    NETFILTER,
    SYNC_FROM_CLOUD
}