import React from 'react'
import './About.scss'



const About = () => {

    return (
        <div className='page-content'>
            <div className='page-header'>
                <h1 id='about'>About</h1>
            </div>
            <div className='about-page'>
                <ul>
                    <li>Version: {process.env.REACT_APP_VERSION}</li>
                </ul>
            </div>
        </div>
    )
}
export default About