import React, { useEffect, useState } from 'react'
import { genericTexts, Loader, SortableTable, Popup, helpers } from 'hub-web-lib'
import { ORG } from 'js/constants/vocabulary'
import OrgApi from 'services/OrgApi'
import { industriesList, licenseTypes } from 'js/constants/enums/org'
import { statuses } from 'js/constants/enums/user'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import UpdateOrg from './UpdateOrg'
import { orgToEditModel } from 'js/constants/models/org'

const orgColumns = [{
    key: 'id',
    name: 'ID',
    type: 'string',
    width: 5
}, {
    key: 'name',
    name: 'Name',
    type: 'string',
    width: 15
}, {
    key: 'status',
    name: 'Status',
    type: 'string',
    width: 15
}, {
    key: 'industry',
    name: 'Industry',
    type: 'string',
    width: 15
}, {
    key: 'license',
    name: 'License',
    type: 'string',
    width: 15
}, {
    key: 'domain',
    name: 'Domain',
    type: 'string',
    width: 15
}, {
    key: 'country',
    name: 'Country',
    type: 'string',
    width: 15
}, {
    key: 'actions',
    name: '',
    type: 'actions',
    sortable: false,
    filterable: false,
    width: 5
}]



const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

export const OrgManagementComponent = ({ currentUser, currentUserOrgs, routerData }) => {

    const { 
        updateCurrentUserOrgs = () => {}
    } = routerData

    const isHubAdmin = currentUser?.org_id === 1 && currentUser?.is_admin
    const currentOrgId = currentUser?.current_org 

    const [isLoading, setIsLoading] = useState(true)
    const [selectedOrgId, setSelectedOrgId] = useState('')
    const [isOpenStatusPopup, setIsOpenStatusPopup] = useState(false)
    const [isOpenEditPopup, setIsOpenEditPopup] = useState(false)
    const [orgToEdit, setOrgToEdit] = useState({ ...orgToEditModel })
    const [isNewOrg, setIsNewOrg] = useState(true)

    const getSelectedOrg = (orgId = selectedOrgId) => {
        const selectedOrg = currentUserOrgs.find(u => u.id === orgId * 1)
        const selectedOrgTitle = selectedOrg?.name || `${ORG} ${orgId}`
        return { selectedOrg, selectedOrgTitle }
    }

    const getOrgActions = org => {
        const actions = { [genericTexts.actionsText.edit]: { text: genericTexts.actionsText.editText(ORG, true), order: 1 } }
        if (currentOrgId !== org.id) actions[genericTexts.actionsText.status] = { text: genericTexts.actionsText.statusText(ORG, org.status === 1), order: 2 }
        return actions
    }

    const { selectedOrgTitle } = getSelectedOrg()
    const columns = isHubAdmin ? orgColumns : orgColumns.filter(c => !['actions', 'status'].includes(c.key))

    const getTableData = () => {

        const data = currentUserOrgs.map(org => {
            const actions = isHubAdmin && org.id !== 1 ? getOrgActions(org) : null
            const orgStatusName = helpers.capitalizeString(statuses.find(s => s.id === org.status)?.name || '-')
            const license = helpers.capitalizeString(licenseTypes.find(l => l.id === org.license_id)?.name || '-')
            const industry = industriesList.find(i => i.id === org.industry_id)?.name || '-'
            const row = {
                ...org,
                industry,
                license,
                highlighted: org.org_id === currentUser.org_id
            }
            if (actions) row.actions = actions
            if (isHubAdmin) row.status = orgStatusName
            return row
        })
        return data
    }

    const tableData = getTableData()

    const openEditOrg = (selectedOrg) => {
        setOrgToEdit({ new_org: { ...selectedOrg } })
        setIsNewOrg(false)
        setIsOpenEditPopup(true)
    }

    const menuClickHandler = async (action, orgId) => {
        setSelectedOrgId(orgId)
        const { selectedOrg } = getSelectedOrg(orgId)
        switch (action) {
        case genericTexts.actionsText.status:
            selectedOrg?.status === 1 ? setIsOpenStatusPopup(true) : await changeOrgStatus(orgId)
            break

        case genericTexts.actionsText.edit:
            openEditOrg(selectedOrg)
            break
        }
    }

    const handleTdClick = (_, orgId) => {
        setSelectedOrgId(orgId)
        const { selectedOrg } = getSelectedOrg(orgId)
        openEditOrg(selectedOrg)
    }

    const changeOrgStatus = async (orgId = selectedOrgId) => {
        const { selectedOrg, selectedOrgTitle } = getSelectedOrg(orgId)
        setIsOpenStatusPopup(false)
        const isActive = selectedOrg?.status === 1
        const orgStatusChanged = isActive ? await OrgApi.disableOrg(orgId) : await OrgApi.enableOrg(orgId)
        if (!orgStatusChanged || orgStatusChanged.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: orgStatusChanged.message, notificationType: 'error' }))
            return
        }
        let message = genericTexts.defaultText.deletedSuccess(selectedOrgTitle, false, true, isActive)
        let notificationType = null
        if(orgStatusChanged.not_locked_users && orgStatusChanged.not_locked_users.length > 0) {
            const userNamesStr = orgStatusChanged.not_locked_users.join(', ')
            message += '. Please note these users were not locked due to their special permissions: ' + userNamesStr
            notificationType = 'warning'
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message, notificationType }))
        await updateCurrentUserOrgs()
    }

    const openAddUserPopup = () => {
        setIsOpenEditPopup(true)
        setOrgToEdit({ ...orgToEditModel })
        setIsNewOrg(true)
    }


    useEffect(async () => {
        if (currentUserOrgs.length) setIsLoading(false)
    }, [currentUserOrgs])

    return (
        <div className='scheduler page-content'>
            <div className='page-header'>
                <h1 id='organization-management'>Organization Management</h1>
                {!isLoading && isHubAdmin && <button onClick={openAddUserPopup} id='add-new-org'>{genericTexts.defaultText.addNewButton(ORG)}</button>}
            </div>
            {isLoading ? <Loader /> : <div className='orgs-con'>
                <SortableTable
                    columns={columns}
                    tableData={tableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    handleTdClick={handleTdClick}
                    tableId='orgs-table' />

                {isHubAdmin && <Popup
                    onClose={() => setIsOpenStatusPopup(false)}
                    isOpen={isOpenStatusPopup}
                    title={genericTexts.defaultText.deletePopupTitle}>
                    <p>{genericTexts.defaultText.deletePopupText(selectedOrgTitle, true)}</p>
                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={() => changeOrgStatus(selectedOrgId)} id='change-org-status'>{genericTexts.defaultText.yes}</button>
                        <button onClick={() => setIsOpenStatusPopup(false)} className='btn-cancel' id='change-org-status-cancel'>{genericTexts.defaultText.no}</button>
                    </div>
                </Popup>}

                {isHubAdmin && <UpdateOrg 
                    isNewOrg={isNewOrg} 
                    isOpenEditPopup={isOpenEditPopup}
                    orgToEdit={orgToEdit}
                    updateOrgs={updateCurrentUserOrgs}
                    setIsOpenEditPopup={setIsOpenEditPopup}
                    orgs={currentUserOrgs}/>
                    
                }
            </div>}
        </div>
    )
}

const OrgManagement = connect(mapStateToProps)(OrgManagementComponent)

export default OrgManagement