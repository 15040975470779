import React, { useEffect, useState } from 'react'
import { genericTexts, Loader, Popup, SortableTable, helpers } from 'hub-web-lib'
import { USER } from 'js/constants/vocabulary'
import UserApi from 'services/UserApi'
import UpdateUser from './UpdateUser'
import { userModel } from 'js/constants/models/user'
import { roles, statuses } from 'js/constants/enums/user'
import { usersColumns, actionColumn } from 'js/constants/table-columns'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { emptyJsonValues } from 'middlewares/ApiHelpers'
import { getUserRole } from 'js/ui-helpers/roles'
const fieldsErrorMsgDefault = emptyJsonValues({ ...userModel, role_id: '' })

const userActions = {
    [genericTexts.actionsText.edit]: { text: genericTexts.actionsText.editText(USER, true), order: 1 }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}


export const UserManagementComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    
    userModel.org_id = currentOrgId

    const columns = [...usersColumns, actionColumn]

    const [isLoading, setIsLoading] = useState(true)
    const [orgUsers, setOrgUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState('')
    const [isOpenStatusPopup, setIsOpenStatusPopup] = useState(false)
    const [isOpenUserPopup, setIsOpenUserPopup] = useState(false)
    const [isOpenRemovePopup, setIsOpenRemovePopup] = useState(false)
    const [isOpenPermissionsPopup, setIsOpenPermissionsPopup] = useState(false)
    const [isNewUser, setIsNewUser] = useState(false)
    const [userToEdit, setUserToEdit] = useState({ ...userModel })
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })

    const getSelectedUser = (userId = selectedUserId) => {
        const selectedUser = orgUsers.find(u => u.id === userId * 1)
        const selectedUserTitle = selectedUser?.name || selectedUser?.user_name || `${USER} ${userId}`
        return { selectedUser, selectedUserTitle }
    }

    const deleteUser = async (userId) =>{
        const userDeleted = await UserApi.deleteUser(userId)
        if (!userDeleted || userDeleted.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(selectedUserTitle), notificationType: 'error' }))
            setIsOpenRemovePopup(false)
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedSuccess(selectedUserTitle) }))
        setIsOpenRemovePopup(false)
        await updateOrgUsers()

    }
    const { selectedUserTitle } = getSelectedUser()

    const getTableData = () => {

        const data = orgUsers.map(user => {
            const actions = { ...userActions }
            const isMe = user.id === currentUser.id
            if (!isMe) {
                actions[genericTexts.actionsText.status] = { text: genericTexts.actionsText.statusText(USER, user.status === 1), order: 2 }
                actions[genericTexts.actionsText.delete] = { text: genericTexts.actionsText.deleteText(USER, true), order: 3 }
            }
            const userRole = roles.find(r => r.id === getUserRole(user))
            const userRoleName = userRole?.name ? helpers.capitalizeString(userRole.name) : '-'
            const userStatus = statuses.find(s => s.id === user.status)
            const userStatusName = userStatus?.name ? helpers.capitalizeString(userStatus.name) : '-'
            return {
                ...user, 
                name: user.name || '',
                role: userRoleName,
                status: userStatusName,
                actions,
                highlighted: isMe
            }
        })
        return data
    }

    const tableData = getTableData()

    const updateOrgUsers = async () => {
        const users = await UserApi.getOrgUsers(currentOrgId)
        if (!users || users.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: users.message, notificationType: 'error' }))
            return
        }
        setOrgUsers(users)
    }

    const openEditUser = (selectedUser) => {
        setIsNewUser(false)
        setUserToEdit({ ...selectedUser })
        setIsOpenUserPopup(true)
    }

    const menuClickHandler = async (action, userId) => {
        setSelectedUserId(userId)
        const { selectedUser } = getSelectedUser(userId)
        switch (action) {
        case genericTexts.actionsText.status:
            selectedUser?.status === 1 ? setIsOpenStatusPopup(true) : await changeUserStatus(userId)
            break
        
        case genericTexts.actionsText.edit:
            openEditUser(selectedUser)
            break
        case genericTexts.actionsText.delete:
            selectedUser?.is_in_use ? setIsOpenPermissionsPopup(true) : setIsOpenRemovePopup(true)
            break
        }
    }

    const handleTdClick = (_, userId) => {
        setSelectedUserId(userId)
        const { selectedUser } = getSelectedUser(userId)
        openEditUser(selectedUser)
    }

    const changeUserStatus = async (userId = selectedUserId) => {
        const { selectedUser, selectedUserTitle } = getSelectedUser(userId)
        setIsOpenStatusPopup(false)
        const isActive = selectedUser?.status === 1
        const userStatusChanged = isActive ? await UserApi.disableUser(userId) : await UserApi.enableUser(userId)
        if (!userStatusChanged || userStatusChanged.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: userStatusChanged.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedSuccess(selectedUserTitle, false, true, isActive) }))
        await updateOrgUsers()
    }

    const openAddUserPopup = () => {
        setIsNewUser(true)
        setIsOpenUserPopup(true)
        setUserToEdit({ ...userModel })
    }

    const updateUserDetails = async() => {
        await updateOrgUsers()
    }

    const closePopupUser = () => {
        setUserToEdit({ ...userModel })
        setFieldsErrorMsg({ ...fieldsErrorMsgDefault })
        setIsOpenUserPopup(false)
    }

    useEffect( async () => {
        await updateOrgUsers()
        setIsLoading(false)
    }, [])

    return (
        <div className='scheduler page-content'>
            <div className='page-header'>
                <h1 id='user-management'>User Management</h1>
                {!isLoading && <button onClick={openAddUserPopup} id='add-new-user'>{genericTexts.defaultText.addNewButton(USER)}</button>}
            </div>
            {isLoading ? <Loader /> : <div className='users-con'>
                <SortableTable 
                    columns={columns}
                    tableData={tableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    handleTdClick={handleTdClick}
                    tableId='users-table' />

                <Popup
                    onClose={() => setIsOpenStatusPopup(false)}
                    isOpen={isOpenStatusPopup}
                    title={genericTexts.defaultText.deletePopupTitle}>
                    <p>{genericTexts.defaultText.deletePopupText(selectedUserTitle, true)}</p>
                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={() => changeUserStatus(selectedUserId)} id='change-user-status'>{genericTexts.defaultText.yes}</button>
                        <button onClick={() => setIsOpenStatusPopup(false)} className='btn-cancel' id='change-user-cancel'>{genericTexts.defaultText.no}</button>
                    </div>
                </Popup>
                <Popup
                    onClose={() => setIsOpenRemovePopup(false)}
                    isOpen={isOpenRemovePopup}
                    title={genericTexts.defaultText.deletePopupTitle}>
                    <p>{genericTexts.defaultText.deletePopupText(selectedUserTitle, false)}</p>
                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={() => deleteUser(selectedUserId)} id='change-user-status'>{genericTexts.defaultText.yes}</button>
                        <button onClick={() => setIsOpenRemovePopup(false)} className='btn-cancel' id='change-user-cancel'>{genericTexts.defaultText.no}</button>
                    </div>
                </Popup>
                <Popup
                    onClose={closePopupUser}
                    isOpen={isOpenUserPopup}
                    title={genericTexts.defaultText.addNewPopupTitle(USER, !isNewUser)}
                    isDisabledClickOutside={true}
                    customClass='update-admin-item-popup extra-large'>
                    
                    <UpdateUser
                        userDetails={userToEdit}
                        isPopup={true}
                        closePopupUser={closePopupUser}
                        isNewUser={isNewUser}
                        updateUserDetails={updateUserDetails}
                        fieldsErrorMsgExternal={fieldsErrorMsg} />
                </Popup>
                {/* No permissions Popup */}
                <Popup
                    onClose={() => setIsOpenPermissionsPopup(false)}
                    isOpen={isOpenPermissionsPopup}
                    title='Cannot Delete'>
                    <p>{`${selectedUserTitle} has related items and cannot be deleted`}</p>
                    <div className='popup-buttons'>
                        <button onClick={() => setIsOpenPermissionsPopup(false)} className='btn-submit' id='delete-qa-activity-cancel'>OK</button>
                    </div>
                </Popup>

            </div>}
        </div>     
    )
}

const UserManagement = connect(mapStateToProps)(UserManagementComponent)

export default UserManagement