import React from 'react'
import Cloud from 'assets/illustrations/cloud.svg'
import './ImportTemplateButtons.scss'

const ImportTemplateButtons = ({ displayText = 'Please import data to display calculated metrics', img = Cloud, templateButtons = [] }) => {
    return (
        <div className='import-alert'>
            <div className='import-button-group'>
                <img src={img} className='boxes-logo' />
                <p className='import-msg'>{displayText} </p>
                <div className={'download-template-buttons'}>
                    {templateButtons.map((templateButton, index) =>
                        <button className='btn-set btn-link' id={templateButton.id} onClick={templateButton.func} key={index}>
                            <i className={'icon-' + templateButton.icon}></i>
                            {templateButton.title}
                        </button>)}
                </div>
            </div>
        </div>

    )
}

export default ImportTemplateButtons