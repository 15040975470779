const APPLICATION = 'application'
const INFRASTRUCTURE = 'infrastructure'

const penTestTypes = [{ 
    id: APPLICATION, 
    name: 'Application' 
}, { 
    id: INFRASTRUCTURE, 
    name: 'Infrastructure' 
}]
const getPenTestTypeNames = () => {
    const names = {}
    penTestTypes.forEach(t => {
        names[t.id] = t.name
    })
    return names
}
const penTestTypeNames = getPenTestTypeNames()

export {
    APPLICATION,
    INFRASTRUCTURE,
    penTestTypes,
    penTestTypeNames
}