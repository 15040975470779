import React from 'react'
import '../InternalThreats.scss'
import { WidgetBlock } from 'hub-web-lib'
import { Accordion } from 'hub-web-lib'
import { SortableTable } from 'hub-web-lib'
import { ChatWidget } from 'hub-web-lib'
import { suggestionsList, internalThreatsData } from 'js/constants/lorem-ipsum'
import { useLocation } from 'react-router-dom'

const threatsColumns = [{ 
    key: 'name', 
    name: 'Asset Name',
    type: 'string',
    width: 60
}, { 
    key: 'defineCriticality', 
    name: '',
    type: 'component',
    sortable: false,
    filterable: false,
    width: 20
}, { 
    key: 'improve', 
    name: '',
    type: 'component',
    sortable: false,
    filterable: false,
    width: 20
}]

export const InternalThreatsAssets = () => {
    const location = useLocation()
    const pathItems = location.pathname.split('/')
    const threatId = pathItems[pathItems.length - 1] * 1
    const threatItem = internalThreatsData.find(i => i.id === threatId) || {}

    const tableData = (threatItem?.assets || []).map(item => {
        return {
            id: item.id,
            name: `Asset ${item.id}: ${item.name}`,
            defineCriticality: <span className='text-icon'>Define criticality <i className='icon-s-menu-v'></i></span>,
            improve: <span className='text-icon'>Improve protection <i className='icon-shield'></i></span>
        }
    })

    return (
        <div className='internal-threats-assets page-content'>
            <div className='page-header'>
                <h1 id={`internal-threat-${threatItem.id}`}>Internal Threat #<strong>{threatItem.id}</strong> [<strong>{threatItem.type}</strong>]: affected assets</h1>
            </div>
            <SortableTable columns={threatsColumns} noFilter={true} tableData={tableData} tableId='internal-threads-assets-table' />
            <div className='bottom-widgets'>

                <ChatWidget />

                <WidgetBlock 
                    title='Top Suggestions'
                    content={ <Accordion list={suggestionsList} count={ 3 } voting={true} /> }
                    size='small'
                />
            </div>
        </div>
    )
}

export default InternalThreatsAssets