import axios from 'axios'
import { dispatchRequest } from 'middlewares/ApiHelpers'

const SettingsApi = {
    getSettingsByCategoryId: async (categoryId) => {
        return dispatchRequest(axios.get(`/settings/get_settings/${categoryId}`)).then(res => res.data)
    },
    updateSetting: async (body) => {
        const updatedSettings = body.map(i => ({ ...i, value: JSON.stringify(i.value) }))
        return dispatchRequest(axios.put('/settings/setting', updatedSettings)).then(res => res.data)
    },
    getAllSettings: async (orgId) => {
        return dispatchRequest(axios.get('/settings/all', { params: { org_id: orgId } })).then(res => res.data)
    },
    orgLogo: async () => {
        const hubGuardAccessToken = process.env.REACT_APP_HUBGUARD_LOGO_API
        const customHeaders = {
            'logo-token': hubGuardAccessToken

        }

        return await dispatchRequest(axios.get('/settings/org-logo', { headers: customHeaders })).then(res => res.data)
    },
}

export default SettingsApi