import { CheckBoxGroup } from 'hub-web-lib'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT, UPDATE_DDOS_FEATURES } from 'js/constants/action-types'
import store from 'js/store'
import { React, useState } from 'react'
import { connect } from 'react-redux'
import SettingsApi from 'services/SettingsApi'
import { getSortedFeatures } from 'js/ui-helpers/settings'


const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        ddosFeaturesList: state.ddosFeaturesList
    }
}

const DDoSFeaturesComponent = ({ currentUser, ddosFeaturesList }) => {
    const [isChanged, setIsChanged] = useState(false)
    const allFeatures = currentUser ? getSortedFeatures(ddosFeaturesList) : []
    const checkedFeaturesDefault = allFeatures.filter(m => m.isShown).map(m => m.id)
    const [checkedFeatures, setCheckedFeatures] = useState(checkedFeaturesDefault)

    const updateItems = (newItems) => {
        setCheckedFeatures(newItems)
        setIsChanged(true)
    }

    const saveVisibleFeatures = async () => {
        const updatedFeatures = ddosFeaturesList.map(item => ({
            ...item,
            value: checkedFeatures.includes(item.key),
        }))
        const settingsUpdated = await SettingsApi.updateSetting(updatedFeatures)
        if (!settingsUpdated || settingsUpdated.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: settingsUpdated.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_DDOS_FEATURES, updatedFeatures))
        setIsChanged(false)
    }

    return (
        <section className='visible-modules'>
            <CheckBoxGroup
                allItems={allFeatures}
                checkedIds={checkedFeatures}
                columns={1}
                isInputRow={true}
                updateItems={updateItems} />
            <button className='btn-submit' id='save-visible-modules' onClick={saveVisibleFeatures} disabled={!isChanged}>Save</button>
        </section>
    )
}


const DDoSFeatures = connect(mapStateToProps)(DDoSFeaturesComponent)

export default DDoSFeatures

