import { penTestTypes } from './enums/pen-test'
import { CATEGORY, FULLY, FULLY_PROP, KEY, NOT, NOT_PROP, PARTIALLY, PARTIALLY_PROP, WIDGET } from './metrics/common-metrics'

const actionColumn = {
    key: 'actions',
    name: '',
    type: 'actions',
    sortable: false,
    filterable: false,
    width: 5
}

const idColumn = {
    key: 'id',
    name: 'ID',
    type: 'string',
    width: 5
}

const findingsColumns = [{
    key: 'title',
    name: 'Finding Title',
    type: 'string'
}, {
    key: 'date',
    name: 'Date',
    type: 'string'
}, {
    key: 'statusTitle',
    name: 'Status',
    type: 'component',
    sortBy: 'status'
}]

const findingsColumnsFull = [...findingsColumns, {
    key: 'risk_description',
    name: 'Risk Description',
    type: 'string'
}, {
    key: 'description',
    name: 'Description',
    type: 'string'
}, {
    key: 'recommendations',
    name: 'Recommendations',
    type: 'component',
    sortable: false,
    filterable: false
}]

const assetColumns = [{
    key: 'id',
    name: 'ID',
    type: 'string',
    width: 5
}, {
    key: 'system_name',
    name: 'System Name',
    type: 'string',
    width: 20
}, {
    key: 'type',
    name: 'Type',
    type: 'string',
    width: 10
}, {
    key: 'created',
    name: 'Created on',
    type: 'string',
    width: 10
}, {
    key: 'domain',
    name: 'Domain',
    type: 'string',
    width: 30
}, {
    key: 'full_url',
    name: 'Full URL',
    type: 'string',
    width: 30
}, {
    key: 'port',
    name: 'Port',
    type: 'string',
    width: 10
}, {
    key: 'actions',
    name: '',
    type: 'actions',
    sortable: false,
    filterable: false,
    width: 5
}]

const usersColumns = [
    idColumn, 
    {
        key: 'name',
        name: 'Full Name',
        type: 'string',
        width: 15
    }, {
        key: 'user_name',
        name: 'Username',
        type: 'string',
        width: 20
    }, {
        key: 'role',
        name: 'Role',
        type: 'string',
        width: 15
    }, {
        key: 'status',
        name: 'Status',
        type: 'string',
        width: 15
    }, {
        key: 'email',
        name: 'Email',
        type: 'string',
        width: 25
    }]

const testTypeColumn = {
    key: 'testType',
    name: 'Test Type',
    type: 'string',
    filterOptions: penTestTypes.map(t => t.name),
    width: 15
}

const activityColumns = [
    {
        key: 'star',
        name: '',
        type: 'component',
        sortable: false,
        filterable: false,

    },
    idColumn,
    {
        key: 'title',
        name: 'Title',
        type: 'string',
        width: 20
    }, {
        key: 'description',
        name: 'Description',
        type: 'string',
        width: 40
    }, {
        key: 'startDate',
        name: 'Start Date',
        type: 'string',
        sortBy: 'start_date',
        width: 10
    }, {
        key: 'endDate',
        name: 'End Date',
        type: 'string',
        sortBy: 'end_date',
        width: 10
    }, {
        key: 'completed',
        name: 'Status',
        type: 'string',
        width: 5
    },
    actionColumn
]

const qaMetricsColumns = [{
    key: CATEGORY,
    name: 'Category',
    type: 'string'
}, {
    key: WIDGET,
    name: 'Widget',
    type: 'string'
}, {
    key: KEY,
    name: 'Key',
    type: 'string'
}, {
    key: 'fully',
    name: FULLY,
    sortBy: 'fully_implemented',
    type: 'component'
}, {
    key: 'partially',
    name: PARTIALLY,
    sortBy: 'partially_implemented',
    type: 'component'
}, {
    key: 'not',
    name: NOT,
    sortBy: 'not_implemented',
    type: 'component'
}, {
    key: 'edit',
    name: 'Editable',
    type: 'string'
}]

const qaMetricsColumnsForm = [{
    key: 'category_select',
    name: 'Category',
    type: 'component',
    sortBy: CATEGORY
}, {
    key: 'widget_select',
    name: 'Widget',
    type: 'component',
    sortBy: WIDGET
}, {
    key: 'key_select',
    name: 'Key',
    type: 'component',
    sortBy: KEY
}, {
    key: 'fully_implemented_value',
    name: '',
    sortBy: FULLY_PROP,
    type: 'component',
    filterable: false,
    sortable: false
}, {
    key: 'partially_implemented_value',
    name: '',
    sortBy: PARTIALLY_PROP,
    type: 'component',
    filterable: false,
    sortable: false
}, {
    key: 'not_implemented_value',
    name: '',
    sortBy: NOT_PROP,
    type: 'component',
    filterable: false,
    sortable: false
}, {
    key: 'edit',
    name: '',
    type: 'component',
    filterable: false,
    sortable: false
}]

const questionnaireListColumns = [
    idColumn, 
    {
        key: 'nameEllipsed',
        name: 'Name',
        type: 'component',
        sortBy: 'name',
    }, {
        key: 'version',
        name: 'Version',
        type: 'string',
    }, {
        key: 'createdDate',
        name: 'Created',
        type: 'string',
        sortBy: 'creation_date',
        filterable: false,
    }, {
        key: 'createdBy',
        name: 'Created By',
        type: 'component',
        sortBy: 'createdByName',
    }, {
        key: 'manager',
        name: 'Manager',
        type: 'component',
        sortBy: 'managerName',
    }, {
        key: 'status',
        name: 'Status',
        type: 'component',
        sortBy: 'approved',
    }, 
    actionColumn
]

const assessmentsColumns = [{
    key: 'title',
    name: 'Title',
    type: 'string'
},
{
    key: 'activityDate',
    name: 'Activity Date',
    type: 'string',
    sortBy: 'activity_date'
},
{
    key: 'questionnaire',
    name: 'Questionnaire',
    type: 'string'
},
{
    key: 'version',
    name: 'Version',
    type: 'string'
}, {
    key: 'questionnaireDate',
    name: 'Questionnaire Date',
    type: 'string',
    sortBy: 'creation_date'
}, {
    key: 'assessmentDate',
    name: 'Assessment Date',
    type: 'string',
    sortBy: 'email_date'
},
{
    key: 'domains',
    name: 'Domains',
    type: 'component',
    sortable: false,
    filterable: false
}, {
    key: 'progress',
    name: 'Progress',
    type: 'component',
    sortBy: 'progressPercent',
    filterable: false
}, {
    key: 'status',
    name: 'Status',
    type: 'component',
    sortBy: 'statusValue',
    filterOptions: ['Pending', 'In process', 'Ready to submit', 'Submitted']
}, actionColumn
]

const assessmentDomainsColumns = [{
    key: 'name',
    name: 'Domain name',
    type: 'string'
}, {
    key: 'categoriesCount',
    name: 'Categories',
    type: 'string'
}, {
    key: 'totalQuestions',
    name: 'Total Questions',
    type: 'string'
}, {
    key: 'answeredQuestions',
    name: 'Answered Questions',
    type: 'string'
}, {
    key: 'totalRequiredQuestions',
    name: 'Total Required',
    type: 'string'
}, {
    key: 'answeredRequiredQuestions',
    name: 'Answered Required',
    type: 'string'
}]

const templateTableColumns = [{
    key: 'selectTemplate',
    name: 'Select',
    type: 'component',
    sortBy: 'selected',
    width: 5
}, {
    key: 'nameEllipsed',
    name: 'Name',
    type: 'component',
    sortBy: 'name',
    width: 20
}, {
    key: 'descriptionEllipsed',
    name: 'Description',
    type: 'component',
    sortBy: 'description',
    width: 20
}, {
    key: 'code',
    name: 'Code',
    type: 'string',
    width: 10
}, {
    key: 'version',
    name: 'Version',
    type: 'string',
    width: 10
}, {
    key: 'creation_date',
    name: 'Creation Date',
    type: 'string',
    width: 10
}]

const penTestActivityColumns = [...activityColumns.slice(0, 4), testTypeColumn, ...activityColumns.slice(4)]

export {
    findingsColumns,
    findingsColumnsFull,
    assetColumns,
    usersColumns,
    actionColumn,
    idColumn,
    activityColumns,
    qaMetricsColumns,
    qaMetricsColumnsForm,
    questionnaireListColumns,
    assessmentsColumns,
    assessmentDomainsColumns,
    templateTableColumns,
    penTestActivityColumns
}