import React, { useEffect, useState } from 'react'
import 'scss/UpdateActivity/UpdateActivity.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Popup, genericTexts, Loader, SortableTable, RadioGroup, menuActionIcons } from 'hub-web-lib'
import { ACTIVITY, ASSESSMENT } from 'js/constants/vocabulary'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import QAApi from 'services/QAApi'
import { activityColumns } from 'js/constants/table-columns'
import { UpdateQAActivity } from './UpdateActivity/UpdateQAActivity'
import QuestionnaireApi from 'services/QuestionnaireApi'
import UserApi from 'services/UserApi'
import { getUserRole } from 'js/ui-helpers/roles'
import { getActivityTableData, getSelectedActivity } from 'js/ui-helpers/activity'
import { ARCHIVED, activityStatusesEnum, allOption, nonCompleteNotification, starArchive, ARCHIVED_PROP, STARRED_PROP } from 'js/constants/enums/activity'
import { ACTIVITY_PATH, CYBER_READINESS_MODULE, appModulesPaths } from 'js/constants/app-modules'
import { ACTIVE } from 'js/constants/metrics/common-metrics'
import 'scss/ActivityManagement.scss'
import AssessmentApi from 'services/AssessmentApi'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

const actions = currentActivity => {
    const result = {}
    result[genericTexts.actionsText.info] = { text: 'View Info', order: 1 }
    if(currentActivity.status !== activityStatusesEnum.cancelled) {
        result[genericTexts.actionsText.edit] = { text: genericTexts.actionsText.editText(ACTIVITY, true), order: 2 }
        result[genericTexts.actionsText.groupRemove] = { text: `${genericTexts.defaultText.cancel} ${ACTIVITY}`, order: 3 }
    }
    result[genericTexts.actionsText.delete] = { text: genericTexts.actionsText.deleteText(ACTIVITY), order: 4 }
    return result
}

export const QAActivityManagementComponent = ({ currentUser, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const [isLoading, setIsLoading] = useState(true)
    const [activities, setActivities] = useState([])
    const [predefinedMetrics, setPredefinedMetrics] = useState([])
    const [selectedActivityId, setSelectedActivityId] = useState('')
    const [selectedActivityIds, setSelectedActivityIds] = useState([])
    const [isOpenActivityPopup, setIsOpenActivityPopup] = useState(false)
    const [isNewActivity, setIsNewActivity] = useState(false)
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const [isOpenPermissionsPopup, setIsOpenPermissionsPopup] = useState(false)
    const [isOpenCancelPopup, setIsOpenCancelPopup] = useState(false)
    const [currentOrg, setCurrentOrg] = useState(null)
    const [orgUsers, setOrgUsers] = useState([])
    const [approvedTemplates, setApprovedTemplates] = useState(null)
    const [isShowArchived, setIsShowArchived] = useState(false)
    const [templateUrl, setTemplateUrl] = useState('')
    const [filterType, setFilterType] = useState('all')
    const [searchParams] = useSearchParams()
    const [starArray, setStarArray] = useState()
    const providedId = searchParams.get('id')
    const activityColumnsUser = currentUser?.is_admin ? activityColumns : [...activityColumns].filter(c => c.key !== 'organization')
    const navigate = useNavigate()
    const { selectedActivity, selectedActivitiesCount, activitiesText, wasText, deleteActivitiesText } = getSelectedActivity(activities, selectedActivityId, selectedActivityIds)
    const selectedActivities = selectedActivitiesCount ? activities.filter(a => selectedActivityIds.includes(a.id)) : [selectedActivity]
    const selectedHasAssessment = selectedActivities.find(a => a.has_assessment)

    const getMultiCheckActions = () => {
        const actions = {
            [genericTexts.actionsText.archive]: { text: isShowArchived ? 'Unarchive' : 'Archive', icon: menuActionIcons[genericTexts.actionsText.archive] },
        }
        const activitiesInUse = activities.filter(a => a.is_in_use)
        if (!activitiesInUse.find(a => selectedActivityIds.includes(a.id))) {
            actions[genericTexts.actionsText.delete] = { text: genericTexts.actionsText.deleteText(), icon: menuActionIcons[genericTexts.actionsText.delete] }
        }
        const cancelledActivities = activities.filter(a => a.status === activityStatusesEnum.cancelled)
        if (!cancelledActivities.find(a => selectedActivityIds.includes(a.id))) {
            actions[genericTexts.actionsText.groupRemove] = { text: genericTexts.defaultText.cancel, icon: menuActionIcons[genericTexts.actionsText.groupRemove] }
        }
        return actions
    }

    const multiCheckActions = getMultiCheckActions()

    const openUpdateActivityPopup = () => {
        setIsNewActivity(true)
        setIsOpenActivityPopup(true)
    }

    const closeUpdateActivityPopup = () => {
        setIsNewActivity(false)
        setIsOpenActivityPopup(false)
        setSelectedActivityId('')
        updateActivities()
    }

    const getTableData = () => {
        const data = getActivityTableData(activities, starArray, actions)
        return data
    }

    const tableData = getTableData()

    const updateActivities = async () => {
        const activities = await QAApi.getAllActivities(currentOrgId)
        if (!activities || activities.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: activities.message, notificationType: 'error' }))
            return
        }
        for await (const activity of activities) {
            const assessment = await AssessmentApi.getAssessmentByActivityId(activity.id)
            activity.has_assessment = (assessment && !assessment.error) ? !!assessment : null
        }
        setActivities(activities)
        const tempStarArray = {}
        activities.map(a => tempStarArray[a.id] = a.starred)
        setStarArray(tempStarArray)  
    }

    const getPermittedUsersList = (users) => {
        return [...users.filter(user => getUserRole(user) < 4)
            .sort((a, b) => a.id * 1 - b.id * 1)
            .map(user => ({ ...user, name: user.name || user.user_name }))]
    }

    const getOrgsAndMetrics = async () => {
        const org = currentUserOrgs.find(o => o.id === currentOrgId) || { id: currentOrgId }
        const users = await UserApi.getOrgUsers(currentOrgId)
        const metrics = await QAApi.getPredefinedMetrics()
        const templates = await QuestionnaireApi.getQuestionnairesTemplatesApproved(currentOrgId)
        setCurrentOrg(org && !org.error ? org : { id: currentOrgId })
        setPredefinedMetrics(metrics && !metrics.error ? metrics : [])
        setApprovedTemplates(templates && !templates.error ? templates : [])
        setOrgUsers(users && !users.error ? getPermittedUsersList(users) : [])
    }

    const getTemplateUrl = async () => {
        const template = await QAApi.downloadMetricsTemplate()
        setTemplateUrl(template?.url)
    }

    const deleteActivity = async () => {
        setIsOpenDeletePopup(false)
        const deletedActiviry = await QAApi.deleteActivity(selectedActivityId)
        if (!deletedActiviry || deletedActiviry.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(ACTIVITY), notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedSuccess(ACTIVITY) }))
        await updateActivities()
    }

    const deleteActivities = async () => {
        setIsOpenDeletePopup(false)
        let error = false
        for await (const id of selectedActivityIds) {
            const deletedActivity = await QAApi.deleteActivity(id)
            if (!deletedActivity || deletedActivity.error) error = true
        }
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${activitiesText} ${wasText} deleted` }))
        setSelectedActivityIds([])
        await updateActivities()
    }

    const updateActivityStatus = async (activityId, actionType) => {
        const { selectedActivity } = getSelectedActivity(activities, activityId)
        const updatedActivity = await QAApi.updateActivity({ ...selectedActivity, [actionType]: !selectedActivity[actionType] })
        if (!updatedActivity || updatedActivity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: updatedActivity?.message || genericTexts.defaultText.genericErrorMsg, notificationType: 'error' }))
            return false
        }
        await updateActivities()
        return true
    }   

    const updateActivitiesStatus = async (activityIds) => {
        let error = false
        for await (const id of activityIds) {
            const { selectedActivity } = getSelectedActivity(activities, id)
            const updatedActivity = await QAApi.updateActivity({ ...selectedActivity, [ARCHIVED_PROP]: !selectedActivity[ARCHIVED_PROP] })
            if (!updatedActivity || updatedActivity.error) error = true
        }
        const { activitiesText, wasText } = getSelectedActivity(activities, '', activityIds)
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${activitiesText} ${wasText} ${isShowArchived ? 'unarchived' : 'archived'}` }))
        setSelectedActivityIds([])
        await updateActivities()
    } 

    const cancelActivity = async () => {
        setIsOpenCancelPopup(false)
        const cancelledActivity = await QAApi.cancelActivity(selectedActivityId)
        if (!cancelledActivity || cancelledActivity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `Some issues with cancelling ${ACTIVITY}`, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${ACTIVITY} was cancelled` }))
        await updateActivities()
    }

    const cancelActivities = async () => {
        setIsOpenCancelPopup(false)
        let error = false
        for await (const id of selectedActivityIds) {
            const cancelledActivity = await QAApi.cancelActivity(id)
            if (!cancelledActivity || cancelledActivity.error) error = true
        }
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${activitiesText} ${wasText} cancelled` }))
        setSelectedActivityIds([])
        await updateActivities()
    }

    const menuClickHandler = async (action, activityId) => {
        setSelectedActivityId(activityId)
        const { selectedActivity } = getSelectedActivity(activities, activityId)
        switch (action) {
        case genericTexts.actionsText.info:
            navigate(`/${appModulesPaths[CYBER_READINESS_MODULE]}/${ACTIVITY_PATH}/${activityId}`, { state: { activity: selectedActivity } })
            break
        case genericTexts.actionsText.edit:
            setIsOpenActivityPopup(true)
            setIsNewActivity(false)
            break
        case genericTexts.actionsText.archive:
            updateActivityStatus(activityId, ARCHIVED_PROP)
            break
        case genericTexts.actionsText.groupRemove:
            setIsOpenCancelPopup(true)
            break
        case genericTexts.actionsText.delete:
            selectedActivity.is_in_use ?  setIsOpenPermissionsPopup(true) : setIsOpenDeletePopup(true)
            break
        }
    }

    const multiCheckClickHandler = (action, activityIds) => {
        setSelectedActivityIds(activityIds)
        switch (action) {
        case genericTexts.actionsText.archive:
            updateActivitiesStatus(activityIds)
            break
        case genericTexts.actionsText.delete:
            setIsOpenDeletePopup(true)
            break
        case genericTexts.actionsText.groupRemove:
            setIsOpenCancelPopup(true)
            break
        }
    }

    const openEditPopup = () => {
        if (!providedId) return
        const { selectedActivity } = getSelectedActivity(activities, providedId)
        if (selectedActivity) {
            if (selectedActivity.status !== activityStatusesEnum.complete) store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: nonCompleteNotification, notificationType: 'warning' }))
            setSelectedActivityId(providedId)
            setIsOpenActivityPopup(true)
            setIsNewActivity(false)
        }
    }

    const handleTdClick = (val, activityId) => {
        const { selectedActivity } = getSelectedActivity(activities, activityId)
        if (val === 'star') {
            const updatedStarArray = { ...starArray } 
            updatedStarArray[activityId] = !updatedStarArray[activityId] 
            setStarArray(updatedStarArray)
            updateActivityStatus(activityId, STARRED_PROP)
            return
        }
        if(selectedActivity?.status !== activityStatusesEnum.cancelled) {
            setSelectedActivityId(activityId)
            setIsOpenActivityPopup(true)
            setIsNewActivity(false)
        }
    }

    useEffect(async () => {
        await getOrgsAndMetrics()
        await updateActivities()
        openEditPopup()
        setIsLoading(false)
        getTemplateUrl()
    }, [])

    const filterTableData = () => {
        if (isShowArchived) return tableData.filter(item => item.archived)
        return tableData.filter(item => (filterType === allOption.id ? true : item.starred) && !item.archived)
    }

    const filteredTableData = filterTableData()

    return (
        <div className='qa-activities page-content'>
            <div className='page-header'>
                <h1 id='qa-activities-management'>Q&A Activities Management</h1>
                {!isLoading && <button onClick={openUpdateActivityPopup} id='add-new-qa-activity'>{genericTexts.defaultText.addNewButton(ACTIVITY)}</button>}
            </div>
            {!!tableData?.length && !selectedActivitiesCount && <div className={`filter-switcher ${filteredTableData.length ? 'has-content' : ''}`}>
                {!isShowArchived && <RadioGroup radioGroupId='filter-switcher-group' checkedId={filterType} isSwitcher={true} updateItem={setFilterType} allItems={ [allOption, starArchive] }/>}
                <div className='archive-button'> 
                    <button className='btn-set' onClick={() => setIsShowArchived(!isShowArchived)}>Show {isShowArchived ? ACTIVE : ARCHIVED}</button>
                </div>
            </div>}
            {isLoading ? <Loader /> : <div className='report-management-con'>
                <SortableTable
                    columns={activityColumnsUser}
                    tableData={filteredTableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    handleTdClick={handleTdClick}
                    tableId='qa-activities-table'
                    multiCheckActions={multiCheckActions}
                    multiCheckClickHandler={multiCheckClickHandler}
                    updateMultiCheckIds={setSelectedActivityIds}
                    multiCheckIds={selectedActivityIds}
                />
                {isOpenActivityPopup && <Popup
                    onClose={closeUpdateActivityPopup}
                    isOpen={isOpenActivityPopup}
                    title={isNewActivity ? genericTexts.defaultText.addNewPopupTitle(ACTIVITY) : genericTexts.actionsText.editText(ACTIVITY, true)}
                    isDisabledClickOutside={true}
                    customClass='update-activity update-activity-qa'>
                    <UpdateQAActivity
                        isNewActivity={isNewActivity}
                        currentOrgId={currentOrgId}
                        currentOrg={currentOrg}
                        orgUsers={orgUsers}
                        currentUser={currentUser}
                        predefinedMetrics={predefinedMetrics}
                        closeUpdateActivityPopup={closeUpdateActivityPopup}
                        updateActivities={updateActivities}
                        activityDetails={selectedActivity}
                        templateUrl={templateUrl}
                        approvedTemplates={approvedTemplates}
                        setApprovedTemplates={setApprovedTemplates}
                        currentUserOrgs={currentUserOrgs} />
                </Popup>}


            </div>}
            <Popup
                onClose={() => setIsOpenDeletePopup(false)}
                isOpen={isOpenDeletePopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>{deleteActivitiesText}</p>
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={selectedActivitiesCount ? deleteActivities : deleteActivity} id='delete-qa-activity'>{genericTexts.defaultText.yes}</button>
                    <button onClick={() => setIsOpenDeletePopup(false)} className='btn-cancel' id='delete-qa-activity-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
            <Popup
                onClose={() => setIsOpenPermissionsPopup(false)}
                isOpen={isOpenPermissionsPopup}
                title='Cannot Delete'>
                <p>{`Cannot delete ${activitiesText}, it is being used by ${ASSESSMENT}.`}</p>
                <div className='popup-buttons'>
                    <button onClick={() => setIsOpenPermissionsPopup(false)} className='btn-submit' id='delete-qa-activity-cancel'>OK</button>
                </div>
            </Popup>
            <Popup
                onClose={() => setIsOpenCancelPopup(false)}
                isOpen={isOpenCancelPopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>
                    Cancelling {activitiesText} will make it uneditable. 
                    {selectedHasAssessment && ` Attached ${ASSESSMENT} will be cancelled too`}
                </p>
                
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={selectedActivitiesCount ? cancelActivities : cancelActivity} id='cancel-qa-activity'>{genericTexts.defaultText.yes}</button>
                    <button onClick={() => setIsOpenCancelPopup(false)} className='btn-cancel' id='delete-qa-activity-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
        </div>
    )
}

const QAActivityManagement = connect(mapStateToProps)(QAActivityManagementComponent)

export default QAActivityManagement