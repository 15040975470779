import React, { useEffect, useState } from 'react'
import './TenantDashboard.scss'
import { LabelDots, Loader, PieChartCustom, ProgressBar, ProgressBarCircular, ValuesList, WidgetBlock } from 'hub-web-lib/dist/index-export'
import { getActivityScore, getDomainsScore, getFindingsValues, getImplementationValues, getMainRecommendations } from 'js/ui-helpers/guest-dashboard'
import { CYBER_READINESS_MODULE, appModulesNames } from 'js/constants/app-modules'
import TenantOnboarding from './TenantOnboarding'
import QAApi from 'services/QAApi'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import UserApi from 'services/UserApi'
import { UPDATE_IS_TENANT_ASSESSMENT_SUBMIT } from 'js/constants/action-types'
import store from 'js/store'
import { action } from 'js/actions'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        isTenanatAssessmentSubmit: state.isTenanatAssessmentSubmit,
    }
}


const TenantDashboardComponent = ({ isTenanatAssessmentSubmit }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [charts, setCharts] = useState({})

    const getData = async () => {
        const data = await QAApi.getTenantMetrics()
        return data
    }

    const generatePDF = () => {
        const element = document.getElementById('home-guest')
        const pdfOptions = {
            image: { type: 'jpeg', quality: 0.9 }, // Adjust quality (0.6 is just an example)
            html2canvas: { scale: 2 }, // Adjust scale (1 is the default)
            jsPDF: { unit: 'mm', format: [1080, 2048], orientation: 'portrait' }
        }

        html2canvas(element, pdfOptions.html2canvas).then(canvas => {
            const pdf = new jsPDF(pdfOptions.jsPDF)
            pdf.addImage(canvas.toDataURL('image/jpeg', pdfOptions.image.quality), 'JPEG', 0, 0, 1080, 2048)
            const pdfBlob = pdf.output('blob')
            const formData = new FormData()
            formData.append('file', pdfBlob, 'dashboard.pdf')
            UserApi.uploadUserDashboard(formData)
        })
        store.dispatch(action(UPDATE_IS_TENANT_ASSESSMENT_SUBMIT, false))

    }

    const getCharts = (data) => {
        const activityScore = getActivityScore(data)
        const findingsValues = getFindingsValues(data)
        const domains = getDomainsScore(data)
        const implemetationValues = getImplementationValues(data)
        const recommendations = getMainRecommendations(data)
        setCharts({
            activityScore,
            findingsValues,
            domains,
            implemetationValues,
            recommendations
        })
    }

    const OrganizationScore = ({ charts }) => {
        const { activityScore, findingsValues } = charts
        return (<div className='organization-score'>
            <ProgressBarCircular
                percent={activityScore}
                size='large'
                chartId='organization-score-chart' />
            <ValuesList list={findingsValues} listId='findings-values' />
        </div>)
    }

    const DomainsScores = ({ charts }) => {
        const { domains } = charts
        return (
            <ul className='score-by-domains' id='score-by-domains-charts'>
                {(domains || []).map((domain, i) => domain?.insights?.description !== null && // No description means it is Intro
                    <li
                        key={domain.id}
                        className='domains'>
                        <h4>{domain.name}</h4>
                        <ProgressBar percent={domain.percent} showIcon={false} chartId={`domain-propress-chart-${i}`} />
                    </li>)}
            </ul>
        )
    }

    const SecurityControls = ({ charts }) => {
        const { implemetationValues, domains } = charts
        return (<div className='security-controls-container' id='security-controls-implementation'>
            <LabelDots list={implemetationValues} />
            <div className='security-controls' id='security-controls-domains'>
                {(domains || []).map((domain, i) => domain?.insights?.description !== null && // No description means it is Intro
                <div key={domain.id} className='security-controls-domain' id={`security-controls-domain-${i}`}>
                    <h3>{domain.name}</h3>
                    <div className='domain-content'>
                        <PieChartCustom chartData={domain.chartData} chartDetails={domain.chartDetails} />
                        <div className='insights'>
                            <h4>Insights</h4>

                            <p>{domain?.insights?.description}</p>
                            <ul>
                                {(domain?.insights?.list || []).map((item, i) => <li key={i}>{item}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>)
    }

    const MainRecommendations = ({ charts }) => {
        const { recommendations } = charts
        return (<div className="main-recommendations">
            {recommendations.map((item, index) => (
                <div key={index} className="recommendation-item">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
            ))}
            <div className='bottom-recommendations'>
                <p>Our world class cybersecurity consultants will help you develop your security posture through targeted and high-impact offensive, defensive and advisory services.</p>
                <p>Contact us, HUB Cyber Security </p>
            </div>
        </div>)
    }

    useEffect(async () => {
        setIsLoading(true)
        const data = await getData()
        getCharts(data)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (isTenanatAssessmentSubmit) {
            setTimeout(() => {
                generatePDF()
            }, 3000)
        }
    }, [isTenanatAssessmentSubmit])
    return (
        <div id='home-guest' className='home-guest page-content'>
            <TenantOnboarding />
            <div className='page-header'>
                <div>
                    <h1 id='guest-dashboard-title'>{appModulesNames[CYBER_READINESS_MODULE]} - Self Assessment</h1>
                </div>
                <button id='print-button' onClick={() => window.print()}>Print Dashboard</button>
            </div>
            {isLoading ? <Loader /> : <div className='dashboard-content home-guest'>
                <div className='dashboard-widgets'>
                    <div className='widgets-row'>
                        <WidgetBlock
                            title='Your Organization Score'
                            content={<OrganizationScore charts={charts} />} />
                        <WidgetBlock
                            title='Domains Breakdown'
                            content={<DomainsScores charts={charts} />} />
                    </div>
                    <div className='widgets-row'>
                        <WidgetBlock
                            title='Domain&#39;s Detailed Breakdown'
                            content={<SecurityControls charts={charts} />} />
                    </div>
                    <div className='widgets-row main-recommendations-row'>
                        <WidgetBlock
                            title='Main Recommendations'
                            content={<MainRecommendations charts={charts} />} />
                    </div>
                </div>
            </div>}
        </div>
    )
}

const TenantDashboard = connect(mapStateToProps)(TenantDashboardComponent)
export default TenantDashboard