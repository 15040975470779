import React, { useEffect, useState } from 'react'
import '../Settings.scss'
import { connect } from 'react-redux'
import OrgApi from 'services/OrgApi'
import { CheckBoxGroup, InputRow, Loader } from 'hub-web-lib/dist/index-export'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    }
}


const LogoEditComponent = ({ currentUser }) => {
    const [currentOrg, setCurrentOrg] = useState()
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDefault, setIsDefault] = useState(false)
    const importMetricsClick = () => {
        const importMetricsRow = document.getElementById('fileUpload')
        importMetricsRow.click()

    }
    const setUpOrg = async () => {
        const currentOrgObj = await OrgApi.getOrg(currentUser.current_org)
        if (!currentOrgObj || currentOrgObj.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: currentOrgObj.message, notificationType: 'error' }))
            return
        }
        setIsDefault(currentOrgObj.default_company_logo)
        setCurrentOrg(currentOrgObj)
    }

    const uploadLogoFile = async (file) => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        await OrgApi.uploadOrgLogo(currentOrg.id, formData, isDefault)
        setIsLoading(false)
        location.reload()
    }
    useEffect(async () => {
        setIsPageLoading(true)
        await setUpOrg()
        setIsPageLoading(false)
    }, [])
    return (

        isPageLoading ? <Loader /> : <section className='company-logo'>
            <h3 id='api-access-point-url'>Edit Organization Logo</h3>
            {isLoading ? <Loader /> :
                <div className='company-logo-current'>
                    <span>Current Logo:</span>
                    <img src={currentOrg?.company_logo_url} />
                </div>}
            <div className='logo-buttons'>
                <CheckBoxGroup title='Default company logo' updateItems={() => setIsDefault(!isDefault)} allItems={[{ id: 1 }]} checkedIds={[isDefault ? 1 : '']} isInputRow={true} />
                <button className='btn-set' id='import-company-logo' onClick={importMetricsClick}>
                    Upload Organization Logo
                </button>
            </div>
            <div className='hidden-row'><InputRow
                inputId='fileUpload'
                title='Import Company Logo'
                inputType='file'
                changeValueHandler={val => uploadLogoFile(val)}
            />
            </div>
        </section>

    )
}


const LogoEdit = connect(mapStateToProps)(LogoEditComponent)

export default LogoEdit