import React from 'react'
import { connect } from 'react-redux'
import { action } from '../../../js/actions'
import { UPDATE_IS_NAV_BACK } from '../../../js/constants/action-types'
import './BackButton.scss'


const mapDispatchToProps = (dispatch) => {
    return {
        updateIsNavBack: val => dispatch(action(UPDATE_IS_NAV_BACK, val))
    }
}

const BackButtonDetails = ({ updateIsNavBack }) => {

    const onBackClickHandler = () => {
        updateIsNavBack(true)

        setTimeout(() => {
            updateIsNavBack(false)
        }, 600)

        history.back()
    }

    return (
        <button className='back-link roundLink' id='go-back-button' onClick={onBackClickHandler}></button>
    )
}

const BackButton = connect(null, mapDispatchToProps)(BackButtonDetails)

export default BackButton
