import React, { useEffect, useState } from 'react'
import './PT.scss'
import { useNavigate } from 'react-router-dom'
import { Alert, Loader, BarChart, WidgetBlock, WidgetList, ProgressBarCircular, helpers } from 'hub-web-lib'
import { getPtData, getNotifications, getScore, getAssetsWidgetList } from 'js/ui-helpers/pt'
import { findingSeverityTypes } from 'js/constants/enums/pt'
import { connect } from 'react-redux'
import PTApi from 'services/PTApi'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

export const PTAssetsComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    const [assetsList, setAssetsList] = useState([])
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    let navigate = useNavigate()

    const handleItemClick = (id) => {
        if (!id) return
        navigate(`/pt/dashboard/${id}`)
    }

    const updateAssetsList = async () => {
        let assets = await PTApi.getAssets(currentOrgId, { asset_type: 1 })
        if (!assets || assets.error) return
        assets = assets.sort((a, b) => b.id - a.id).slice(0, 5)
        let liveNotifications = []
        for await (const asset of assets) {
            let findings  = await PTApi.getFindingsByAssetId(asset.id)
            if (!findings || findings.error) findings = { items: [] }
            asset.findings = findings.items
            asset.severity = {}
            findingSeverityTypes.forEach(severity => {
                asset.severity[severity.name] = asset.findings.filter(f => f.severity === severity.id)?.length || 0
            })
            asset.score = getScore(asset.severity)
            liveNotifications = [...liveNotifications, ...asset.findings]
        }
        setAssetsList(assets)
        setNotifications(getNotifications(liveNotifications))
        setIsLoading(false)
    }
    const assetsForWidget = getAssetsWidgetList(assetsList)
    const assetChart = getPtData(assetsList, { title: 'Findings Per Asset', axisStep: 10, colorScheme: 'primary-reverse', }, 'system_name')

    useEffect(async () => {
        await updateAssetsList()
    }, [])

    return (
        <div className='pt page-content'>
            <div className='page-header'>
                <h1 id='pt-application-security-testing'>Application Security Testing</h1>
            </div>
            {isLoading ? <Loader /> : assetsList?.length ? <div className='pt-con'>
                <h2 id='last-asset-score-sctivity'>Last Asset Score Activity</h2>
                <div className='pt-scores'>
                    {assetsList
                        .filter(asset => asset.score)
                        .slice(0, 5)
                        .map(asset => 
                            <ProgressBarCircular key={asset.id} percent={asset.score} type={helpers.getCircularProgressBarDetails(asset.score)} size='large' title={asset.system_name} />)}
                </div>
                <BarChart chartData={assetChart} handleChartRowClick={(rowIndex) => handleItemClick(assetsList[rowIndex].id)} />
                <div className='pt-widgets'>
                    <WidgetBlock 
                        title='Assets List'
                        content={ 
                            <WidgetList 
                                list={assetsForWidget} 
                                handleItemClick={handleItemClick} 
                                count={6} /> }/>
                    <WidgetBlock 
                        title='Live Notifications' 
                        content={ <WidgetList list={notifications} hideTime={true} /> }/>
                </div>
            </div> : <Alert message='No assets found' alertType='warning' />}
        </div>
    )
}

const PTAssets = connect(mapStateToProps)(PTAssetsComponent)

export default PTAssets