import axios from 'axios'
import { dispatchRequest } from 'middlewares/ApiHelpers'

const PTApi = {

    getAssets: async (orgId, params) => {
        return await dispatchRequest(axios.get(`/assets/org/${orgId}`, { params })).then(res => res.data)
    },

    getAsset: async (assetId) => {
        return await dispatchRequest(axios.get(`/assets/${assetId}`)).then(res => res.data)
    },

    getAssetsByReportId: async (reportId) => {
        return await dispatchRequest(axios.get(`/assets/report/${reportId}`)).then(res => res.data)
    },

    getRecentAssets: async (orgId, amount_of_assets) => {
        return await dispatchRequest(axios.get(`/assets/org/${orgId}/recent`, { params: { amount_of_assets } })).then(res => res.data)
    },

    createAsset: async (newAsset) => {
        return await dispatchRequest(axios.post('/assets/create', newAsset )).then(res => res.data)
    },

    updateAsset: async (assetId, asset) => {
        return await dispatchRequest(axios.post(`/assets/${assetId}`, asset )).then(res => res.data)
    },

    createFinding: async (newFinding) => {
        return await dispatchRequest(axios.post('/findings/create', newFinding )).then(res => res.data)
    },

    updateFinding: async (updatedFinding, findingId) => {
        return await dispatchRequest(axios.put(`/findings/${findingId}`, updatedFinding )).then(res => res.data)
    },

    deleteFinding: async (findingId) => {
        return await dispatchRequest(axios.delete(`/findings/${findingId}` )).then(res => res.data)
    },

    getFindings: async (orgId, params) => {
        return await dispatchRequest(axios.get(`/findings/orgs/${orgId}`, { params })).then(res => res.data)
    },

    getFinding: async (findingId) => {
        return await dispatchRequest(axios.get(`/findings/${findingId}`)).then(res => res.data)
    },

    getFindingsByReportId: async (reportId) => {
        return await dispatchRequest(axios.get(`/findings/report/${reportId}`)).then(res => res.data)
    },

    getFindingsByAssetId: async (assetId) => {
        return await dispatchRequest(axios.get(`/findings/assets/${assetId}`)).then(res => res.data)
    },

    getUsersByReportId: async (reportId) => {
        return await dispatchRequest(axios.get(`/users/reports/${reportId}`)).then(res => res.data)
    },

    createReport: async (newReport) => {
        if (!newReport.date) newReport.date = new Date()
        return await dispatchRequest(axios.post('/reports/create', newReport)).then(res => res.data)
    },

    getReports: async (orgId) => {
        return await dispatchRequest(axios.get(`/reports/orgs/${orgId}` )).then(res => res.data)
    },

    getReportsByAssetId: async (assetId) => {
        return await dispatchRequest(axios.get(`/reports/assets/${assetId}` )).then(res => res.data)
    }
}
export default PTApi
