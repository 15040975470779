import { userModel } from './user'

const orgModel = {
    name: '',
    parent_id: 1,
    status: 1,
    industry_id: '',
    license_id: 1,
    domain: '',
    country: '',
    org_type: 1,
    size: 1,
    mfa: false
}

const orgToEditModel = {
    new_org: { ...orgModel },
    new_user: { ...userModel }
}
delete orgToEditModel.new_user.org_id
delete orgToEditModel.new_user.is_admin

export {
    orgModel,
    orgToEditModel
}