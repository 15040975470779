import { action } from 'js/actions'
import { UPDATE_DASHBOARD_SETTINGS, UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { React, useState } from 'react'
import './DashboardStyles.scss'
import { connect } from 'react-redux'
import SettingsApi from 'services/SettingsApi'
import { ALL_DIMENSIONS, ALL_MODULES, dashboardDimensions } from 'js/constants/dashboard-settings'
import { InputRow, Popup, RadioGroup, SortableTable, WidgetBlock, genericTexts, helpers } from 'hub-web-lib/dist/index-export'
import { checkIsDashboardSettingsAllDefault, checkIsDashboardSettingsChanged, checkIsDashboardSettingsModuleDefault, getAliasError, getAllModulesDashboard, getCurrentPropValue, getDimensionName, getModuleName, getSettingForResetKey, getSettingsForChangedValue, getSettingsForResetAll, getSettingsForResetModule, validateAlias } from 'js/ui-helpers/settings'

const dimensionsTableColumns = [{
    key: 'key',
    name: 'Key',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 40
}, {
    key: 'alias',
    name: 'Alias',
    type: 'component',
    sortable: false,
    filterable: false
}, {
    key: 'color',
    name: 'Color',
    type: 'component',
    sortable: false,
    filterable: false
}, {
    key: 'reset',
    name: '',
    type: 'component',
    sortable: false,
    filterable: false
}]

const mapStateToProps = state => {
    return {
        visibleModules: state.visibleModules,
        dashboardSettings: state.dashboardSettings
    }
}

const DashboardStylesComponent = ({ dashboardSettings, visibleModules }) => {

    const allModules = getAllModulesDashboard(visibleModules)
    const [currentModule, setCurrentModule] = useState(ALL_MODULES)
    const [currentDimension, setCurrentDimension] = useState(ALL_DIMENSIONS)
    const [currentDashboardSettings, setCurrentDashboardSettings] = useState(helpers.createNewInstance(dashboardSettings))
    const [isOpenResetPopup, setIsOpenResetPopup] = useState(false)

    const isChanged = checkIsDashboardSettingsChanged(currentDashboardSettings, dashboardSettings)
    const changedItems = checkIsDashboardSettingsAllDefault(currentDashboardSettings, allModules)
    const isModuleDefault = checkIsDashboardSettingsModuleDefault(currentModule, currentDashboardSettings)
    const aliasError = getAliasError(currentDashboardSettings, allModules)

    const currentData = {
        currentDashboardSettings,
        currentModule,
        currentDimension,
    }

    const updateDashboardSettings = (val, key, prop) => {
        setCurrentDashboardSettings(getSettingsForChangedValue(val, key, prop, currentData))
    }

    const resetAllToAppDefault = () => {
        setCurrentDashboardSettings(getSettingsForResetAll(currentData))
        setIsOpenResetPopup(false)
    }

    const saveDashboardStyles = async () => {
        const settingsUpdated = await SettingsApi.updateSetting(currentDashboardSettings)
        if (!settingsUpdated || settingsUpdated.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: settingsUpdated.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_DASHBOARD_SETTINGS, helpers.createNewInstance(currentDashboardSettings)))
    }

    const setModuleDimension = (module, dimension) => {
        setCurrentModule(module)
        setCurrentDimension(dimension)
    }

    const getTableData = () => {
        const data = currentDashboardSettings.map(prop => {
            const { value: aliasValue, isChanged: isAliasChanged } = getCurrentPropValue(prop.key, 'alias', currentData)
            const { value: colorValue, isChanged: isColorChanged } = getCurrentPropValue(prop.key, 'color', currentData)
            const reset = isAliasChanged || isColorChanged ? <button 
                className='btn-icon' 
                onClick={() => setCurrentDashboardSettings(getSettingForResetKey(prop.key, currentData))}>
                <i className='icon-reset'></i>
            </button> : <></>
            const alias = <InputRow
                inputId={`prop-alias-${prop.key}`}
                placeholder={prop.label}
                defaultValue={aliasValue}
                errorMsg={validateAlias(aliasValue)}
                changeValueHandler={(val) => updateDashboardSettings(val, prop.key, 'alias')} />
            const color = <InputRow
                inputId={`prop-alias-${prop.key}`}
                inputType='color'
                defaultValue={colorValue}
                changeValueHandler={(val) => updateDashboardSettings(val, prop.key, 'color')} />
            return {
                id: prop.id,
                key: prop.label,
                alias,
                color,
                reset
            }
        })
        return data
    }

    const resetAllComponent = <div className='reset-all-wrapper'>
        <p>Your settings are different from the default {process.env.REACT_APP_SITE_BRAND_NAME || 'HUBGuard'} settings:</p>
        <ul className='changed-modules'>
            {Object.keys(changedItems || {}).map(module => <li key={module} className='changed-module'>
                <h3>{getModuleName(module, allModules)}</h3>
                <ul>
                    {Object.keys(changedItems[module]).map(dimension => <li key={dimension} className='changed-dimension'>
                        <strong onClick={() => setModuleDimension(module, dimension)}>{getDimensionName(dimension)}:</strong> {changedItems[module][dimension].join(', ')}
                    </li>)}
                </ul>
            </li>)}
        </ul>
        <button className='btn-cancel' id='reset-all-to-app-default' onClick={() => setIsOpenResetPopup(true)}><i className='icon-reset'></i>Reset All</button>

        
        <Popup
            onClose={() => setIsOpenResetPopup(false)}
            isOpen={isOpenResetPopup}
            title={genericTexts.defaultText.deletePopupTitle}>
            <p>This action will reset all your settings to {process.env.REACT_APP_SITE_BRAND_NAME || 'HUBGuard'} default</p>
            <div className='popup-buttons'>
                <button className='btn-delete' onClick={resetAllToAppDefault} id='delete-pen-test-activity'>Yes, Reset</button>
                <button onClick={() => setIsOpenResetPopup(false)} className='btn-cancel' id='delete-pen-test-activity-cancel'>{genericTexts.defaultText.no}</button>
            </div>
        </Popup>
    </div>

    const tableData = getTableData()

    return (
        <section className='dashboard-styles'>
            <h3 id='dashboard-styles'>Dashboard Styles</h3>
            <div className='dashboard-styles-wrapper'>
                <div className='dashboard-styles-form'>
                    <div className='dashboard-styles-selectors'>
                        <InputRow
                            inputId='dashboard-style-module'
                            title='Module'
                            inputTag='select'
                            placeholder='Select'
                            options={allModules}
                            defaultValue={currentModule}
                            changeValueHandler={setCurrentModule} />
                        <RadioGroup 
                            radioGroupId='dashboard-style-dimention' 
                            checkedId={currentDimension} 
                            isSwitcher={true} 
                            updateItem={setCurrentDimension} 
                            allItems={ dashboardDimensions } />
                    </div>
                    <div className='dashboard-styles-values'>
                        <SortableTable columns={dimensionsTableColumns} tableData={tableData} noFilter={true} />
                    </div>
                    <div className='error-msg'>{aliasError}</div>
                    <div className='form-buttons'>
                        {!isModuleDefault && <button className='btn-cancel' id='reset-all-dashboard-styles' onClick={() => setCurrentDashboardSettings(getSettingsForResetModule(currentData))}>
                            Reset {getModuleName(currentModule, allModules)} to your Org Defaults
                        </button>}
                    </div>
                    <button className='btn-submit' id='save-dashboard-styles' onClick={saveDashboardStyles} disabled={!isChanged || aliasError}>Save All</button>
                </div>
                {changedItems && <WidgetBlock content={resetAllComponent} />}
            </div>
            
            
        </section>
    )
}


const DashboardStyles = connect(mapStateToProps)(DashboardStylesComponent)

export default DashboardStyles

