import { helpers } from 'hub-web-lib'
import { BY_CATEGORY, BY_IMPACT, BY_QUARTER, BY_SEVERITY, CATEGORY_BY_YEAR, FINDINGS, FINDINGS_SEVERITY, KEY, OTHER_PROP, RADAR, RISK_LEVEL_BY_CATEGORY, RISK_LEVEL_TREND, WIDGET } from 'js/constants/metrics/common-metrics'
import { getMetricByProps, getMetricsByProps } from 'js/ui-helpers/metrics'
import { sortByYearQuarter } from './home-dashboard'
import { IMPACT_DIMENSION, RISK_DIMENSION, SEVERITY_DIMENSION } from 'js/constants/dashboard-settings'
import { getFilteredMetricsProps } from './filter'
import { FINDING_CATEGORY } from 'js/constants/filter'

const bySeverityChartDetailsDefault = {
    direction: 'vertical',
    size: 'small',
    isStacked: true,
    chartId: 'findings-by-severity-chart',
    isValueInside: true,
    hasToolTip: false,
}

const bySeverityQuarterlyChartDetailsDefault = {
    direction: 'horizontal',
    size: 'small',
    chartId: 'findings-by-severity-period-chart',
}

const categoryByYearChartDetails = {
    direction: 'vertical',
    size: 'small',
    colorValues: ['#F9668B', '#7E5FF8', '#07719F'],
    isValueInside: true,
    hasToolTip: false,
    chartId: 'findings-category-by-year-chart'
}

const donatChartDetails = {
    isPercentage: false,
    direction: 'vertical',
    size: 'regular',
    isShownLabels: true,
    isShownLabelsValues: true,
    isDonat: true,
}

const byCategoryChartDetails = {
    ...donatChartDetails,
    colorValues: [
        '#E055E2',
        '#893DE7',
        '#686DF4',
        '#3892F3',
        '#34C5E8',
        '#4aebe2',
        '#4ae881',
        '#bced68',
        '#eae54b',
        '#ffc823',
        '#ff8223',
    ],
    chartId: 'findings-by-category',
    centeredValue: {
        title: 'Total',
        value: 0
    }
}

const byImpactChartDetailsDefault = {
    ...donatChartDetails,
    chartId: 'findings-by-impact'
}

const riskLevelTrendChartDetailsDefault = {
    isGradient: false,
    colorScheme: 'primary',
    height: 250,
    isShownLabels: true,
    isShownDots: true,
    chartType: 'linear',
    chartId: 'risk-level-trend-chart'
}

const getRiskLevelChartData = (metricsData, metricsProps, filter) => {
    const riskLevel = getMetricsByProps(metricsData, RISK_LEVEL_BY_CATEGORY, RADAR)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const riskLevelChartData = riskLevel
        .filter(metric => filter[FINDING_CATEGORY] ? (filter[FINDING_CATEGORY] || []).includes(metric[KEY]) : true)
        .map(metric => {
            const row = { name: metric[KEY] }
            filteredProps.forEach(value => {
                row[value.name] = metric[value.id]
            })
            return row
        })
    const riskLevelChartDetails = {
        chartId: 'risk-level-chart',
        colorValues: filteredProps.map(s => s.color)
    }
    return { riskLevelChartData, riskLevelChartDetails }
}

const getByCategoryChartData = (metricsData) => {
    const byCategory = getMetricsByProps(metricsData, FINDINGS, BY_CATEGORY)
    const byCategoryChartData = byCategory.map(metric => ({ name: metric[KEY], value: metric[OTHER_PROP] || 0 }))
    const byCategoryTotal = byCategory?.length ? byCategory.map(metric => (metric[OTHER_PROP] || 0) * 1).reduce((a, b) => a + b) : 0
    byCategoryChartDetails.centeredValue.value = byCategoryTotal
    return { byCategoryChartData, byCategoryChartDetails }
}

const getByImpactChartData = (metricsData, metricsProps, filter) => {
    const byImpact = getMetricByProps(metricsData, FINDINGS, BY_IMPACT, BY_IMPACT)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, IMPACT_DIMENSION)
    const byImpactChartData = byImpact ? filteredProps.map(value => ({ 
        name: value.name, 
        value: byImpact[value.id],
        color: value.color
    })) : []
    const highestLevelWithValue = byImpactChartData.find(row => row.value) || byImpactChartData[0]
    const centeredValue = byImpactChartData?.length ? {
        ...highestLevelWithValue,
        title: highestLevelWithValue.name
    } : {}
    const byImpactChartDetails = {
        ...byImpactChartDetailsDefault,
        colorValues: byImpactChartData.map(r => r.color),
        centeredValue
    }
    return { byImpactChartData, byImpactChartDetails }
}

const getBySeverityChartData = (metricsData, metricsProps, filter) => {
    const bySeverity = getMetricByProps(metricsData, FINDINGS, BY_SEVERITY, BY_SEVERITY)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)
    const bySeverityChartData = bySeverity ? filteredProps.map(value => {
        const row = { name: value.name }
        filteredProps.forEach(v => {
            row[v.name] = v.id === value.id ? bySeverity[v.id] : 0
        })
        return row
    }) : []
    const bySeverityChartDetails = {
        ...bySeverityChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { bySeverityChartData, bySeverityChartDetails }
}

const getImplementationList = (metric, metricsProps) => {
    if (!metric) return []
    return metricsProps[SEVERITY_DIMENSION].map(item => ({
        ...item,
        label: item.name,
        value: metric[item.id]
    })).slice(0, 3)
}

const getBySeverityValues = (metricsData, metricsProps) => {
    const bySeverity = getMetricByProps(metricsData, FINDINGS, BY_SEVERITY, BY_SEVERITY)
    return getImplementationList(bySeverity, metricsProps)
}

const getBySeverityQuarterlyChartData = (metricsData, metricsProps, filter) => {
    const bySeverityQuarterly = getMetricsByProps(metricsData, FINDINGS_SEVERITY, BY_QUARTER)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, SEVERITY_DIMENSION)

    let bySeverityQuarterlyChartData = (bySeverityQuarterly || []).map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    })
    bySeverityQuarterlyChartData = sortByYearQuarter(bySeverityQuarterlyChartData, 'name')

    const bySeverityQuarterlyChartDetails = {
        ...bySeverityQuarterlyChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { bySeverityQuarterlyChartData, bySeverityQuarterlyChartDetails }
}

const getRiskLevelTrendChartData = (metricsData, metricsProps, filter) => {
    const riskLevelTrend = getMetricsByProps(metricsData, RISK_LEVEL_TREND, BY_QUARTER)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const riskLevelTrendChartData = riskLevelTrend.map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    })
    const riskLevelTrendChartDetails = {
        ...riskLevelTrendChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { riskLevelTrendChartData, riskLevelTrendChartDetails }
}

const getCategoryByYearChartData = (metricsData) => {
    const categoryByYear = getMetricsByProps(metricsData, CATEGORY_BY_YEAR)
    const categoryByYearYears = helpers.getUniqueArray(categoryByYear.map(m => m[KEY]))
    const categoryByYearWidgets = helpers.getUniqueArray(categoryByYear.map(m => m[WIDGET]))
    const categoryByYearChartData = categoryByYearWidgets.map(widget => {
        const row = { name: widget }
        categoryByYearYears.forEach(year => {
            const metric = categoryByYear.find(m => m[WIDGET] === widget && m[KEY] === year)
            row[year] = metric?.[OTHER_PROP] || 0
        })
        return row
    })
    return { categoryByYearChartData, categoryByYearChartDetails }
}

export {
    getRiskLevelChartData,
    getByCategoryChartData,
    getByImpactChartData,
    getBySeverityChartData,
    getBySeverityQuarterlyChartData,
    getRiskLevelTrendChartData,
    getCategoryByYearChartData,
    getBySeverityValues
}