import React, { useState } from 'react'
import { InputRow, RadioGroup } from 'hub-web-lib'
import { EXISTING, EXTERNAL, participantTypes } from 'js/constants/enums/common-activity'
import { penTestTypes } from 'js/constants/enums/pen-test'

const PenTestGeneralInformation = ({ data }) => {
    const {
        activityToUpdate,
        fieldsErrorMsg,
        changeValueHandler,
        errorMsg,
        orgUsers,
        currentOrg
    } = data

    const [reporterType, setReporterType] = useState(activityToUpdate?.external_reporter ? EXTERNAL : EXISTING)
    const dateTimeDetails = {
        showTimeSelect: false,
        dateFormat: 'dd MMMM yy',
    }
    const changeValue = (key, val, isAuto) => {
        if (isAuto) return
        const updatedActivity = { ...activityToUpdate, [key]: val }
        if (key === 'start_date' && new Date(val) > (activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : new Date())) updatedActivity.end_date = val
        changeValueHandler(key, updatedActivity)
    }

    const updateReporterType = (type) => {
        setReporterType(type)
        changeValue('external_reporter', '')
        changeValue('reported_by', '')
    }

    return (<div className='activity-general-information'>
        <h3 id='pen-test-general-information'>General Information</h3>
        <InputRow
            inputId='org_id'
            title='Organization'
            layout='vertical'
            disabled={true}
            defaultValue={currentOrg?.name || currentOrg?.id || ''}
            errorMsg={fieldsErrorMsg.org_id} />
        <InputRow
            inputId='title'
            title='Title'
            layout='vertical'
            defaultValue={activityToUpdate?.title || ''}
            required={true}
            changeValueHandler={(val) => changeValue('title', val)}
            errorMsg={fieldsErrorMsg.title} />
        <InputRow
            inputId='description'
            title='Description'
            inputTag='textarea'
            layout='vertical'
            required={true}
            changeValueHandler={(val) => changeValue('description', val)}
            defaultValue={activityToUpdate?.description || ''}
            errorMsg={fieldsErrorMsg.description} />
        <InputRow
            inputId='test_type'
            title='Test Type'
            inputTag='select'
            placeholder='Select'
            layout='vertical'
            required={true}
            options={penTestTypes}
            changeValueHandler={(val) => changeValue('test_type', val)}
            defaultValue={activityToUpdate?.test_type || ''}
            errorMsg={fieldsErrorMsg.test_type} />
        <RadioGroup
            title='Reported By'
            allItems={participantTypes}
            checkedId={reporterType}
            updateItem={updateReporterType} />

        {reporterType === EXISTING ?
            <InputRow
                inputId='reported_by'
                title='Participant Username'
                layout='vertical'
                inputTag='select'
                placeholder='Select'
                options={orgUsers}
                defaultValue={activityToUpdate?.reported_by || ''}
                changeValueHandler={val => changeValue('reported_by', val)}
                errorMsg={fieldsErrorMsg.reported_by} /> :
            <InputRow
                inputId='external_reporter'
                title='External Reporter Name'
                layout='vertical'
                defaultValue={activityToUpdate?.external_reporter || ''}
                changeValueHandler={val => changeValue('external_reporter', val)}
                errorMsg={fieldsErrorMsg.external_reporter} />}

        <InputRow
            inputId='start_date'
            title='Start Date'
            inputType='dateTime'
            layout='vertical'
            defaultValue={activityToUpdate?.start_date ? new Date(activityToUpdate.start_date) : ''}
            dateTimeDetails={dateTimeDetails}
            changeValueHandler={(val, isAuto) => changeValue('start_date', val, isAuto)}
            errorMsg={fieldsErrorMsg.start_date} />

        <InputRow
            inputId='end_date'
            title='End Date'
            inputType='dateTime'
            layout='vertical'
            defaultValue={activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : ''}
            dateTimeDetails={{ ...dateTimeDetails, minDate: new Date(activityToUpdate?.start_date) || new Date() }}
            changeValueHandler={(val, isAuto) => changeValue('end_date', val, isAuto)}
            errorMsg={fieldsErrorMsg.end_date} />

        <InputRow
            inputId='is_complete'
            title='Completed'
            inputType='checkbox'
            defaultValue={activityToUpdate?.is_complete}
            changeValueHandler={val => changeValue('is_complete', val)}
            errorMsg={fieldsErrorMsg.is_complete} />

        <p className='error-msg' id='pen-test-activity-general-info-error'>{errorMsg}</p>
    </div>)
}
export default PenTestGeneralInformation