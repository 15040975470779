const assetModel = {
    id: 0,
    org_id: 0,
    domain: '',
    full_url: '',
    port: 0,
    env: {
        scope: '',
        exposure: ''
    },
    asset_type: 0,
    system_name: ''
}

const reportModel = {
    id: '',
    org_id: '', 
    pdf_report_id: '', 
    date: '',
    is_completed: true,
    logical_asset: '', 
    main_recommendations: [],
    technical_assets: [], 
    findings: [], 
    user_ids: [] 
}

const findingModel = {
    id: 0,
    org_id: 0,
    title: '',
    description: '',
    risk_description: '',
    severity: 0,
    recommendations: [],
    scoring: {
        'Impact': 0,
        'Likelihood': 0,
        'Risk Level': 0
    },
    technical_details: [],
    reports: []
}

const participantModel =  {
    id: 0,
    name: '',
    title: '',
    type: ''
}

export {
    assetModel,
    reportModel,
    findingModel,
    participantModel
}