import React, { useState } from 'react'
import { InputRow, RadioGroup } from 'hub-web-lib'
import './QAGeneralInformation.scss'
import { EXISTING, EXTERNAL, participantTypes } from 'js/constants/enums/common-activity'
import { getUserRole } from 'js/ui-helpers/roles'
import { activityStatusesEnum } from 'js/constants/enums/activity'

const QAGeneralInformation = ({ data }) => {
    const {
        activityToUpdate,
        fieldsErrorMsg,
        changeValueHandler,
        errorMsg,
        currentOrg,
        orgUsers,
        currentUser
    } = data

    const [reporterType, setReporterType] = useState(activityToUpdate?.external_reporter ? EXTERNAL : EXISTING)
    const isUserAdmin = getUserRole(currentUser) === 1 && currentUser.is_admin
    const dateTimeDetails = {
        showTimeSelect: false,
        dateFormat: 'dd MMMM yy',
    }
    const changeValue = (key, val, isAuto) => {
        if (isAuto) return
        const updatedActivity = { ...activityToUpdate, [key]: val }
        if (key === 'start_date' && new Date(val) > (activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : new Date())) updatedActivity.end_date = val
        changeValueHandler(key, updatedActivity)
    }

    const updateReporterType = (type) => {
        setReporterType(type)
        changeValue('external_reporter', '')
        changeValue('reported_by', '')
    }

    return (<div className='activity-general-information'>
        <h3 id='qa-general-information'>General Information</h3>
        <InputRow
            inputId='org_id'
            title='Organization'
            layout='vertical'
            disabled={true}
            defaultValue={currentOrg?.name || currentOrg?.id || ''}
            errorMsg={fieldsErrorMsg.org_id} />
        <InputRow
            inputId='title'
            title='Title'
            layout='vertical'
            required={true}
            defaultValue={activityToUpdate?.title || ''}
            changeValueHandler={(val) => changeValue('title', val)}
            errorMsg={fieldsErrorMsg.title} />
        <InputRow
            inputId='description'
            title='Description'
            required={true}
            inputTag='textarea'
            layout='vertical'
            changeValueHandler={(val) => changeValue('description', val)}
            defaultValue={activityToUpdate?.description || ''}
            errorMsg={fieldsErrorMsg.description} />
        <RadioGroup
            title='Reported By'
            allItems={participantTypes}
            checkedId={reporterType}
            updateItem={updateReporterType} />

        {reporterType === EXISTING ?
            <InputRow
                inputId='reported_by'
                title='Participant Username'
                layout='vertical'
                inputTag='select'
                placeholder='Select'
                options={orgUsers.filter(user => getUserRole(user) < 3)}
                defaultValue={activityToUpdate?.reported_by || ''}
                changeValueHandler={val => changeValue('reported_by', val)}
                errorMsg={fieldsErrorMsg.reported_by} /> :
            <InputRow
                inputId='external_reporter'
                title='External Reporter Name'
                layout='vertical'
                defaultValue={activityToUpdate?.external_reporter || ''}
                changeValueHandler={val => changeValue('external_reporter', val)}
                errorMsg={fieldsErrorMsg.external_reporter} />}
        <InputRow
            inputId='start_date'
            title='Start Date'
            inputType='dateTime'
            layout='vertical'
            defaultValue={activityToUpdate?.start_date ? new Date(activityToUpdate.start_date) : ''}
            dateTimeDetails={dateTimeDetails}
            changeValueHandler={(val, isAuto) => changeValue('start_date', val, isAuto)}
            errorMsg={fieldsErrorMsg.start_date} />
        <InputRow
            inputId='end_date'
            title='End Date'
            inputType='dateTime'
            layout='vertical'
            dateTimeDetails={{ ...dateTimeDetails, minDate: new Date(activityToUpdate?.start_date) || new Date() }}
            defaultValue={activityToUpdate?.end_date ? new Date(activityToUpdate.end_date) : ''}
            changeValueHandler={(val, isAuto) => changeValue('end_date', val, isAuto)}
            errorMsg={fieldsErrorMsg.end_date} />

        <InputRow
            inputId='is_complete'
            title='Completed'
            inputType='checkbox'
            defaultValue={activityToUpdate?.status == activityStatusesEnum.complete}
            changeValueHandler={val => changeValue('status', val ? activityStatusesEnum.complete : activityStatusesEnum.started)}
            errorMsg={fieldsErrorMsg.is_complete} />
        {isUserAdmin  && <InputRow
            inputId='auto_complete'
            title='Auto Complete'
            inputType='checkbox'
            defaultValue={activityToUpdate?.auto_complete}
            changeValueHandler={val => changeValue('auto_complete', val)}
            errorMsg={fieldsErrorMsg.auto_complete} />}

        <p className='error-msg' id='qa-activity-general-info-error'>{errorMsg}</p>
    </div>)
}
export default QAGeneralInformation