import React from 'react'
import './AssessmentFirst.scss'
import questionnaireFirst from 'assets/illustrations/questionnaire-first.svg'


export const QuestionnaireFirst = ({ startAnswer = () => {} }) => {

    return (
        <>
            <div className='first-content'>
                <img src={questionnaireFirst} className='boxes-logo'/>
                <h1>Get your tailored <strong> Cyber Readiness score </strong> today</h1>
                <p>Q&A Assessment of your organization cyber readiness by answering short questionnaires</p>
                <button className='btn-submit' id='start-first-questionnaire' onClick={startAnswer}>Let&apos;s Go &gt;</button>
            </div>
        </>

    )
}

export default QuestionnaireFirst
