import { getActivityError, getMetricsError } from './metrics'

const getTemplateError = (template) => {
    return template?.id ? false : { message: 'Template is not selected' }
}

const getAssessmentError = (assessment) => {
    return assessment?.id ? false : { message: 'Activity Assessment doesn\'t exist' }
}

const getSubmitAssessmentError = (assessment) => {
    return assessment?.mail_to ? false : { message: 'User to Submit is not selected' }
}

const getStepsErrors = (activity, assessment, template, metrics, isReview) => {
    return {
        0: getActivityError(activity),
        1: isReview ? getAssessmentError(assessment) : getTemplateError(template),
        2: getMetricsError(metrics),
        3: getSubmitAssessmentError(assessment)
    }
}

export {
    getMetricsError,
    getActivityError,
    getTemplateError,
    getAssessmentError,
    getSubmitAssessmentError,
    getStepsErrors
}