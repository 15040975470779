import axios from 'axios'
import { dispatchRequest } from 'middlewares/ApiHelpers'
import { Cookies } from 'react-cookie'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_CURRENT_USER } from 'js/constants/action-types'
const cookies = new Cookies()

const UserApi = {

    userLogin: async (user) => {
        return await dispatchRequest(axios.post('/auth/token', user )).then(res => res.data)
    },

    getCurrentUser: async () => {
        // NOTE: test for tenant mode
        // const me = await dispatchRequest(axios.get('/users/me' )).then(res => res.data)
        // if (me?.id === 3) me.is_tenant = true
        // return me
        return await dispatchRequest(axios.get('/users/me' )).then(res => res.data)
    },

    getUser: async (userId) => {
        return await dispatchRequest(axios.get(`/users/${userId}`)).then(res => res.data)
    },

    updateUser: async (userId, updatedUser) => {
        return await dispatchRequest(axios.put(`/users/${userId}`, updatedUser)).then(res => res.data)
    },
    updateUserPasswordAdmin: async (userId, password) => {
        return await dispatchRequest(axios.put(`/users/change-password-admin/${userId}`, { new_pwd: password })).then(res => res.data)
    },
    updateUserCurrentOrg: async (userId, orgId) => {
        return await dispatchRequest(axios.put(`/users/current_org_change/${userId}`, { current_org: orgId })).then(res => res.data)
    },
    updateUserTerms: async (userId) =>{
        return await dispatchRequest(axios.put(`/users/t_c_change/${userId}`)).then(res => res.data)
    },
    deleteUser: async (userId) =>{
        return await dispatchRequest(axios.delete(`/users/delete_user/${userId}`)).then(res => res.data)
    },

    createUser: async (newUser) => {
        if (!newUser.avatar_url) delete newUser.avatar_url
        return await dispatchRequest(axios.post('/users/create', newUser )).then(res => res.data)
    },

    disableUser: async (userId) => {
        return await dispatchRequest(axios.post(`/users/${userId}/disable` )).then(res => res.data)
    },

    enableUser: async (userId) => {
        return await dispatchRequest(axios.post(`/users/${userId}/enable` )).then(res => res.data)
    },

    changeUserPassword: async (newPassDetails) => {
        const isLogined = !newPassDetails?.user_token
        return await dispatchRequest(axios.put(isLogined ? '/users/change-password' : '/auth/new-password', newPassDetails)).then(res => res)
    },

    checkPasswordUpdateTokenValidity: async (user_token) => {
        return await dispatchRequest(axios.post('/auth/check-token', { new_pwd: '', user_token: user_token })).then(res => res)
    },

    sendNewUserPassword: async (email, isResend, captchaValue) => {
        return dispatchRequest(axios.post(`/auth/${isResend ? 'resend-recovery-link' : 'recover-password'}`, null, { params: { email: email, captcha_value: captchaValue } })).then(res => res)
    },

    getOrgUsers: async (orgId) => {
        return await dispatchRequest(axios.get(`/users/orgs/${orgId}`)).then(res => res.data)
    },

    setCurrentUserCookies: async () => {
        let message = ''
        let user = ''
        const isLoggedIn = cookies.get('isLoggedIn')
        if (!isLoggedIn) {
            return { message: 'Could not validate credentials', user }
        }
        const currentUserDetails = await UserApi.getCurrentUser()
        if (!currentUserDetails || currentUserDetails.error || !currentUserDetails.id) {
            message = currentUserDetails.message || 'Could not validate credentials'
            UserApi.clearCurrentUserCookies()
        } else {
            user = currentUserDetails
            UserApi.setUserPropCookie('isQaWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isEasmWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isRedTeamWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isPenTestWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isDdosWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isRiskAssessmentWarningMessageDisabled', false, user.id)
            UserApi.setUserPropCookie('isTenantOnboardingFinished', !currentUserDetails.is_tenant, user.id)
            UserApi.setUserPropCookie('isTenantAssessmentOnboardingFinished', !currentUserDetails.is_tenant, user.id)
            UserApi.setUserPropCookie('isDemoMode', false, user.id)
            store.dispatch(action(UPDATE_CURRENT_USER, user))
        }
        return { message, user }
    },

    setUserPropCookie: (prop, value, userId, changeProp) => {
        const userProps = cookies.get('hubUserProps') || {}
        const expirationDate = new Date()
        expirationDate.setDate(expirationDate.getDate() + 1)
        if (!userProps[userId]) userProps[userId] = {}
        if (!(prop in userProps[userId]) || changeProp) {
            userProps[userId][prop] = value
            cookies.set('hubUserProps', userProps, { path: '/', expires: expirationDate })
        }
    },

    getUserProp: (prop, userId) => {
        const userProps = cookies.get('hubUserProps')
        return userProps && userProps[userId] ? userProps[userId][prop] || false : false
    },

    clearUserNameCookies: () => {
        cookies.remove('isLoggedIn', { path: '/' })
        document.cookie = `isLoggedIn=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;Domain=.${document.domain.split('.').splice(1).join('.')}`
    },

    clearCurrentUserCookies: () => {
        UserApi.clearUserNameCookies()
        store.dispatch(action(UPDATE_CURRENT_USER, null))
    },

    setUserTokenCookies: async (userCredentials) => {
        let command = ''
        let parameter = ''
        let message = ''
        const loginData = await UserApi.userLogin(userCredentials)
        if (loginData?.command) {
            command = loginData.command
            parameter = loginData.parameter
        } else if (loginData?.user_name) {
            UserApi.clearUserNameCookies()
            const expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + 1)
            cookies.set('isLoggedIn', 'true', { path: '/', expires: expirationDate })
        } else {
            message = loginData?.message ?? 'Authentication failed'
            UserApi.clearCurrentUserCookies()
        }
        return { command, parameter, message }
    },

    getCaptchaData: async (isCaptchaMandatory) => {
        return await dispatchRequest(axios.get('/auth/captcha_data', { params: { is_captcha_mandatory: isCaptchaMandatory } })).then(res => res.data)
    },

    userLogout: async () => {
        return await dispatchRequest(axios.delete('/auth/token')).then(res => res.data)
    },
    uploadUserDashboard: async (file) =>{
        return await dispatchRequest(axios.post('/files/upload-hubspot-pdf', file))
    }
}
export default UserApi
