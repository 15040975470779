
import React from 'react'

const activitiesList = [{
    message: 'DDoS Simulation 22.05.22',
    date: new Date(2022, 4, 22, 10, 33, 0, 0)
}, {
    message: 'DDoS Simulation 22.04.22',
    date: new Date(2022, 3, 22, 10, 33, 0, 0)
}, {
    message: 'DDoS Simulation 21.03.22',
    date: new Date(2022, 2, 21, 10, 33, 0, 0)
}]

const suggestionsList = [{
    id: 1,
    header: 'Consider to implement SAST solution in the development lifecycle',
    description: 'description'
}, {
    id: 2,
    header: 'It is recommended to refresh hardening procedures for web servers',
    description: 'description'
}, {
    id: 3,
    header: 'It is recommended to perform secure coding training to the developers demonstrating examples from the performed tests',
    description: 'description'
}, {
    id: 4,
    header: 'Some other suggestion',
    description: 'description'
}]



const internalThreatsData = [{
    id: 1,
    type: 'Data leakage',
    scoreImpactPercent: -2,
    assets: [{
        id: 1,
        name: 'Database 1'
    }, {
        id: 2,
        name: 'Database 2'
    }, {
        id: 3,
        name: 'Data Storage'
    }, {
        id: 4,
        name: 'Hadoop/Splunk'
    }]
}, {
    id: 2,
    type: 'Insider threat (Employee, contractor, partners, service provider)',
    scoreImpactPercent: -7
}, {
    id: 3,
    type: 'Privilege Escalation (abuse authorization)',
    scoreImpactPercent: -1
}, {
    id: 4,
    type: 'Unauthorized devices',
    scoreImpactPercent: -4
}, {
    id: 5,
    type: 'Social engineering',
    scoreImpactPercent: -1
}, {
    id: 6,
    type: 'Data sabotage',
    scoreImpactPercent: -1
}, {
    id: 7,
    type: 'Supply chain misconfiguration',
    scoreImpactPercent: -2
}]

const MINOR = 'Minor'
const MAJOR = 'Major'
const EXTREME = 'Extreme'

const getStatusValuesObject = (a, b, c) => {
    return {
        [EXTREME]: a,
        [MAJOR]: b,
        [MINOR]: c
    }
}

const statusTypes = getStatusValuesObject('error', 'warning', 'primary')

const findingsStatuses = [EXTREME, MAJOR, MINOR]
const findingsChartColumns = [EXTREME, MAJOR, MINOR].map(status => ({ label: status }))


const ptAssetsList = [{
    id: '1',
    title: 'eBanking Desktop',
    findings: getStatusValuesObject(39, 36, 23),
    percent: 15,
    ipAddress: '125.0.39.59'
}, {
    id: '2',
    title: 'eBanking API',
    findings: getStatusValuesObject(16, 35, 43),
    percent: 53,
    ipAddress: '127.0.0.0'
}, {
    id: '3',
    title: 'Voice of Client',
    findings: getStatusValuesObject(38, 46, 8),
    percent: 65,
    ipAddress: '45.70.9.4'
}, {
    id: '4',
    title: 'Voice of Client 2.0 MC',
    findings: getStatusValuesObject(6, 56, 32),
    percent: 89,
    ipAddress: '127.0.0.1'
}]

const chartDataAssets = {
    details: {
        colorScheme: 'primary-reverse',
        title: 'Findings Per Asset',
        axisStep: 10
    },
    columns: findingsChartColumns,
    rows: ptAssetsList.map(asset => ({ label: asset.title, ...asset.findings }))
}

const ptAssetActivities = [{
    title: 'Retest report',
    date: new Date(2022, 9, 25, 14, 30, 0, 0),
    percent: 89,
    findings: getStatusValuesObject(7, 11, 10)
}, {
    title: 'Technical Review',
    date: new Date(2022, 9, 22, 12, 15, 0, 0),
    percent: 61,
    findings: getStatusValuesObject(9, 11, 8)
}, {
    title: 'Draft Report',
    date: new Date(2022, 9, 20, 17, 0, 0, 0),
    percent: 45,
    findings: getStatusValuesObject(6, 5, 4)
}, {
    title: 'Finalization',
    date: new Date(2022, 9, 19, 9, 20, 0, 0),
    percent: 26,
    findings: getStatusValuesObject(6, 5, 1)
}, {
    title: 'First report',
    date: new Date(2022, 9, 18, 9, 20, 0, 0),
    percent: 15,
    findings: getStatusValuesObject(11, 4, 1)
}]

const chartDataActivities = {
    details: {
        colorScheme: 'primary-reverse',
        title: 'Activity Findings Summary',
        isShownScore: true,
        axisStep: 2
    },
    columns: findingsChartColumns,
    rows: ptAssetActivities.map(activity => ({ label: activity.title, ...activity.findings, percent: activity.percent }))
}


const phishingAssetsList = [{
    id: '1',
    title: 'Dev department'
}, {
    id: '2',
    title: 'Marketing department'
}, {
    id: '3',
    title: 'Marketing department'
}]

const ptAssetActivityFindings = [{
    name: 'The Web Server Supports Unnecessary Methods',
    status: EXTREME
}, {
    name: 'Authorization Bypass',
    status: EXTREME
}, {
    name: 'Flawed OTP Verification Mechanism',
    status: EXTREME
}, {
    name: 'Lack of “Forgot Password” Mechanism',
    status: EXTREME
}, {
    name: 'Lack of Content Security Policy',
    status: EXTREME
}, {
    name: 'Missing Referrer Policy Implementation',
    status: EXTREME
}, {
    name: 'Persistent Cross-Site Scripting (XSS)',
    status: EXTREME
}, {
    name: 'Insecure OAuth Configuration',
    status: EXTREME
}, {
    name: 'Flawed Authentication Mechanism - Fingerprint Addition Does not Disable Biometric Authentication',
    status: EXTREME
}, {
    name: 'Insecure Access Token Generation Mechanism',
    status: EXTREME
}, {
    name: 'No Certificate Enforcement (SSL Pinning)',
    status: EXTREME
}, {
    name: 'Lack of Account Access Restrictions',
    status: EXTREME
}, {
    name: 'Weak Password Policy and Lack of Multi-Factor Authentication for ASM Accounts',
    status: EXTREME
}, {
    name: 'Misconfigured Access Permissions: Orders',
    status: EXTREME
}, {
    name: 'Misconfigured Access Permissions: Account info',
    status: EXTREME
}, {
    name: 'Cross-Site Scripting',
    status: EXTREME
}, {
    name: 'Sensitive Data is Transferred Using the GET Method',
    status: EXTREME
}, {
    name: 'Insufficient Input Validation May Alter Business',
    status: MAJOR
}, {
    name: 'Insufficient Input Validation May Alter Business',
    status: MAJOR
}, {
    name: 'Missing Software Updates',
    status: MAJOR
}, {
    name: 'Sequential Identifiers',
    status: MAJOR
}, {
    name: 'Information Disclosure',
    status: MAJOR
}, {
    name: 'Internal Resource Path Exposure',
    status: MAJOR
}, {
    name: 'Session Tokens are Stored in the Browser&#96;s Local Storage',
    status: MAJOR
}, {
    name: 'Logout Feature Does Not Terminate the Session at the server side',
    status: MAJOR
}, {
    name: 'Insufficient Input Validation Can Lead to Business Logic Bypass',
    status: MAJOR
}, {
    name: 'Administrative Interface is Accessible over the Internet',
    status: MAJOR
}, {
    name: 'Username Harvesting',
    status: MAJOR
}, {
    name: 'Missing Software Updates',
    status: MAJOR
}, {
    name: 'Flawed Account Lockout Mechanism - Denial of Service',
    status: MAJOR
}, {
    name: 'Insufficient Session Timeout',
    status: MAJOR
}, {
    name: 'Insecure Cryptographic Storage | Encryption of Passwords within Configuration Files',
    status: MAJOR
}, {
    name: 'Weak Password Policy',
    status: MAJOR
}, {
    name: 'Cross-Domain Script Inclusion Without Subresource Integrity Verification',
    status: MAJOR
}, {
    name: 'Weak Authentication Method - Basic Authentication',
    status: MAJOR
}, {
    name: 'Insecure Configuration | CSRF',
    status: MAJOR
}, {
    name: 'Information Exposure Through an Error Message',
    status: MAJOR
}, {
    name: 'Weak Cryptographic Implementation: Hardcoded Salt ',
    status: MAJOR
}, {
    name: 'Username Harvesting',
    status: MAJOR
}, {
    name: 'Missing Anti-Automation Mechanism',
    status: MAJOR
}, {
    name: 'Flawed Account Lockout Mechanism - Denial of Service',
    status: MAJOR
}, {
    name: 'Missing HTTP Host Header Verification',
    status: MAJOR
}, {
    name: 'Insufficient Transport Layer Protection – Weak Cipher Suites',
    status: MAJOR
}, {
    name: 'Improper Segregation Between Environments: Use of Single Certificate for Multiple Domains',
    status: MAJOR
}, {
    name: 'Logout Feature Does Not Terminate the Session at the Server Side',
    status: MAJOR
}, {
    name: 'The system Does Not Properly Secure Its Cookies',
    status: MINOR
}, {
    name: 'Low Redundant Services are Accessible over',
    status: MINOR
}, {
    name: 'Incomplete Configuration of HTTP Strict',
    status: MINOR
}, {
    name: 'Transport Security (HSTS)',
    status: MINOR
}, {
    name: 'Lack of Content Security Policy',
    status: MINOR
}, {
    name: 'Sensitive Client Data Exposure',
    status: MINOR
}, {
    name: 'Missing HTTP Host Header Verification',
    status: MINOR
}, {
    name: 'Information Disclosure',
    status: MINOR
}, {
    name: 'Incomplete Configuration of HTTP Strict Transport Security (HSTS)',
    status: MINOR
}, {
    name: 'Web Server Directory Listing',
    status: MINOR
}, {
    name: 'Missing Anti-Automation Mechanism',
    status: MINOR
}, {
    name: 'The System Does Not Properly Secure Its Cookies – HttpOnly, Secure, Samsite Attributes',
    status: MINOR
}, {
    name: 'Lack of “Forgot Password” Mechanism',
    status: MINOR
}, {
    name: 'Insufficient Transport Layer Protection - Weak Cipher Suites',
    status: MINOR
}, {
    name: 'Missing iOS Snapshot Protection',
    status: MINOR
}, {
    name: 'Missing Android Recent Tasks Protection',
    status: MINOR
}, {
    name: '',
    status: MINOR
}, {
    name: 'Missing Device Sharing Warning for Biometric Authentication',
    status: MINOR
}, {
    name: 'Shared Clipboard is Enabled',
    status: MINOR
}, {
    name: 'Heap Inspection',
    status: MINOR
}, {
    name: 'Cross-Domain Script Inclusion',
    status: MINOR
}, {
    name: 'Improper Segregation Between Environments: Use of Single Certificate for Multiple Domains',
    status: MINOR
}, {
    name: 'Logout Feature Does Not Terminate the Session at the Server Side',
    status: MINOR
}, {
    name: 'Non-Secure Configuration for Cross-Origin Resource Sharing (CORS) ',
    status: MINOR
}, {
    name: 'The System Does Not Inform the User of Last Login Time',
    status: MINOR
}, {
    name: 'Logout Feature Does Not Terminate the Session at the Server Side',
    status: MINOR
}, {
    name: '',
    status: MINOR
}, {
    name: 'Non-Secure Configuration for Cross-Origin Resource Sharing (CORS)',
    status: MINOR
}]

const findingsColumns = [{
    key: 'name',
    name: 'Finding Title',
    type: 'string'
}, {
    key: 'date',
    name: 'Date',
    type: 'string'
}, {
    key: 'statusTitle',
    name: 'Status',
    type: 'component',
    sortBy: 'status'
}, ]

const ptLiveNotifications = [
    ptAssetActivityFindings.find(f => f.status === MAJOR), 
    ptAssetActivityFindings.filter(f => f.status === MAJOR)[1], 
    ptAssetActivityFindings.find(f => f.status === EXTREME)]
    .map(n => ({ 
        ...n, 
        message: n.name, 
        statusType: statusTypes[n.status], 
        date: ptAssetActivities[0].date 
    }))


const scheduleActivityTypes = [{
    id: 'PT'
}, {
    id: 'Ddos simulation'
}, {
    id: 'Phishing campaigns'
}]

const ptScheduledActivities = [{
    id: '1',
    title: ptAssetActivities[3].title,
    asset: ptAssetsList[0].title,
    date: new Date(2022, 9, 30, 10, 45, 0, 0),
    type: scheduleActivityTypes[0].id
}, {
    id: '2',
    title: ptAssetActivities[1].title,
    asset: ptAssetsList[3].title,
    date: new Date(2022, 10, 3, 14, 15, 0, 0),
    type: scheduleActivityTypes[0].id
}, {
    id: '3',
    title: ptAssetActivities[2].title,
    asset: ptAssetsList[2].title,
    date: new Date(2022, 10, 6, 15, 0, 0, 0),
    type: scheduleActivityTypes[0].id
}]

const ptAssetRecommendationList = [{
    id: 1,
    header: 'Consider disabling the HTTP PUT method on...',
    description: 'Consider disabling the HTTP PUT method on the server'
}, {
    id: 2,
    header: 'Ensure that all application’s users must follow...',
    description: 'Ensure that all application’s users must follow each of the OTP verification steps in order to use sensitive functionality. A unique token can be used to achieve this goal.'
}, {
    id: 3,
    header: 'When an unexpected error occurs, the system should...',
    description: 'When an unexpected error occurs, the system should output a general error page or error code without exposing any further details regarding the error itself.'
}, {
    id: 4,
    header: 'Discuss the findings with the parties involved to agree appropriate ways...',
    description: 'Discuss the findings with the parties involved to agree appropriate ways to correct the deficiencies and timeframes for remediation.'
}, {
    id: 5,
    header: 'Re-review the application after the mitigation of...',
    description: 'Re-review the application after the mitigation of the findings to ensure that the vulnerabilities have been effectively remediated and that the remediation has not caused any new vulnerabilities.'
}]

const ptAssetTable = {
    columns: [{
        key: 'domain',
        name: 'Testing Domain',
        type: 'string'
    }, {
        key: 'status',
        name: 'Global Risk Severity',
        type: 'component'
    }],
    tableData: [{
        id: 1,
        domain: 'Authentication',
        status: <p className="status primary">Good</p>
    }, {
        id: 2,
        domain: 'Authorisation',
        status: <p className="status primary">Good</p>
    }, {
        id: 3,
        domain: 'Session Management',
        status: <p className="status warning">Medium</p>
    }, {
        id: 4,
        domain: 'Parameter Filtering',
        status: <p className="status primary">Good</p>
    }, {
        id: 5,
        domain: 'Cryptography',
        status: <p className="status primary">Good</p>
    }, {
        id: 6,
        domain: 'Server Configuration',
        status: <p className="status error">Low</p>
    }, ]
}

export {
    activitiesList,
    suggestionsList,
    internalThreatsData,
    findingsStatuses,
    chartDataAssets,
    chartDataActivities,
    ptAssetsList,
    ptLiveNotifications,
    ptScheduledActivities,
    ptAssetRecommendationList,
    ptAssetActivities,
    ptAssetTable,
    scheduleActivityTypes,
    ptAssetActivityFindings,
    findingsColumns,
    phishingAssetsList,
    statusTypes
}
