import { helpers } from 'hub-web-lib/dist/index-export'
import { ERROR, GENERAL, KEY, LOW, LOW_PROP, MEDIUM, MEDIUM_PROP, PRIMARY, TOTAL, WARNING } from 'js/constants/metrics/common-metrics'
import { getMetricByProps, getMetricsByProps } from './metrics'
import { CYBER_READINESS } from 'js/constants/app-modules'

const GOOD = 'Good'
const GOOD_PROP = 'good'
const CYBER_AWARENESS = 'Cyber Awareness'

const findingsValues = [{
    id: GOOD_PROP,
    label: GOOD,
    status: PRIMARY
}, {
    id: MEDIUM_PROP,
    label: MEDIUM,
    status: WARNING
}, {
    id: LOW_PROP,
    label: LOW,
    status: ERROR
}]

const chartDetailsDefault = {
    size: 'medium',
    isPercentage: true,
    isDonat: true,
    isShownLabels: false
}

const getActivityScore = (data) => {
    const score = getMetricByProps(data, GENERAL, CYBER_READINESS, TOTAL)
    return score?.[GOOD_PROP] || 0
}

const getFindingsValues = (data) => {
    const byPercentage = getMetricByProps(data, GENERAL, CYBER_READINESS, 'By Percentage')
    return findingsValues.map(v => ({
        ...v,
        value: byPercentage?.[v.id] || 0
    }))
}

const getDomainsScore = (data) => {
    const scoreByDomains = getMetricsByProps(data, GENERAL, 'Score By Domains')
    const cyberAwareness = getMetricsByProps(data, CYBER_AWARENESS, 'Security Control Implementation')
    const domains = scoreByDomains.map((d, i) => ({
        id: i + 1,
        name: d[KEY],
        percent: d[GOOD_PROP]
    }))

    return (domains || []).map((domain, i) => {

        const { status } = helpers.getProgressBarDetails(domain.percent || 0)
        const cyberAwarenessDomain = cyberAwareness.find(ca => ca[KEY] === domain.name)
        const chartData = findingsValues.map(v => ({
            ...v,
            value: cyberAwarenessDomain?.[v.id] || 0
        }))
        const chartDetails = {
            ...chartDetailsDefault,
            chartId: `domain-chart-${i}`,
            centeredValue: {
                value: domain.percent !== null ? `${domain.percent}%` : '-/-',
            }
        }
        const insights = {
            description: cyberAwarenessDomain?.insight,
            list: []
        }
        return {
            ...domain,
            status,
            chartData,
            chartDetails,
            insights
        }
    })
}

const getImplementationValues = () => {
    return findingsValues
}

const getMainRecommendations = (data) => {
    const recommendations = getMetricsByProps(data, 'Recommendations', 'Main Recommendations')
    return recommendations.map((item) => {
        return {
            id: item.id,
            title: item.key,
            description: item.insight
        }

    })
}

export {
    getActivityScore,
    getFindingsValues,
    getDomainsScore,
    getImplementationValues,
    getMainRecommendations
}