import { Gantt, helpers } from 'hub-web-lib/dist/index-export'
import { getGanttData } from 'js/ui-helpers/activity-gantt'
import React from 'react'
import './ActivityGantt.scss'
import { connect } from 'react-redux'
import { getUserRole } from 'js/ui-helpers/roles'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const ActivityGanttComponent = ({ activitiesData = [], defaultYear = helpers.getCurrentYear(), currentUser }) => {

    const canEditActivity = getUserRole(currentUser) <= 2
    const ganttData = getGanttData(activitiesData, canEditActivity)

    return (
        !!ganttData.length &&
            <div className='widgets-row activites-plan'>
                <Gantt ganttData={ganttData} defaultYear={defaultYear} />
            </div>
    )
}
const ActivityGantt = connect(mapStateToProps)(ActivityGanttComponent)
export default ActivityGantt
