import React, { useEffect, useState } from 'react'
import './Settings.scss'
import OrgSelector from './SettingsSections/OrgSelector'
import { Loader, Tabs } from 'hub-web-lib'
import ApiAccessPoint from './SettingsSections/ApiAccessPoint'
import { connect } from 'react-redux'
import ModulesVisibility from './SettingsSections/ModulesVisibility'
import QuestionnaireFeatures from './SettingsSections/QuestionnaireFeatures'
import DashboardStyles from './SettingsSections/DashboardStyles'
import { ORG, QUESTIONNAIRE, TEMPLATE } from 'js/constants/vocabulary'
import { DASHBOARD } from 'js/constants/app-modules'
import RiskAssessmentTemplate from './SettingsSections/RiskAssessmentTemplate'
import { RISK_MATRIX } from 'js/constants/metrics/risk-assessment-metrics'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DDoSFeatures from './SettingsSections/DDoSFeatures'
import LogoEdit from './SettingsSections/LogoEdit'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        visibleModules: state.visibleModules,
        dashboardSettings: state.dashboardSettings
    }
}

const SettingsComponent = ({ currentUser, visibleModules, dashboardSettings }) => {

    const [isLoading, setIsLoading] = useState(true)
    
    let settingsTabs = [{
        id: 1,
        label: ORG,
        contentComponent: OrgSelector
    }, {
        id: 2,
        label: 'API Access Point',
        contentComponent: ApiAccessPoint
    }]

    if (currentUser?.is_admin && currentUser?.org_id === 1) {
        settingsTabs = [...settingsTabs, {
            id: 3,
            label: 'Modules',
            contentComponent: ModulesVisibility
        }, {
            id: 4,
            label: QUESTIONNAIRE,
            contentComponent: QuestionnaireFeatures
        }, {
            id: 5,
            label: DASHBOARD,
            contentComponent: DashboardStyles
        }, {
            id: 6,
            label: `${RISK_MATRIX} ${TEMPLATE}`,
            contentComponent: RiskAssessmentTemplate
        }, {
            id: 7,
            label: 'DDoS',
            contentComponent: DDoSFeatures
        },
        {
            id: 8,
            label: 'Company Logo',
            contentComponent: LogoEdit
        }]
    }
    
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const providedTab = (searchParams.get('tab') || 1) * 1
    const activeIndex = (providedTab && settingsTabs.map(t => t.id).includes(providedTab)) ? providedTab - 1 : 0
    
    const handleActiveTab = (index) => {
        navigate(`/settings?tab=${index + 1}`)
    }

    useEffect(() => {
        if (visibleModules?.length && dashboardSettings?.length) {
            setIsLoading(false)
        }
    }, [visibleModules, dashboardSettings])

    return (<div className='page-content'>
        <div className='page-header'>
            <h1 id='settings-page'>Settings</h1>
        </div>
        {isLoading ? <Loader /> : <div className='settings-con'>
            <Tabs
                tabs={settingsTabs} data={{ currentUser }} activeIndex={activeIndex} handleActiveTab={handleActiveTab} />
        </div>}
    </div>)
}

const Settings = connect(mapStateToProps)(SettingsComponent)

export default Settings