import { InputList, InputRow, Popup, genericTexts, helpers } from 'hub-web-lib'
import './TemplateGeneralInfo.scss'
import { useState, React, useEffect, useRef } from 'react'
import { QUESTIONNAIRE } from 'js/constants/vocabulary'
import { questionnaireDomainModel } from 'js/constants/models/questionnaire-template'
import { getLastSecondOfCurrentDay } from 'middlewares/ApiHelpers'
import { connect } from 'react-redux'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'
import { getUserRole } from 'js/ui-helpers/roles'


const mapStateToProps = state => {
    return {
        questionnareFeaturesList: state.questionnareFeaturesList
    }
}

const TemplateGeneralInfoComponent = ({ data = {}, questionnareFeaturesList }) => {

    const {
        isOpenInfoPopup,
        isNewQuestionnaire,
        questionnaire = {},
        domainsError,
        errorMsgs = [],
        orgUsers = [],
        isSaveButtonLoading,
        fieldsErrorMsg = {},
        closeInfoPopup = () => { },
        saveAndContinue = () => { },
        updateTemplateDetails = () => { },
        isInfoOpenedFromClient
    } = data
    const ref = useRef(null)
    const visibleQuestionnaireFeatures = getEnabledSettingItems(questionnareFeaturesList)

    const [domains, setDomains] = useState((questionnaire.domains || []).map(d => d.name))
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState({})
    const [isDescriptionEnabled, setIsDescriptionEnabled] = useState(!!questionnaire.description)
    const [isRemarksEnabled, setIsRemarksEnabled] = useState(!!questionnaire.remarks)
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const [domainToDelete, setDomainToDelete] = useState('')
    const isApproved = questionnaire?.approved
    const isViewOnly = isApproved || isInfoOpenedFromClient

    const changeValue = (val, key, isAuto) => {
        if (isAuto) return
        const updatedQuestionnaire = { ...currentQuestionnaire, [key]: val }
        setCurrentQuestionnaire({ ...currentQuestionnaire, [key]: val })
        updateTemplateDetails(updatedQuestionnaire, key)
    }

    const updateTemplateDomains = (updated) => {
        const updatedDomains = updated.map(tag => {
            const domain = currentQuestionnaire.domains.find(d => d.name === tag)
            return domain || { ...questionnaireDomainModel, name: tag, categories: [] }
        })
        changeValue(updatedDomains, 'domains')
    }

    const deleteDomain = () => {
        const updatedDomains = [...domains].filter(t => t !== domainToDelete)
        setDomains(updatedDomains)
        updateTemplateDomains(updatedDomains)
        closeDeletePopup()
    }

    const updateDomains = (updatedDomains) => {
        const deletedDomain = domains.find(d => !updatedDomains.includes(d))
        if (deletedDomain && updatedDomains.length < domains.length) {
            setDomainToDelete(deletedDomain)
            setIsOpenDeletePopup(true)
        } else {
            setDomains(updatedDomains)
            updateTemplateDomains(updatedDomains)
        }
    }


    const closeDeletePopup = () => {
        setIsOpenDeletePopup(false)
        setDomainToDelete('')
    }

    const managerList = orgUsers.filter(user => getUserRole(user) < 3)
    const dateTimeDetails = {
        showTimeSelect: false,
        dateFormat: 'yyyy/MM/dd',
        maxDate: getLastSecondOfCurrentDay()
    }

    useEffect(() => {
        if (!helpers.isSameObject(questionnaire, currentQuestionnaire)) {
            setCurrentQuestionnaire(questionnaire)
            setDomains((questionnaire.domains || []).map(d => d.name))
        }
    }, [questionnaire])
    useEffect(() => {
        if (fieldsErrorMsg.name !== '') ref.current?.scrollIntoView({ behavior: 'smooth' })

    }, [fieldsErrorMsg])
    return (
        <>
            <Popup
                isOpen={isOpenInfoPopup}
                title={isNewQuestionnaire ? 'Bring your new questionnaire to life' : `Edit ${QUESTIONNAIRE} Details`}
                onClose={closeInfoPopup}
                isDisabledClickOutside={true}
                customClass={'large new-template'}>
                <div className={`template-edit-general-info ${isViewOnly ? 'view-only' : ''}`}>
                    <fieldset ref={ref}
                    >
                        <h3 id='basic-info'>Basic Information</h3>
                        <InputRow
                            layout='vertical'
                            title={isNewQuestionnaire ? 'Give it a name' : 'Name'}
                            inputId='template-name'
                            defaultValue={currentQuestionnaire.name}
                            changeValueHandler={(val) => changeValue(val, 'name')}
                            disabled={isViewOnly}
                            errorMsg={fieldsErrorMsg.name}
                        />
                        {isDescriptionEnabled ?
                            <InputRow
                                inputTag='textarea'
                                title='Enter Description'
                                inputId='template-description'
                                layout='vertical'
                                autoHeight={true}
                                defaultValue={currentQuestionnaire.description || ''}
                                changeValueHandler={(val) => changeValue(val, 'description')}
                                disabled={isViewOnly}
                                errorMsg={fieldsErrorMsg.description} /> :
                            currentQuestionnaire.description || !isApproved ?
                                <button className='btn-link' onClick={() => setIsDescriptionEnabled(true)}><i className='icon-plus'></i>Add Description</button> : <></>}
                        <InputList
                            list={domains}
                            isTagsView={true}
                            details={{ addButtonText: 'Add Domain', title: 'Domains', inputId: 'select-domains' }}
                            disabled={isViewOnly || !visibleQuestionnaireFeatures?.domains_add_delete}
                            updateInputList={updateDomains} />
                        {domainsError && <div className='error-msg'>{domainsError}</div>}

                    </fieldset>
                    <fieldset>
                        <h3 className='details-title' id='details-review'>Details & Review</h3>
                        <InputRow
                            layout='vertical'
                            title='Version'
                            inputId='template-version'
                            defaultValue={currentQuestionnaire.version}
                            changeValueHandler={(val) => changeValue(val, 'version')}
                            disabled={isViewOnly}
                            errorMsg={fieldsErrorMsg.version} />
                        <InputRow
                            layout='vertical'
                            title='Code'
                            inputId='template-code'
                            defaultValue={currentQuestionnaire.code || ''}
                            changeValueHandler={(val) => changeValue(val, 'code')}
                            disabled={isViewOnly}
                            errorMsg={fieldsErrorMsg.code} />
                        <InputRow
                            layout='vertical'
                            inputId='template-date'
                            dateTimeDetails={dateTimeDetails}
                            title='Creation Date'
                            inputType='dateTime'
                            defaultValue={!isNewQuestionnaire ? new Date(currentQuestionnaire.creation_date || new Date()) : null}
                            changeValueHandler={(val, isAuto) => changeValue(val, 'creation_date', isAuto)}
                            disabled={isViewOnly}
                            errorMsg={fieldsErrorMsg.creation_date} />
                        <InputRow
                            inputId='template-approved-by'
                            layout='vertical'
                            inputTag='select'
                            placeholder='Select'
                            title={isNewQuestionnaire ? 'Submit for Manager Review' : 'Reviewer'}
                            options={managerList}
                            defaultValue={currentQuestionnaire.approved_by || ''}
                            changeValueHandler={(val) => changeValue(val, 'approved_by')}
                            disabled={isViewOnly}
                            errorMsg={fieldsErrorMsg.approved_by} />
                        {isRemarksEnabled ?
                            <InputRow
                                inputTag='textarea'
                                title='Enter Remarks'
                                inputId='template-remarks'
                                layout='vertical'
                                defaultValue={currentQuestionnaire.remarks || ''}
                                changeValueHandler={(val) => changeValue(val, 'remarks')}
                                disabled={isViewOnly}
                                errorMsg={fieldsErrorMsg.remarks} /> :
                            currentQuestionnaire.remarks || !isViewOnly ? <button className='btn-link' onClick={() => setIsRemarksEnabled(true)}><i className='icon-plus'></i>Add Remarks</button> : <></>}
                    </fieldset>
                    <p className='error-msg'>{errorMsgs.map((msg, i) => <span key={i}>{msg}</span>)}</p>
                    <div className='popup-buttons'>
                        {isInfoOpenedFromClient ? 
                            <button className='btn-cancel' onClick={closeInfoPopup}>Close</button> :
                            <button className={`btn-submit ${isSaveButtonLoading ? 'loader' : ''}`} onClick={saveAndContinue} id='edit-template-continue'>
                                {isApproved ? 'View questionnaire' : 'Save And Continue'}
                            </button>}
                    </div>
                </div>
            </Popup>
            <Popup
                onClose={closeDeletePopup}
                isOpen={isOpenDeletePopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>{genericTexts.defaultText.deletePopupText('domain')}</p>
                <div className='popup-buttons'>
                    <button className='btn-delete' onClick={deleteDomain} id='delete-domain'><i className='icon-delete'></i>{genericTexts.defaultText.delete}</button>
                </div>
            </Popup>
        </>
    )
}
const TemplateGeneralInfo = connect(mapStateToProps)(TemplateGeneralInfoComponent)

export default TemplateGeneralInfo