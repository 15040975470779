import { helpers } from 'hub-web-lib/dist/index-export'
import { ACTIVITY_PATH, DASHBOARD_PATH, appModulesNames } from 'js/constants/app-modules'
import { activityStatusesEnum } from 'js/constants/enums/activity'

const getSelectedActivityYear = (id, activities = []) => {
    const activity = (activities|| []).find(a => a.id === id)
    if (!activity) return helpers.getCurrentYear()
    const startDate = new Date(activity.start_date)
    return startDate.getFullYear()
} 

const getActivityLink = (row, activity, canEditActivity, completed, name) => {
    if (!canEditActivity && !completed) return null
    let moduleLink = row.link || helpers.transformToKebab(name)
    const modulePage = completed ? 
        `${DASHBOARD_PATH}${row.modulePropValue ? `-${row.modulePropValue}` : ''}` : 
        ACTIVITY_PATH
    return `/${moduleLink}/${modulePage}?id=${activity.id}`
}

const getRowName = (row) => {
    let name = ''
    if ('name' in row) {
        name = row.name
    } else {
        name = appModulesNames[row.module || '']
        if (row.modulePropValue) name = `${name} ${helpers.capitalizeString(row.modulePropValue)}`
    }
    return name
}

const getRowItems = (row, canEditActivity, name) => {
    return (row.activities || [])
        .filter(a => !a.archived && a.status !== activityStatusesEnum.cancelled)
        .map(a => {
            const completed = a.status == activityStatusesEnum.complete || a.is_complete
            const link = getActivityLink(row, a, canEditActivity, completed, name)
            const title = a.title || a.description
            return {
                id: a.id,
                title,
                start: a.start_date,
                end: a.end_date,
                completed,
                link
            }
        })
}

const getGanttData = (activitiesData, canEditActivity) => {
    if (!activitiesData) return []
    const data = activitiesData.map(row => {
        const name = getRowName(row)
        const items = getRowItems(row, canEditActivity, name)
        return {
            name,
            items
        }
    })
    return data
}

export {
    getSelectedActivityYear,
    getGanttData
}