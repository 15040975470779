import React from 'react'
import './InternalThreats.scss'
import { WidgetBlock } from 'hub-web-lib'
import { ChatWidget } from 'hub-web-lib'
import { Accordion } from 'hub-web-lib'
import { ProgressItem } from 'hub-web-lib'
import { ProgressBar } from 'hub-web-lib'
import { SortableTable } from 'hub-web-lib'
import { suggestionsList, internalThreatsData } from 'js/constants/lorem-ipsum'
import { home360CenterMenu } from 'js/constants/main-menus'
import { Link } from 'react-router-dom'

const threatsColumns = [{ 
    key: 'type', 
    name: 'Threat Type',
    type: 'string',
    width: 45
}, { 
    key: 'scoreImpact', 
    name: 'Score Impact',
    type: 'component',
    sortable: false,
    filterable: false,
    width: 20
}, { 
    key: 'affectedAssets', 
    name: 'Affected Assets',
    type: 'component',
    sortable: false,
    filterable: false,
    width: 35
}]

export const InternalThreats = () => {

    const tableData = internalThreatsData.map(item => {
        return {
            id: item.id,
            type: <span className='dropdown-item'><i className='icon-arrow-right'></i>{item.type}</span>,
            scoreImpact: <ProgressBar percent={item.scoreImpactPercent} title={`${item.scoreImpactPercent >= 0 ? 'up' : 'down'}`} />,
            affectedAssets: <Link to={`${item.id}`} id={`open-thread-${item.id}`}>Click to browse the assets affected <i className='icon-right'></i></Link>
        }
    })

    return (
        <div className='internal-threats page-content'>
            <div className='page-header'>
                <ProgressItem item={home360CenterMenu[0]} noLink={true} />
            </div>
            <SortableTable columns={threatsColumns} noFilter={true} tableData={tableData} tableId='internal-threads-table' />
            <div className='bottom-widgets'>

                <ChatWidget />

                <WidgetBlock 
                    title='Top Suggestions'
                    content={ <Accordion list={suggestionsList} count={ 3 } voting={true} /> }
                    size='small'
                />
            </div>
        </div>
    )
}

export default InternalThreats