import React, { useEffect, useState } from 'react'
import { genericTexts } from 'hub-web-lib'
import { ASSET } from 'js/constants/vocabulary'
import { Loader } from 'hub-web-lib'
import { SortableTable } from 'hub-web-lib'
import { Popup } from 'hub-web-lib'
import { InputRow } from 'hub-web-lib'
import { assetModel } from 'js/constants/models/pt'
import { assetTypes } from 'js/constants/enums/pt'
import { helpers } from 'hub-web-lib'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { numericJsonValues, emptyJsonValues } from 'middlewares/ApiHelpers'
import { assetColumns } from 'js/constants/table-columns'
import PTApi from 'services/PTApi'
import moment from 'moment'

const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(assetModel))

const actions = { 
    [genericTexts.actionsText.edit]: { text: genericTexts.actionsText.editText(ASSET, true), order: 1 },
    copy: { text: 'Copy JSON', order: 2 }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

export const AssetManagementComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    const [isLoading, setIsLoading] = useState(true)
    const [assets, setAssets] = useState([])
    const [selectedAssetId, setSelectedAssetId] = useState('')
    const [isOpenNewPopup, setIsOpenNewPopup] = useState(false)
    const [assetToEdit, setAssetToEdit] = useState({ ...assetModel })
    const [isNewAsset, setIsNewAsset] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })

    const getSelectedAsset = (assetId = selectedAssetId) => {
        const selectedAsset = assets.find(u => u.id === assetId * 1)
        const selectedAssetTitle = selectedAsset?.system_name || `${ASSET} ${assetId}`
        return { selectedAsset, selectedAssetTitle }
    }

    // const { selectedAssetTitle } = getSelectedAsset()

    const getTableData = () => {

        const data = assets.map(asset => {
            const type = helpers.capitalizeString(assetTypes.find(t => t.id === asset.asset_type)?.name || '-')
            const created = moment(asset.created_on).format('DD/MM/YY')
            const row = {
                ...asset, 
                system_name: asset.system_name || '',
                domain: asset.domain || '',
                full_url: asset.full_url || '',
                type,
                created,
                actions
            }
            return row
        })
        return data
    }

    const tableData = getTableData()

    const updateAssets = async () => {
        let allAssets = []
        for await (const assetType of assetTypes) {
            const assets = await PTApi.getAssets(currentOrgId, { asset_type: assetType.id })
            allAssets = [...allAssets, ...assets]
        }
        const assets = allAssets.sort((a, b) => a.id - b.id)
        if (!assets || assets.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: assets.message, notificationType: 'error' }))
            return
        }
        setAssets(assets)
    }

    const menuClickHandler = async (action, assetId) => {
        setSelectedAssetId(assetId)
        const { selectedAsset } = getSelectedAsset(assetId)
        const assetForCopy = { 
            domain: selectedAsset.domain, 
            port: selectedAsset.port, 
            full_url: selectedAsset.full_url, 
            environment: selectedAsset.env 
        }
        switch (action) {
        case genericTexts.actionsText.edit:
            setAssetToEdit({ ...selectedAsset })
            setIsNewAsset(false)
            setIsOpenNewPopup(true)
            break
        
        case 'copy':
            navigator.clipboard.writeText(JSON.stringify(assetForCopy, null, 4))
            break
        }

    }

    const openAddAssetPopup = () => {
        setIsOpenNewPopup(true)
        setIsNewAsset(true)
        setAssetToEdit({ ...assetModel })
    }

    const updateAsset = async () => {
        const numerizedAsset = numericJsonValues(assetToEdit)
        numerizedAsset.org_id = currentOrgId
        const assetUpdated = await updateCreateAssetAction(numerizedAsset)
        if (!assetUpdated || assetUpdated.error) {
            
            if (Array.isArray(assetUpdated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                assetUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                const message = typeof assetUpdated.message === 'string' ? assetUpdated.message : assetUpdated.message.join(', ')
                setErrorMsg(message)
            }
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: getSuccessMessage() }))
        closePopupCreate()
        await updateAssets()
    }

    const updateCreateAssetAction = async (asset) => {
        let updated
        if (isNewAsset) {
            updated = await PTApi.createAsset(asset)
        } else {
            updated = await PTApi.updateAsset(asset.id, asset)
        }
        return updated
    }

    const getSuccessMessage = () => {
        return isNewAsset ? genericTexts.defaultText.createdSuccess(ASSET) : genericTexts.defaultText.updatedSuccess(ASSET)
    }

    const changeValueHandler = (key, val) => {
        setAssetToEdit({ ...assetToEdit, [key]: val })
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
    }

    const closePopupCreate = () => {
        setAssetToEdit({ ...assetModel })
        setIsOpenNewPopup(false)
        setErrorMsg('')
        setFieldsErrorMsg({ ...fieldsErrorMsgDefault })
    }

    useEffect( async () => {
        await updateAssets()
        setIsLoading(false)
    }, [])

    return (
        <div className='scheduler page-content'>
            <div className='page-header'>
                <h1 id='pt-assets-management'>Assets Management</h1>
                {!isLoading && <button onClick={openAddAssetPopup} id='add-new-asset'>{genericTexts.defaultText.addNewButton(ASSET)}</button>}
            </div>
            {isLoading ? <Loader /> : <div className='assets-con'>
                <SortableTable 
                    columns={assetColumns}
                    tableData={tableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    tableId='pt-assets-table' />

                <Popup
                    onClose={closePopupCreate}
                    isOpen={isOpenNewPopup}
                    title={genericTexts.defaultText.addNewPopupTitle(ASSET)}
                    isDisabledClickOutside={true}>
                
                    <InputRow 
                        inputId='asset_type' 
                        title='Type' 
                        inputTag='select'
                        placeholder='Select'
                        options={assetTypes.map(t => ({ ...t, name: helpers.capitalizeString(t.name) }))}
                        changeValueHandler={(val) => changeValueHandler('asset_type', val)} 
                        defaultValue={ assetToEdit.asset_type || '' }
                        errorMsg={fieldsErrorMsg.asset_type} />
                    
                    {!!(assetToEdit.asset_type) && <>
                        <InputRow 
                            inputId='name' 
                            title='Name' 
                            changeValueHandler={(val) => changeValueHandler('system_name', val)} 
                            defaultValue={ assetToEdit.system_name || '' }
                            errorMsg={fieldsErrorMsg.system_name} />

                        <InputRow 
                            inputId='domain' 
                            title='Domain' 
                            changeValueHandler={(val) => changeValueHandler('domain', val)} 
                            defaultValue={ assetToEdit.domain || '' }
                            errorMsg={fieldsErrorMsg.domain} />

                        <InputRow 
                            inputId='full_url' 
                            title='Full URL' 
                            changeValueHandler={(val) => changeValueHandler('full_url', val)} 
                            defaultValue={ assetToEdit.full_url || '' }
                            errorMsg={fieldsErrorMsg.full_url} />

                        <InputRow 
                            inputId='port' 
                            title='Port' 
                            changeValueHandler={(val) => changeValueHandler('port', val)} 
                            defaultValue={ assetToEdit.port || '' }
                            errorMsg={fieldsErrorMsg.port} />

                        <InputRow 
                            inputId='scope' 
                            title='Env Scope' 
                            changeValueHandler={(val) => setAssetToEdit({ ...assetToEdit, env: { ...assetToEdit.env, scope: val } })} 
                            defaultValue={ assetToEdit.env.scope || '' }
                            errorMsg={fieldsErrorMsg.env.scope} />

                        <InputRow 
                            inputId='exposure' 
                            title='Env Exposure' 
                            changeValueHandler={(val) => setAssetToEdit({ ...assetToEdit, env: { ...assetToEdit.env, exposure: val } })} 
                            defaultValue={ assetToEdit.env.exposure || '' }
                            errorMsg={fieldsErrorMsg.env.exposure} />
                    </>}

                    

                    <p className='error-msg' id='update-asset-error'>{errorMsg}</p>

                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={updateAsset} disabled={!assetToEdit.asset_type} id='create-new-asset'>{genericTexts.defaultText.createNewButton(ASSET, !isNewAsset)}</button>
                        <button onClick={closePopupCreate} className='btn-cancel' id='create-new-asset-cancel'>{genericTexts.defaultText.cancel}</button>
                    </div>
                </Popup>

            </div>}
        </div>     
    )
}

const AssetManagement = connect(mapStateToProps)(AssetManagementComponent)

export default AssetManagement