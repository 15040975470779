import React, { createRef, useEffect, useRef } from 'react'
import TemplateQuestion from './TemplateQuestion'
import './TemplateQuestions.scss'
import TemplateCategoryContent from './TemplateCategoryContent'
import NoDataMessage from './NoDataMessage'
import { CATEGORY, QUESTION } from 'js/constants/vocabulary'

const TemplateQuestions = ({ data = {} }) => {

    const {
        categories = [],
        activeCategoryId,
        activeQuestionId,
        updateActiveQuestion = () => {},
        updateCategoryName = () => {},
        isShown,
        addQuestion = () => {},
        addCategory = () => {}
    } = data
    const activeCategory = categories.find(c => c.id === activeCategoryId)
    const activeCategoryQuestions = activeCategory?.questions || []

    const componentRefs = useRef([])
    componentRefs.current = activeCategoryQuestions.map(q => `category-question-${q.id}`).map((_, i) => componentRefs.current[i] ?? createRef())
    useEffect(() => {
        setTimeout(() => {
            componentRefs.current = activeCategoryQuestions.map(q => `category-question-${q.id}`).map((_, i) => componentRefs.current[i] ?? createRef())
            const refIndex = activeCategoryQuestions.findIndex(q => q.id === activeQuestionId)
            if (refIndex > -1 && componentRefs.current && componentRefs.current[refIndex]?.current) {
                componentRefs.current[refIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true })
            } else {
                const activeQuestion = document.getElementById(`category-question-${activeQuestionId}`)
                if (activeQuestion) activeQuestion.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true })
            }
        }, 100)
    }, [activeQuestionId])

    const questionsForm = <div className='template-questions-form-wrapper scroll'>
        <div className='form template-questions-form'>
            {activeCategoryQuestions?.length ? activeCategoryQuestions.map((question, i) => 
                <div key={question.id} ref={componentRefs.current[i]} id={`category-question-${question.id}`} onClick={() => updateActiveQuestion(question.id)}>
                    <TemplateQuestion data={{ ...data, question, isShown }} />
                </div>
            ) : <NoDataMessage itemType={QUESTION} addNewAction={() => addQuestion(activeCategoryId)} />}
        </div>
    </div>

    return (
        <div className='questionnaire-questions-section template-questions-section'>
            {activeCategory ? <>
                <TemplateCategoryContent 
                    data={data}
                    category={activeCategory} 
                    updateName={updateCategoryName}
                    isSimpleMode={true}
                    isShown={isShown} />
                {questionsForm}
            </> : <NoDataMessage itemType={CATEGORY} addNewAction={addCategory} />}
            
        </div>
    )
}

export default TemplateQuestions