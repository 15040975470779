import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, helpers, InfoCard, Loader, SortableTable } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { findingsColumnsFull, assetColumns, usersColumns } from 'js/constants/table-columns'
import { getReportInfo, statusTypes } from 'js/ui-helpers/pt'
import moment from 'moment'
import { assetTypes, findingSeverityTypes } from 'js/constants/enums/pt'
import { roles, statuses } from 'js/constants/enums/user'
import store from 'js/store'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import PTApi from 'services/PTApi'
import { getUserRole } from 'js/ui-helpers/roles'

const assetInfoDefault = {
    id: {
        title: `${ACTIVITY} ID`
    },
    pdf_report_id: {
        title: 'PDF ID'
    },
    score: {
        title: 'Score'
    },
    created_on: {
        title: 'Created date'
    }
}


const ReportInfo = () => {
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentActivityInfo, setCurrentActivityInfo] = useState({})

    const getActivityInfo = async () => {
        let info = location?.state?.report
        if (info) {
            setCurrentActivityInfo(info)
            return
        }
        const reports = await PTApi.getReports(store.getState().currentUser?.current_org)
        if (!reports || reports.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: reports.message, notificationType: 'error' }))
            return
        }
        const reportId = (location?.pathname || '').split('/')[3]*1
        let report = reports.find(r => r.id === reportId)
        report = await getReportInfo(report)
        setCurrentActivityInfo(report)
    }

    const getCardData = () => {
        const data = { ...assetInfoDefault }
        for (const key of Object.keys(data)) {
            data[key].value = currentActivityInfo[key] || '-'
        }
        data.created_on.value = moment(currentActivityInfo.created_on).format('DD/MM/YY')
        return data
    }

    const getFindingsTableData = () => {
        const data = (currentActivityInfo?.findings || []).map((finding, index) => {
            const severity = findingSeverityTypes.find(t => t.id === finding.severity)?.name || ''
            const recommendationsArray = (Array.isArray(finding.recommendations) ? finding.recommendations : JSON.parse(finding.recommendations || '[]')).map(r => JSON.stringify(r))
            const recommendations = <ul>{recommendationsArray.map((r, i) => <li key={i}>{r}</li>)}</ul>
            return {
                ...finding,
                id: finding.id || index + 1,
                date: moment(finding.date).format('DD/MM/YY'),
                statusTitle: <p className={`status ${statusTypes[severity]}`}>{severity}</p>,
                recommendations
            }
        })
        return data
    }

    const getAssetsTableData = () => {
        const data = (currentActivityInfo?.assets || []).map(asset => {
            const type = helpers.capitalizeString(assetTypes.find(t => t.id === asset.asset_type)?.name || '-')
            const created = moment(asset.created_on).format('DD/MM/YY')
            const row = {
                ...asset, 
                system_name: asset.system_name || '',
                domain: asset.domain || '',
                full_url: asset.full_url || '',
                type,
                created
            }
            return row
        })
        return data
    }

    const getUsersTableData = () => {

        const data = (currentActivityInfo?.participants || []).map(user => {
            const userRole = roles.find(r => r.id === getUserRole(user))
            const userRoleName = userRole?.name ? helpers.capitalizeString(userRole.name) : '-'
            const userStatus = statuses.find(s => s.id === user.status)
            const userStatusName = userStatus?.name ? helpers.capitalizeString(userStatus.name) : '-'
            return {
                ...user, 
                name: user.name || '',
                role: userRoleName,
                status: userStatusName,
            }
        })
        return data
    }

    const cardData = getCardData()
    const findingsTableData = getFindingsTableData()
    const assetsTableData = getAssetsTableData()
    const usersTableData = getUsersTableData()


    useEffect( async () => {
        await getActivityInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content'>
            <div className='page-header'>
                <h1 id='pt-activity-info'>{ACTIVITY} {`${moment(currentActivityInfo.created_on).format('DD/MM/YY') || ''}`} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentActivityInfo.id ? <div className='report-info-con info-card-con'>
                <InfoCard cardData={cardData} />
                <h2 id='pt-assets'>Assets</h2>
                <SortableTable columns={assetColumns} tableData={assetsTableData} tableId='pt-info-assets-table' />
                <h2 id='pt-findings'>Findings</h2>
                <SortableTable columns={findingsColumnsFull} tableData={findingsTableData} tableId='pt-info-findings-table' />
                <h2 id='pt-participants'>Participants</h2>
                <SortableTable columns={usersColumns} tableData={usersTableData} tableId='pt-info-participants-table' />
            </div> : <Alert message={`No data for this ${ACTIVITY}`} />}
        </div>
    )
}

export default ReportInfo