export const UPDATE_IS_NAV_BACK = 'UPDATE_IS_NAV_BACK'
export const UPDATE_ERROR_CONFIG = 'UPDATE_ERROR_CONFIG'
export const REQUEST = 'REQUEST'
export const UPDATE_NOTIFICATION_CONTENT = 'UPDATE_NOTIFICATION_CONTENT'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_PROGRESS_STEP = 'UPDATE_PROGRESS_STEP'
export const UPDATE_VISIBLE_MODULES = 'UPDATE_VISIBLE_MODULES'
export const UPDATE_QUESTIONNAIRE_FEATURES = 'UPDATE_QUESTIONNAIRE_FEATURES'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_DASHBOARD_SETTINGS = 'UPDATE_DASHBOARD_SETTINGS'
export const UPDATE_RISK_MATRIX_SETTINGS = 'UPDATE_RISK_MATRIX_SETTINGS'
export const UPDATE_CURRENT_USER_ORGS = 'UPDATE_CURRENT_USER_ORGS'
export const UPDATE_IS_TENANT_ASSESSMENT_SUBMIT = 'UPDATE_IS_TENANT_ASSESSMENT_SUBMIT'
export const UPDATE_DDOS_FEATURES = 'UPDATE_DDOS_FEATURES'


