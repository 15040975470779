import { helpers } from 'hub-web-lib/dist/index-export'
import { DDOS_RESILIENCE_MODULE } from 'js/constants/app-modules'
import { RISK_DIMENSION, RISK_LEVEL_DIMENSION } from 'js/constants/dashboard-settings'
import { EXPOSURE, filterDates, levelDimensionFilter, levelDimensionsDefault } from 'js/constants/filter'
import React from 'react'

const getFilterDynamicDimensions = (findings, dimension) => {
    const findingCategoriesAll = (findings || []).map(f => f[dimension]).filter(f => f)
    return helpers.getUniqueArray(findingCategoriesAll)
}

const getFilterLevelDimensions = () => {
    const filter = {}
    levelDimensionsDefault.forEach(dimension => {
        filter[dimension] = levelDimensionFilter
    })
    return filter
}

const transformUnderscoreToSentanse = (string) => {
    return string.replace(/_/g, ' ')
}

const getFilterLevelDimensionsUi = (metricsProps, levelDimensions, findings, module) => {
    if (!findings?.length) return {}
    const propsFilter = {}
    levelDimensions.forEach(dimension => {
        // Note: replace risk with risk level :'(
        const metricsPropsDimension = dimension === RISK_LEVEL_DIMENSION ? RISK_DIMENSION : dimension
        propsFilter[dimension] = metricsProps[metricsPropsDimension].map(p => {
            // Note: replace risk level with exporuse for ddos only :'(
            const dimensionUI = module === DDOS_RESILIENCE_MODULE && dimension === RISK_LEVEL_DIMENSION ? EXPOSURE : dimension
            const number = findings.filter(f => f[dimensionUI] === p.id).length
            return {
                id: p.id,
                name: <>
                    <span className='dimension-name' id={`dimension-name-${dimension}-${p.id}`}>{p.name}</span>
                    <span className='dimension-color' id={`dimension-color-${dimension}-${p.id}`} style={{ backgroundColor: p.color }}></span>
                    <span className='dimension-number' id={`dimension-number-${dimension}-${p.id}`}>{number}</span>
                </>
            }
        })
    })
    return propsFilter
}

const getActivityFilter = (findings, dynamicDimensions) => {
    if (!findings?.length) return {}
    const filter = {
        ...filterDates,
        ...getFilterLevelDimensions()
    }
    dynamicDimensions.forEach(dimension => {
        filter[dimension] = getFilterDynamicDimensions(findings, dimension)
    })
    return filter
}

const getDefaultFilter = (activityFilter, defaultFilter, filterPin) => {
    const filter = {}
    Object.keys(activityFilter).forEach(key => {
        filter[key] = filterPin[key] && defaultFilter[key] ? defaultFilter[key] : activityFilter[key]
    })
    return filter
}

const getFilteredMetricsProps = (metricsProps, filter, dimension) => {
    const filterDimention = dimension === RISK_DIMENSION ? RISK_LEVEL_DIMENSION : dimension
    return filter?.[filterDimention] ? (metricsProps[dimension] || []).filter(p => (filter[filterDimention] || []).includes(p.id)) : metricsProps[dimension] || []
}

const getChangedFiltersCount = (activityFilter, filter) => {
    let count = 0
    Object.keys(activityFilter || {}).forEach(dimension => {
        if (!helpers.isSameObject(activityFilter[dimension], filter[dimension])) count++
    })
    return count
}

export {
    getFilterDynamicDimensions,
    getFilterLevelDimensions,
    getActivityFilter,
    getFilterLevelDimensionsUi,
    getDefaultFilter,
    getFilteredMetricsProps,
    transformUnderscoreToSentanse,
    getChangedFiltersCount
}