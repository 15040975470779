import { 
    CHAIN_OF_THE_ATTACK,
    BREACHING_THE_INTERNAL_PERIMETER,
    INTERNAL_ATTACK,
    EXTERNAL_ATTACK,
    APPLICATION_PT,
    INFRASTRUCTURE_PT,
    BY_SEVERITY,
    RISK_LEVEL_BY_CATEGORY,
    FINDINGS,
    FINDINGS_SEVERITY,
    CATEGORY_BY_YEAR,
    RISK_LEVEL_TREND,
    RADAR,
    BY_CATEGORY,
    BY_IMPACT,
    BY_QUARTER,
} from './common-metrics'

const redTeamMetricsKeys = [
    CHAIN_OF_THE_ATTACK,
    BREACHING_THE_INTERNAL_PERIMETER,
    INTERNAL_ATTACK,
    EXTERNAL_ATTACK,  
    APPLICATION_PT,
    INFRASTRUCTURE_PT,
    BY_IMPACT,
    BY_SEVERITY
]

const redTeamMetricsCategories = [
    RISK_LEVEL_BY_CATEGORY,
    FINDINGS,
    FINDINGS_SEVERITY,
    CATEGORY_BY_YEAR,
    RISK_LEVEL_TREND
]

const redTeamMetricsWidgets = [
    RADAR,
    BY_CATEGORY,
    BY_IMPACT,
    BY_SEVERITY,
    BY_QUARTER,
    CHAIN_OF_THE_ATTACK,
    INTERNAL_ATTACK,
    EXTERNAL_ATTACK, 
    APPLICATION_PT,
    INFRASTRUCTURE_PT,
    BREACHING_THE_INTERNAL_PERIMETER,
    'Internal Attack - Full Network Compromise',
    'Application Misconfigurations',
    'Broken Access Control',
    'Brute Force Attack',
    'Credential Harvesting Attack',
    'Credential Stuffing',
    'Information Disclosure Attack',
    'Infrastructure Misconfigurations',
    'Reflected Cross-Site Scripting (XSS)',
    'Transport Layer Protection'
]

export {
    redTeamMetricsKeys,
    redTeamMetricsCategories,
    redTeamMetricsWidgets
}