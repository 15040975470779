import { ACTIVITIES, ACTIVITY_PATH, ASSESSMENTS, ASSESSMENTS_PATH, ATTACK_SURFACE_MODULE, CYBER_READINESS_MODULE, DASHBOARD_PATH, DASHBOARD, DDOS_RESILIENCE_MODULE, INCIDENT_RESPONSE_MODULE, PEN_TEST_MODULE, PHISHING_SIMULATION_MODULE, QUESTIONNAIRES, QUESTIONNAIRES_PATH, RANSOMWARE_RESILIENCE_MODULE, RED_TEAM_MODULE, appModulesNames, appModulesPaths, RISK_ASSESSMENT_MODULE, SSDLC_MODULE } from './app-modules'
import { APPLICATION, INFRASTRUCTURE, penTestTypeNames } from './enums/pen-test'

const home360Menu = [{
    id: 'people',
    name: 'People',
    percent: 55
}, {
    id: 'processes',
    name: 'Processes',
    percent: 16
}, {
    id: 'technology',
    name: 'Technology',
    percent: 75
}]

const home360CenterMenu = [{
    id: 'internalThreats',
    name: 'Internal Threats',
    percent: 18,
    link: 'internal-threats'
}, {
    id: 'externalThreats',
    name: 'External Threats',
    percent: 45,
    link: 'external-threats'
}]


const home360BottomMenu = [{
    id: 'iso27000',
    name: 'ISO 27000',
    percent: 91
}, {
    id: 'hipaa',
    name: 'HIPAA',
    percent: 73
}, {
    id: 'pciDss',
    name: 'PCI-DSS',
    percent: 43
}]

const sideMenuSections = [{
    id: 'navigation',
    name: 'Platform Navigation'
}, {
    id: 'comingSoon',
    name: 'Coming Soon'
}]

const sideMenuItems = [{
    id: 'home',
    name: 'Home',
    icon: 'home',
    link: '/',
}, {
    id: CYBER_READINESS_MODULE,
    name: appModulesNames[CYBER_READINESS_MODULE],
    icon: 'shield-side',
    link: `${appModulesPaths[CYBER_READINESS_MODULE]}/${DASHBOARD_PATH}`,
    subItems: [{
        id: `${CYBER_READINESS_MODULE}${DASHBOARD}`,
        name: DASHBOARD,
        link: `${appModulesPaths[CYBER_READINESS_MODULE]}/${DASHBOARD_PATH}`
    }, {
        id: `${CYBER_READINESS_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[CYBER_READINESS_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]
    }, {
        id: `${CYBER_READINESS_MODULE}${QUESTIONNAIRES}`,
        name: QUESTIONNAIRES,
        link: `${appModulesPaths[CYBER_READINESS_MODULE]}/${QUESTIONNAIRES_PATH}`,
        roles: [1, 2]
    }, {
        id: `${CYBER_READINESS_MODULE}${ASSESSMENTS}`,
        name: ASSESSMENTS,
        link: `${appModulesPaths[CYBER_READINESS_MODULE]}/${ASSESSMENTS_PATH}`,
        roles: [1, 2, 3]
    }]
}, {
    id: ATTACK_SURFACE_MODULE,
    name: appModulesNames[ATTACK_SURFACE_MODULE],
    icon: 'dart',
    link: `${appModulesPaths[ATTACK_SURFACE_MODULE]}/${DASHBOARD_PATH}`,
    subItems: [{
        id: `${ATTACK_SURFACE_MODULE}${DASHBOARD}`,
        name: DASHBOARD,
        link: `${appModulesPaths[ATTACK_SURFACE_MODULE]}/${DASHBOARD_PATH}`
    }, {
        id: `${ATTACK_SURFACE_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[ATTACK_SURFACE_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]
    }]
}, {
    id: RED_TEAM_MODULE,
    name: appModulesNames[RED_TEAM_MODULE],
    icon: 'group-v',
    link: `${appModulesPaths[RED_TEAM_MODULE]}/${DASHBOARD_PATH}`,
    subItems: [{
        id: `${RED_TEAM_MODULE}${DASHBOARD}`,
        name: DASHBOARD,
        link: `${appModulesPaths[RED_TEAM_MODULE]}/${DASHBOARD_PATH}`
    }, {
        id: `${RED_TEAM_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[RED_TEAM_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]

    }]
}, {
    id: PEN_TEST_MODULE,
    name: appModulesNames[PEN_TEST_MODULE],
    icon: 'shield-lock',
    link: `${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${APPLICATION}`,
    subItems: [{
        id: `${PEN_TEST_MODULE}${DASHBOARD}${penTestTypeNames[APPLICATION]}`,
        name: `${penTestTypeNames[APPLICATION]} ${DASHBOARD}`,
        link: `${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${APPLICATION}`
    }, {
        id: `${PEN_TEST_MODULE}${DASHBOARD}${penTestTypeNames[INFRASTRUCTURE]}`,
        name: `${penTestTypeNames[INFRASTRUCTURE]} ${DASHBOARD}`,
        link: `${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${INFRASTRUCTURE}`
    }, {
        id: `${PEN_TEST_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[PEN_TEST_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]

    }]
}, {
    id: INCIDENT_RESPONSE_MODULE,
    name:  appModulesNames[INCIDENT_RESPONSE_MODULE],
    icon: 'settings',
    link: `${appModulesPaths[INCIDENT_RESPONSE_MODULE]}/${DASHBOARD_PATH}`
}, {
    id: DDOS_RESILIENCE_MODULE,
    name: appModulesNames[DDOS_RESILIENCE_MODULE],
    icon: 'rocket',
    link: `${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${DASHBOARD_PATH}`,
    subItems: [{
        id: `${DDOS_RESILIENCE_MODULE}${DASHBOARD}`,
        name: DASHBOARD,
        link: `${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${DASHBOARD_PATH}`
    }, {
        id: `${DDOS_RESILIENCE_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]

    }]
}, {
    id: RISK_ASSESSMENT_MODULE,
    name: appModulesNames[RISK_ASSESSMENT_MODULE],
    icon: 'monitoring',
    link: `${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${DASHBOARD_PATH}`,
    subItems: [{
        id: `${RISK_ASSESSMENT_MODULE}${DASHBOARD}`,
        name: DASHBOARD,
        link: `${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${DASHBOARD_PATH}`
    }, {
        id: `${RISK_ASSESSMENT_MODULE}${ACTIVITIES}`,
        name: ACTIVITIES,
        link: `${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${ACTIVITY_PATH}`,
        roles: [1, 2]

    }]
}, {
    id: PHISHING_SIMULATION_MODULE,
    name: appModulesNames[PHISHING_SIMULATION_MODULE],
    icon: 'settings',
    link: `${appModulesPaths[PHISHING_SIMULATION_MODULE]}/${DASHBOARD_PATH}`
}, {
    id: RANSOMWARE_RESILIENCE_MODULE,
    name: appModulesNames[RANSOMWARE_RESILIENCE_MODULE],
    icon: 'settings',
    link: `${appModulesPaths[RANSOMWARE_RESILIENCE_MODULE]}/${DASHBOARD_PATH}`
// }, {
//     id: 'pt',
//     name: 'PT',
//     icon: 'settings',
//     link: 'pt/dashboard',
//     subItems: [{
//         id: 'ptDashboard',
//         name: 'Dashboard',
//         link: 'pt/dashboard'
//     }, {
//         id: 'assetManagement',
//         name: 'Assets',
//         link: 'pt/assets',
//         roles: [1]
//     }, {
//         id: 'reportManagement',
//         name: 'Activities',
//         link: 'pt/activities',
//         roles: [1]
//     }]
}, {
    id: SSDLC_MODULE,
    name: appModulesNames[SSDLC_MODULE],
    icon: 'settings',
    link: `${appModulesPaths[SSDLC_MODULE]}/${DASHBOARD_PATH}`
}, {
    id: 'admin',
    name: 'Admin',
    icon: 'user',
    link: 'admin/orgs',
    roles: [1],
    subItems: [{
        id: 'orgManagement',
        name: 'Organizations',
        link: 'admin/orgs'
    // }, {
    //     id: 'currentOrg',
    //     name: 'Current Organization',
    //     link: 'admin/current-org'
    }, {
        id: 'userManagement',
        name: 'Users',
        link: 'admin/users'
    }]

},]

const tenantSubmenu = [{
    id: `tenant${DASHBOARD}`,
    name: DASHBOARD,
    link: `tenant/${DASHBOARD_PATH}`
}, {
    id: `tenant${ASSESSMENTS}`,
    name: ASSESSMENTS,
    link: `tenant/${ASSESSMENTS_PATH}`

}]

const settingsMenuItem = {
    id: 'settings',
    name: 'Settings',
    icon: 'settings',
    link: 'settings'
}

const headerMenuItems = [{
    id: 'orgSelectorHeader'
}, {
    id: 'profileAvatar',
    subItems: [{
        id: 'profile',
        name: 'Profile',
        link: 'profile'
    }, {
        id: 'logout',
        name: 'Log Out',
        action: 'logOut'
    }]
}, {
    id: 'en',
    name: 'EN'
}]

const ddossMenu = [{
    id: 'targetsExposure',
    name: 'Targets Exposure',
    percent: 76
}, {
    id: 'attackTypes',
    name: 'Attack Types',
    percent: 11
}, {
    id: 'simulatedBots',
    name: 'Simulated Bots',
    percent: 76
}]

export {
    home360Menu,
    sideMenuSections,
    sideMenuItems,
    headerMenuItems,
    home360CenterMenu,
    home360BottomMenu,
    ddossMenu,
    tenantSubmenu,
    settingsMenuItem
}