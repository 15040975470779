import React, { useState } from 'react'
import { Alert, CheckBoxGroup, InputList, InputRow } from 'hub-web-lib'
import moment from 'moment'
import AssessmentView from 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentView'
import './ReviewAssessment.scss'
// import UserApi from 'services/UserApi'
import { getUserRole } from 'js/ui-helpers/roles'
// import { connect } from 'react-redux'
import { assessmentStatusesEnum } from 'js/constants/enums/questionnaire'


// const mapStateToProps = state => {
//     return {
//         currentUser: state.currentUser,
//     }
// }
const ReviewAssessment = ({ data }) => {
    const {
        isReview,
        orgUsers,
        currentOrg,
        selectedTemplate,
        currentAssessment,
        setCurrentAssessment = () => { },
        setIsEmailListChanged = () => { },
        // orgs,
    } = data
    // const [currentOrgUsers, setCurrentOrgUsers] = useState(orgUsers)
    // const [currentOrgSelector, setCurrentOrgSelector] = useState(currentUser?.current_org)
    const [isUserChanged, setIsUserChanged] = useState(false)
    const templateDomains = (selectedTemplate?.domains || []).map(d => d.name)
    const tabLabel = isReview ? 'Review Assessment' : 'Submit & Review'

    // const updateCurrentOrgSelector = async () => {
    // setCurrentOrgSelector(orgId)
    //     const users = await UserApi.getOrgUsers(currentUser?.current_org)
    //     setCurrentOrgUsers(users)

    // }

    // useEffect(() => {
    //     updateCurrentOrgSelector()
    // }, [])
    const assessmentFields = <>
        <>
            <div className='user-submit-unlock-title'>
                <h4>Users To Submit</h4>
                {isReview ? <button className='btn-icon icon-lock' onClick={() => setIsUserChanged(!isUserChanged)} /> : <></>}
            </div>
            {/* {orgs.length
             ?
                <InputRow
                    inputId='org_id'
                    inputTag='select'
                    placeholder='Select'
                    options={orgs.sort((a, b) => a.id - b.id)}
                    changeValueHandler={(val) => updateCurrentOrgSelector(val)}
                    defaultValue={currentOrgSelector}
                /> : <></>} */}
            <CheckBoxGroup
                layout='vertical'
                checkedIds={currentAssessment.mail_to || []}
                allItems={orgUsers.filter(user => getUserRole(user) <= 3)}
                updateItems={(val) => setCurrentAssessment({ ...currentAssessment, mail_to: val })}
                isInputRow={true}
                disabledIds={isReview ? isUserChanged ? [] : orgUsers.map(o => o.id) : []} />
            {isReview && isUserChanged ? <div className='email-all'><InputRow inputId='mark-send-all-box' inputType='checkbox' title='Send email to all users again?' changeValueHandler={setIsEmailListChanged} />
            </div> : <></>}
        </>

        <InputRow
            inputTag='textarea'
            title='Message (Optional)'
            inputId='submit-message'
            layout='vertical'
            defaultValue={currentAssessment.remarks}
            changeValueHandler={(val) => setCurrentAssessment({ ...currentAssessment, remarks: val })}
            disabled={isReview} />
    </>

    const templateFields = <>
        <InputRow inputId='submit-template-name'
            title='Questionnaire Name'
            layout='vertical'
            defaultValue={selectedTemplate?.name}
            disabled={true} />
        {isReview && <>
            <InputList list={templateDomains} disabled={true} isTagsView={true} details={{ inputId: 'domains-list', title: 'Domains' }} />
        </>}
        <InputRow inputId='submit-template-version'
            title='Version'
            layout='vertical'
            defaultValue={selectedTemplate?.version}
            disabled={true} />
        <InputRow inputId='submit-template-creation_date'
            title='Creation Date'
            layout='vertical'
            defaultValue={selectedTemplate?.creation_date ? moment(selectedTemplate.creation_date).format('DD/MM/YY') : ''}
            disabled={true} />
        <InputRow inputId='submit-template-created-by'
            title='Created By'
            layout='vertical'
            defaultValue={orgUsers.find(u => u.id === selectedTemplate?.created_by)?.name}
            disabled={true} />
    </>

    const assessmentStatus = <InputRow
        title='Status'
        inputId='submit-status'
        layout='vertical'
        defaultValue={currentAssessment.status === assessmentStatusesEnum.submitted ? 'Submitted' : 'In Process'}
        disabled={true} />

    return (
        <div className='assessment-review'>
            <div className='assessment-review-header'>
                <h3 id='review-assessment'>{tabLabel}</h3>
                {isReview && <AssessmentView selectedQuestionnaire={selectedTemplate} assessmentId={currentAssessment?.id} currentOrgId={currentOrg?.id} />}
            </div>
            <div className='assessment-form'>
                {selectedTemplate ? (isReview ? <>
                    {templateFields}
                    {assessmentFields}
                    {assessmentStatus}
                </> : <>
                    {assessmentFields}
                    {templateFields}

                </>) : <Alert message='Please Select Template to Submit' alertType='warning' />}
            </div>
        </div>
    )
}

// const ReviewAssessment = connect(mapStateToProps)(ReviewAssessmentComponent)

export default ReviewAssessment
