import React, { useEffect, useState } from 'react'
import './PT.scss'
import { useNavigate } from 'react-router-dom'
import { genericTexts } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { Loader } from 'hub-web-lib'
import { SortableTable } from 'hub-web-lib'
import { getReportInfo } from 'js/ui-helpers/pt'
import { helpers } from 'hub-web-lib'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import PTApi from 'services/PTApi'
import moment from 'moment'

const reportColumns = [{ 
    key: 'id', 
    name: 'ID',
    type: 'string',
    width: 5
}, { 
    key: 'system_name', 
    name: 'System Name',
    type: 'string',
    width: 20
}, { 
    key: 'created', 
    name: 'Created on',
    type: 'string',
    width: 10
}, { 
    key: 'score', 
    name: 'Score',
    type: 'string',
    width: 5
}, { 
//     key: 'completed', 
//     name: 'Completed',
//     type: 'string',
//     width: 5
// }, { 
    key: 'severityList', 
    name: 'Findings',
    type: 'list',
    filterable: false,
    sortable: false,
    width: 40
}, { 
    key: 'actions', 
    name: '',
    type: 'actions',
    sortable: false,
    filterable: false,
    width: 5
}]

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

export const ReportManagementComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org
    const [isLoading, setIsLoading] = useState(true)
    const [reports, setReports] = useState([])
    const [selectedReportId, setSelectedReportId] = useState('')

    const navigate = useNavigate()

    const getSelectedReport = (reportId = selectedReportId) => {
        const selectedReport = reports.find(u => u.id === reportId * 1) || {}
        const selectedReportTitle = `${selectedReport?.system_name} ${moment(selectedReport.created_on).format('DD-MM-YY')}`
        return { selectedReport, selectedReportTitle }
    }

    const getTableData = () => {

        const data = reports.map(report => {
            const actions = { 
                [genericTexts.actionsText.download]: { text: genericTexts.actionsText.downloadText('PDF'), order: 1 },
                [genericTexts.actionsText.info]: { text: 'View Info', order: 2 }
            }
            const created = moment(report.created_on).format('DD/MM/YY')
            const severityList = Object.entries(report.severity).map(([key, value]) => `${helpers.capitalizeString(key)} ${value}`)
            // const completed = report.is_complete ? 'Yes' : 'No'
            const row = {
                ...report, 
                system_name: report.system_name || '',
                severityList,
                created,
                // completed,
                actions
            }
            return row
        })
        return data
    }

    const tableData = getTableData()

    const updateReports = async () => {
        const reports = await PTApi.getReports(currentOrgId)
        if (!reports || reports.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: reports.message, notificationType: 'error' }))
            return
        }
        for await (let report of reports) {
            report = await getReportInfo(report)
            // // findings 
            // let findings = await PTApi.getFindingsByReportId(report.id)
            // if (!findings || findings.error) findings = { items: [] }
            // report.findings = findings.items

            // // assets 
            // let assets = await PTApi.getAssetsByReportId(report.id)
            // if (!assets || assets.error) assets = []
            // report.assets = assets
            // const logicalAsset = assets.find(a => a.asset_type === 1)
            // report.system_name = logicalAsset?.system_name

            // // participants 
            // const participants = await PTApi.getUsersByReportId(report.id)
            // report.participants = participants

            // report.severity = {}
            // const fullSeverity = {}
            // findingSeverityTypes.forEach(severity => {
            //     const findingsCount = report.findings.filter(f => f.severity === severity.id)?.length || 0
            //     if (findingsCount) report.severity[severity.name] = findingsCount
            //     fullSeverity[severity.name] = findingsCount
            // })
            // report.score = getScore(fullSeverity)
        }
        setReports(reports)
    }

    const menuClickHandler = async (action, reportId) => {
        setSelectedReportId(reportId)
        const { selectedReport } = getSelectedReport(reportId)
        switch (action) {
        case genericTexts.actionsText.download:
            downloadPdf(selectedReport)
            break
        case genericTexts.actionsText.info:
            navigate(`/pt/activities/${reportId}`, { state: { report: selectedReport } })
        }
    }

    const downloadPdf = async (selectedReport) => {
        const link = document.createElement('a')
        link.href = selectedReport.pdf_report_id
        link.setAttribute('download', `${selectedReport.pdf_report_id}.pdf`) 
        document.body.appendChild(link)
        link.click()
    }

    const openAddReportPopup = () => {
        navigate('/pt/activities/new')
    }

    useEffect( async () => {
        await updateReports()
        setIsLoading(false)
    }, [])

    return (
        <div className='scheduler page-content'>
            <div className='page-header'>
                <h1 id='pt-activities-management'>Activities Management</h1>
                {!isLoading && <button onClick={openAddReportPopup} id='add-new-pt-report'>{genericTexts.defaultText.addNewButton(ACTIVITY)}</button>}
            </div>
            {isLoading ? <Loader /> : <div className='report-management-con'>
                <SortableTable 
                    columns={reportColumns}
                    tableData={tableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    tableId='reports-table' />

            </div>}
        </div>     
    )
}

const ReportManagement = connect(mapStateToProps)(ReportManagementComponent)

export default ReportManagement