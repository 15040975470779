import React from 'react'
import './AssessmentQuestionsCategoryName.scss'

const AssessmentQuestionsCategoryName = ({ category }) => {
    return (
        <div className='questions-category-name'>
            <h3>
                <i className='icon-folder'></i>
                <span className='category-name'>{category?.name}</span>
            </h3>
            {category?.description && <div className='category-description-assessment'>{category?.description}</div>}
        </div>
    )
}

export default AssessmentQuestionsCategoryName
