import React, { useEffect, useState } from 'react'
import './Filter.scss'
import { Alert, CheckBoxGroup, InputRow, SidePopup, helpers } from 'hub-web-lib/dist/index-export'
import { DASHBOARD, DDOS_RESILIENCE_MODULE } from 'js/constants/app-modules'
import { END_DATE, START_DATE, EXPOSURE } from 'js/constants/filter'
import { transformUnderscoreToSentanse } from 'js/ui-helpers/filter'
import { RISK_LEVEL_DIMENSION } from 'js/constants/dashboard-settings'

const dateTimeDetails = {
    showTimeSelect: false,
    dateFormat: 'dd MMMM yy',
}

const Filter = ({
    isOpenFilter,
    setIsOpenFilter = () => {},
    activityFilter = {},
    defaultFilter = {},
    applyFilter = () => {},
    dynamicDimensions,
    filterPin = {},
    setFilterPin = () => {},
    levelDimenstionsUI,
    module
}) => {
    const [currentFilter, setCurrentFilter] = useState(defaultFilter)
    
    const canReset = !helpers.isSameObject(currentFilter, activityFilter) || currentFilter[START_DATE] || currentFilter[END_DATE]

    const changeValue = (key, val, isAuto) => {
        if (isAuto) return
        const updatedFilters = { ...currentFilter, [key]: val }
        if (key === START_DATE && new Date(val) > (currentFilter?.end_date ? new Date(currentFilter.end_date) : new Date())) currentFilter.end_date = val
        setCurrentFilter(updatedFilters)
    }

    const changePin = (key) => {
        setFilterPin({ ...filterPin, [key]: !filterPin[key] })
    }

    const focusField = (id) => {
        const field = document.getElementById(id)
        field.focus()
    }

    const closeFilter = () => {
        setIsOpenFilter(false)
        setCurrentFilter(defaultFilter)
    }

    useEffect(() => {
        if (defaultFilter && !helpers.isSameObject(defaultFilter, currentFilter)) setCurrentFilter(defaultFilter)
    }, [defaultFilter])

    return (
        <div className='dashboard-filter'>
            <SidePopup 
                isOpen={isOpenFilter}
                closeSidePopup={closeFilter}>
                {Object.keys(activityFilter || {})?.length ? <>
                    <div className='filter-form'>
                        <h3>Filter {DASHBOARD} Results</h3>
                        <div className='date-wrapper'>
                            <InputRow
                                inputId={START_DATE}
                                title='Start Date'
                                inputType='dateTime'
                                dateTimeDetails={{ 
                                    ...dateTimeDetails, 
                                    maxDate: currentFilter?.[END_DATE] || null 
                                }}
                                defaultValue={currentFilter?.start_date}
                                changeValueHandler={(val, isAuto) => changeValue(START_DATE, val, isAuto)}
                            />
                            {!currentFilter?.start_date ? 
                                <div className='any-value input-row-field' onClick={() => focusField(START_DATE)}>Any</div> :
                                <i className='icon-x' onClick={() => changeValue(START_DATE, null)}></i>}
                            <button className='btn-icon' onClick={() => changePin(START_DATE)} id={`switch-pin-${START_DATE}`}>
                                <i className={`icon-${filterPin[START_DATE] ? 'pin': 'unpin'}`}></i>
                            </button>
                        </div>
                        <div className='date-wrapper'>
                            <InputRow
                                inputId={END_DATE}
                                title='End Date'
                                inputType='dateTime'
                                dateTimeDetails={{ 
                                    ...dateTimeDetails, 
                                    minDate: currentFilter?.[START_DATE] || null 
                                }}
                                defaultValue={currentFilter?.end_date}
                                changeValueHandler={(val, isAuto) => changeValue(END_DATE, val, isAuto)}
                            />
                            {!currentFilter?.end_date ? 
                                <div className='any-value input-row-field' onClick={() => focusField(END_DATE)}>Any</div> :
                                <i className='icon-x' onClick={() => changeValue(END_DATE, null)}></i>}
                            <button className='btn-icon' onClick={() => changePin(END_DATE)} id={`switch-pin-${END_DATE}`}>
                                <i className={`icon-${filterPin[END_DATE] ? 'pin': 'unpin'}`}></i>
                            </button>
                        </div>
                        <div className='dynamic-dimensions'>
                            {dynamicDimensions.map(dimension =>  
                                <div className='dimension' key={dimension}>
                                    {!!activityFilter[dimension]?.length && <CheckBoxGroup
                                        title={transformUnderscoreToSentanse(dimension)}
                                        allItems={activityFilter[dimension].map(item => ({ id: item, name: transformUnderscoreToSentanse(item) }))}
                                        checkedIds={currentFilter?.[dimension]}
                                        updateItems={(val) => changeValue(dimension, val)}
                                        layout='vertical'
                                        isSentanceTitle={true}
                                        isInputRow={true} />}
                                </div>
                            )}
                        </div>
                        <div className='level-dimensions'>
                            {Object.keys(levelDimenstionsUI).map(dimension =>
                                <div className='dimension' key={dimension}>
                                    <CheckBoxGroup
                                        title={transformUnderscoreToSentanse((module === DDOS_RESILIENCE_MODULE && dimension === RISK_LEVEL_DIMENSION) ? EXPOSURE : dimension)}
                                        allItems={levelDimenstionsUI[dimension]}
                                        checkedIds={currentFilter?.[dimension]}
                                        updateItems={(val) => changeValue(dimension, val)}
                                        layout='vertical'
                                        isInputRow={true} />
                                    <button className='btn-icon' onClick={() => changePin(dimension)} id={`switch-pin-${dimension}`}>
                                        <i className={`icon-${filterPin[dimension] ? 'pin': 'unpin'}`}></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    
                    </div>
                    <div className='filter-buttons'>
                        <button className='btn-set' disabled={!canReset} onClick={() => setCurrentFilter(activityFilter)} id='reset-filter'><i className='icon-reset'></i>Reset</button>
                        <button className='btn-submit' onClick={() => applyFilter(currentFilter)} id='apply-filter'><i className='icon-filter'></i>Apply</button>
                    </div>
                </> : <Alert message='This Activity does not have any Findings raw data' alertType='neutral' />}
                
            </SidePopup>
        </div>
    )
}

export default Filter
