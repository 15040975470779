import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './PT.scss'
import moment from 'moment'
import { helpers, Loader, SortableTable } from 'hub-web-lib'
import { findingSeverityTypes } from 'js/constants/enums/pt'
import { getReportInfo, statusTypes } from 'js/ui-helpers/pt'
import { findingsColumns } from 'js/constants/table-columns'
import PTApi from 'services/PTApi'
import store from 'js/store'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'

export const PTFindings = () => {
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(true)
    const [currentReport, setCurrentReport] = useState({})
    const [allFindings, setAllFindings] = useState([])
    const [findingsStatus, setFindingsStatus] = useState('')

    const getTableData = () => {
        let findingsData = []
        const statuses = findingsStatus === 'all' ? findingSeverityTypes : findingSeverityTypes.filter(s => s.name === findingsStatus)
        statuses.forEach(status => {
            const findingsPerStatus = allFindings.filter(f => f.severity === status.id).map(f => ({ ...f, status: status.name }))
            findingsData = [...findingsData, ...findingsPerStatus]
        })
        const data = findingsData.map((f, i) => {
            return {
                ...f,
                id: i + 1,
                date: moment(f.date).format('DD MMMM'),
                statusTitle: <p className={`status ${statusTypes[f.status]}`}>{f.status}</p>
            }
        })
        return data
    }

    const tableData = getTableData()

    const updateFindings = async () => {
        const reports = await PTApi.getReports(store.getState().currentUser?.current_org)
        if (!reports || reports.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: reports.message, notificationType: 'error' }))
            return
        }
        const reportId = (location?.pathname || '').split('/')[4]*1
        const status = (location?.pathname || '').split('/')[5]
        let report = reports.find(r => r.id === reportId)
        report = await getReportInfo(report)
        setCurrentReport(report || {})
        setAllFindings(report?.findings || [])
        setFindingsStatus((status || '').split('-')[0])
        setIsLoading(false)
    }

    useEffect(async () => {
        await updateFindings()
    }, [])

    return (
        <div className='pt page-content'>
            <div className='page-header'>
                <h1 id='activity-findings'>{currentReport?.created_on ? moment(currentReport.created_on).format('DD/MM/YY') : ''} {helpers.capitalizeString(findingsStatus)} Findings</h1>
            </div>
            <div className='pt-con'>
                {isLoading ? <Loader /> : <SortableTable columns={findingsColumns} tableData={tableData} tableId='pt-findings-table' />}
            </div>
        </div>
    )
}

export default PTFindings