import { defaultTemplateDomains } from 'js/constants/enums/questionnaire'
import { getMetricByProps, getMetricsByProps, getValueByProps } from './metrics'
import { qaImplementationValues } from 'js/constants/metrics/qa-metrics'

const { FULLY_IMPLEMENTED, PARTIALLY_IMPLEMENTED, NOT_IMPLEMENTED, SCORING_BY_CATEGORY, GENERAL, TOTAL, FULLY_PROP, IMPLEMENTATION_OF_SECURITY_CONTROL, CHART_PERCENTAGE, CHART_VALUES, READINESS_BY_CATEGORY, LEVEL_OF_READINESS, PARTIALLY_PROP, NOT_PROP, KEY } = require('js/constants/metrics/common-metrics')
import { CYBER_READINESS } from 'js/constants/app-modules'
import { helpers } from 'hub-web-lib/dist/index-export'

const categoryChartDetailsDefault = {
    isPercentage: false,
    isStacked: true,
    direction: 'vertical',
    colorValues: ['#65D283', '#FF8B37', '#FF4A4A'],
    isValueInside: true
}

const securityControlsChartDetails = {
    isShownLabels: false,
    size: 'large',
    isPercentage: true,
    chartId: 'security-control-percentage'
}

const getScoringByCategoryMenu = (metricsData) => {
    const generalScoringByCategory = defaultTemplateDomains
        .map(category => getMetricByProps(metricsData, GENERAL, SCORING_BY_CATEGORY, category))
        .filter(v => v)
    const scoringByCategoryMenu = generalScoringByCategory.map(metric => ({ 
        id: metric.id, 
        name: metric.key, 
        percent: metric[FULLY_PROP] || 0 
    }))

    return scoringByCategoryMenu
}

const getScoringByCategoryValues = (metricsData) => {
    const generalScoringByCategory = defaultTemplateDomains
        .map(category => getMetricByProps(metricsData, GENERAL, SCORING_BY_CATEGORY, category))
        .filter(v => v)

    const scoringByCategoryValues = generalScoringByCategory.map(metric => ({ 
        label: metric[KEY], 
        status: 'light', 
        value: `${metric[FULLY_PROP] || 0}%`
    }))
    return scoringByCategoryValues
}

const getSecurePercent = (metricsData) => {
    return getValueByProps(metricsData, GENERAL, CYBER_READINESS, TOTAL, FULLY_PROP)
}

const getImplementationList = (metric) => {
    if (!metric) return []
    return qaImplementationValues.map(item => ({
        ...item,
        label: item.name,
        value: metric ? metric[item.id] : 0
    }))
}

const getSecurityControlsValues = (metricsData) => {
    const metric = getMetricByProps(metricsData, GENERAL, IMPLEMENTATION_OF_SECURITY_CONTROL, CHART_VALUES)
    return getImplementationList(metric)
}

const getSecurityControlsChartData = (metricsData) => {
    const metric = getMetricByProps(metricsData, GENERAL, IMPLEMENTATION_OF_SECURITY_CONTROL, CHART_PERCENTAGE)
    const securityControlsChartData = getImplementationList(metric)
    return { securityControlsChartData, securityControlsChartDetails }
}

const getReadinessChartData = (metricsData, category) => {
    const readinessChartData = getMetricsByProps(metricsData, category, READINESS_BY_CATEGORY)
    const chartData = (readinessChartData || []).map(metric => {
        return {
            name: metric.key,
            [FULLY_IMPLEMENTED]: metric[FULLY_PROP],
            [PARTIALLY_IMPLEMENTED]: metric[PARTIALLY_PROP],
            [NOT_IMPLEMENTED]: metric[NOT_PROP],
        }
    })
    const chartDetails = {
        ...categoryChartDetailsDefault,
        chartId: `readiness-by-category-${helpers.transformToKebab(category)}-chart`
    }
    return { chartData, chartDetails }
}

const getReadinessCirclesData = (metricsData, category) => {
    return getMetricByProps(metricsData, category, LEVEL_OF_READINESS, CHART_VALUES)
}

export {
    getScoringByCategoryMenu,
    getScoringByCategoryValues,
    getSecurePercent,
    getSecurityControlsChartData,
    getSecurityControlsValues,
    securityControlsChartDetails,
    getReadinessChartData,
    getReadinessCirclesData
}

