import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, InfoCard, Loader, SortableTable, genericTexts } from 'hub-web-lib'
import { ACTIVITY, ASSESSMENT } from 'js/constants/vocabulary'
import { qaMetricsColumns } from 'js/constants/table-columns'
import QAApi from 'services/QAApi'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import { connect } from 'react-redux'
import { IS_EDITABLE } from 'js/constants/metrics/common-metrics'
import AssessmentApi from 'services/AssessmentApi'
import AssessmentView from 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentView'
import QuestionnaireApi from 'services/QuestionnaireApi'
import './QAActivityInfo.scss'
import { activityCardInfoDefault } from 'js/constants/enums/activity'
import { getActivityInfoCardData, getSelectedActivityTitle } from 'js/ui-helpers/activity'
import BackButton from 'components/Assets/BackButton/BackButton'
import { assessmentStatuses } from 'js/constants/enums/questionnaire'
import moment from 'moment'
import UserApi from 'services/UserApi'
import { getUserRole } from 'js/ui-helpers/roles'

const assessmentInfoDefault = {
    id: {
        title: `${ASSESSMENT} ID`
    },
    questionnaire: {
        title: 'Questionnaire'
    },
    domains: {
        title: 'Domains'
    },
    questionnaireVersion: {
        title: 'Version'
    },
    date: {
        title: 'Created Date'
    },
    status: {
        title: 'Status'
    },
    usersToSubmit: {
        title: 'Users To Submit'
    },
    remarks: {
        title: 'Message'
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

const QAActivityInfoComponent = ({ currentUser, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentActivityInfo, setCurrentActivityInfo] = useState({})
    const [currentAssessment, setCurrentAssessment] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [orgUsers, setOrgUsers] = useState([])
    const currentActivityTitle = getSelectedActivityTitle(currentActivityInfo)


    const getPermittedUsersList = (users) => {
        return [...users.filter(user => getUserRole(user) < 4)
            .sort((a, b) => a.id * 1 - b.id * 1)
            .map(user => ({ ...user, name: user.name || user.user_name }))]
    }

    const getActivityInfo = async () => {
        let info = location?.state?.activity
        if (info) {
            setCurrentActivityInfo(info)
            return
        }
        const activityId = (location?.pathname || '').split('/')[3] * 1
        const activity = await QAApi.getActivity(activityId)
        if (!activity || activity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(ACTIVITY), notificationType: 'error' }))
            return
        }
        activity.metrics = await QAApi.getActivityMetrics(activityId)
        let assessment = await AssessmentApi.getAssessmentByActivityId(activityId)
        if (!assessment || assessment.error) assessment = null
        setCurrentAssessment(assessment)
        if (!activity.metrics || activity.metrics.error) activity.metrics = []
        const org = currentUserOrgs.find(o => o.id === currentOrgId) || { id: currentOrgId }
        activity.organization = org?.name || currentOrgId
        setCurrentActivityInfo(activity)
        let activityTemplate = assessment ? await QuestionnaireApi.getQuestionnaireTemplateFull(assessment?.template_id, currentOrgId) : null
        if (!activityTemplate || activityTemplate.error) activityTemplate = null
        setSelectedTemplate(activityTemplate)

        const users = await UserApi.getOrgUsers(currentOrgId)
        setOrgUsers(users && !users.error ? getPermittedUsersList(users) : [])
    }

    const getAssessmentCardData = () => {
        const data = { ...assessmentInfoDefault }
        data.id.value = currentAssessment?.id || '-'
        data.date.value = moment(currentAssessment?.creation_date).format('DD/MM/YY')
        data.questionnaire.value = selectedTemplate?.name || '-'
        data.questionnaireVersion.value = selectedTemplate?.version || '-'
        data.domains.value = (selectedTemplate?.domains || []).map(d => d.name).join(', ')
        const statusObject = assessmentStatuses.find(s => s.id === currentAssessment?.status) || {}
        data.status.value = statusObject.statusValue
        data.usersToSubmit.value = orgUsers.filter(u => (currentAssessment?.mail_to || []).includes(u.id)).map(u => u.name || u.username).join(', ')
        data.remarks.value = currentAssessment?.remarks || '-'
        return data
    }

    const getMetricsTableData = () => {
        const data = (currentActivityInfo?.metrics || []).map(metric => {
            return {
                ...metric,
                fully: <span className='status primary'>{metric.fully_implemented}</span>,
                partially: <span className='status warning'>{metric.partially_implemented}</span>,
                not: <span className='status error'>{metric.not_implemented}</span>,
                edit: metric[IS_EDITABLE] ? 'Yes' : 'No'
            }
        })
        return data
    }

    const cardData = getActivityInfoCardData(activityCardInfoDefault, currentActivityInfo)
    const metricsTableData = getMetricsTableData()
    const assessmentCardData = getAssessmentCardData()

    useEffect(async () => {
        await getActivityInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content'>
            <div className='page-header'>
                <BackButton />
                <h1 id='qa-activity-info'>{ACTIVITY} {currentActivityTitle} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentActivityInfo.id ? <div className='qa-activity-info-con info-card-con'>
                <div className='activity-cards'>
                    <div className='activity-card-activity'>
                        <h3 id='qa-activity-info-section'>{ACTIVITY} Info</h3>
                        <InfoCard cardData={cardData} />
                    </div>
                    <div className='activity-card-assessment'>
                        <h3 id='qa-assessment-info-section'>{ASSESSMENT} Info</h3>
                        {currentAssessment ? <>
                            <InfoCard cardData={assessmentCardData} />
                            <AssessmentView selectedQuestionnaire={selectedTemplate} assessmentId={currentAssessment?.id} currentOrgId={currentOrgId} />
                        </> : <Alert message={`No ${ASSESSMENT} was assigned to this ${ACTIVITY}`} />}
                    </div>
                </div>
                <h2 id='qa-mertics'>Metrics</h2>
                <SortableTable columns={qaMetricsColumns} tableData={metricsTableData} itemsPerPage={0} tableId='qa-activity-info-metrics-table' />
            </div> : <Alert message={`No data for this ${ACTIVITY}`} />}

        </div>
    )
}

const QAActivityInfo = connect(mapStateToProps)(QAActivityInfoComponent)
export default QAActivityInfo