import React, { useEffect, useState } from 'react'
import './ResetPassword.scss'
import { useSearchParams } from 'react-router-dom'
import ChangePassword from '../Settings/ProfileSections/ChangePassword'
import UserApi from 'services/UserApi'
import { loginModes } from 'js/constants/enums/user'
const regexExp = /^[a-f0-9]{64}$/gi

export const ResetPassword = ({ userToken = '', onGoToLogin = () => { } }) => {

    const [searchParams] = useSearchParams()
    const [isTokenExpired, setIsTokenExpired] = useState(false)

    const goToLogin = (loginMode) => {
        const path = searchParams.get('path')
        onGoToLogin(path, loginMode)
    }

    useEffect(() => {
        if (!regexExp.test(userToken)) {
            setIsTokenExpired(true)
        } else {
            UserApi.checkPasswordUpdateTokenValidity(userToken).then(res => {
                if (res?.status !== 200) {
                    setIsTokenExpired(true)
                }
            })
        }
    }, [userToken])

    return (

        <div className='login'>
            <div className='login-container'>
                {!isTokenExpired ? <ChangePassword userToken={userToken} updatePassword={() => goToLogin(loginModes.login)} /> : <>
                    <div className='login-header'>
                        <i className='icon-warning'></i>
                        <h2 id='reset-token-expired'>Password reset link is invalid</h2>
                    </div>
                    <p>This link is either already used or expired.</p>

                    <p>For security reasons, password reset links expire after a little while. If you still need to reset your password, you can request a new reset email.</p>

                    <div className='form'>
                        <div className='form-buttons'>
                            <button className='btn-set' onClick={() => goToLogin(loginModes.login)} id='back-to-login-expired'>Proceed to Login</button>
                            <button onClick={() => goToLogin(loginModes.resetPassword)} className={'btn-set'} id='resend-link-expired'>Resend Link</button>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default ResetPassword