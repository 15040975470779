import axios from 'axios'
import { jsonToFormData } from './ApiHelpers'
import { getErrorMessage, getErrorDetail } from './ApiHelpers'
import UserApi from 'services/UserApi'

const axiosMiddleware = () => {
    axios.interceptors.request.use(async (req) => {
        const activeAccessPoint = (process.env.REACT_APP_API_ACCESS_POINT).replace(/\/+$/, '')

        req.url = activeAccessPoint + req.url
        
        if (req.url.includes('/token') && req.data) {
            req.data = jsonToFormData(req.data)
            req.headers['content-type'] = 'multipart/form-data'
        }

        return req

    }, function (error) {
        // TODO: Do something with request error
        return Promise.reject(error)
    })

    axios.interceptors.response.use(async (response) => {
        return response
    }, async error => {
        if (error.response?.data?.detail === 'Could not validate credentials') UserApi.clearCurrentUserCookies()
        return { data: { error: getErrorMessage(error), message: getErrorDetail(error), details: getErrorDetail(error, true), status: error.response?.status } }
    })

    axios.defaults.withCredentials = true
}

export default axiosMiddleware
