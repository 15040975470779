import { IMPACT_DIMENSION, RISK_DIMENSION } from 'js/constants/dashboard-settings'
import { sortByYearQuarter } from './home-dashboard'
import { getAxisStep, getMetricsByProps } from './metrics'
import { getFilteredMetricsProps } from './filter'
import { helpers } from 'hub-web-lib/dist/index-export'

const { KEY, FINDINGS, OTHER_PROP, BY_QUARTER, PRIMARY, WARNING, ERROR } = require('js/constants/metrics/common-metrics')
const { EXPOSURE_BY_LAYER, CYLINDER, BY_ATTACK_VECTORS, BY_ATTACK_TYPES, BY_MITIGATION, MITIGATED, PARTIALLY_MITIGATED, UNMITIGATED, EXPOSURE_TRENDS_OVER_TIME, ATTACK_VECTORS_PRIORITIZED, BY_VULNERABLE_TARGETS } = require('js/constants/metrics/ddos-metrics')

const cylinderChartColors = [
    '#6898F3',
    '#A16EF3',
    '#F364E2',
    '#F36280',
    '#F3886E',
    '#F3C374',
    '#F3F17F'
]

const riskByLevelLayerChartDetailsDefault = {
    chartId: 'risk-by-level-chart'
}

const findingsByAttackVectorsChartDetailsDefault = {
    isPercentage: false,
    direction: 'vertical',
    size: 'large',
    isShownLabels: true,
    isShownLabelsValues: true,
    isDonat: true,
    chartId: 'findings-by-attach-vectors-chart',
    colorValues: [
        '#E055E2',
        '#893DE7',
        '#686DF4',
        '#3892F3',
        '#34C5E8',
        '#4aebe2',
        '#4ae881',
        '#bced68',
        '#eae54b',
        '#ffc823',
        '#ff8223',
    ],
    centeredValue: {
        title: 'Total',
        value: 0
    }
}

const findingsByAttackTypesChartDetails = {
    isPercentage: true,
    direction: 'vertical',
    size: 'large',
    isShownLabels: true,
    chartId: 'findings-by-attach-types-chart',
    colorValues: [
        '#E055E2',
        '#893DE7',
        '#686DF4',
        '#3892F3',
        '#34C5E8',
        '#4aebe2',
        '#4ae881',
        '#bced68',
        '#eae54b',
        '#ffc823',
        '#ff8223',
    ],
}



const findingsByMitigationChartDetailsDefault = {
    isPercentage: false,
    direction: 'vertical',
    size: 'large',
    isShownLabels: true,
    isShownLabelsValues: true,
    isDonat: true,
    isActiveShape: true,
    colorStatuses: ['primary', 'warning', 'error'],
    chartId: 'findings-by-migation-chart',
}
const findingsByMitigationChartStatuses = {
    [MITIGATED]: PRIMARY,
    [PARTIALLY_MITIGATED]: WARNING,
    [UNMITIGATED]: ERROR

}

const exposureTrendsChartDetailsDefault = {
    isGradient: false,
    height: 250,
    isShownLabels: true,
    isShownDots: true,
    chartId: 'exposure-trends-chart',
}

const attackVectorsChartDetails = {
    isStacked: true,
    chartId: 'attack-vectors-chart',
    isValueInside: true,
    hasToolTip: false,
    colorValues: [
        '#E055E2',
        '#893DE7',
        '#686DF4',
        '#3892F3',
        '#34C5E8',
        '#4aebe2',
        '#4ae881',
        '#bced68',
        '#eae54b',
        '#ffc823',
        '#ff8223',
    ]
}

const getLayerNum = (layer) => {
    if (!layer || !layer[KEY]) return 0
    return layer[KEY][layer[KEY].length - 2] * 1
}

const getLayerName = (name) => {
    if (!name) return 0
    const title = name.split(' Layer ')[0]
    const number = name[name.length - 2]
    return `${number}. ${title}`
}

const sortByLayer = (a, b) => {
    return getLayerNum(a) - getLayerNum(b)
}

const getRiskByLevelLayerChart = (metricsData, metricsProps, filter) => {
    const riskByLevelLayer = getMetricsByProps(metricsData, EXPOSURE_BY_LAYER, CYLINDER)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const riskByLevelLayerChartData = riskByLevelLayer
        .sort(sortByLayer)
        .map((metric, i) => {
            const barChartRow = {}
            filteredProps.forEach(value => {
                barChartRow[value.name] = metric[value.id] || 0
            })
            return {
                name: getLayerName(metric[KEY]),
                color: cylinderChartColors[i],
                recommendations: metric.recommendations,
                barChartRow: Object.keys(barChartRow)?.length ? barChartRow : null
            }
        })
    const riskByLevelLayerBarChartColumns = filteredProps.map(s => ({ label: s.name, id: s.id }))
    const chartDataWithRows = { 
        rows: riskByLevelLayerChartData.map(l => l.barChartRow), 
        columns: riskByLevelLayerBarChartColumns,
        details: {
            isStackedSeparatelly: true
        }
    }
    const axisStep = getAxisStep(chartDataWithRows, 1)
    const riskByLevelLayerChartDetails = {
        ...riskByLevelLayerChartDetailsDefault,
        axisStep: axisStep,
        colorValues: filteredProps.map(s => s.color)
    }
    return { riskByLevelLayerChartData, riskByLevelLayerBarChartColumns, riskByLevelLayerChartDetails }
}

const getLayerRecommendations = (cylinderChartClickedElement, riskByLevelLayerChartData, riskByLevelLayerBarChartColumns, metricsProps) => {
    const { itemIndex, columnIndex } = cylinderChartClickedElement
    const cylinderItem = riskByLevelLayerChartData[itemIndex] || null
    const cylinderColumn = riskByLevelLayerBarChartColumns[columnIndex]?.id || null
    let recommendations = cylinderItem?.recommendations ? cylinderItem?.recommendations : []
    if (recommendations.length) {
        recommendations = recommendations.map(r => {
            const metricProps = metricsProps[RISK_DIMENSION].find(p => p.id === r.exposure)
            return {
                ...r,
                ...metricProps
            }
        })
        if (cylinderColumn) recommendations = recommendations.filter(r => r.exposure === cylinderColumn)
    }
    return recommendations
}

const getFindingsbyAttackVectorsChart = (metricsData) => {
    const findingsByAttackVectors = getMetricsByProps(metricsData, FINDINGS, BY_ATTACK_VECTORS)
    const findingsByAttackVectorsChartData = findingsByAttackVectors.map(metric => ({
        name: metric[KEY],
        value: (metric[OTHER_PROP] || 0) * 1
    }))
    const valuesArray = findingsByAttackVectorsChartData.map(i => i.value).filter(v => v)
    const findingsByAttackVectorsChartDetails = {
        ...findingsByAttackVectorsChartDetailsDefault,
        centeredValue: {
            ...findingsByAttackVectorsChartDetailsDefault.centeredValue,
            value: valuesArray.length ? valuesArray.reduce((a, b) => a + b) : 0
        }
    }
    return { findingsByAttackVectorsChartData, findingsByAttackVectorsChartDetails }
}

const getFindingsbyAttackTypesChart = (metricsData) => {
    const findingsByAttackTypes = getMetricsByProps(metricsData, FINDINGS, BY_ATTACK_TYPES)
    const findingsByAttackTypesChartData = findingsByAttackTypes.map(metric => ({
        name: helpers.capitalizeString(metric[KEY]),
        value: (metric[OTHER_PROP] || 0) * 1
    }))
    return { findingsByAttackTypesChartData, findingsByAttackTypesChartDetails }
}

const getFindingsbyMitigationChart = (metricsData) => {
    const findingsByMitigation = getMetricsByProps(metricsData, FINDINGS, BY_MITIGATION)
    const findingsByMitigationChartData = Object.keys(findingsByMitigationChartStatuses)
        .map(key => {
            const metric = findingsByMitigation.find(m => m[KEY] === key)
            return { 
                name: key, 
                value: (metric?.[OTHER_PROP] || 0) * 1,
                status: findingsByMitigationChartStatuses[key] 
            }
        })
    const valuesArray = findingsByMitigationChartData.map(i => i.value).filter(v => v)
    const valuesSum = valuesArray.length ? valuesArray.reduce((a, b) => a + b) : 0
    const maxValue = valuesArray.length ? Math.max(...valuesArray) : 0
    const maxRow = [...findingsByMitigationChartData].reverse().find(m => m.value === maxValue)
    const findingsByMitigationChartDetails = {
        ...findingsByMitigationChartDetailsDefault,
        centeredValue: {
            title: maxRow?.name || '',
            value: valuesSum && maxRow ? `${Math.ceil(maxValue / valuesSum * 100)}%` : '',
            status: maxRow ? findingsByMitigationChartDetailsDefault.colorStatuses[findingsByMitigationChartData.indexOf(maxRow)] : ''
        }
    }
    return { findingsByMitigationChartData, findingsByMitigationChartDetails }
}

const getExposureTrendsChart = (metricsData, metricsProps, filter) => {
    let exposureTrends = getMetricsByProps(metricsData, EXPOSURE_TRENDS_OVER_TIME, BY_QUARTER)
    exposureTrends = sortByYearQuarter(exposureTrends, KEY)
    const filteredProps = getFilteredMetricsProps(metricsProps, filter, RISK_DIMENSION)
    const exposureTrendsChartData = exposureTrends.map(metric => {
        const row = { name: metric[KEY] }
        filteredProps.forEach(value => {
            row[value.name] = metric[value.id]
        })
        return row
    })

    const exposureTrendsChartDetails = {
        ...exposureTrendsChartDetailsDefault,
        colorValues: filteredProps.map(s => s.color)
    }
    return { exposureTrendsChartData, exposureTrendsChartDetails }
}

const getDdosHomeDashboardValues = (metricsData, metricsProps) => {
    let exposureTrends = getMetricsByProps(metricsData, EXPOSURE_TRENDS_OVER_TIME, BY_QUARTER)
    exposureTrends = sortByYearQuarter(exposureTrends, KEY) 
    const byImpact = exposureTrends?.length ? exposureTrends[exposureTrends.length - 1] : null
    const values = metricsProps[IMPACT_DIMENSION].map(v => {
        return { 
            label: v.name,
            value: byImpact ? (byImpact[v.id] || 0) * 1 : 0,
            color: v.color
        }
    })
    return values.slice(0, 3)
}

const getAttackVectorsChart = (metricsData) => {
    const attackVectors = getMetricsByProps(metricsData, ATTACK_VECTORS_PRIORITIZED, BY_VULNERABLE_TARGETS)
    const attackVectorsChartData = attackVectors.map(m => {
        const rowValues = {}
        attackVectors.forEach(c => {
            rowValues[c[KEY]] = c[KEY] === m[KEY] ? m[OTHER_PROP] : 0
        })
        return {
            name: m[KEY],
            ...rowValues
        }
    })
    return { attackVectorsChartData, attackVectorsChartDetails }
}

export {
    getDdosHomeDashboardValues,
    getLayerRecommendations,
    getRiskByLevelLayerChart,
    getFindingsbyAttackVectorsChart,
    getFindingsbyAttackTypesChart,
    getFindingsbyMitigationChart,
    getExposureTrendsChart,
    getAttackVectorsChart
}