import { AreaChartCustom, BarChartCustom, CylinderChart, PieChartCustom, SidePopup, WidgetBlock } from 'hub-web-lib'
import React, { useEffect, useState } from 'react'
import './Ddos.scss'

import { getAttackVectorsChart, getExposureTrendsChart, getFindingsbyAttackTypesChart, getFindingsbyAttackVectorsChart, getFindingsbyMitigationChart, getLayerRecommendations, getRiskByLevelLayerChart } from 'js/ui-helpers/ddos-dashboard'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { FINDINGS } from 'js/constants/metrics/common-metrics'
import { BY_ATTACK_TYPES, BY_ATTACK_VECTORS, EXPOSURE_TRENDS_OVER_TIME } from 'js/constants/metrics/ddos-metrics'


const Ddos = ({ metricsData, metricsProps, filter, isAnimationActive }) => {
    const [isOpenSidePopup, setIsOpenSidePopup] = useState(false)
    const [cylinderChartClickedElement, setCylinderChartClickedElement] = useState({})
    const [charts, setCharts] = useState({})

    useEffect(() => {
        const { riskByLevelLayerChartData, riskByLevelLayerBarChartColumns, riskByLevelLayerChartDetails } = getRiskByLevelLayerChart(metricsData, metricsProps, filter)
        const layerRecommendations = getLayerRecommendations(cylinderChartClickedElement, riskByLevelLayerChartData, riskByLevelLayerBarChartColumns, metricsProps)
        const { findingsByAttackVectorsChartData, findingsByAttackVectorsChartDetails } = getFindingsbyAttackVectorsChart(metricsData)
        const { findingsByAttackTypesChartData, findingsByAttackTypesChartDetails } = getFindingsbyAttackTypesChart(metricsData)
        const { findingsByMitigationChartData, findingsByMitigationChartDetails } = getFindingsbyMitigationChart(metricsData)
        const { exposureTrendsChartData, exposureTrendsChartDetails } = getExposureTrendsChart(metricsData, metricsProps, filter)
        const { attackVectorsChartData, attackVectorsChartDetails } = getAttackVectorsChart(metricsData)
        setCharts({
            riskByLevelLayerChartData,
            riskByLevelLayerBarChartColumns,
            riskByLevelLayerChartDetails,
            layerRecommendations,
            findingsByAttackVectorsChartData,
            findingsByAttackVectorsChartDetails,
            findingsByAttackTypesChartData,
            findingsByAttackTypesChartDetails,
            findingsByMitigationChartData,
            findingsByMitigationChartDetails,
            exposureTrendsChartData,
            exposureTrendsChartDetails,
            attackVectorsChartData,
            attackVectorsChartDetails
        })
    }, [])

    const cylinderChartClick = (val) => {
        setCylinderChartClickedElement(val)
        setIsOpenSidePopup(true)
    }

    const SidePopupContent = () => {
        return (<div className='recommendations'>
            {charts.layerRecommendations?.length ? charts.layerRecommendations.map((recommendation, i) => 
                <div key={i} className='recommendation-widget'><WidgetBlock
                    content={<>
                        <h4 style={{ color: recommendation.color }}>{recommendation.attackname}</h4>
                        <ul className='recommendations-list'>
                            {recommendation.recommendations.map((r, ri) => <li key={ri}>{r}</li>)}
                        </ul>
                    </>} /></div>) : 
                <div className='no-data-message'>No Recommendations Provided</div>}
        </div>)
    }

    return (
        <>
            <div className='module-widgets-row risk-by-level'>
                <WidgetBlock title='Exposure by Layers' content={charts.riskByLevelLayerChartData?.length ? 
                    <CylinderChart 
                        chartData={charts.riskByLevelLayerChartData}
                        barChartColumns={charts.riskByLevelLayerBarChartColumns}
                        chartDetails={charts.riskByLevelLayerChartDetails}
                        elementClick={cylinderChartClick} /> : 
                    <NoDataPlaceholder />} />
                <SidePopup 
                    isOpen={isOpenSidePopup}
                    closeSidePopup={() => setIsOpenSidePopup(false)}
                    isHiddenOverlay={false}>
                    {<SidePopupContent />}
                </SidePopup>
            </div>
            <div className='module-widgets-row pie-charts'>
                <WidgetBlock title={`${FINDINGS} ${BY_ATTACK_VECTORS}`} content={charts.findingsByAttackVectorsChartData?.length ? 
                    <PieChartCustom chartData={charts.findingsByAttackVectorsChartData} chartDetails={{ ...charts.findingsByAttackVectorsChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
                
                <WidgetBlock title={`${FINDINGS} ${BY_ATTACK_TYPES}`} content={charts.findingsByAttackTypesChartData?.length ? 
                    <PieChartCustom chartData={charts.findingsByAttackTypesChartData} chartDetails={charts.findingsByAttackTypesChartDetails} /> :
                    <NoDataPlaceholder />} />
                
                <WidgetBlock title='Attacks Detection and Mitigation' content={charts.findingsByMitigationChartData?.length ? 
                    <PieChartCustom chartData={charts.findingsByMitigationChartData} chartDetails={{ ...charts.findingsByMitigationChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row area-chart'>
                <WidgetBlock title={EXPOSURE_TRENDS_OVER_TIME} content={charts.exposureTrendsChartData?.length ? 
                    <AreaChartCustom chartData={charts.exposureTrendsChartData} chartDetails={{ ...charts.exposureTrendsChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row'>
                <WidgetBlock title='# of Targets per Attack Vector' content={charts.attackVectorsChartData?.length ? 
                    <BarChartCustom chartData={charts.attackVectorsChartData} chartDetails={{ ...charts.attackVectorsChartDetails, isAnimationActive }} /> :
                    <NoDataPlaceholder />} />
            </div>
        </>
    )
}

export default Ddos