import React, { useEffect, useState } from 'react'
import 'scss/UpdateActivity/UpdateActivity.scss'
import { genericTexts, helpers,  Tabs } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { easmActivityModel } from 'js/constants/models/easm'
import EasmApi from 'services/EasmApi'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { emptyJsonValues, objectHasValuesForKeys } from 'middlewares/ApiHelpers'
import GeneralInformation from './UpdateActivityTabs/EasmGeneralInformation'
import ActivityMetrics from './UpdateActivityTabs/EasmActivityMetrics'
import { metricsTextProps, valuesPropsGeneric } from 'js/constants/metrics/common-metrics'
import { findDeletedMetrics, getNumerizedMetrics } from 'js/ui-helpers/metrics'
import { getStepsErrors } from 'js/ui-helpers/easm-activity'
import { metricModel } from 'js/constants/models/metric'

const newActivityModel = helpers.createNewInstance(easmActivityModel)
delete newActivityModel.id
delete newActivityModel.org_id
const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(newActivityModel))

const newMetricModel = helpers.createNewInstance(metricModel)


export const UpdateEasmActivity = ({ 
    currentUser,
    activityDetails = {},
    isNewActivity,
    currentOrg,
    orgUsers,
    closeUpdateActivityPopup,
    updateActivities,
    templateUrl
}) => {
    const [activityToUpdate, setActivityToUpdate] = useState(isNewActivity ? { 
        ...newActivityModel, 
        org_id: currentOrg?.id, 
        reported_by: currentOrg?.id === currentUser?.org_id ? currentUser?.id : '' 
    } : activityDetails)
    
    const [customMetrics, setCustomMetrics] = useState([{ ...newMetricModel }])
    const providedMetrics = isNewActivity ? helpers.createNewInstance([]) : helpers.createNewInstance(activityDetails.metrics || [])
    const [predefinedMetricsToEdit, setPredefinedMetricsToEdit] = useState(providedMetrics)
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })
    const [isMetricsTabOpened, setIsMetricsTabOpened] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const metrics = [...predefinedMetricsToEdit, ...customMetrics.filter(m => objectHasValuesForKeys(m, metricsTextProps))]
    const [activityFindings, setActivityFindings] = useState(isNewActivity ? [] : helpers.createNewInstance(activityDetails.findings || []))
    const stepsErrors = getStepsErrors(activityToUpdate, metrics)

    const hasFindings = !!activityFindings?.length
    const deletedMetrics = findDeletedMetrics(providedMetrics, predefinedMetricsToEdit)
    const deletedFindings = findDeletedMetrics(activityToUpdate.findings, activityFindings)

    const checkReadyToSubmit = () => {
        const errors = Object.values(stepsErrors).filter(e => e).map(e => e.message)
        if (errors.length) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: errors.join('. '), notificationType: 'error' }))
            if (stepsErrors[0]) {
                const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
                setFieldsErrorMsg(errorDetails)
            }
            stepsErrors[0] ? setActiveTab(0) : setActiveTab(1)
            return false
        }
        return true
    }

    const saveActivity = async () => {
        if (stepsErrors[0]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[0].message, notificationType: 'error' }))
            const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
            setFieldsErrorMsg(errorDetails)
            setActiveTab(0)
            return false
        }
        const activity = { ...activityToUpdate, has_findings: hasFindings }
        delete activity.metrics
        delete activity.findings
        const activityUpdated = isNewActivity ? await EasmApi.createActivity(activity) : await EasmApi.updateActivity(activity)
        if (!activityUpdated || activityUpdated.error) {
            if (Array.isArray(activityUpdated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                activityUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                setErrorMsg(activityUpdated.message)
            }
            setActiveTab(0)
            return null
        }
        const activityId = isNewActivity ? activityUpdated.id : activityDetails.id
        return activityId
    }

    const saveMetrics = async (activityId) => {
        if (stepsErrors[1]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[1].message, notificationType: 'error' }))
            setActiveTab(1)
            return false
        }
        const metricsWithValues = getNumerizedMetrics(metrics, isNewActivity, valuesPropsGeneric, true)
        const metricsSaved = await EasmApi.updateActivityMetrics(activityId, metricsWithValues)
        if (!metricsSaved?.message?.success) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Metrics saving failed. Please check the missed data', notificationType: 'error' }))
            setActiveTab(1)
            return false
        }
        if (deletedMetrics.length && !isNewActivity) await EasmApi.deleteActivityMetricsList(activityId, deletedMetrics)
        return true
    }

    const saveFindings = async (activityId) => {
        if (hasFindings) await EasmApi.updateActivityFindings(activityId, activityFindings)
        if (deletedFindings.length && !isNewActivity) await EasmApi.deleteActivityFindingsList(activityId, deletedFindings)
        return true
    }

    const updateActivity = async () => {

        // check if all the steps are validated - if submit action
        const readyToSubmit = checkReadyToSubmit()
        if (!readyToSubmit) return

        // save activity
        const activityId = await saveActivity()
        if (!activityId) return

        // save metrics
        const metricsSaved = await saveMetrics(activityId)
        if (!metricsSaved) return
        
        // save findings
        const findingsSaved = await saveFindings(activityId)
        if (!findingsSaved) return

        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: isNewActivity ? genericTexts.defaultText.createdSuccess(ACTIVITY) : genericTexts.defaultText.updatedSuccess(ACTIVITY) }))
        closeUpdateActivityPopup()
        await updateActivities()
    }

    const changeValueHandler = (key, updatedActivity) => {
        setActivityToUpdate(updatedActivity)
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
    }

    const updateMetrics = (updatedMetrics, isPredefined) => {
        isPredefined ? setPredefinedMetricsToEdit(updatedMetrics) : setCustomMetrics(updatedMetrics)
    }

    const formTabs = [{
        label: 'General Information',
        contentComponent: GeneralInformation
    }, {
        label: 'Activity Metrics',
        contentComponent: ActivityMetrics
    }]

    const changeMetricsTab = (isNext) => {
        let newTabIndex = isNext ? activeTab + 1 : activeTab - 1
        if (newTabIndex < 0) newTabIndex = 0
        if (newTabIndex > formTabs.length - 1) newTabIndex = formTabs.length - 1
        setActiveTab(newTabIndex)
        if (newTabIndex === 1) setIsMetricsTabOpened(true)
    }

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex)
        if (tabIndex === 1) setIsMetricsTabOpened(true)
    }

    const calculateCompletedSteps = () => {
        let steps = Object.keys(stepsErrors).filter(step => !stepsErrors[step]).map(step => step * 1)
        if (steps.includes(1) && isNewActivity && !isMetricsTabOpened) steps = steps.filter(s => s !== 1)
        return steps
    }
    
    const completedSteps = calculateCompletedSteps()

    const data = {
        changeValueHandler,
        setActivityToUpdate,
        setFieldsErrorMsg,
        activityToUpdate, 
        fieldsErrorMsg,
        errorMsg,
        currentOrg,
        orgUsers,
        customMetrics,
        predefinedMetricsToEdit,
        updateMetrics,
        newMetricModel,
        isNewActivity,
        templateUrl,
        hasFindings,
        setActivityFindings
    }

    useEffect(() => {
        const tabsContent = document.getElementsByClassName('tab-content')
        for (let i = 0; i < tabsContent.length; i++) {
            tabsContent[i].classList.add('scroll')
        }
    }, [activeTab])

    return (
        <div className='update-activity-wrapper'>
            <Tabs 
                layout='vertical' 
                data={data} 
                tabs={formTabs} 
                activeIndex={activeTab} 
                handleActiveTab={handleTabClick}
                isSteps={true}
                completedSteps={completedSteps} />
            <div className='form-buttons'>
                <div className='steps-buttons'>
                    {activeTab === 0 ? <button className='btn-submit' onClick={() => changeMetricsTab(true)} id='create-easm-activity-next-step'>Next</button> :
                        <>
                            <button className='btn-submit' onClick={updateActivity} id='save-easm-activity'>{genericTexts.defaultText.saveItem(ACTIVITY)}</button>
                            <button className='btn-link' onClick={() => changeMetricsTab(false)} id='create-easm-activity-previous-step'>Back</button>
                        </>}
                </div>
            </div>
        </div>   
    )
}

export default UpdateEasmActivity