import { 
    PRIMARY,
    WARNING,
    ERROR,
    GENERAL,
    TECHNOLOGY,
    PEOPLE,
    PROCESSES,
    TOTAL,
    FULLY_IMPLEMENTED,
    PARTIALLY_IMPLEMENTED,
    NOT_IMPLEMENTED,
    FULLY,
    PARTIALLY,
    NOT,
    FULLY_PROP,
    PARTIALLY_PROP,
    NOT_PROP,
    EXTERNAL_THREATS,
    INTERNAL_THREATS,
    READINESS_BY_CATEGORY,
    SCORING_BY_CATEGORY,
    LEVEL_OF_READINESS,
    CHART_VALUES,
    CHART_PERCENTAGE,
    IMPLEMENTATION_OF_SECURITY_CONTROL
} from './common-metrics'

import { CYBER_READINESS } from 'js/constants/app-modules'

const qaImplementationValues = [{
    id: FULLY_PROP,
    name: FULLY,
    fullName: FULLY_IMPLEMENTED,
    status: PRIMARY
}, {
    id: PARTIALLY_PROP,
    name: PARTIALLY,
    fullName: PARTIALLY_IMPLEMENTED,
    status: WARNING
}, {
    id: NOT_PROP,
    name: NOT,
    fullName: NOT_IMPLEMENTED,
    status: ERROR
}]

const qaValueProps = qaImplementationValues.map(v => v.id)

const qaMetricsCategories = [
    GENERAL,
    TECHNOLOGY,
    PEOPLE,
    PROCESSES
]

const qaMetricsWidgets = [
    'Cyber Authority compatibility',
    CYBER_READINESS,
    TECHNOLOGY,
    PEOPLE,
    PROCESSES,
    EXTERNAL_THREATS,
    INTERNAL_THREATS,
    'Pro-Active Defence',
    LEVEL_OF_READINESS,
    READINESS_BY_CATEGORY,
    SCORING_BY_CATEGORY,
    IMPLEMENTATION_OF_SECURITY_CONTROL,
    'Tools - immediate response',
    GENERAL,
    'Missing tools - high importance',
    'Level of readiness: Technologies'

]

const qaMetricsKeys = [
    TECHNOLOGY,
    PEOPLE,
    PROCESSES,
    TOTAL,
    EXTERNAL_THREATS,
    INTERNAL_THREATS,
    'Pro-Active Defence',
    'Inventory and Control of Software Assets',
    'Inventory and Control of Enterprise Assets',
    'Secure Configuration of Enterprise Assets and Software',
    'Data Protection',
    'Audit Log Management',
    'Email and Web Browser Protections',
    'Malware Defenses',
    'Network Infrastructure Management',
    'Network Monitoring and Defense',
    'Application Software Security',
    'SOC',
    'Wi-Fi Network',
    'OT Infrastructures',
    'Security Awareness and Skills Training',
    'Service Provider Management',
    'Account Management',
    'Access Control Management',
    'Continuous Vulnerability Management',
    'Data Recovery',
    'Incident Response Management',
    'Penetration Testing',
    'Human Resources (HR) Security Aspects',
    'Physical Security',
    'Business Continuity Planning',
    CHART_VALUES,
    CHART_PERCENTAGE
]

export {
    qaValueProps,
    qaMetricsCategories,
    qaMetricsWidgets,
    qaMetricsKeys,
    qaImplementationValues
}