import { genericTexts, menuActionIcons } from 'hub-web-lib/dist/index-export'
import { ACTIVITY } from '../vocabulary'
import React from 'react'
const activityStatusesEnum = {
    started: 1,
    complete: 2,
    cancelled: 3,
}

const activityStatuses = [{
    id: activityStatusesEnum.started,
    status: 'In Process'
}, {
    id: activityStatusesEnum.complete,
    status: 'Completed'
}, {
    id: activityStatusesEnum.cancelled,
    status: 'Cancelled'
}]

const activityCardInfoDefault = {
    id: {
        title: `${ACTIVITY} ID`
    }, title: {
        title: 'Title'
    },
    organization: {
        title: 'Organization'
    },
    start_date: {
        title: 'Start Date'
    },
    end_date: {
        title: 'End Date'
    },
    is_complete: {
        title: 'Completed'
    },
    description: {
        title: 'Description'
    },
}

const activityActions = {
    [genericTexts.actionsText.info]: { text: 'View Info', order: 1 },
    [genericTexts.actionsText.edit]: { text: genericTexts.actionsText.editText(ACTIVITY, true), order: 2 },
    [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(ACTIVITY), order: 4 }
}

const allOption = { id: 'all', name: 'All' }
const allTypes = { id: 'all_types', name: 'All Types' }

const starArchive = { id: 'starred', name: <i className='icon-star-filled'></i> }
const nonCompleteNotification = 'Selected Acivity has not been completed'

const ACTIVE = 'Active'
const ARCHIVED = 'Archived'

const ARCHIVED_PROP = 'archived'
const STARRED_PROP = 'starred'

const getMultiCheckActions = (isArchived) => {
    return {
        [genericTexts.actionsText.archive]: { text: isArchived ? 'Unarchive' : 'Archive', icon: menuActionIcons[genericTexts.actionsText.archive] },
        [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(), icon: menuActionIcons[genericTexts.actionsText.delete] }
    }
}

export {
    activityStatusesEnum,
    activityStatuses,
    activityCardInfoDefault,
    activityActions,
    nonCompleteNotification,
    allOption,
    starArchive,
    ACTIVE,
    ARCHIVED,
    ARCHIVED_PROP,
    STARRED_PROP,
    allTypes,
    getMultiCheckActions
}