import { QUESTIONNAIRE } from 'js/constants/vocabulary'
import React from 'react'
import './TemplateSummary.scss'
import { genericTexts } from 'hub-web-lib'

const TemplateSummary = ({ data }) => {

    const { 
        questionnaire, 
        saveQuestionnaireCategoriesNested = () => {}, 
        isChanged, 
        isProgressButtonLoading,
        isButtonLoading,
        currentUser,
        domainsCount
    } = data
    return (
        <div className='questionnaire-summary template-summary'>
            {questionnaire.approved ? <div></div> : <button 
                className={`btn-set ${isProgressButtonLoading ? 'loader' : ''}`} 
                disabled={!isChanged} 
                onClick={() => saveQuestionnaireCategoriesNested(false)}>
                {isChanged ? 'Save Progress' : <span><i className='icon-v'></i> Progress Saved</span>}
            </button>}
            <button 
                className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} 
                disabled={!domainsCount || questionnaire.is_in_use || isProgressButtonLoading} 
                onClick={() => saveQuestionnaireCategoriesNested(true)} id='save-questionnaire-nested'>
                {currentUser.id === questionnaire.approved_by ? 
                    (questionnaire.approved ? <span><i className='icon-unlock'></i> Revoke Approval</span> : <span>Save and Approve <i className='icon-send'></i></span>)
                    : genericTexts.defaultText.saveItem(QUESTIONNAIRE)}
            </button>
        </div>
    )
}

export default TemplateSummary
