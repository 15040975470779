import React, { useState } from 'react'
import { CopyValueInput, EnterCode } from 'hub-web-lib'
import './OtpSetup.scss'

const OtpSetup = ({ submitOtp = () => { }, secretKey, errorMsg = ''
}) => {

    const [otp, setOtp] = useState('')
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const onSubmit = async () => {
        setIsLoadingButton(true)
        await submitOtp(otp)
        setIsLoadingButton(false)
    }

    const content = (
        <>
            <div className='login-header'>
                <h2 id='mfaSetup'>MFA Setup</h2>
            </div>
            <div>
                Scan the QR code with your mobile authenticator app
            </div>
            <div className='qr-container-parent'>
                <div className='qr-container'>
                    <img src={process.env.REACT_APP_API_ACCESS_POINT + '/auth/otp/media/' + secretKey + '.svg'} className="barcode-image" alt="QR code" width="200px" height="200px" />
                </div>
            </div>
            <div className='qr-description'>
                or type the key manually on your mobile
            </div>
            <CopyValueInput
                details={{ inputId: 'secretKey' }}
                defaultValue={secretKey}
                isWide={true} />
            <EnterCode
                enterCodeId='otp'
                title='Enter the key you received in authenticator'
                digitsCount={6}
                updateValue={setOtp} />
            <p className='error-msg' id='login-error'>{errorMsg}</p>
            <button onClick={onSubmit} className={`btn-submit ${isLoadingButton ? 'loader' : ''}`} disabled={otp.length !== 6} id='next'>Next</button>

        </>
    )
    return content
}

export default OtpSetup