import React, { useEffect, useState } from 'react'
import 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentCategories.scss'
import './TemplateCategories.scss'
import { DragAndDropList, helpers } from 'hub-web-lib'
import TemplateCategoryContent from './TemplateCategoryContent'
import { connect } from 'react-redux'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'

const mapStateToProps = state => {
    return {
        questionnareFeaturesList: state.questionnareFeaturesList
    }
}


const TemplateCategoriesComponent = ({ data = {}, questionnareFeaturesList }) => {

    const {
        isApproved,
        categories = [],
        activeCategoryId,
        activeQuestionId,
        updateCategories = () => { },
        addCategory = () => { },
        updateCategoryName = () => { },
        updateQuestionProp = () => { },
        addQuestion = () => { }
    } = data

    const visibleQuestionnaireFeatures = getEnabledSettingItems(questionnareFeaturesList)
    const [openedIds, setOpenedIds] = useState(categories.length ? [categories[0].id] : [])

    const updateOpenedIds = (updatedIds) => {
        setOpenedIds(updatedIds)
    }

    const expandCategory = (categoryId) => {
        setOpenedIds(openedIds.includes(categoryId) ? [...openedIds].filter(id => id !== categoryId) : [...openedIds, categoryId])
    }

    const addQuestionToCategory = (categoryId) => {
        addQuestion(categoryId)
        setOpenedIds([...openedIds, categoryId])
    }

    const updateList = (updatedList) => {
        const listNoContent = [...updatedList]
        listNoContent.forEach(item => {
            delete item.content
            const subItems = item.subItems || []
            subItems.forEach(subItem => {
                delete subItem.content
            })
        })
        const updatedListNoContent = helpers.createNewInstance(listNoContent)
        updatedListNoContent.forEach(item => {
            item.questions = item.subItems || []
            delete item.subItems
        })
        updateCategories(updatedListNoContent)
    }

    const list = categories.map(item => ({
        ...item,
        content: <TemplateCategoryContent
            category={item}
            updateName={updateCategoryName}
            data={{ ...data, addQuestion: addQuestionToCategory, expandCategory }} />,
        subItems: (item.questions || []).map(subItem => ({
            ...subItem,
            content: <TemplateCategoryContent
                question={subItem}
                category={item}
                updateName={updateQuestionProp}
                data={data} />
        }))
    }))

    const isItemVisible = (activeElement) => {
        const dragAndDrop = document.getElementById('drag-and-drop-template-categories')
        if (!dragAndDrop || !activeElement) return false

        const dragAndDropRect = dragAndDrop.getBoundingClientRect()
        const activeElementRect = activeElement.getBoundingClientRect()
        const isTopVisible = activeElementRect.top >= dragAndDropRect.top && activeElementRect.top <= dragAndDropRect.bottom
        const isBottomVisible = activeElementRect.bottom <= dragAndDropRect.bottom && activeElementRect.bottom >= dragAndDropRect.top

        return isTopVisible && isBottomVisible
    }

    const scrollIntoItem = (categoryId, questionId) => {
        setTimeout(() => {
            const activeElement = document.getElementById(`content-${categoryId}-${questionId}`)
            if (activeElement && !isItemVisible(activeElement)) activeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true })
        }, 100)
    }

    useEffect(() => {
        if (!openedIds.includes(activeCategoryId)) setOpenedIds([...openedIds, activeCategoryId])
        scrollIntoItem(activeCategoryId, 'category')
    }, [activeCategoryId])


    useEffect(() => {
        scrollIntoItem(activeCategoryId, activeQuestionId)
    }, [activeQuestionId])


    return (
        <div className='questionnaire-categories-section template-categories-section'>
            <div className='categories-header'>
                <h4>Categories <span>({categories.length})</span></h4>
                {(!isApproved && visibleQuestionnaireFeatures.categories_add_delete) && <button className='btn-icon' id='add-category' title='Add Category' onClick={addCategory}><i className='icon-plus'></i></button>}
            </div>
            <DragAndDropList list={list} listId='template-categories' updateList={updateList} openedIds={openedIds} updateOpenedIds={updateOpenedIds} isViewOnly={isApproved} />
        </div>
    )
}

const TemplateCategories = connect(mapStateToProps)(TemplateCategoriesComponent)


export default TemplateCategories