import React from 'react'
import { getScoringByCategoryValues } from './qa-dashboard'
import { getBySeverityValues } from './red-team-dashboard'
import { getActiveAlertsBySeverityValuesImportant } from './easm-dashboard'
import { FINDINGS, LOW_PROP, WARNING } from 'js/constants/metrics/common-metrics'
import { getVulnerabilitiesTotalsBySeverityValuesImportant } from './pen-test-dashboard'
import { getDdosHomeDashboardValues } from './ddos-dashboard'
import { getIncidentResponseHomeDashboardValues } from './incident-response-dashboard'
import { getPhishingSimulationHomeDashboardValues } from './phishing-simulation-dashboard'
import { getRansomwareResilienceHomeDashboardValues } from './ransomware-resilience-dashboard'
import { activityStatusesEnum } from 'js/constants/enums/activity'
import { ATTACK_SURFACE_MODULE, CYBER_READINESS_MODULE, DASHBOARD_PATH, DDOS_RESILIENCE_MODULE, INCIDENT_RESPONSE_MODULE, PEN_TEST_MODULE, PHISHING_SIMULATION_MODULE, RANSOMWARE_RESILIENCE_MODULE, RED_TEAM_MODULE, RISK_ASSESSMENT_MODULE, appModulesPaths } from 'js/constants/app-modules'
import { getRiskLevelChartDataImportant } from './risk-assessment-dashboard'

const defaultLockValues = { label: 'Issues', status: LOW_PROP, value: <i className='icon-lock'></i> }

const getQaValues = (metricsData, isVisible) => {
    const qaValues = isVisible ? getScoringByCategoryValues(metricsData) : [defaultLockValues]
    const qaIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[CYBER_READINESS_MODULE]}/${DASHBOARD_PATH}` } : null
    return { qaValues, qaIcon }
}
const getRedTeamValues = (metricsData, isVisible, metricsProps) => {
    const redTeamValues = isVisible ? getBySeverityValues(metricsData, metricsProps) : [defaultLockValues]
    const redTeamIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[RED_TEAM_MODULE]}/${DASHBOARD_PATH}` } : null
    return { redTeamValues, redTeamIcon }
}
const getEasmValues = (metricsData, isVisible, metricsProps) => {
    const easmValues = isVisible ? getActiveAlertsBySeverityValuesImportant(metricsData, metricsProps) : [defaultLockValues]
    const easmIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[ATTACK_SURFACE_MODULE]}/${DASHBOARD_PATH}` } : null
    return { easmValues, easmIcon }
}
const getPtValues = () => {
    return { ptValues: [{ ...defaultLockValues, label: FINDINGS }], ptIcon: null }
}
const getDdosValues = (metricsData, isVisible, metricsProps) => {
    const ddosValues = isVisible ? getDdosHomeDashboardValues(metricsData, metricsProps) : [{ ...defaultLockValues, label: FINDINGS }]
    const ddosIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${DASHBOARD_PATH}` } : null
    return { ddosValues, ddosIcon }
}

const getRiskAssessmentValues = (metricsData, isVisible, metricsProps) => {
    const riskAssessmentValues = isVisible ? getRiskLevelChartDataImportant(metricsData, metricsProps) : [{ ...defaultLockValues, label: FINDINGS }]
    const riskAssessmentIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${DASHBOARD_PATH}` } : null
    return { riskAssessmentValues, riskAssessmentIcon }
}

const getIncidentResponseValues = (metricsData, isVisible) => {
    const incidentResponseValues = isVisible ?
        metricsData.length ? getIncidentResponseHomeDashboardValues(metricsData) : [{ label: 'Coming Soon', status: WARNING, value: <i className='icon-notifications'></i> }] :
        [defaultLockValues]
    const incidentResponseIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[INCIDENT_RESPONSE_MODULE]}/${DASHBOARD_PATH}` } : null
    return { incidentResponseValues, incidentResponseIcon }
}
const getPhishingSimulationValues = (metricsData, isVisible) => {
    const phishingSimulationValues = isVisible ? getPhishingSimulationHomeDashboardValues(metricsData) : [{ ...defaultLockValues, label: FINDINGS }]
    const phishingSimulationIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[PHISHING_SIMULATION_MODULE]}/${DASHBOARD_PATH}` } : null
    return { phishingSimulationValues, phishingSimulationIcon }
}
const getRansomwareResilienceValues = (metricsData, isVisible) => {
    const ransomwareResilienceValues = isVisible ? getRansomwareResilienceHomeDashboardValues(metricsData) : [defaultLockValues]
    const ransomwareResilienceIcon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[RANSOMWARE_RESILIENCE_MODULE]}/${DASHBOARD_PATH}` } : null
    return { ransomwareResilienceValues, ransomwareResilienceIcon }
}
const getPenTestValues = (metricsData, isVisible, type, metricsProps) => {
    const values = isVisible ? getVulnerabilitiesTotalsBySeverityValuesImportant(metricsData, metricsProps) : [defaultLockValues]
    const icon = isVisible ? { icon: 'launch', link: `/${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${type}` } : null
    return { values, icon }
}

const getSortedFilteredActivities = (activities, filterProp, filterValue, completedOnly) => {
    if (!activities || activities.error || !activities.length) return null
    let allActivities = activities
    if (completedOnly) allActivities = allActivities.filter(a => a.status === activityStatusesEnum.complete || a.is_complete)
    if (filterProp && filterValue) allActivities = allActivities.filter(a => a[filterProp] === filterValue)
    return allActivities.sort((a, b) => new Date(b.end_date) - new Date(a.end_date))
}

const getLatestActivity = (activities, filterProp, filterValue, completedOnly) => {
    const sortedActivities = getSortedFilteredActivities(activities, filterProp, filterValue, completedOnly)
    return sortedActivities?.length ? sortedActivities[0] : null
}

const sortByYearQuarter = (rows, key) => {
    return rows.sort((a, b) => {
        // Extract the quarter and year from the label
        const [quarterA, yearA] = a[key].split(' ')
        const [quarterB, yearB] = b[key].split(' ')

        // Compare the years first
        if (yearA !== yearB) {
            return parseInt(yearA) - parseInt(yearB)
        }

        // If years are the same, compare quarters
        const quartersOrder = { 'Q1': 1, 'Q2': 2, 'Q3': 3, 'Q4': 4 }
        return quartersOrder[quarterA] - quartersOrder[quarterB]
    })
}

export {
    getQaValues,
    getRedTeamValues,
    getEasmValues,
    getPenTestValues,
    getPtValues,
    getDdosValues,
    getRiskAssessmentValues,
    getIncidentResponseValues,
    getPhishingSimulationValues,
    getRansomwareResilienceValues,
    getLatestActivity,
    getSortedFilteredActivities,
    sortByYearQuarter
}