import React, { useState } from 'react'
import 'components/Modules/Questionnaire/Assessment/Pages/AssessmentClient.scss'
import 'components/Modules/Questionnaire/Questionnaire.scss'
import './TemplateClient.scss'
import { Tabs } from 'hub-web-lib'
import TemplateDomain from '../Widgets/TemplateDomain'
import TemplateSummary from '../Widgets/TemplateSummary'

function TemplateClient({ 
    questionnaire,
    updateDomainCategories = () => {},
    saveQuestionnaireCategoriesNested = () => {},
    questionnaireTitleClick,
    isProgressButtonLoading,
    isButtonLoading,
    isChanged,
    currentUser,
    isApproved
}) {

    const [activeTab, setActiveTab] = useState(0)
    const domains = questionnaire.domains

    const questionnaireTabs = domains.map(domain => ({
        label: domain.name,
        contentComponent: () => <></>
    }))

    const data = {
        updateDomainCategories,
        isApproved
    }

    const summaryData = {
        questionnaire, 
        saveQuestionnaireCategoriesNested, 
        isChanged, 
        isProgressButtonLoading,
        isButtonLoading,
        currentUser,
        domainsCount: domains.length
    }

    return (
        <div className='questionnaire-client'>
            <Tabs 
                tabs={questionnaireTabs} 
                activeIndex={activeTab}
                handleActiveTab={setActiveTab}
                tabsTitle={{ title: questionnaire.name, inline: true, titleClick: questionnaireTitleClick }} />
            {domains.length ? 
                domains.map((domain, i) => <TemplateDomain key={i} data={{ ...data, domain, isShown: i === activeTab }} /> )
                : <div className='questionnaire-domain show no-data-message'>No domains created</div>}
            <TemplateSummary data={summaryData} />
        </div>
    )
}

export default TemplateClient