import { APPLICATION, INFRASTRUCTURE } from '../enums/pen-test'
import { BY_QUARTER, FINDINGS } from './common-metrics'


const EXPOSURE_BY_LAYER = 'Exposure By Layer'
const EXPOSURE_TRENDS_OVER_TIME = 'Exposure Trends Over Time'
const ATTACK_VECTORS_PRIORITIZED = 'Attack Vectors Prioritized'

const CYLINDER = 'Cylinder'
const BY_MITIGATION = 'By Mitigation'
const BY_ATTACK_VECTORS = 'By Attack Vectors'
const BY_ATTACK_TYPES = 'By Attack Types'
const BY_VULNERABLE_TARGETS = 'By # of Vulnerable Targets'
const MITIGATED = 'Mitigated'
const PARTIALLY_MITIGATED = 'Partially Mitigated'
const UNMITIGATED = 'Unmitigated'

const ddosMetricsKeys = [
    'Physical Layer (Layer 1)',
    'Data Link Layer (Layer 2)',
    'Network Layer (Layer 3)',
    'Transport Layer (Layer 4)',
    'Session Layer (Layer 5)',
    'Presentation Layer (Layer 6)',
    'Application Layer (Layer 7)',
    MITIGATED,
    PARTIALLY_MITIGATED,
    UNMITIGATED,
    'ICMP FLood',
    'TCP Flood',
    'Slow POST',
    'SSL Renegotiation',
    'TCP Syn Flood',
    'Get Flood to Non-Existing Endpoint',
    'LOGIN Flood',
    'Get Flood',
    INFRASTRUCTURE,
    APPLICATION,
    'Volumetric',

]

const ddosMetricsCategories = [
    EXPOSURE_BY_LAYER,
    FINDINGS,
    EXPOSURE_TRENDS_OVER_TIME,
    ATTACK_VECTORS_PRIORITIZED
]

const ddosMetricsWidgets = [
    CYLINDER,
    BY_MITIGATION,
    BY_ATTACK_VECTORS,
    BY_ATTACK_TYPES,
    BY_QUARTER,
    BY_VULNERABLE_TARGETS
]

export {
    ddosMetricsKeys,
    ddosMetricsCategories,
    ddosMetricsWidgets,

    CYLINDER,
    EXPOSURE_TRENDS_OVER_TIME,
    ATTACK_VECTORS_PRIORITIZED,
    EXPOSURE_BY_LAYER,
    BY_MITIGATION,
    BY_ATTACK_VECTORS,
    BY_ATTACK_TYPES,
    BY_VULNERABLE_TARGETS,

    MITIGATED,
    PARTIALLY_MITIGATED,
    UNMITIGATED,
}