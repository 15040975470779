import {
    UPDATE_IS_NAV_BACK,
    UPDATE_NOTIFICATION_CONTENT,
    UPDATE_ERROR_CONFIG,
    UPDATE_NOTIFICATIONS,
    UPDATE_CURRENT_USER,
    UPDATE_PROGRESS_STEP,
    UPDATE_VISIBLE_MODULES,
    UPDATE_SETTINGS,
    UPDATE_QUESTIONNAIRE_FEATURES,
    UPDATE_DASHBOARD_SETTINGS,
    UPDATE_RISK_MATRIX_SETTINGS,
    UPDATE_CURRENT_USER_ORGS,
    UPDATE_IS_TENANT_ASSESSMENT_SUBMIT,
    UPDATE_DDOS_FEATURES
} from '../../js/constants/action-types'
import { genericTexts } from 'hub-web-lib'

const initialState = {
    isNavBack: false,
    notificationContent: null,
    notifications: [],
    errorConfig: { showErrorPage: false },
    currentUser: null,
    currentUserOrgs: [],
    visibleModules: [],
    questionnareFeaturesList: [],
    dashboardSettings: [],
    riskMatrixTemplate: {},
    progressBarConfig: { tasksLength: 0, currentStep: 0 },
    overallSettings: [],
    isTenanatAssessmentSubmit: false
}

const updateState = (state, prop, payload) => {
    return { ...state, [prop]: payload }
}

function rootReducer(state = initialState, action) {
    if (action.type.endsWith('_REJECTED') && action.meta)
    {   
        const response = action.payload?.response
        const statusMessage = (response?.status && response?.statusText) ? `${response.status} ${response.statusText}` : genericTexts.defaultText.genericErrorMsg
        const errorMessage = action.meta.errorMessage || response?.data?.message || response?.data?.details || statusMessage
        state = {
            ...state, errorConfig: { ...state.errorConfig, showErrorPage: action.meta.showErrorPage, errorMessage }
        }
    }

    switch (action.type)
    {

    case UPDATE_IS_NAV_BACK:
        return updateState(state, 'isNavBack', action.payload)

    case UPDATE_NOTIFICATION_CONTENT:
        if (action.payload) {
            const notifications = [...state.notifications, { ...action.payload, date: new Date() }]
            // LocalStorageApi.updateNotifications(notifications)
            return { 
                ...state, 
                'notificationContent': action.payload,
                'notifications': notifications
            }
        }
        return updateState(state, 'notificationContent', action.payload)

    case UPDATE_NOTIFICATIONS:
        if (action.payload) {
            return updateState(state, 'notifications', action.payload)
        }
        break

    case UPDATE_ERROR_CONFIG:
        return {
            ...state, errorConfig: { ...action.payload }
        }

    case UPDATE_CURRENT_USER: 
        return updateState(state, 'currentUser', action.payload)
    
    case UPDATE_CURRENT_USER_ORGS: 
        return updateState(state, 'currentUserOrgs', action.payload)

    case UPDATE_PROGRESS_STEP: 
        return updateState(state, 'progressBarConfig', { ...state.progressBarConfig, ...action.payload })
    
    case UPDATE_VISIBLE_MODULES: 
        return updateState(state, 'visibleModules', action.payload)

    case UPDATE_QUESTIONNAIRE_FEATURES: 
        return updateState(state, 'questionnareFeaturesList', action.payload)

    case UPDATE_DASHBOARD_SETTINGS:
        return updateState(state, 'dashboardSettings', action.payload)

    case UPDATE_RISK_MATRIX_SETTINGS:
        return updateState(state, 'riskMatrixTemplate', action.payload)
        
    case UPDATE_SETTINGS:
        return updateState(state, 'overallSettings', action.payload)
    
    case UPDATE_IS_TENANT_ASSESSMENT_SUBMIT:
        return updateState(state, 'isTenanatAssessmentSubmit', action.payload)

    case UPDATE_DDOS_FEATURES: 
        return updateState(state, 'ddosFeaturesList', action.payload)
    default: break
    }

    return state
}

export default rootReducer
