import React from 'react'
import UpdateUser from 'components/Views/UserManagement/UpdateUser'
import UserApi from 'services/UserApi'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const UserDetailsComponent = ({ currentUser }) => {

    const updateUserDetails = async () => {
        await UserApi.setCurrentUserCookies()
    }

    return (
        <section>
            <div className='form'>
                <UpdateUser
                    userDetails={currentUser}
                    updateUserDetails={updateUserDetails} />
            </div>
        </section>)
}

const UserDetails = connect(mapStateToProps)(UserDetailsComponent)

export default UserDetails