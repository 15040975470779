import React, { useEffect, useState } from 'react'
import QuestionnaireTemplateBlock from './TemplateBlock'
import './TemplateSelect.scss'
import { RadioGroup, InputRow, SortableTable, EllipsisText } from 'hub-web-lib'
import { templateTableColumns } from 'js/constants/table-columns'
import moment from 'moment'

const selectedTemplateSort = { key: 'selectTemplate', direction: 'descending' }

const TemplateSelect = ({ data }) => {

    const {
        activeTab,
        currentTemplates,
        setCurrentTemplates = () => {},
        selectedTemplate,
        loadActivityTemplate = () => {}
    } = data

    const [isCards, setIsCards] = useState(false)
    const [isSortedTable, setIsSortedTable] = useState(false)
    const [activeTemplateId, setActiveTemplateId] = useState(selectedTemplate?.id)
    const templatesList = (currentTemplates || [])
        .map(t => ({ 
            id: t.id, 
            name: 'Select', 
            content: <QuestionnaireTemplateBlock template={t} /> 
        }))

    const updateActiveId = (templateId) => {
        setIsSortedTable(false)
        setActiveTemplateId(templateId)
        loadActivityTemplate(templateId)
    }

    const reorderTemplatesList = () => {
        let approvedTemplatesTemp = currentTemplates
        const activeTemplateIndex = currentTemplates.findIndex(item => item.id === activeTemplateId)

        if (activeTemplateIndex !== -1) {
            const activeTemplate = approvedTemplatesTemp.splice(activeTemplateIndex, 1)[0]
            approvedTemplatesTemp.unshift(activeTemplate)
        }
        setCurrentTemplates(approvedTemplatesTemp)
        setIsSortedTable(true)
    }

    const getTableData = () => {
        const data = (currentTemplates || []).map(t => {
            const creation_date = t.creation_date ? moment(t.creation_date).format('DD/MM/YY') : ''
            const selectTemplate = <InputRow inputId={`templateSelect-${t.id}`} inputType='radio' inputName='templateSelect' defaultValue={activeTemplateId === t.id} changeValueHandler={() => updateActiveId(t.id)} />
            const nameEllipsed = <EllipsisText text={t.name} noBreak={true} width={170} />
            const descriptionEllipsed = t.description ? <EllipsisText text={t.description} noBreak={true} width={170} /> : <></>
            const row = {
                selectTemplate,
                ...t,
                nameEllipsed,
                descriptionEllipsed,
                code: t.code || '',
                creation_date,
                selected: activeTemplateId === t.id ? 1 : 0

            }
            return row
        })
        return data
    }

    const handleTdClick = (_, templateId) => {
        updateActiveId(templateId)
    }


    useEffect(() => {
        if (activeTemplateId && currentTemplates[0]?.id !== activeTemplateId) reorderTemplatesList(activeTemplateId)
    }, [activeTab])

    const tableData = getTableData()

    return (
        <div className='questionnaire-template'>
            <div className='template-head'>
                <h3 id='select-template-title'>Select Questionnaire Template</h3>
                <div className='template-change-button'>
                    <button className='btn-icon' onClick={() => setIsCards(!isCards)} id='switch-template-view'><i className={`icon-${isCards ? 'table' : 'rules'}`}></i></button>
                </div>
            </div>
            {isCards ? <div className='template-select'>
                <div className='template-cards'>
                    <RadioGroup radioGroupId='templates-select' checkedId={activeTemplateId} allItems={templatesList} updateItem={(val) => updateActiveId(val)} />
                </div>

            </div>
                :
                <div className='template-rows'>
                    <SortableTable 
                        tableData={tableData} 
                        columns={templateTableColumns} 
                        defaultSort={isSortedTable ? selectedTemplateSort : null } 
                        handleTdClick={handleTdClick}
                        tableId='template-select-table' />
                </div>
            }


        </div>
    )
}

export default TemplateSelect
