import { activityActions } from 'js/constants/enums/activity'
import moment from 'moment'
import { activityStatusesEnum, activityStatuses } from 'js/constants/enums/activity'
import React from 'react'
import { genericTexts } from 'hub-web-lib/dist/index-export'
import { ACTIVITY } from 'js/constants/vocabulary'

const getActivityInfoCardData = (activityCardInfo, currentActivityInfo) => {
    const data = { ...activityCardInfo }
    for (const key of Object.keys(data)) {
        data[key].value = currentActivityInfo[key] || '-'
    }
    data.start_date.value = moment(currentActivityInfo.start_date).format('DD/MM/YY')
    data.end_date.value = moment(currentActivityInfo.end_date).format('DD/MM/YY')
    data.is_complete.value = data.status == activityStatusesEnum.complete || data.is_complete ? 'Yes' : 'No'
    return data
}


const getActivityTableData = (activities, starArray, actions = activityActions) => {
    const data = activities.map(a => {
        const endDate = moment(a.end_date).format('DD/MM/YY')
        const startDate = moment(a.start_date).format('DD/MM/YY')
        let completed
        if (a.status) {
            completed = activityStatuses.find(s => s.id === a.status)?.status
        }
        else {
            completed = a.is_complete ? 'Completed' : 'In Process'
        }
        const actualActions = actions instanceof Function ? actions(a) : { ...actions }
        actualActions[genericTexts.actionsText.archive] = { text: genericTexts.actionsText.archiveText(ACTIVITY, a.archived), order: 3 }
     
        const star = !a.archived ? <button className='btn-icon' disabled={a.status === 3}><i className={`icon-star${starArray ? starArray[a.id] ? '-filled' : '' : ''}`}></i></button> : <></>
        const row = {
            ...a,
            title: a.title || '-',  
            organization: a.organization,
            startDate,
            endDate,
            completed,
            star,
            actions: actualActions
        }
        return row
    })
    return data
}

const getSelectedActivityTitle = (activity) => {
    if (!activity) return ''
    return activity.title || `${activity.id || ''} ${activity.description || ''}`
}

const getSelectedActivity = (activities, activityId, activityIds = []) => {
    if (!activityId && activityIds?.length === 1) activityId = activityIds[0]
    if (!activities?.length || (!activityId && !activityIds?.length)) { 
        return { 
            selectedActivity: {}, 
            selectedActivityTitle: '',
            selectedActivitiesCount: 0,
            activitiesText: '',
            deleteActivitiesText: '',
            wasText: ''
        }
    }
    const selectedActivity = activities.find(u => u.id * 1 === activityId * 1) || { id: activityId }
    const selectedActivityTitle = getSelectedActivityTitle(selectedActivity)
    const selectedActivitiesCount = activityIds?.length
    const activitiesText = selectedActivitiesCount > 1 ? `${selectedActivitiesCount} activities` : `${ACTIVITY} ${selectedActivityTitle}`
    const wasText = selectedActivitiesCount > 1 ? 'were' : 'was'
    const deleteActivitiesText = genericTexts.defaultText.deletePopupText(activitiesText)
    return { selectedActivity, selectedActivityTitle, selectedActivitiesCount, activitiesText, deleteActivitiesText, wasText }
}

const getSelectedActivityDetails = (activity) => {
    if (!activity) return ''
    return `${activity.title} (${activity.description}) ${moment(activity.start_date).format('DD/MM/YY')} - ${moment(activity.end_date).format('DD/MM/YY')}`
}

export {
    getActivityInfoCardData,
    getActivityTableData,
    getSelectedActivityTitle,
    getSelectedActivity,
    getSelectedActivityDetails
}