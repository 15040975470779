import React, { useEffect, useState } from 'react'
import './PenTest.scss'
import { AreaChartCustom, BarChartCustom, PieChartCustom, TreemapChartCustom, ValuesList, WidgetBlock } from 'hub-web-lib'
import { BY_SEVERITY, FINDINGS, RISK_LEVEL_BY_CATEGORY } from 'js/constants/metrics/common-metrics'

import { 
    getVulnerabilitiesByRiskLevelChart, 
    getVulnerabilitiesTotalsBySeverityValues,
    getRiskLevelByCategoryChart,
    getTopVulnerabilitiesCategoriesData,
    getVulnerabilitiesByStatusChart,
    getVulnerabilitiesBySeverityChartData,
    getRiskLevelTrendChart,
    getVulnerabilitiesSeverityByCategoryChartData
} from 'js/ui-helpers/pen-test-dashboard'
import { VULNERABILITIES_BY_RISK_LEVEL, TOP_5_VULNERABILITIES_CATEGORIES, VULNERABILITIES_BY_STATUS, VULNERABILITIES_CATEGORY_BY_SEVERITY, VULNERABILITIES_TOTALS_BY_SEVERITY } from 'js/constants/metrics/pen-test-metrics'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'


export const PenTest = ({ metricsData, metricsProps, filter, isAnimationActive }) => {
    const [charts, setCharts] = useState({})
    useEffect(() => {
        const vulnerabilitiesTotalsBySeverityValues = getVulnerabilitiesTotalsBySeverityValues(metricsData, metricsProps, filter)
        const { vulnerabilitiesByRiskChartData, vulnerabilitiesByRiskChartDetails } = getVulnerabilitiesByRiskLevelChart(metricsData, metricsProps, filter)
        const { riskLevelByCategoryChartData, riskLevelByCategoryChartDetails } = getRiskLevelByCategoryChart(metricsData, metricsProps)
        const { topVulnerabilitiesCategoriesChartData, topVulnerabilitiesCategoriesChartDetails } = getTopVulnerabilitiesCategoriesData(metricsData)
        const { vulnerabilitiesByStatusChartData, vulnerabilitiesByStatusChartDetails } = getVulnerabilitiesByStatusChart(metricsData)
        const { vulnerabilitiesBySeverityChartData, vulnerabilitiesBySeverityChartDetails } = getVulnerabilitiesBySeverityChartData(metricsData, metricsProps, filter)
        const { riskLevelTrendChartData, riskLevelTrendChartDetails } = getRiskLevelTrendChart(metricsData, metricsProps, filter)
        const { vulnerabilitiesSeverityByCategoryChartData, vulnerabilitiesSeverityByCategoryChartDetails } = getVulnerabilitiesSeverityByCategoryChartData(metricsData, metricsProps, filter)
        setCharts({
            vulnerabilitiesTotalsBySeverityValues, 
            vulnerabilitiesByRiskChartData,
            vulnerabilitiesByRiskChartDetails, 
            riskLevelByCategoryChartData,
            riskLevelByCategoryChartDetails,
            topVulnerabilitiesCategoriesChartData,
            topVulnerabilitiesCategoriesChartDetails,
            vulnerabilitiesByStatusChartData,
            vulnerabilitiesByStatusChartDetails,
            vulnerabilitiesBySeverityChartData,
            vulnerabilitiesBySeverityChartDetails,
            riskLevelTrendChartData,
            riskLevelTrendChartDetails,
            vulnerabilitiesSeverityByCategoryChartData,
            vulnerabilitiesSeverityByCategoryChartDetails
        })
    }, [])

    return (
        <>
            <div className='module-widgets-row totals'>
                <WidgetBlock title={VULNERABILITIES_TOTALS_BY_SEVERITY} content={charts.vulnerabilitiesTotalsBySeverityValues?.length ? <ValuesList list={charts.vulnerabilitiesTotalsBySeverityValues} listId='vulnerabilities-totals-values' /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row risk-level'>
                <WidgetBlock title={VULNERABILITIES_BY_RISK_LEVEL} content={charts.vulnerabilitiesByRiskChartData?.length ? <PieChartCustom chartData={charts.vulnerabilitiesByRiskChartData} chartDetails={charts.vulnerabilitiesByRiskChartDetails} /> : <NoDataPlaceholder />} />
                <WidgetBlock title={RISK_LEVEL_BY_CATEGORY} content={charts.riskLevelByCategoryChartData?.length ? <TreemapChartCustom chartData={charts.riskLevelByCategoryChartData} chartDetails={{ ...charts.riskLevelByCategoryChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row vulnerabilities'>
                <WidgetBlock title={TOP_5_VULNERABILITIES_CATEGORIES} content={charts.topVulnerabilitiesCategoriesChartData?.length ? <BarChartCustom chartData={charts.topVulnerabilitiesCategoriesChartData} chartDetails={{ ...charts.topVulnerabilitiesCategoriesChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
                <WidgetBlock title={VULNERABILITIES_BY_STATUS} content={charts.vulnerabilitiesByStatusChartData?.length ? <PieChartCustom chartData={charts.vulnerabilitiesByStatusChartData} chartDetails={{ ...charts.vulnerabilitiesByStatusChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row'>
                <WidgetBlock title={`${FINDINGS} ${BY_SEVERITY} Periods Comparison`} content={charts.vulnerabilitiesBySeverityChartData?.length ? <BarChartCustom chartData={charts.vulnerabilitiesBySeverityChartData} chartDetails={{ ...charts.vulnerabilitiesBySeverityChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row'>
                <WidgetBlock title='Findings’ Risk Level Trends Over Time' content={charts.riskLevelTrendChartData?.length ? <AreaChartCustom chartData={charts.riskLevelTrendChartData} chartDetails={{ ...charts.riskLevelTrendChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row vulnerabilities-category'>
                <WidgetBlock title={VULNERABILITIES_CATEGORY_BY_SEVERITY} content={charts.vulnerabilitiesSeverityByCategoryChartData?.length ? <BarChartCustom chartData={charts.vulnerabilitiesSeverityByCategoryChartData} chartDetails={{ ...charts.vulnerabilitiesSeverityByCategoryChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />                
            </div>
        </>
            

    )
}

export default PenTest
