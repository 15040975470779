import { ptScheduledActivities } from 'js/constants/lorem-ipsum'

const LocalStorageApi360 = {

    // Scheduler
    updateScheduled: async (newScheduled) => {
        let scheduled = await LocalStorageApi360.getScheduled()
        const ids = scheduled.map(s => s.id * 1)
        const maxId = ids.length ? Math.max(...ids) : 0
        scheduled.push({ ...newScheduled, id: `${maxId + 1}` })
        await localStorage.setItem('scheduled', JSON.stringify(scheduled))
        return scheduled
    },

    deleteScheduled: async (id) => {
        let scheduled = await LocalStorageApi360.getScheduled()
        scheduled = scheduled.filter(s => s.id !== id)
        await localStorage.setItem('scheduled', JSON.stringify(scheduled))
        return scheduled
    },

    getScheduled: async () => {
        let scheduled = await localStorage.getItem('scheduled')
        if (!scheduled) {
            scheduled = JSON.stringify(ptScheduledActivities)
            await localStorage.setItem('scheduled', scheduled)
        }
        return JSON.parse(scheduled)
    }
}

export default LocalStorageApi360

