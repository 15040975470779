import { RadioGroup, helpers } from 'hub-web-lib/dist/index-export'
import { getRiskAssessmentActivityTemplateTypes } from 'js/constants/enums/risk-assessment'
import { RISK_MATRIX } from 'js/constants/metrics/risk-assessment-metrics'
import { TEMPLATE } from 'js/constants/vocabulary'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './RiskAssessmentTemplate.scss'
import SettingsApi from 'services/SettingsApi'
import { action } from 'js/actions'
import store from 'js/store'
import { UPDATE_DASHBOARD_SETTINGS, UPDATE_NOTIFICATION_CONTENT, UPDATE_RISK_MATRIX_SETTINGS } from 'js/constants/action-types'
import { changeDashboardSettingByTemplate } from 'js/ui-helpers/settings'

const mapStateToProps = state => {
    return {
        dashboardSettings: state.dashboardSettings,
        riskMatrixTemplate: state.riskMatrixTemplate
    }
}

const RiskAssessmentTemplateComponent = ({ dashboardSettings, riskMatrixTemplate }) => {
    const [templateType, setTemplateType] = useState(riskMatrixTemplate?.value)
    const isChanged = templateType !== riskMatrixTemplate?.value

    const riskAssessmentActivityTemplateTypes = getRiskAssessmentActivityTemplateTypes(dashboardSettings)

    const changeTemplate = (val) => {
        setTemplateType(val)
    }

    const saveTemplate = async () => {
        const updatedRiskMatrixTemplate = { ...riskMatrixTemplate, value: templateType }
        const updatedDashboardSettings = changeDashboardSettingByTemplate(dashboardSettings, templateType)
        const settingsUpdated = await SettingsApi.updateSetting([updatedRiskMatrixTemplate, ...updatedDashboardSettings])
        if (!settingsUpdated || settingsUpdated.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: settingsUpdated.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_DASHBOARD_SETTINGS, helpers.createNewInstance(updatedDashboardSettings)))
        store.dispatch(action(UPDATE_RISK_MATRIX_SETTINGS, helpers.createNewInstance(updatedRiskMatrixTemplate)))
    }

    useEffect(() => {
        if (riskMatrixTemplate?.value && riskMatrixTemplate.value !== templateType) setTemplateType(riskMatrixTemplate.value)
    }, [riskMatrixTemplate])

    return (
        <section className='risk-assessment-template'>
            <h3 id='risk-assessment-template'>{RISK_MATRIX} {TEMPLATE}</h3>
            <RadioGroup 
                title='' 
                radioGroupId='risk-matrix-template-type'
                checkedId={templateType}
                updateItem={changeTemplate}
                allItems={riskAssessmentActivityTemplateTypes} />
            <button className='btn-submit' id='save-dashboard-styles' onClick={saveTemplate} disabled={!isChanged}>Save</button>
        </section>
    )
}


const RiskAssessmentTemplate = connect(mapStateToProps)(RiskAssessmentTemplateComponent)

export default RiskAssessmentTemplate
