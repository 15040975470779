import { phishingSimulationImplementationValues } from 'js/constants/metrics/phishing-simulation-metrics'
import { getMetricByProps } from './metrics'
const { CRITICAL, HIGH, MEDIUM, TOTAL } = require('js/constants/metrics/common-metrics')

const severityColumnsImportant = [CRITICAL, HIGH, MEDIUM]
const severityValuesImportant = severityColumnsImportant.map(c => phishingSimulationImplementationValues.find(v => v.name === c))

const getPhishingSimulationHomeDashboardValues = (metricsData) => {
    const bySeverity = getMetricByProps(metricsData, TOTAL, TOTAL)
    if (!bySeverity) return []
    const values = severityValuesImportant.map(v => ({ 
        label: v.name,
        value: (bySeverity[v.id] || 0) * 1,
        status: v.status
    }))
    return values
}

export {
    getPhishingSimulationHomeDashboardValues
}