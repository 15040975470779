import { CRITICAL, CRITICAL_PROP, HIGH, HIGH_PROP, INFOMATIONAL, INFOMATIONAL_PROP, LOW, LOW_PROP, MEDIUM, MEDIUM_PROP, MEDIUM_WARNING, NEUTRAL, OTHER, OTHER_PROP, PRIMARY, WARNING } from './common-metrics'

const ransomwareResilienceImplementationValues = [{
    id: OTHER_PROP,
    name: OTHER,
    fullName: OTHER,
    status: NEUTRAL
}, {
    id: INFOMATIONAL_PROP,
    name: INFOMATIONAL,
    fullName: INFOMATIONAL,
    status: LOW_PROP
}, {
    id: LOW_PROP,
    name: LOW,
    fullName: LOW,
    status: PRIMARY
}, {
    id: MEDIUM_PROP,
    name: MEDIUM,
    fullName: MEDIUM,
    status: MEDIUM_WARNING
}, {
    id: HIGH_PROP,
    name: HIGH,
    fullName: HIGH,
    status: WARNING
}, {
    id: CRITICAL_PROP,
    name: CRITICAL,
    fullName: CRITICAL,
    status: CRITICAL_PROP
}]

const ransomwareResilienceValueProps = ransomwareResilienceImplementationValues.map(v => v.id)

export {
    ransomwareResilienceImplementationValues,
    ransomwareResilienceValueProps
}