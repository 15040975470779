import React, { useState } from 'react'
import { EnterCode } from 'hub-web-lib'

export const Otp = ({ submitOtp = () => { }, errorMsg = '' }) => {
    const [otp, setOtp] = useState('')
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const onSubmit = async () => {
        setIsLoadingButton(true)
        await submitOtp(otp)
        setIsLoadingButton(false)
    }

    return (
        <>
            <div className='login-header'>
                <h2 id='enterOtp'>Enter OTP</h2>
            </div>
            <EnterCode
                enterCodeId='otp'
                title='Please enter your one-time password'
                digitsCount={6}
                updateValue={setOtp} />
            <p className='error-msg' id='login-error'>{errorMsg}</p>
            <button onClick={onSubmit} className={`btn-submit ${isLoadingButton ? 'loader' : ''}`} disabled={otp.length !== 6} id='sendOtp'>Verify & Login</button>
        </>
    )
}

export default Otp