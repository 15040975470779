import { helpers } from 'hub-web-lib'
import { defaultTemplateDomains, questionnaireAnswerTypes } from 'js/constants/enums/questionnaire'
import { questionnaireDomainModel, questionnaireTemplateModel, questionnaireCategoryModel, questionnaireQuestionModel } from 'js/constants/models/questionnaire-template'
import { CATEGORY, QUESTION } from 'js/constants/vocabulary'

const categoryNameDefault = `Untitled ${CATEGORY}`
const questionTextDefault = `Untitled ${QUESTION}`
const questionTextDefaultFirst = 'Here is my first question?'

const questionnaireHierarhy = {
    domains: {
        parentType: 'templates',
        parentIdProp: 'template_id',
        children: 'categories'
    },
    categories: {
        parentType: 'domains',
        parentIdProp: 'domain_id',
        children: 'questions'
    },
    questions: {
        parentType: 'categories',
        parentIdProp: 'category_id',
        children: 'possible_answers'
    },
    possible_answers: {
        parentType: 'questions',
        parentIdProp: 'question_id',
        children: ''
    },
}

const createEmptyQuestion = (maxQuesId) => {
    const emptyQuestion = {
        ...helpers.createNewInstance(questionnaireQuestionModel),
        id: `${maxQuesId + 1}`,
        text: maxQuesId ? `${questionTextDefault} ${maxQuesId + 1}` : questionTextDefaultFirst,
        answer_type_id: 4,
        isNew: true,
        possible_answers: []
    }
    return emptyQuestion
}

const createNewCategory = (maxCatId, maxQuesId) => {
    const emptyCategory = {
        ...helpers.createNewInstance(questionnaireCategoryModel),
        id: `${maxCatId + 1}`,
        name: `${categoryNameDefault} ${maxCatId + 1}`,
        isNew: true,
        questions: maxQuesId ? [] : [createEmptyQuestion(maxQuesId)]
    }
    return emptyCategory
}

const createDuplicatedCategory = (category, copiesCount, maxCatId, maxQuesId) => {
    const newId = `${maxCatId + 1}`
    const newCategory = {
        ...helpers.createNewInstance(category),
        id: newId,
        name: `${category.name} (${copiesCount.length})`,
        isNew: true,
        questions: category.questions.map((question, i) => ({
            ...helpers.createNewInstance(question),
            id: `${maxQuesId + i + 1}`
        }))
    }
    return newCategory
}

const createDuplicatedQuestion = (question, copiesCount, maxQuesId) => {
    const newQuestion = {
        ...helpers.createNewInstance(question),
        id: `${maxQuesId + 1}`,
        text: `${question.text} (${copiesCount.length})`,
        isNew: true
    }
    return newQuestion
}

const getListFromCategories = (cats, domainId) => {
    if (!cats || !cats.length) return []
    const updatedCategories = cats
        .sort((a, b) => a.order - b.order)
        .map(c => ({
            ...c,
            id: `${c.id}`,
            domain_id: domainId,
            questions: (c.questions || [])
                .sort((a, b) => a.order - b.order)
                .map(q => ({
                    ...q,
                    id: `${q.id}`,
                    possible_answers: q.possible_answers?.length ? (q.possible_answers || []).sort((a, b) => a.order - b.order) : q.possible_answers
                }))
        }))
    return updatedCategories
}

const getUnansweredCategory = (categories, requiredOnly) => {
    return categories.find(c => getUnansweredQuestion(c, requiredOnly))
}

const getUnansweredQuestion = (category, requiredOnly) => {
    return (category?.questions || []).find(q => !q.isAnswered && (requiredOnly ? q.is_required : true))
}

const getUnansweredQuestions = (category, requiredOnly) => {
    return (category?.questions || []).filter(q => !q.isAnswered && (requiredOnly ? q.is_required : true))
}

const getFirstUnansweredQuestion = (categories, requiredOnly, categoryId) => {
    const category = categoryId ? categories.find(c => c.id === categoryId) : getUnansweredCategory(categories, requiredOnly)
    const question = getUnansweredQuestion(category, requiredOnly)
    return [category?.id, question?.id].filter(id => id)
}

const getUpdatedAnswers = (answers, answer, questionId) => {
    if (!questionId) return answers
    let updatedAnswers = [...answers]
    const answerIndex = answers.findIndex(a => a.question_id === questionId)
    if (answerIndex !== -1) {
        updatedAnswers[answerIndex].answer = answer
    } else {
        updatedAnswers.push({ question_id: questionId, answer })
    }
    return updatedAnswers
}

const isAnsweredQuestion = (answers, questionId) => {
    const answer = answers.find(a => a.question_id === questionId)?.answer
    const isAnswered = helpers.isNumber(answer) ||
        (Array.isArray(answer) && answer.length) ||
        typeof answer === 'string' && answer
    return { answer, isAnswered: !!isAnswered }
}

const mapDomainCategories = (answers, domain) => {
    return domain.categories
        .sort((a, b) => a.order - b.order)
        .filter(c => c.questions?.length)
        .map(c => ({
            ...c,
            questions: c.questions
                .sort((a, b) => a.order - b.order)
                .map(q => ({
                    ...q,
                    ...isAnsweredQuestion(answers, q.id)
                }))
        }))
}

const listAllQuestions = (domains) => {
    return (domains || []).map(d => (d.categories || [])).flat()
        .map(c => (c.questions || [])).flat()
        .map(q => ({ id: q.id, isAnswered: q.isAnswered, is_required: q.is_required, answer_type_id: q.answer_type_id }))
}

const getAssessmentAnswers = (assessment, domains) => {
    const answers = listAllQuestions(domains)
    answers.forEach(question => {
        question.question_id = question.id
        const result = assessment.assessment_results.find(a => a.question_id === question.id)
        question.id = result?.id || ''
        question.is_hidden = result?.is_hidden
        question.answer = result?.answer || null
    })

    return answers.filter(a => !a.is_hidden)
}

const getFilteredQuestionnaire = (questionnaire) => {
    questionnaire?.domains.forEach(d => {
        d.categories.forEach(c => {
            c.questions = c.questions.filter(q => !q.is_hidden)
        })
    })
    return questionnaire
}

const refactorAssessmentAnswersToResults = (answers, domains) => {
    const allQuestions = listAllQuestions(domains)
    const refactoredAnswers = helpers.createNewInstance(answers).map(answer => {
        const question = allQuestions.find(q => q.id === answer.question_id)
        const propName = questionnaireAnswerTypes.find(t => t.id === question?.answer_type_id)?.propName
        const isAnswered = question?.answer_type_id === 1 ? !!(answer.answer?.length || false) : !!(answer.answer)
        const result = {
            id: answer.id,
            question_id: answer.question_id,
            is_answered: isAnswered,
        }
        if (isAnswered) result[propName] = answer.answer
        return result
    })
    return refactoredAnswers
}

const getCleanQuestionnaire = (questionnaire) => {
    const cleanQuestionnaire = helpers.createNewInstance(questionnaire)
    const domains = cleanQuestionnaire.domains || []
    domains.forEach(domain => {
        (domain.categories || []).forEach(category => {

            if (category.isNew) {
                delete category.id
                delete category.isNew
            }

            (category.questions || []).forEach(question => {
                if (question.isNew) {
                    delete question.id
                    delete question.isNew
                }

                const selectedType = questionnaireAnswerTypes.find(t => t.id === question.answer_type_id)
                if (!selectedType?.hasOptions) question.possible_answers = []
                const answers = question.possible_answers || []
                answers.forEach(answer => {
                    answer.score = helpers.isNumber(answer.score) ? (answer.score || 0) * 1 : null
                })

            })
        })
    })
    return cleanQuestionnaire
}

const getCleanQuestionnaireTemplate = (questionnaire) => {
    const template = helpers.createNewInstance(questionnaire)
    delete template.domains
    return template
}

const getCleanQuestionnaireItems = (questionnaireItems, itemsType, parentId) => {
    const {
        children,
        parentIdProp
    } = questionnaireHierarhy[itemsType]
    const items = helpers.createNewInstance(questionnaireItems || []).map(item => {
        if (parentId) item[parentIdProp] = parentId
        // delete nested items
        if (children) delete item[children]

        // set score for questions and possible answers
        if ('score' in item) {
            item.score = helpers.isNumber(item.score) ? (item.score || 0) * 1 : null
            if (item.score > 100) item.score = 100
        }

        return item
    })
    return items
}

const getCUDQuestionnaireItems = (items, originalItems) => {
    items = (items || []).map(item => {
        if (item.isNew) {
            delete item.id
            delete item.isNew
        }
        return item
    })
    const existingOriginalItems = (originalItems || []).filter(items => items.id && !items.isNew)
    const created = items.filter(d => !d.id)
    const updated = items.filter(d => d.id).filter(item => {
        const originalItem = existingOriginalItems.find(i => i.id === item.id)
        return !helpers.isSameObject(item, originalItem)
    })

    const deletedIds = existingOriginalItems.filter(item => !items.find(d => d.id === item.id) && !updated.map(i => i.id).includes(item.id)).map(item => item.id)

    return { created, updated, deletedIds }
}

const getQuestionnaireItemsAndActions = (items, originalItems, itemsType, parentId) => {
    const cleanItems = getCleanQuestionnaireItems(helpers.createNewInstance(items), itemsType, parentId)
    const cleanOriginalItems = getCleanQuestionnaireItems(helpers.createNewInstance(originalItems), itemsType, parentId)
    const { created, updated, deletedIds } = getCUDQuestionnaireItems(cleanItems, cleanOriginalItems)
    return {
        create: created,
        update: updated,
        delete: deletedIds
    }
}

const getQuestionnaireItemsAndActionsFromList = (
    flatCurrentItems,
    flatOriginalItems,
    itemsType,
    createdParents
) => {
    const { parentType, parentIdProp } = questionnaireHierarhy[itemsType]
    const flatCurrentItemsWithIds = helpers.createNewInstance(flatCurrentItems[itemsType]).map(item => {
        const parentsIdsArray = item.ui_id.split('-')
        parentsIdsArray.pop()
        const parentUiId = parentsIdsArray.join('-')
        const parent = flatCurrentItems[parentType].find(p => p.ui_id === parentUiId)
        const parentId = parent.isNew ? createdParents.find(p => p.ui_id === parentUiId)?.id : parent.id
        return {
            ...item,
            [parentIdProp]: parentId
        }

    })
    const itemsAndActions = getQuestionnaireItemsAndActions(flatCurrentItemsWithIds, flatOriginalItems[itemsType], itemsType)
    return itemsAndActions
}

const getEmptyQuestionnaire = (userId, currentOrgId) => {
    return {
        ...questionnaireTemplateModel,
        org_id: currentOrgId,
        created_by: userId,
        domains: defaultTemplateDomains.map(domainName => ({
            ...questionnaireDomainModel,
            name: domainName,
            categories: []
        }))
    }
}

const getMissedQuestionnaireDomains = (questionnaire) => {
    const domains = Array.isArray(questionnaire.domains) ? questionnaire.domains : []
    defaultTemplateDomains.forEach(domainName => {
        if (!domains.find(d => d.name === domainName)) { domains.push({ 
            ...questionnaireDomainModel, 
            name: domainName, 
            categories: [] 
        }) }
    })
    return domains
}

const getItemOrderAndUiId = (item, index, parentId, noOrder) => {
    const updatedItem = {
        ...item,
        ui_id: parentId ? `${parentId}-${item.id || index}` : `${item.id}`
    }
    if (!noOrder) updatedItem.order = index + 1
    return updatedItem
}

const getFlatItemsList = (questionnaire, noOrder) => {
    const domains = (questionnaire?.domains || []).map((d, i) => (getItemOrderAndUiId(d, i)))

    const categories = domains.map(d => d.categories.map((c, i) => ({
        ...getItemOrderAndUiId(c, i, d.id, noOrder),
        domain_id: d.id
    }))).flat()

    const questions = (categories || []).map(c => c.questions.map((q, i) => ({
        ...getItemOrderAndUiId(q, i, c.ui_id, noOrder),
        category_id: c.isNew ? null : c.id,
        possible_answers: q.answer_type_id < 4 ? (q.possible_answers || []) : []
    }))).flat()
    const possible_answers = (questions || []).map(q => q.possible_answers.map((a, i) => ({
        ...getItemOrderAndUiId(a, i, q.ui_id, noOrder),
        question_id: q.isNew ? null : q.id,
    }))).flat()

    return { domains, categories, questions, possible_answers }
}

const refactorCategories = (categories) => {
    return helpers.createNewInstance(categories).map(c => ({
        ...c,
        id: c.id * 1,
        questions: c.questions.map(q => ({
            ...q,
            id: q.id * 1,
            possible_answers: q.possible_answers.map(a => ({
                ...a,
                id: a.id * 1,
            }))
        }))
    }))
}

const orderQuestionnaireItems = (questionnaire) => {
    return {
        ...questionnaire,
        domains: (questionnaire.domains || []).map(domain => ({
            ...domain,
            categories: (domain.categories || []).sort((a, b) => a.order - b.order).map(category => ({
                ...category,
                questions: (category.questions || []).sort((a, b) => a.order - b.order).map(question => ({
                    ...question,
                    possible_answers: question.possible_answers?.length ? (question.possible_answers || []).sort((a, b) => a.order - b.order) : question.possible_answers
                }))
            }))
        }))
    }
}

export {
    categoryNameDefault,
    questionTextDefault,
    questionTextDefaultFirst,
    createEmptyQuestion,
    createNewCategory,
    createDuplicatedCategory,
    createDuplicatedQuestion,
    getListFromCategories,
    getUnansweredCategory,
    getUnansweredQuestion,
    getUnansweredQuestions,
    getFirstUnansweredQuestion,
    getUpdatedAnswers,
    isAnsweredQuestion,
    mapDomainCategories,
    listAllQuestions,
    getAssessmentAnswers,
    refactorAssessmentAnswersToResults,
    getCleanQuestionnaire,
    getEmptyQuestionnaire,
    getMissedQuestionnaireDomains,
    getCleanQuestionnaireTemplate,
    getCleanQuestionnaireItems,
    getCUDQuestionnaireItems,
    getQuestionnaireItemsAndActions,
    getQuestionnaireItemsAndActionsFromList,
    getFlatItemsList,
    refactorCategories,
    orderQuestionnaireItems,
    getFilteredQuestionnaire
}