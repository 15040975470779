import React, { useEffect, useState } from 'react'
import 'components/Modules/Questionnaire/Assessment/Widgets/AssessmentDomain.scss'
import TemplateCategories from './TemplateCategories'
import TemplateQuestions from './TemplateQuestions'
import { Popup, genericTexts, helpers } from 'hub-web-lib'
import { createDuplicatedCategory, createDuplicatedQuestion, createEmptyQuestion, createNewCategory, getListFromCategories, refactorCategories } from 'js/ui-helpers/questionnaire'
import { CATEGORY, QUESTION } from 'js/constants/vocabulary'

const actions = {
    [genericTexts.actionsText.edit]: { text: 'Rename', order: 1 },
    [genericTexts.actionsText.duplicate]: { text: genericTexts.actionsText.duplicateText(''), order: 2 },
    [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(''), order: 3 }
}

const questionActions = {
    [genericTexts.actionsText.edit]: { text: 'Rename', order: 1 },
    [genericTexts.actionsText.duplicate]: { text: genericTexts.actionsText.duplicateText(''), order: 2 },
    [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(''), order: 3 }
}
function TemplateDomain({ data = {} }) {

    const {
        domain = {},
        isShown,
        updateDomainCategories = () => {},
        isApproved
    } = data

    const [categories, setCategories] = useState(getListFromCategories(domain.categories, domain.id))
    const [activeCategoryId, setActiveCategoryId] = useState(categories?.length ? categories[0].id : '')
    const [activeQuestionId, setActiveQuestionId] = useState(categories?.length && categories[0].questions?.length ? categories[0].questions[0].id : '')
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const [itemToDelete, setItemToDelete] = useState({})
    const maxCategoryId = categories.length ? Math.max(...categories.map(c => c.id * 1)) : 0
    const allQuestions = categories.map(c => c.questions).flat()
    const maxQuestionId = allQuestions.length ? Math.max(...allQuestions.map(question => question.id * 1)) : 0
    
    const updateCategories = (updatedCategories) => {
        setCategories(updatedCategories)
    }

    const addCategory = () => {
        const newCategory = createNewCategory(maxCategoryId, maxQuestionId)
        const updatedCategories = [ ...categories, newCategory]
        setCategories(updatedCategories)
        setActiveCategoryId(updatedCategories[updatedCategories.length - 1].id)
        setActiveQuestionId(newCategory.questions?.length ? newCategory.questions[0].id : '')
    }

    const duplicateCategory = (categoryId) => {
        const category = categories.find(c => c.id === categoryId)
        const copiesCount = categories.filter(c => c.name.includes(category.name))
        const newCategory = createDuplicatedCategory(category, copiesCount, maxCategoryId, maxQuestionId)
        const updatedCategories = [ ...categories, newCategory]
        setCategories(updatedCategories)
        setActiveCategoryId(newCategory.id)
        setActiveQuestionId(newCategory.questions?.length ? newCategory.questions[0].id : '')
    }

    const duplicateQuestion = (categoryId, questionId) => {
        const category = categories.find(c => c.id === categoryId)
        const categoryIndex = categories.indexOf(category)
        const updatedCategories = [...categories]
        const question = category.questions.find(q => q.id === questionId)
        const copiesCount = category.questions.filter(c => c.text.includes(question.text))
        const newQuestion = createDuplicatedQuestion(question, copiesCount, maxQuestionId)
        updatedCategories[categoryIndex].questions = [...category.questions, newQuestion]
        setCategories(updatedCategories)
        setActiveQuestionId(newQuestion.id || '')
    }

    const deleteCategory = (categoryId) => {
        const updatedCategories = [...categories].filter(c => c.id !== categoryId)
        setCategories(updatedCategories)
        setActiveCategoryId(categories[0]?.id || '')
        setActiveQuestionId(categories[0]?.questions?.length ? categories[0].questions[0].id : '')
    }

    const deleteQuestion = (categoryId, questionId) => {
        const category = categories.find(c => c.id === categoryId)
        const categoryIndex = categories.indexOf(category)
        const updatedCategories = [...categories]
        const categoryQuestions = updatedCategories[categoryIndex].questions
        const questionIndex = categoryQuestions.findIndex(q => q.id === questionId)
        const newActiveQuestionId = questionIndex > 0 ? categoryQuestions[questionIndex - 1].id : (categoryQuestions[questionIndex + 1]?.id || '')
        updatedCategories[categoryIndex].questions = categoryQuestions.filter(q => q.id !== questionId)
        setCategories(updatedCategories)
        setActiveCategoryId(categoryId)
        setActiveQuestionId(newActiveQuestionId)
    }

    const updateCategoryName = (newName, categoryId) => {
        const categoryIndex = categories.findIndex(c => c.id === categoryId)
        const updatedCategories = [...categories]
        updatedCategories[categoryIndex].name = newName
        setCategories(updatedCategories)
    }

    const updateCategoryDescription = (newDescription, categoryId) => {
        const categoryIndex = categories.findIndex(c => c.id === categoryId)
        const updatedCategories = [...categories]
        updatedCategories[categoryIndex].description = newDescription
        setCategories(updatedCategories)
    }

    const updateQuestionProp = (newProp, categoryId, questionId, prop) => {
        const category = categories.find(c => c.id === categoryId)
        const categoryIndex = categories.indexOf(category)
        const questionIndex = category.questions.findIndex(q => q.id === questionId)
        const updatedCategories = [...categories]
        updatedCategories[categoryIndex].questions[questionIndex][prop] = newProp
        setCategories(updatedCategories)
    }

    const updateQuestionPossibleAnswerProp = (newProp, categoryId, questionId, possibleAnswerIndex, prop) => {
        const category = categories.find(c => c.id === categoryId)
        const categoryIndex = categories.indexOf(category)
        const questionIndex = category.questions.findIndex(q => q.id === questionId)
        const updatedCategories = [...categories]
        updatedCategories[categoryIndex].questions[questionIndex].possible_answers[possibleAnswerIndex][prop] = newProp
        setCategories(updatedCategories)
    }

    const addQuestion = (categoryId) => {
        const category = categories.find(c => c.id === categoryId)
        const categoryIndex = categories.indexOf(category)
        const updatedCategories = [...categories]
        updatedCategories[categoryIndex] = { 
            ...category,
            questions: [
                ...category.questions,
                createEmptyQuestion(maxQuestionId)
            ]
        }
        setCategories(updatedCategories)
        setActiveCategoryId(categoryId)
        setActiveQuestionId(maxQuestionId + 1)
    }

    const deleteQuestionHandler = (categoryId, questionId) => {
        setItemToDelete({
            categoryId,
            questionId
        })
        setIsOpenDeletePopup(true)
    }

    const categoryMenuClickHandler = (action, categoryId, questionId) => {
        switch (action) {   
        case genericTexts.actionsText.duplicate:
            questionId ? duplicateQuestion(categoryId, questionId) : duplicateCategory(categoryId)
            break
        case genericTexts.actionsText.delete:
            deleteQuestionHandler(categoryId, questionId)
            break
        }
    }

    const updateActiveCategory = (categoryId) => {
        setActiveCategoryId(categoryId)
        const category = categories.find(c => c.id === categoryId)
        const questionId = category?.questions?.length ? category.questions[0].id : ''
        setActiveQuestionId(questionId)
    }

    const updateActiveQuestion = (questionId) => {
        setActiveQuestionId(questionId)
        const categoryId = categories.find(c => (c.questions || []).find(q => q.id === questionId))?.id || ''
        setActiveCategoryId(categoryId)
    }

    const closeDeletePopup = () => {
        setIsOpenDeletePopup(false)
        setItemToDelete({})
    }

    const deleteItem = () => {
        itemToDelete.questionId ? deleteQuestion(itemToDelete.categoryId, itemToDelete.questionId) : deleteCategory(itemToDelete.categoryId)
        closeDeletePopup()
    }

    useEffect(() => {
        const refactoredCategories = refactorCategories(categories)
        updateDomainCategories(refactoredCategories, domain.id)
    }, [categories])

    useEffect(() => {
        const categoriesList = getListFromCategories(domain.categories, domain.id)
        if (!helpers.isSameObject(categoriesList, categories)) {
            setCategories(categoriesList)
            if (categoriesList.length && (!activeCategoryId || !categoriesList.map(c => c.id).includes(activeCategoryId))) {
                setActiveCategoryId(categoriesList[0].id)
                setActiveQuestionId(categoriesList[0].questions?.length ? categoriesList[0].questions[0]?.id : '')
            }
        }
    }, [domain.categories])

    const categoriesData = {
        domainId: domain.id,
        categories,
        isApproved,
        actions,
        questionActions,
        updateCategories,
        addCategory,
        updateCategoryName,
        updateCategoryDescription,
        updateQuestionProp,
        updateQuestionPossibleAnswerProp,
        addQuestion,
        categoryMenuClickHandler,
        updateActiveCategory,
        updateActiveQuestion,
        activeCategoryId,
        activeQuestionId,
        deleteQuestion,
        duplicateQuestion
    }
    
    return (<div className={`questionnaire-domain ${isShown ? 'show' : ''}`}>
        <div className='categories-questions'>
            <TemplateCategories data={categoriesData} />
            <TemplateQuestions data={{ ...categoriesData, deleteQuestion: deleteQuestionHandler, isShown }} />
        </div>

        <Popup
            onClose={closeDeletePopup}
            isOpen={isOpenDeletePopup}
            title={genericTexts.defaultText.deletePopupTitle}>
            <p>{genericTexts.defaultText.deletePopupText(itemToDelete.questionId ? QUESTION : CATEGORY)}</p>
            <div className='popup-buttons'>
                <button className='btn-delete' onClick={deleteItem} id='delete-template-item'><i className='icon-delete'></i>{genericTexts.defaultText.delete}</button>
            </div>
        </Popup>
    </div>)
}

export default TemplateDomain