import { CATEGORY, WIDGET, KEY, FULLY_PROP, PARTIALLY_PROP, NOT_PROP, IS_EDITABLE } from 'js/constants/metrics/common-metrics'
import { activityStatusesEnum } from 'js/constants/enums/activity'

const qaActivityModel = {
    id: 0,
    org_id: 0,
    title: '',
    start_date: '',
    end_date: '',
    status: activityStatusesEnum.started,
    description: '',
    attachment_url: '',
    external_reporter: '',
    reported_by: 0,
    archived: false,
    starred: false,
    auto_complete: false,

}

const qaMetricsModel = {
    [CATEGORY]: '',
    [WIDGET]: '',
    [KEY]: '',
    [FULLY_PROP]: '',
    [PARTIALLY_PROP]: '',
    [NOT_PROP]: '',
    [IS_EDITABLE]: true
}

export {
    qaActivityModel,
    qaMetricsModel
}

