import TemplateClient from 'components/Modules/Questionnaire/Template/Pages/TemplateClient'
import { Popup, genericTexts, helpers } from 'hub-web-lib'
import React, { useEffect, useState } from 'react'
import QuestionnaireApi from 'services/QuestionnaireApi'
import TemplateGeneralInfo from 'components/Modules/Questionnaire/Template/Widgets/TemplateGeneralInfo'
import store from 'js/store'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import { QUESTIONNAIRE } from 'js/constants/vocabulary'
import { getCleanQuestionnaireTemplate, createNewCategory, getQuestionnaireItemsAndActions, getFlatItemsList, getQuestionnaireItemsAndActionsFromList, refactorCategories, orderQuestionnaireItems } from 'js/ui-helpers/questionnaire'
import { questionnaireTemplateModel } from 'js/constants/models/questionnaire-template'

const fieldsErrorMsgDefault = helpers.createNewInstance(questionnaireTemplateModel)


const UpdateTemplate = ({
    data = {}
}) => {
    const {
        questionnaire = {},
        isOpenInfoPopup,
        setIsImportState = () => {},
        closeEditQuestionnaire = () => {},
        updateQuestionnaireTemplates = () => {},
        setIsOpenInfoPopup = () => {},
        currentOrgId,
        currentUser,
        orgUsers = [],
        isNewQuestionnaire
    } = data

    const [isDomainsPopupOpen, setIsDomainsPopupOpen] = useState(false)
    const [originalQuestionnaire, setOriginalQuestionnaire]  = useState({})
    const [editableQuestionnaire, setEditableQuestionnaire]  = useState({})
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState({})
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false)
    const [isProgressButtonLoading, setIsProgressButtonLoading] = useState(false)
    const [isOpenExitPopup, setIsOpenExitPopup] = useState(false)
    const [errorMsgs, setErrorMsgs] = useState([])
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState(fieldsErrorMsgDefault)
    const [domainsError, setDomainsError] = useState('')
    const [isChanged, setIsChanged] = useState(false)
    const [isInfoOpenedFromClient, setIsInfoOpenedFromClient] = useState(false)

    const flatCurrentItems = getFlatItemsList(currentQuestionnaire)
    const flatOriginalItems = getFlatItemsList(originalQuestionnaire, true)


    const closeInfoPopup = () => {
        setDomainsError('')
        setErrorMsgs([])
        setFieldsErrorMsg(fieldsErrorMsgDefault)
        setIsOpenInfoPopup(false)
    }

    const closeDomainsPopup = () => {
        if (isProgressButtonLoading) return
        isChanged && !currentQuestionnaire.approved ? setIsOpenExitPopup(true) : closeEditTemplate()
    }

    const closeEditTemplate = () => {
        setIsDomainsPopupOpen(false)
        closeEditQuestionnaire()
        updateQuestionnaireTemplates()
    }

    const validateQuestionnaireTemplateGeneratlInfo = () => {
        const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
        let domainErrors = ''
        let errors = false
        if (!currentQuestionnaire.name) {
            errorDetails.name = 'This field is required'
            errors = true
        }
        if (!currentQuestionnaire.approved_by) {
            errorDetails.approved_by = 'This field is required'
            errors = true
        }
        if (!currentQuestionnaire.domains?.length) {
            domainErrors = 'At least one domain should be specified'
            errors = true
        }
        if (currentQuestionnaire.domains.find(d => !d.name)) {
            domainErrors = 'Each domain should have a name'
            errors = true
        }
        setFieldsErrorMsg(errorDetails)
        setDomainsError(domainErrors)
        return errors
    }

    const updateQuestionnaireTemplate = async () => {
        setErrorMsgs([])
        setDomainsError('')
        const questionnaireTemplate = getCleanQuestionnaireTemplate(currentQuestionnaire)
        const questionnaireOriginalTemplate = getCleanQuestionnaireTemplate(originalQuestionnaire)
        const isChanged = !currentQuestionnaire.id || !helpers.isSameObject(questionnaireTemplate, questionnaireOriginalTemplate)

        const updatedQuestionnaire = !currentQuestionnaire.id ? 
            await QuestionnaireApi.createQuestionnaireTemplate(questionnaireTemplate) : 
            (isChanged ? await QuestionnaireApi.updateQuestionnaireTemplate(questionnaireTemplate) : true)

        if (!updatedQuestionnaire || updatedQuestionnaire.error) {
            if (Array.isArray(updatedQuestionnaire?.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                updatedQuestionnaire.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                setErrorMsgs([updatedQuestionnaire.message] || [updatedQuestionnaire.error])
            }
            setIsSaveButtonLoading(false)
            return null
        }
        
        return currentQuestionnaire.id || updatedQuestionnaire.id
    }

    const updateQuestionnaireDomains = async (questionnaireId) => {
        const itemsAndActions = getQuestionnaireItemsAndActions(flatCurrentItems.domains, flatOriginalItems.domains, 'domains', questionnaireId)
        let errors = []

        for await (const action of Object.keys(itemsAndActions)) {
            if (itemsAndActions[action].length) {
                const updatedItems = await QuestionnaireApi[`${action}QuestionnaireItems`](itemsAndActions[action], 'domains')
                if (!updatedItems || updatedItems.error) errors.push(updatedItems.error)
            }
        }

        if (errors.length) {
            setErrorMsgs(errors)
            setDomainsError('')
            setIsSaveButtonLoading(false)
            return false
        }

        return true
    }

    const saveQuestionnaireItems = async (itemsAndActions, itemsType) => {
        let error
        let createdItems = []
        let itemsToDelete = itemsAndActions.delete

        for await (const action of Object.keys(itemsAndActions)) {
            const items = itemsAndActions[action]
            if (items.length && action !== 'delete') {
                const updatedItems = await QuestionnaireApi[`${action}QuestionnaireItems`](items, itemsType)
                if (!updatedItems || updatedItems.error) {
                    error = true
                } else if (action === 'create') {
                    createdItems = updatedItems
                }
            }
        }
        return { createdItems, error, itemsToDelete }
    }

    const deleteNestedItems = async (categoriesToDelete, questionToDelete, possibleAnswersToDelete) => {
        let error
        const itemsAll = {
            possible_answers: possibleAnswersToDelete,
            questions: questionToDelete,
            categories: categoriesToDelete
        }
        for await (const itemsType of Object.keys(itemsAll)) {
            const items = itemsAll[itemsType]
            if (items.length) {
                const deletedItems = await QuestionnaireApi.deleteQuestionnaireItems(items, itemsType)
                if (!deletedItems || deletedItems.error) {
                    error = true
                }
            }
        }
        return { error }
    }

    const updateQuestionnaireItems = async (itemsType, createdParents, createdGrandParents) => {
        const itemsAndActions = getQuestionnaireItemsAndActionsFromList(
            flatCurrentItems, 
            flatOriginalItems,
            itemsType,  
            createdParents,
            createdGrandParents
        )
        return await saveQuestionnaireItems(itemsAndActions, itemsType)
    }

    const setQuestionnaireTemplateFull = async (questionnaireId, addDefaultCategories) => {
        const fullQuestionnaire = await QuestionnaireApi.getQuestionnaireTemplateFull(questionnaireId, currentOrgId)
        if (!fullQuestionnaire || fullQuestionnaire.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: fullQuestionnaire.error,
                notificationType: 'error'
            }))
            return
        }
        const orderedFullQuestionnaire = orderQuestionnaireItems(fullQuestionnaire)
        if (addDefaultCategories && isNewQuestionnaire) {
            for (const domain of orderedFullQuestionnaire.domains) {
                if (!domain.categories || !domain.categories.length) domain.categories = [createNewCategory(0, 0, domain.id)]
            }
        }
        setOriginalQuestionnaire(helpers.createNewInstance(orderedFullQuestionnaire))
        setCurrentQuestionnaire(helpers.createNewInstance(orderedFullQuestionnaire))
        setEditableQuestionnaire(helpers.createNewInstance(orderedFullQuestionnaire))
    }

    const approveQuestionnaireTemplate = async () => {
        const questionnaireTemplate = getCleanQuestionnaireTemplate(currentQuestionnaire)
        const updatedQuestionnaire = await QuestionnaireApi.updateQuestionnaireTemplate({ ...questionnaireTemplate, approved: !currentQuestionnaire.approved }) 
        if (!updatedQuestionnaire || updatedQuestionnaire.error) return true 
        return false
    }

    const saveAndContinue = async () => {
        if (!currentQuestionnaire.approved) {

            setIsSaveButtonLoading(true)
            if (validateQuestionnaireTemplateGeneratlInfo()) {
                setIsSaveButtonLoading(false)
                return 
            }

            // edit questionnaire template
            const updatedQuestionnaireId = await updateQuestionnaireTemplate()
            if (!updatedQuestionnaireId) return
    
            // edit domains
            const updatedDomains = await updateQuestionnaireDomains(updatedQuestionnaireId)
            if (!updatedDomains) return
            if (helpers.isSameObject(flatCurrentItems, flatOriginalItems)) {
                await setQuestionnaireTemplateFull(updatedQuestionnaireId, true)
            } else {
                if (isNewQuestionnaire) await setQuestionnaireTemplateFull(updatedQuestionnaireId, true)
                await saveQuestionnaireCategoriesNested(false, false, true)
            }
    
            setIsSaveButtonLoading(false)
            updateQuestionnaireTemplates()
        }
        closeInfoPopup(false)
        setIsDomainsPopupOpen(true)
    }

    const saveQuestionnaireCategoriesNested = async (isSubmit, isClose, changed = isChanged) => {
        isSubmit ? setIsSaveButtonLoading(true) : setIsProgressButtonLoading(true)

        let generalError = false
        let approvalError = false

        // approve questionnaire
        if (currentUser.id === currentQuestionnaire.approved_by && isSubmit && !isClose) {
            approvalError = await approveQuestionnaireTemplate()
        } 

        if (changed) {

            // create/update categories
            const updatedCategories = await updateQuestionnaireItems('categories', [])
            if (updatedCategories.error) generalError = true
    
            // create/update questions
            const updatedQuestions = await updateQuestionnaireItems('questions', updatedCategories.createdItems)
            if (updatedQuestions.error) generalError = true
    
            // create/update possible answers
            const updatedPossibleAnswers = await updateQuestionnaireItems('possible_answers', updatedQuestions.createdItems, updatedCategories.createdItems)
            if (updatedPossibleAnswers.error) generalError = true

            // delete items
            const deletedNestedItems = await deleteNestedItems(updatedCategories.itemsToDelete, updatedQuestions.itemsToDelete, updatedPossibleAnswers.itemsToDelete)
            if (deletedNestedItems.error) generalError = true
    
            if (generalError || approvalError) {
                store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                    message: generalError ? 'Not all the data saved. Please try again' : 'Some issues with approval. Please try again',
                    notificationType: 'error'
                }))
                isSubmit ? setIsSaveButtonLoading(false) : setIsProgressButtonLoading(false)
                return
            }
        }

        if (isSubmit) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: genericTexts.defaultText.submittedSuccess(QUESTIONNAIRE)
            }))
            setIsSaveButtonLoading(false)
            setIsImportState(false)
            setIsOpenExitPopup(false)
            closeEditTemplate()

            await updateQuestionnaireTemplates()
        } else {
            if (originalQuestionnaire.id) await setQuestionnaireTemplateFull(originalQuestionnaire.id) 
            setIsProgressButtonLoading(false)
            setIsChanged(false)
        }
    }

    const updateTemplateDetails = (updatedQuestionnaire, key) => {
        setCurrentQuestionnaire(updatedQuestionnaire)
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
        if (key === 'domains' && updatedQuestionnaire?.domains?.length) setDomainsError('')
    }
    
    const updateDomainCategories = (categories, domainId) => {
        const updatedQuestionnaire = helpers.createNewInstance(currentQuestionnaire)
        const domainIndex = updatedQuestionnaire.domains.findIndex(d => d.id === domainId)
        if (domainIndex > -1) {
            if (!helpers.isSameObject(refactorCategories(updatedQuestionnaire.domains[domainIndex].categories), categories)) {
                setIsChanged(true)
                updatedQuestionnaire.domains[domainIndex].categories = categories
                setCurrentQuestionnaire(updatedQuestionnaire)
            }
        }
    }

    const questionnaireTitleClick = () => {
        setIsInfoOpenedFromClient(true)
        setIsOpenInfoPopup(true)
    }

    useEffect(() => {
        if (!helpers.isSameObject(questionnaire, currentQuestionnaire)) {
            setOriginalQuestionnaire(helpers.createNewInstance(questionnaire))
            setCurrentQuestionnaire(helpers.createNewInstance(questionnaire))
            setEditableQuestionnaire(helpers.createNewInstance(questionnaire))
        }
    }, [questionnaire])

    const infoData = {
        questionnaire: originalQuestionnaire,
        isNewQuestionnaire,
        isOpenInfoPopup,
        errorMsgs,
        domainsError,
        orgUsers,
        isSaveButtonLoading,
        fieldsErrorMsg,
        closeInfoPopup,
        saveAndContinue,
        updateTemplateDetails,
        isInfoOpenedFromClient
    }

    return (
        <>
            {isDomainsPopupOpen && <Popup
                isOpen={isDomainsPopupOpen}
                title=''
                onClose={closeDomainsPopup}
                isDisabledClickOutside={true}
                customClass='questionnaire-popup'>
                <TemplateClient
                    isButtonLoading={isSaveButtonLoading}
                    isProgressButtonLoading={isProgressButtonLoading}
                    questionnaire={editableQuestionnaire} 
                    isChanged={isChanged}
                    updateDomainCategories={updateDomainCategories} 
                    isApproved={editableQuestionnaire.approved || isProgressButtonLoading}
                    saveQuestionnaireCategoriesNested={saveQuestionnaireCategoriesNested}
                    currentUser={currentUser}
                    questionnaireTitleClick={questionnaireTitleClick} />
            </Popup>}
            {isOpenInfoPopup && <TemplateGeneralInfo data={infoData} />}

            <Popup
                onClose={() => setIsOpenExitPopup(false)}
                isOpen={isOpenExitPopup}
                title={`${QUESTIONNAIRE} is not saved`}
                customClass='large exit-confirmation'>
                <p>Do you want to save the changes you made to {currentQuestionnaire.name}?</p>
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={() => saveQuestionnaireCategoriesNested(true, true)} id='template-exit-save'>{genericTexts.defaultText.save}</button>
                    <button onClick={closeEditTemplate} className='btn-cancel' id='template-exit-dont-save'>Don&apos;t save</button>
                </div>
            </Popup>
        </>
    )
}

export default UpdateTemplate