const tenantDashboardOnboardingTourSteps = [{
    elementId: 'organization-score-chart',
    tooltipDetails: {
        title: 'Your Organization Score',
        description: 'This score represents our understanding of your overall posture based on the data you shared with us.',
        position: 'right'
    },
    goNextOnElementClick: true,
    nextStepAction: () => { return true }
}, {
    elementId: 'findings-values',
    tooltipDetails: {
        title: 'Findings Breakdown',
        description: 'This bar represents your cyber posture breakdowns according to your answers',
        position: 'top'
    },
    nextStepAction: () => { return true }
}, {
    elementId: 'score-by-domains-charts',
    tooltipDetails: {
        title: 'Domains Breakdown',
        description: 'These bars represents domain\'s score',
        position: 'left'
    },
    nextStepAction: () => { return true }
}, {
    elementId: 'security-controls-domains',
    tooltipDetails: {
        title: 'Domain\'s Detailed Breakdown',
        description: 'This section breakdown each domain and reflect insights according to your tailored assessment',
        position: 'top'
    },
    nextStepAction: () => { return true }
}]

const tenantDashboardOnboardingDetails = {
    currentStep: 1,
    totalSteps: 1,
    description: 'You completed the tour',
    buttonText: 'Finish Tour',
    upNextAction: () => {}
}

const tenantAssessmentOnboardingTourSteps = [{
    elementId: 'assessment-title',
    tooltipDetails: {
        title: 'Assessment General Info',
        description: 'Click here to display the Assessment detailed general info.',
        position: 'right'
    },
    goNextOnElementClick: true,
    nextStepAction: () => { return true }
}, {
    elementId: 'assessment-domains',
    tooltipDetails: {
        title: 'Domains Navigation',
        description: 'This panel indicate the current domain, navigate among domains by clicking selected domain.',
        position: 'bottom'
    },
    nextStepAction: () => { return true }
}, {
    elementId: 'assessment-categories',
    tooltipDetails: {
        title: 'Questions Navigation Tree',
        description: 'Here you can see all categories relevant to the current domain, and navigate among categories.',
        position: 'right'
    },
    nextStepAction: () => { return true }
}, {
    elementId: 'assessment-questions',
    tooltipDetails: {
        title: 'Questions Area',
        description: 'Here you can answer the questions and navigate among questions',
        position: 'left'
    },
    nextStepAction: () => { return true }
}, {
    elementId: 'questionnaire-answers-submit',
    tooltipDetails: {
        title: 'Submit',
        description: 'When you finish answering all required questions, the submit button will be active, Click on this button to submit the assessment, calculate your score and see your assessment reflected on the dashboard.',
        position: 'top'
    },
    nextStepAction: () => { return true }
}]

const tenantAssessmentOnboardingDetails = {
    currentStep: 1,
    totalSteps: 1,
    description: 'You completed the tour',
    buttonText: 'Finish Tour',
    upNextAction: () => {}
}

export {
    tenantDashboardOnboardingTourSteps,
    tenantDashboardOnboardingDetails,
    tenantAssessmentOnboardingTourSteps,
    tenantAssessmentOnboardingDetails
}