import React from 'react'
import './TemplateBlock.scss'
import moment from 'moment'
import { EllipsisText } from 'hub-web-lib'
const TemplateBlock = ({ template }) => {
    return (
        <div className='template-body'>
            <div className='template-widget'>
                <div className='template-block-head'>
                    <EllipsisText id={`template-name-${template.id}`} text={template.name} width={220} noBreak={true} />
                    <p className='template-description'>{template.description}</p>
                </div>

                <ul>
                    <li className='template-details'>
                        <p>Code</p>
                        <p>{template.code}</p>
                    </li>
                    <li className='template-details'>
                        <p>Version</p>
                        <p>{template.version}</p>
                    </li>
                    <li className='template-details'>
                        <p>Creation Date</p>
                        <p>{template.creation_date ? moment(template.creation_date).format('DD/MM/YY') : ''}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default TemplateBlock