import React from 'react'
import { WIDGET, KEY, CATEGORY, metricsTextProps, IS_EDITABLE, OTHER_PROP, otherValueProps, metricsColumnsText, metricsColumnsTextForm, metricsColumnsEdit, metricsColumnInfoEdit } from 'js/constants/metrics/common-metrics'
import { objectHasValuesForKeys } from 'middlewares/ApiHelpers'
import { InputRow, helpers } from 'hub-web-lib/dist/index-export'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { ALL_DIMENSIONS, ALL_MODULES, dashboardDimensions } from 'js/constants/dashboard-settings'
import moment from 'moment'

const getMetricsByProps = (metricsData, category, widget) => {
    if (!metricsData || !metricsData.length) return []
    return metricsData.filter(m => m[CATEGORY].trim().replace(/^\s+/, '') === category && (m[WIDGET].trim().replace(/^\s+/, '') === widget || !widget))
}

const getMetricByProps = (metricsData, category, widget, key) => {
    if (!metricsData || !metricsData.length) return null
    const metrics =  getMetricsByProps(metricsData, category, widget)
    const metric = key ? metrics.find(m => m[KEY] === key) : metrics[0]
    return metric || null
}

const getValueByProps = (metricsData, category, widget, key, value) => {
    const metric = getMetricByProps(metricsData, category, widget, key)
    return metric ? metric[value] || 0 : 0
}

const preparePredefinedMetrics = (metricsToEdit, metricsConsts, isWidgetInput) => {
    const { props, categories, widgets } = metricsConsts
    const metrics = [...metricsToEdit].sort((a, b) => a.id - b.id).map(metric => {
        [...metricsTextProps, ...props].forEach(key => {
            if (!metric[key]) metric[key] = ''
        })
        metricsTextProps.forEach(key => {
            metric[key] = metric[key].trim().replace(/^\s+/, '')
            metric[key] = metric[key].replace(/\u00A0/g, ' ')
        })
        if (!categories.includes(metric[CATEGORY])) metric[CATEGORY] = ''
        if (!isWidgetInput && !widgets.includes(metric[WIDGET])) metric[WIDGET] = ''
        return metric
    })
    return metrics
}

const replaceNullWithEmptyValue = (object) => {
    if (!object) return {}
    for (let key of Object.keys(object)) if (!object[key] && typeof object[key] !== 'boolean') object[key] = ''
    return object
}

const findDeletedMetrics = (providedMetrics, predefinedMetricsToEdit) => {
    const providedIds = (providedMetrics || []).map(metric => metric.id)
    const editedIds = (predefinedMetricsToEdit || []).map(metric => metric.id)
    const deletedIds = providedIds.filter(id => !editedIds.includes(id))
    return deletedIds
}

const getActivityError = (activity, activityRequiredFields = ['title', 'description']) => {
    let errorFields = {}
    activityRequiredFields.forEach(field => {
        if (!activity[field]) errorFields[field] = `Activity ${field} is missed`
    })
    if (!Object.keys(errorFields).length) return false
    errorFields.message = Object.values(errorFields).join(', ')
    return errorFields
}

const getMetricsError = (metricsList) => {
    let errorEmpty = false
    metricsList.forEach(metric => {
        metricsTextProps.forEach(key => {
            if (!metric[key]) errorEmpty = true
        })
        return metric
    })
    if (errorEmpty) return { message: 'Category, Widget and Key fields are required' }
    return false
}

const getAllCurrentMetrics = (predefined, custom) => {
    return [
        ...predefined, 
        ...custom.filter(m => objectHasValuesForKeys(m, metricsTextProps))
    ]
}

const getNumerizedMetrics = (metricsList, removeId, valueProps, isOtherString) => {

    const numerizedMetrics = metricsList.map(metric => {
        delete metric.activity_id
        if (removeId) delete metric.id
        delete metric.is_missed
        valueProps.forEach(prop => {
            if (prop === OTHER_PROP && isOtherString) {
                metric[prop] = metric[prop] || ''
            } else {
                metric[prop] = metric[prop] * 1 || 0
            }
        })
        return metric
    })
    return numerizedMetrics
}

const refactorImportedMetrics = (metrics, valueProps, isOtherString) => {
    return metrics.map(m => {
        const metric = { ...m }
        for (const key of metricsTextProps) {
            if (!metric[key]) {
                metric[key] = ''
                metric.is_missed = true
            }
            metric[key] = metric[key].trim().replace(/^\s+/, '')
            metric[key] = metric[key].replace(/\u00A0/g, ' ')
        }
        for (const key of valueProps) {
            if (!metric[key]) {
                metric[key] = ''
            }
        }
        metric[IS_EDITABLE] = IS_EDITABLE in metric ? metric[IS_EDITABLE] : true
        if (isOtherString && metric[OTHER_PROP] && !metric[OTHER_PROP].includes(',')) metric[OTHER_PROP] = `${metric[OTHER_PROP] * 1}`
        return metric
    })
}

const refactorFindings = (findings) => {
    return findings.map(f => ({ ...f, activity_date: new Date(f.activity_date) }))
}

const getAxisStep = (chartData, roundValue = 5) => {
    if (!chartData.rows.length || !chartData.columns.length) return 0
    let max = 0
    let rowsSumArray = []
    for (const row of chartData.rows) {
        const columnLabels = chartData.columns.map(c => c.label)
        const valuesArray = columnLabels.map(c => row[c])
        rowsSumArray.push(valuesArray.reduce((a, b) => a + b))
        const rowMaxValue = Math.max(...valuesArray)
        if (rowMaxValue > max) max = rowMaxValue
    }
    const maxValue = chartData.details.isStackedSeparatelly ? Math.max(...rowsSumArray) : max
    return Math.ceil(maxValue / roundValue) * roundValue
}

const getMetricsConsts = (categories, widgets, keys, propsValues) => {
    return {
        categories,
        widgets,
        keys,
        propsValues,
        props: propsValues.map(v => v.id)
    }
}

const getMetricsValuesProps = (dashboardSettings, module, dimension, isReverse) => {
    const moduleDimentionSettings = dashboardSettings.map(s => {
        const valueModule = s.value[module] || s.value[ALL_MODULES]
        const valueModuleDimension = valueModule[dimension] || valueModule[ALL_DIMENSIONS]
        return {
            id: s.key,
            name: valueModuleDimension.alias,
            color: valueModuleDimension.color
        }
    })
    return isReverse ? moduleDimentionSettings.reverse() : moduleDimentionSettings
}

const getMetricsValuesPropsAllDimensions = (dashboardSettings, module, isReverse) => {
    const metricsValuesPropsAllDimensions = {}
    dashboardDimensions.forEach(d => {
        metricsValuesPropsAllDimensions[d.id] = getMetricsValuesProps(dashboardSettings, module, d.id, isReverse)
    })
    return metricsValuesPropsAllDimensions
}

const getActivityMetricsValuesProps = (dashboardSettings, module, dimension = ALL_DIMENSIONS) => {
    const moduleDimentionSettings = dashboardSettings.map(s => {
        const valueModule = s.value[module] || s.value[ALL_MODULES]
        const valueModuleDimension = valueModule[dimension] || valueModule[ALL_DIMENSIONS]
        return {
            id: s.key,
            name: valueModuleDimension.alias,
            color: valueModuleDimension.color
        }
    })
    return [ otherValueProps, ...moduleDimentionSettings]
}

const getMetricsLabelDots = (metricsConsts) => {
    const { propsValues } = metricsConsts
    return propsValues.map(v => {
        const item = { label: v.fullName || v.name }
        if (v.color) item.color = v.color
        if (v.status) item.status = v.status
        return item
    })
}

const getChangedMetrics = (metrics, key, i, val, metricsConsts, isWidgetInput) => {
    metrics[i][key] = val
    metrics = preparePredefinedMetrics(metrics, metricsConsts, isWidgetInput)
    return metrics
}

const getRequiredFieldError = (val, i, isPredefined, metricsLength) => {
    return !val && (isPredefined || (!isPredefined && i < metricsLength - 1)) ? 'Required' : ''
}

const getMetricId = (metric, i, isPredefined, predefined) => {
    return isPredefined ? 
        (metric.id || i + 1) : 
        Math.max(...predefined.map((m, mi) => (m.id || mi + 1) * 1), predefined.length) + 1 + i
}

const getTextFields = ( metricProps, metricsConsts, metricsFunctions, isWidgetInput, hasFindings) => {
    const { metric, i, metricsLength, id, isPredefined, keyToDisplay } = metricProps
    const { categories, widgets, keys } = metricsConsts
    const { changeMetricValue, checkRequiredField, deleteMetric } = metricsFunctions
    const hasEditButton = !hasFindings && metric[IS_EDITABLE] && (!isPredefined && i < metricsLength - 1 || isPredefined)
    const isDisabled = !metric[IS_EDITABLE] || hasFindings
    return {
        category_select: <InputRow
            inputId={`${CATEGORY}-${id}`}
            inputTag='select'
            placeholder='Select'
            options={categories.sort()}
            changeValueHandler={(val) => changeMetricValue(CATEGORY, i, val, isPredefined)}
            defaultValue={metric[CATEGORY]}
            disabled={isDisabled}
            errorMsg={checkRequiredField(metric[CATEGORY], isPredefined, i)} />,
        widget_select: <InputRow
            inputId={`${WIDGET}-${id}`}
            inputTag={isWidgetInput ? 'input' : 'select'}
            inputType={isWidgetInput ? 'search' : 'text'}
            placeholder='Select'
            options={isWidgetInput ? null : widgets.sort()}
            autocompleteOptions={isWidgetInput ? widgets.sort() : null}
            changeValueHandler={(val) => changeMetricValue(WIDGET, i, val, isPredefined)}
            defaultValue={metric[WIDGET]}
            disabled={isDisabled}
            errorMsg={checkRequiredField(metric[WIDGET], isPredefined, i)} />,
        key_select: <InputRow
            inputId={`${KEY}-${id}`}
            inputType='search'
            placeholder='Select'
            autocompleteOptions={keys.sort()}
            changeValueHandler={(val) => changeMetricValue(KEY, i, val, isPredefined)}
            defaultValue={keyToDisplay || metric[KEY]}
            disabled={isDisabled}
            errorMsg={checkRequiredField(metric[KEY], isPredefined, i)} />,
        edit: hasEditButton ? <button className='btn-icon' onClick={() => deleteMetric(i, isPredefined)} id={`delete-metric-${id}`}><i className='icon-delete'></i><span>Delete</span></button> : <></>
    }
}

const getValuesFields = (metricProps, metricsConsts, metricsFunctions) => {
    const { metric, i, id, isPredefined } = metricProps
    const { changeMetricValue } = metricsFunctions
    const { props, prefix } = metricsConsts
    const valuesFields = {}
    props.forEach(prop => {
        valuesFields[`${prop}_value`] = <InputRow
            inputId={`${prop}${prefix}-${id}`}
            changeValueHandler={(val) => changeMetricValue(prop, i, val, isPredefined)}
            inputType='number'
            defaultValue={metric[prop]}
            selectOnFocus={true}
            max='100' />
    })
    return valuesFields
}

const getValuesFieldsDynamic = (metricProps, metricsConsts, metricsFunctions, hasFindings) => {
    const { metric, i, id, isPredefined } = metricProps
    const { changeMetricValue } = metricsFunctions
    const { propsValues } = metricsConsts
    const valuesFields = {}
    propsValues.forEach(prop => {
        valuesFields[`${prop.id}_value`] = <InputRow
            inputId={`${prop.id}-${id}`}
            changeValueHandler={(val) => changeMetricValue(prop.id, i, val, isPredefined)}
            inputType='number'
            defaultValue={metric[prop.id]}
            selectOnFocus={true}
            style={{ borderColor: prop.color }}
            disabled={hasFindings}
            max='100' />
    })
    return valuesFields
}

const getActivityInfoMetricsValues = (propsValues, metric) => {
    const valuesColumns = {}
    propsValues.forEach(value => {
        valuesColumns[`${value.id}_value`] = <span style={{ color: value.color }}>{metric[value.id]}</span>
    })
    return valuesColumns
}

const getActivityInfoMetricsTableData = (currentActivityInfo, valuesProps) => {
    const data = (currentActivityInfo?.metrics || []).map(metric => {
        const valuesColumns = getActivityInfoMetricsValues(valuesProps, metric)
        return {
            ...metric,
            key: helpers.checkDateIsValid(new Date(metric.key)) ? moment(metric.key).format('MMM YY') : metric.key,
            ...valuesColumns,
            edit: metric[IS_EDITABLE] ? 'Yes' : 'No'
        }
    })
    return data
}

const getActivityInfoMetricsColumns = (propsValues) => {
    return [
        ...metricsColumnsText, 
        ...propsValues.map(v => ({
            key: `${v.id}_value`,
            name: v.name,
            sortBy: v.id,
            type: 'component'
        })),
        metricsColumnInfoEdit
    ]
}

const getActivityInfoMetricsColumnsForm = (propsValues) => {
    return [
        ...metricsColumnsTextForm, 
        ...propsValues.map(v => ({
            key: `${v.id}_value`,
            name: '',
            sortBy: v.id,
            type: 'component',
            filterable: false,
            sortable: false
        })),
        metricsColumnsEdit
    ]
}

const checkImportingErrors = (dataMetrics, updatedMetrics, setImportingErrors, setIsOpenedMetricsErrorsPopup) => {
    let errors = []
    if (dataMetrics?.missing_columns?.length) errors.push(`Missing Columns: ${dataMetrics.missing_columns.join(', ')}`)
    if (updatedMetrics.find(m => m.is_missed)) errors.push('Category, Widget and Key fields are required')
    setImportingErrors(errors)
    if (errors.length) {
        setIsOpenedMetricsErrorsPopup(true)
    } else {
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Import completed successfully' }))
    }
    return errors
}

export {
    getMetricsByProps,
    getMetricByProps,
    getValueByProps,
    preparePredefinedMetrics,
    replaceNullWithEmptyValue,
    findDeletedMetrics,
    getActivityError,
    getMetricsError,
    getAllCurrentMetrics,
    getNumerizedMetrics,
    refactorImportedMetrics,
    refactorFindings,
    getAxisStep,
    getMetricsConsts,
    getMetricsValuesProps,
    getMetricsValuesPropsAllDimensions,
    getActivityMetricsValuesProps,
    getMetricsLabelDots,
    getChangedMetrics,
    getRequiredFieldError,
    getMetricId,
    getTextFields,
    getValuesFields,
    getValuesFieldsDynamic,
    getActivityInfoMetricsValues,
    getActivityInfoMetricsTableData,
    getActivityInfoMetricsColumns,
    getActivityInfoMetricsColumnsForm,
    checkImportingErrors
}