import React, { useEffect, useState } from 'react'
import './HomeDashboard.scss'
import { Loader, ValuesList, WelcomeUser, WidgetBlock, helpers } from 'hub-web-lib'
import { connect } from 'react-redux'
import CyberReadinessTopWidgets from '../CyberReadiness/Dashboards/Widgets/CyberReadinessTopWidgets'
import QAApi from 'services/QAApi'
import '../CyberReadiness/Dashboards/CyberReadiness.scss'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { defaultTemplateDomains } from 'js/constants/enums/questionnaire'
import CyberReadinessProgress from '../CyberReadiness/Dashboards/Widgets/CyberReadinessProgress'
import EasmApi from 'services/EasmApi'
import RedTeamApi from 'services/RedTeamApi'
import { getDdosValues, getEasmValues, getIncidentResponseValues, getPenTestValues, getPhishingSimulationValues, getQaValues, getRansomwareResilienceValues, getRedTeamValues, getRiskAssessmentValues } from 'js/ui-helpers/home-dashboard'
import { ATTACK_SURFACE, RED_TEAM, CYBER_READINESS, DDOS_RESILIENCE, INCIDENT_RESPONSE, PEN_TEST, PHISHING_SIMULATION, RANSOMWARE_RESILIENCE, CYBER_READINESS_MODULE, RED_TEAM_MODULE, ATTACK_SURFACE_MODULE, PEN_TEST_MODULE, DDOS_RESILIENCE_MODULE, INCIDENT_RESPONSE_MODULE, PHISHING_SIMULATION_MODULE, RANSOMWARE_RESILIENCE_MODULE, appModulesPaths, RISK_ASSESSMENT_MODULE, RISK_ASSESSMENT } from 'js/constants/app-modules'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { APPLICATION, INFRASTRUCTURE } from 'js/constants/enums/pen-test'
import PenTestApi from 'services/PenTestApi'
import UserApi from 'services/UserApi'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'
import { START_FILLING_TEXT, ddosMetricsPlaceholder, easmMetricsPlaceholder, incidentResponseActivityDemo, incidentResponseMetricsDemo, penTestMetricsPlaceholder, phishingSimulationActivityDemo, phishingSimulationMetricsDemo, qaMetricsPlaceholder, ransomwareResilienceActivityDemo, ransomwareResilienceMetricsDemo, redTeamMetricsPlaceholder, riskAssessmentMetricsPlaceholder } from 'js/constants/enums/home-dashboard-demo'
import DdosApi from 'services/DdosApi'
import { getMetricsValuesPropsAllDimensions } from 'js/ui-helpers/metrics'
import ActivityGantt from 'components/Modules/Activity/ActivityGantt/ActivityGantt'
import RiskAssessmentApi from 'services/RiskAssessmentApi'


const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        visibleModules: state.visibleModules,
        dashboardSettings: state.dashboardSettings
    }
}
export const HomeDashboardComponent = ({ currentUser, visibleModules, dashboardSettings }) => {
    const currentOrgId = currentUser?.current_org

    const visibleMods = getEnabledSettingItems(visibleModules)
    const isDemoMode = UserApi.getUserProp('isDemoMode', currentUser?.id)
    const [qaMetricsData, setQaMetricsData] = useState([])
    const [easmMetricsData, setEasmMetricsData] = useState([])
    const [redTeamMetricsData, setRedTeamMetricsData] = useState([])
    const [penTestAMetricsData, setPenTestAMetricsData] = useState([])
    const [penTestIMetricsData, setPenTestIMetricsData] = useState([])
    const [ddosMetricsData, setDdosMetricsData] = useState([])
    const [riskAssessmentMetricsData, setRiskAssessmentMetricsData] = useState([])
    const [incidentResponseMetricsData, setIncidentResponseMetricsData] = useState([])
    const [phishingSimulationMetricsData, setPhishingSimulationMetricsData] = useState([])
    const [ransomwareResilienceMetricsData, setRansomwareResilienceMetricsData] = useState([])

    const [qaActivities, setQaActivities] = useState(null)
    const [easmActivities, setEasmActivities] = useState(null)
    const [redTeamActivities, setRedTeamActivities] = useState(null)
    const [penTestAActivities, setPenTestAActivities] = useState(null)
    const [penTestIActivities, setPenTestIActivities] = useState(null)
    const [ddosActivities, setDdosActivities] = useState(null)
    const [riskAssessmentActivities, setRiskAssessmentActivities] = useState(null)
    const [incidentResponseActivities, setIncidentResponseActivities] = useState(null)
    const [phishingSimulationActivities, setPhishingSimulationActivities] = useState(null)
    const [ransomwareResilienceActivities, setRansomwareResilienceActivities] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isPlaceholderMode, setIsPlaceholderMode] = useState(false)

    const easmMetricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, ATTACK_SURFACE_MODULE, true)
    const redTeamMetricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, RED_TEAM_MODULE, true)
    const penTestMetricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, PEN_TEST_MODULE, true)
    const ddosMetricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, DDOS_RESILIENCE_MODULE, true)
    const riskAssessmentMetricsProps = getMetricsValuesPropsAllDimensions(dashboardSettings, RISK_ASSESSMENT_MODULE, true)
    const { qaValues, qaIcon } = getQaValues(qaMetricsData, visibleMods[CYBER_READINESS_MODULE])
    const { redTeamValues, redTeamIcon } = getRedTeamValues(redTeamMetricsData, visibleMods[RED_TEAM_MODULE], redTeamMetricsProps)
    const { easmValues, easmIcon } = getEasmValues(easmMetricsData, visibleMods[ATTACK_SURFACE_MODULE], easmMetricsProps)
    const { values: penTestAValues, icon: penTestAIcon } = getPenTestValues(penTestAMetricsData, visibleMods[PEN_TEST_MODULE], APPLICATION, penTestMetricsProps)
    const { values: penTestIValues, icon: penTestIIcon } = getPenTestValues(penTestIMetricsData, visibleMods[PEN_TEST_MODULE], INFRASTRUCTURE, penTestMetricsProps)
    // const { ptValues, ptIcon } = getPtValues(visibleModules)
    const { ddosValues, ddosIcon } = getDdosValues(ddosMetricsData, visibleMods[DDOS_RESILIENCE_MODULE], ddosMetricsProps)
    const { riskAssessmentValues, riskAssessmentIcon } = getRiskAssessmentValues(riskAssessmentMetricsData, visibleMods[RISK_ASSESSMENT_MODULE], riskAssessmentMetricsProps)
    const { incidentResponseValues, incidentResponseIcon } = getIncidentResponseValues(incidentResponseMetricsData, visibleMods[INCIDENT_RESPONSE_MODULE])
    const { phishingSimulationValues, phishingSimulationIcon } = getPhishingSimulationValues(phishingSimulationMetricsData, visibleMods[PHISHING_SIMULATION_MODULE])
    const { ransomwareResilienceValues, ransomwareResilienceIcon } = getRansomwareResilienceValues(ransomwareResilienceMetricsData, visibleMods[RANSOMWARE_RESILIENCE_MODULE])

    const activitiesData = [{
        activities: qaActivities,
        module: CYBER_READINESS_MODULE,
    }, {
        activities: easmActivities,
        module: ATTACK_SURFACE_MODULE,
    }, {
        activities: redTeamActivities,
        module: RED_TEAM_MODULE,
    }, {
        activities: penTestAActivities,
        module: PEN_TEST_MODULE,
        modulePropValue: APPLICATION,
        link: appModulesPaths[PEN_TEST_MODULE]
    }, {
        activities: penTestIActivities,
        module: PEN_TEST_MODULE,
        modulePropValue: INFRASTRUCTURE,
        link: appModulesPaths[PEN_TEST_MODULE]
    }, {
        activities: ddosActivities,
        module: DDOS_RESILIENCE_MODULE
    }, {
        activities: riskAssessmentActivities,
        module: RISK_ASSESSMENT_MODULE
    }, {
        activities: incidentResponseActivities,
        module: INCIDENT_RESPONSE_MODULE,
    }, {
        activities: phishingSimulationActivities,
        module: PHISHING_SIMULATION_MODULE,
    }, {
        activities: ransomwareResilienceActivities,
        module: RANSOMWARE_RESILIENCE_MODULE,
    }]

    const getMetricsData = async () => {
        setIsLoading(true)
        const qaMetrics = visibleMods[CYBER_READINESS_MODULE] ? await QAApi.getLatestMetrics(currentOrgId) : []
        const easmMetrics = visibleMods[ATTACK_SURFACE_MODULE] ? await EasmApi.getLatestMetrics(currentOrgId) : []
        const redTeamMetrics = visibleMods[RED_TEAM_MODULE] ? await RedTeamApi.getLatestMetrics(currentOrgId) : []
        const penTestAMetrics = visibleMods[PEN_TEST_MODULE] ? await PenTestApi.getLatestMetrics(currentOrgId, APPLICATION) : []
        const penTestIMetrics = visibleMods[PEN_TEST_MODULE] ? await PenTestApi.getLatestMetrics(currentOrgId, INFRASTRUCTURE) : []
        const ddosMetrics = visibleMods[DDOS_RESILIENCE_MODULE] ? await DdosApi.getLatestMetrics(currentOrgId) : []
        const riskAssesmentMetrics = visibleMods[RISK_ASSESSMENT_MODULE] ? await RiskAssessmentApi.getLatestMetrics(currentOrgId) : []
        const qaLatestActivities = visibleMods[CYBER_READINESS_MODULE] ? await QAApi.getAllActivities(currentOrgId) : []
        const easmLatestActivities = visibleMods[ATTACK_SURFACE_MODULE] ? await EasmApi.getAllActivities(currentOrgId) : []
        const redTeamLatestActivities = visibleMods[RED_TEAM_MODULE] ? await RedTeamApi.getAllActivities(currentOrgId) : []
        const penTestLatestActivities = visibleMods[PEN_TEST_MODULE] ? await PenTestApi.getAllActivities(currentOrgId) : []
        const ddosLatestActivities = visibleMods[DDOS_RESILIENCE_MODULE] ? await DdosApi.getAllActivities(currentOrgId) : []
        const riskAssesmentLatestActivities = visibleMods[RISK_ASSESSMENT_MODULE] ? await RiskAssessmentApi.getAllActivities(currentOrgId) : []
        if (
            !qaMetrics || qaMetrics.error ||
            !easmMetrics || easmMetrics.error ||
            !redTeamMetrics || redTeamMetrics.error ||
            !penTestAMetrics || penTestAMetrics.error ||
            !penTestIMetrics || penTestIMetrics.error ||
            !ddosMetrics || ddosMetrics.error ||
            !riskAssesmentMetrics || riskAssesmentMetrics.error ||
            !qaLatestActivities || qaLatestActivities.error ||
            !easmLatestActivities || easmLatestActivities.error ||
            !redTeamLatestActivities || redTeamLatestActivities.error ||
            !penTestLatestActivities || penTestLatestActivities.error ||
            !ddosLatestActivities || ddosLatestActivities.error ||
            !riskAssesmentLatestActivities || riskAssesmentLatestActivities.error
        ) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Some issues to get dashboard data', notificationType: 'error' }))
        }

        setQaActivities(qaLatestActivities)
        setEasmActivities(easmLatestActivities)
        setRedTeamActivities(redTeamLatestActivities)
        setPenTestAActivities(penTestLatestActivities.filter(a => a.test_type === APPLICATION))
        setPenTestIActivities(penTestLatestActivities.filter(a => a.test_type === INFRASTRUCTURE))
        setDdosActivities(ddosLatestActivities)
        setRiskAssessmentActivities(riskAssesmentLatestActivities)


        if (
            !qaMetrics?.length &&
            !easmMetrics?.length &&
            !redTeamMetrics?.length &&
            !penTestAMetrics?.length &&
            !penTestIMetrics?.length &&
            !ddosMetrics?.length &&
            !riskAssesmentMetrics?.length
        ) {
            setIsPlaceholderMode(true)
            setQaMetricsData(qaMetricsPlaceholder)
            setEasmMetricsData(easmMetricsPlaceholder)
            setRedTeamMetricsData(redTeamMetricsPlaceholder)
            setPenTestAMetricsData(penTestMetricsPlaceholder)
            setPenTestIMetricsData(penTestMetricsPlaceholder)
            setDdosMetricsData(ddosMetricsPlaceholder)
            setRiskAssessmentMetricsData(riskAssessmentMetricsPlaceholder)
        } else {
            setIsPlaceholderMode(false)
            setQaMetricsData(!qaMetrics || qaMetrics.error ? [] : qaMetrics)
            setEasmMetricsData(!easmMetrics || easmMetrics.error ? [] : easmMetrics)
            setRedTeamMetricsData(!redTeamMetrics || redTeamMetrics.error ? [] : redTeamMetrics)
            setPenTestAMetricsData(!penTestAMetrics || penTestAMetrics.error ? [] : penTestAMetrics)
            setPenTestIMetricsData(!penTestIMetrics || penTestIMetrics.error ? [] : penTestIMetrics)
            setDdosMetricsData(!ddosMetrics || ddosMetrics.error ? [] : ddosMetrics)
            setRiskAssessmentMetricsData(!riskAssesmentMetrics || riskAssesmentMetrics.error ? [] : riskAssesmentMetrics)
        }

        // temporary solution until this modules release

        if (isDemoMode) {
            setIncidentResponseMetricsData(incidentResponseMetricsDemo)
            setPhishingSimulationMetricsData(phishingSimulationMetricsDemo)
            setRansomwareResilienceMetricsData(ransomwareResilienceMetricsDemo)

            setIncidentResponseActivities([incidentResponseActivityDemo])
            setPhishingSimulationActivities([phishingSimulationActivityDemo])
            setRansomwareResilienceActivities([ransomwareResilienceActivityDemo])
        }
        setIsLoading(false)

    }

    useEffect(async () => {
        if (Object.keys(visibleMods || {}).length) {
            await getMetricsData()
        }
    }, [])

    useEffect(async () => {
        await getMetricsData()
    }, [visibleModules])




    return (
        <div id='home' className={`home page-content ${isPlaceholderMode ? 'placeholder-mode' : ''}`}>
            <div className='page-header'>
                <div>
                    <WelcomeUser userName={currentUser?.name || currentUser?.user_name || ''} helpCenterLink='mailto:support@@hubsecurity.io' />
                    {isPlaceholderMode && <p>{START_FILLING_TEXT}</p>}
                </div>
                {!isPlaceholderMode && !isLoading && <button id='print-button' onClick={() => window.print()}>Print Dashboard</button>}
            </div>
            {isLoading ? <Loader /> : <div className='dashboard-content home'>
                {visibleMods[CYBER_READINESS_MODULE] && <div className='cyber-readiness dashboard-widgets'>
                    {isPlaceholderMode && <div className='placeholder-mode-overlay'></div>}
                    <CyberReadinessTopWidgets isHome={true} metricsData={qaMetricsData} />
                    <div className='widgets-row'>
                        {defaultTemplateDomains.map(category => <CyberReadinessProgress key={category} data={{ category, metricsData: qaMetricsData }} />)}
                    </div>
                </div>}
                <div className='dashboard-widgets'>
                    <div className='widgets-row dashboard-links'>
                        {isPlaceholderMode && <div className='placeholder-mode-overlay'></div>}
                        <WidgetBlock title={CYBER_READINESS} icon={qaIcon} content={qaValues.length ? <ValuesList list={qaValues} listId='cyber-readiness-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={ATTACK_SURFACE} icon={easmIcon} content={easmValues.length ? <ValuesList list={easmValues} listId='attack-surface-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={RED_TEAM} icon={redTeamIcon} content={redTeamValues.length ? <ValuesList list={redTeamValues} listId='read-team-values' /> : <NoDataPlaceholder />} />
                        {/* <WidgetBlock title={PT} icon={ptIcon} content={ptValues.length ? <ValuesList list={ptValues} /> : <NoDataPlaceholder />} /> */}
                        <WidgetBlock title={`${PEN_TEST} ${helpers.capitalizeString(APPLICATION)}`} icon={penTestAIcon} content={penTestAValues.length ? <ValuesList list={penTestAValues} listId='pen-test-application-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={`${PEN_TEST} ${helpers.capitalizeString(INFRASTRUCTURE)}`} icon={penTestIIcon} content={penTestIValues.length ? <ValuesList list={penTestIValues} listId='pen-test-infrastructure-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={DDOS_RESILIENCE} icon={ddosIcon} content={ddosValues.length ? <ValuesList list={ddosValues} listId='ddos-resilience-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={RISK_ASSESSMENT} icon={riskAssessmentIcon} content={riskAssessmentValues.length ? <ValuesList list={riskAssessmentValues} listId='risk-assessment-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={INCIDENT_RESPONSE} icon={incidentResponseIcon} content={incidentResponseValues.length ? <ValuesList list={incidentResponseValues} listId='incident-response-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={PHISHING_SIMULATION} icon={phishingSimulationIcon} content={phishingSimulationValues.length ? <ValuesList list={phishingSimulationValues} listId='phishing-simulation-values' /> : <NoDataPlaceholder />} />
                        <WidgetBlock title={RANSOMWARE_RESILIENCE} icon={ransomwareResilienceIcon} content={ransomwareResilienceValues.length ? <ValuesList list={ransomwareResilienceValues} listId='ransomware-resilience-values' /> : <NoDataPlaceholder />} />
                    </div>
                    {!!Object.keys(visibleMods || {}).length && <ActivityGantt activitiesData={activitiesData} title='Activities Plan' />}
                </div>

            </div>}
        </div>

    )
}

const HomeDashboard = connect(mapStateToProps)(HomeDashboardComponent)
export default HomeDashboard