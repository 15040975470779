import { ToolTip } from 'hub-web-lib'
import React from 'react'
import './AssessmentSummary.scss'

function AssessmentSummary({
    domains = [],
    allQuestions,
    isReview,
    submitAssessment = () => {},
    reopenAssessment = () => {}
}) {
    
    const requiredQuestionsCount = allQuestions.filter(q => q.is_required).length
    const allQuestionsCount = requiredQuestionsCount || allQuestions.length
    const answeredQuestionsCount = requiredQuestionsCount ? allQuestions.filter(q => q.isAnswered && q.is_required).length : allQuestions.filter(q => q.isAnswered).length
    const answeredPercent = Math.round(answeredQuestionsCount / allQuestionsCount * 100)

    const domainsQuestions = domains.map(d => {
        const allQuestions = (d.categories || []).map(c => (c.questions || [])).flat()
        const requiredCount = allQuestions.filter(q => q.is_required).length
        const questionsCount = requiredQuestionsCount ? requiredCount : allQuestions.length
        const answeredQuestionsCount = requiredQuestionsCount ? allQuestions.filter(q => q.is_required && q.isAnswered).length : allQuestions.filter(q => q.isAnswered).length
        return {
            name: d.name,
            id: d.id,
            questionsCount,
            answeredQuestionsCount
        }
    })

    const tooltipContent = <>
        <span>({answeredQuestionsCount} of {allQuestionsCount} {requiredQuestionsCount ? 'required ' : ''}questions answered)</span>
        {domainsQuestions.map(d => <span key={d.id} className={d.answeredQuestionsCount === d.questionsCount ? 'completed' : ''}>
            <span>{d.name}</span><span>{d.answeredQuestionsCount}/{d.questionsCount}</span>
        </span>)}
    </>

    const tooltipDetails = {
        title: `${answeredPercent}% completed`, 
        description: tooltipContent,
        isHiddenNextButton: true
    }

    return (
        <div className='questionnaire-summary answers-summary'>
            <div className='questionnaire-summary-unanswered questionnaire-progress-tooltip'>
                <p>{answeredQuestionsCount}/{allQuestionsCount} {requiredQuestionsCount ? 'required ' : ''}questions answered</p>
                <i className='icon-information-outline' id='show-questionnaire-summary'></i>
                <ToolTip tooltipDetails={tooltipDetails} />
            </div>
            {isReview ? <button 
                id='questionnaire-answers-submit' 
                className='btn-submit'
                onClick={reopenAssessment}>
                <i className='icon-unlock'></i> Reopen
            </button> : <button 
                id='questionnaire-answers-submit' 
                className='btn-submit' 
                disabled={requiredQuestionsCount && requiredQuestionsCount !== answeredQuestionsCount}
                onClick={submitAssessment}>
                    Submit<i className='icon-send'></i>
            </button>}
        </div>
    )
}

export default AssessmentSummary