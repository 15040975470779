import { Alert, Loader, Popup } from 'hub-web-lib'
import { ASSESSMENT } from 'js/constants/vocabulary'
import React, { useState } from 'react'
import AssessmentApi from 'services/AssessmentApi'
import QuestionnaireClient from '../Pages/AssessmentClient'
import { getAssessmentAnswers } from 'js/ui-helpers/questionnaire'
import './AssessmentView.scss'
import { assessmentStatusesEnum } from 'js/constants/enums/questionnaire'

const AssessmentView = ({ selectedQuestionnaire, assessmentId }) => {
    const [selectedAssessment, setSelectedAssessment] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [answers, setAnswers] = useState([])

    const loadAssessment = async () => {
        setIsPopupOpen(true)
        setIsLoading(true)
        const assessment = await AssessmentApi.getAssessmentResults(assessmentId, selectedQuestionnaire.org_id)
        if (!assessment || assessment.error) {
            setIsLoading(false)
            return
        }
        const answers = getAssessmentAnswers(assessment, selectedQuestionnaire.domains || [])
        setAnswers(answers)
        setSelectedAssessment(assessment)
        setIsLoading(false)
    }

    return (
        <div className='view-assessment'>
            <button 
                className='btn-link' 
                onClick={loadAssessment} 
                id='view-assessment'>
                <i className='icon-eye-opened'></i> View {ASSESSMENT}
            </button>
            <Popup
                isOpen={isPopupOpen}
                title=''
                onClose={() => setIsPopupOpen(false)}
                isDisabledClickOutside={true}
                customClass='questionnaire-popup'>
                {isLoading ? <Loader /> :
                    (selectedQuestionnaire && selectedAssessment ?
                        <QuestionnaireClient
                            questionnaire={selectedQuestionnaire}
                            answers={answers}
                            isReview={selectedAssessment.status === assessmentStatusesEnum.submitted}
                            isViewOnly={true}
                            closeViewAssessment={() => setIsPopupOpen(false)} />: <Alert message={`Nodata for this ${ASSESSMENT}`} alertType='neutral' />
                    )}
            </Popup>
        </div>
    )
}

export default AssessmentView
