import { getActivityError, getMetricsError } from './metrics'

const getStepsErrors = (activity, metrics) => {
    return {
        0: getActivityError(activity),
        1: getMetricsError(metrics)
    }
}

export {
    getStepsErrors
}