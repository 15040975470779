import React, { useState } from 'react'
import './CyberReadiness.scss'
import 'scss/Dashboards/DashboardPlaceholder.scss'
import 'scss/Dashboards/DashboardPageHeader.scss'
import { Tabs } from 'hub-web-lib'
import CyberReadinessTopWidgets from './Widgets/CyberReadinessTopWidgets'
import CyberReadinessBottomWidgets from './Widgets/CyberReadinessBottomWidgets'
import { defaultTemplateDomains } from 'js/constants/enums/questionnaire'

export const CyberReadiness = ({ metricsData, isAnimationActive }) => {
    const [activeTab, setActiveTab] = useState(0)
    const cyberReadinessTabs = defaultTemplateDomains.map(label => ({ label, contentComponent: () => <></> }))

    return (
        <>
            <CyberReadinessTopWidgets metricsData={metricsData} />
            <Tabs tabs={cyberReadinessTabs}
                handleActiveTab={setActiveTab} />
            {defaultTemplateDomains.map((category, i) => <div className='tab-content-custom' key={`tab-${i}`}>
                <h3 className='print-title'>{category}</h3>
                <CyberReadinessBottomWidgets data={{ category, isShown: i === activeTab, metricsData, isAnimationActive }} />
            </div>)}
        </>

    )
}

export default CyberReadiness
