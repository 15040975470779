import QuestionnaireClient from 'components/Modules/Questionnaire/Assessment/Pages/AssessmentClient'
import './Assessments.scss'
import { Alert, Loader, Popup, ProgressBar, SortableTable, ToolTip, genericTexts, InputRow, Chips } from 'hub-web-lib'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AssessmentFirst from 'components/Modules/Questionnaire/Assessment/Pages/AssessmentFirst'
import QuestionnaireApi from 'services/QuestionnaireApi'
import { assessmentsColumns } from 'js/constants/table-columns'
import moment from 'moment'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_IS_TENANT_ASSESSMENT_SUBMIT, UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { ASSESSMENT } from 'js/constants/vocabulary'
import AssessmentApi from 'services/AssessmentApi'
import { getAssessmentAnswers, getFilteredQuestionnaire, refactorAssessmentAnswersToResults } from 'js/ui-helpers/questionnaire'
import AssessmentGeneralInfo from './UpdateAssessment/AssessmentGeneralInfo'
import { assessmentStatusesEnum, assessmentStatuses } from 'js/constants/enums/questionnaire'
import { getUserRole } from 'js/ui-helpers/roles'
import { ASSESSMENTS_PATH, CYBER_READINESS_MODULE, DASHBOARD_PATH, appModulesPaths } from 'js/constants/app-modules'
import TenantOnboardingAssessment from 'components/Views/Tenant/TenantOnboardingAssessment'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
    }
}

function AssessmentsComponent({ currentUser }) {
    const currentOrgId = currentUser?.current_org

    const [assessments, setAssessments] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isLoadingAssessment, setIsLoadingAssessment] = useState(false)
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null)
    const [selectedAssessment, setSelectedAssessment] = useState(null)
    const [isFirstVisit, setIsFirstVisit] = useState(false)
    const [isInfoOpenedFromClient, setIsInfoOpenedFromClient] = useState(false)
    const [selectedAssessmentId, setSelectedAssessmentId] = useState('')
    const [answers, setAnswers] = useState([])
    const [selectedActivity, setSelectedActivity] = useState()
    const [isGeneralInfoPopupOpen, setIsGeneralInfoPopupOpen] = useState(false)
    const [isImportPopupOpen, setIsImportPopupOpen] = useState(false)
    const [isSubmitPopupOpen, setIsSubmitPopupOpen] = useState(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const saveAnswers = async (updatesAnswers = answers) => {
        setAnswers(updatesAnswers)
        const refactoredAnswers = refactorAssessmentAnswersToResults(updatesAnswers, selectedQuestionnaire.domains)
        const savedAnswers = await AssessmentApi.updateAssessmentResults(selectedAssessmentId, currentOrgId, refactoredAnswers)
        if (!savedAnswers || savedAnswers.error) {
            return false
        }
        return true
    }

    const firstVisitId = searchParams.get('id')

    const getAssessmentStatus = (a) => {
        const statusObject = assessmentStatuses.find(s => s.id === a.status)
        const { status, statusValue } = statusObject
        return { status, statusValue }
    }

    const getAssessmentProgress = (a) => {
        const questionsTotal = a.domains.length ? a.domains.map(d => d.total_questions).reduce((a, b) => a + b) : 0
        const questionsAnswered = a.domains.length ? a.domains.map(d => d.answered_questions).reduce((a, b) => a + b) : 0
        const progressPercent = questionsTotal ? Math.round(questionsAnswered / questionsTotal * 100) : 0
        const tooltipContent = <>
            <span>({questionsAnswered} of {questionsTotal} questions answered)</span>
            {a.domains.map(d => <span key={d.id} className={d.answered_questions === d.total_questions ? 'completed' : ''}>
                <span>{d.name}</span><span>{d.answered_questions}/{d.total_questions}</span>
            </span>)}
        </>
        const tooltipDetails = {
            title: `${progressPercent}% completed`,
            description: tooltipContent,
            isHiddenNextButton: true
        }

        const progress = <div className='assessment-progress questionnaire-progress-tooltip'>
            <ProgressBar percent={progressPercent} type='primary' showIcon={false} />
            <ToolTip tooltipDetails={tooltipDetails} />
        </div>
        return { progress, progressPercent }
    }

    const getAssessmentActions = (a) => {
        const assessmentActions = { [genericTexts.actionsText.info]: { text: 'View Info', order: 1 } }
        if (a.status !== assessmentStatusesEnum.cancelled) {
            assessmentActions[genericTexts.actionsText.edit] = {
                text: a.status === assessmentStatusesEnum.pending ?
                    `Start ${ASSESSMENT}` :
                    genericTexts.actionsText.editText(ASSESSMENT, true, a.status === assessmentStatusesEnum.submitted),
                order: 2
            }
        }
        if (a.status === assessmentStatusesEnum.readyToSubmit) {
            assessmentActions[genericTexts.actionsText.submit] = { text: genericTexts.actionsText.submitText, order: 3 }
        }
        if (a.status === assessmentStatusesEnum.submitted) {
            assessmentActions[genericTexts.actionsText.editPermissions] = { text: `Reopen ${ASSESSMENT}`, order: 3 }
        }
        if (getUserRole(currentUser) < 3) {
            assessmentActions[genericTexts.actionsText.export] = { text: genericTexts.actionsText.exportText, order: 4 }
            if (a.status !== assessmentStatusesEnum.cancelled) {
                assessmentActions[genericTexts.actionsText.import] = { text: genericTexts.actionsText.importText, order: 5 }
            }
        }
        return assessmentActions
    }

    const getTableData = () => {

        const data = (assessments || []).map(a => {
            const questionnaireDate = moment(a.creation_date).format('DD/MM/YY')
            const activityDate = moment(a.activity_date).format('DD/MM/YY')
            const assessmentDate = moment(a.email_date).format('DD/MM/YY')
            const domains = a.domains.length > 3 ? <Chips list={a.domains} icon='' type='domain' isHiddenAvatar={true} noSort={true} /> : a.domains.map(d => d.name).join(', ')
            const { progress, progressPercent } = getAssessmentProgress(a)
            const { status, statusValue } = getAssessmentStatus(a)
            const actions = getAssessmentActions(a)
            const row = {
                id: a.id,
                title: a.qa_activity_title,
                questionnaire: a.template_name,
                version: a.template_version,
                questionnaireDate,
                activityDate,
                domains,
                assessmentDate,
                progress,
                progressPercent,
                status,
                actions,
                statusValue
            }
            return row
        })
        return data
    }

    const tableData = getTableData()

    const menuClickHandler = (actionClicked, assessmentId) => {
        
        setSelectedAssessmentId(assessmentId)
        switch (actionClicked) {
        case genericTexts.actionsText.info:
            navigate(`/${appModulesPaths[CYBER_READINESS_MODULE]}/${ASSESSMENTS_PATH}/${assessmentId}`)
            break
        case genericTexts.actionsText.edit:
            editOrStartAssessment(assessmentId)
            break
        case genericTexts.actionsText.submit:
            setIsSubmitPopupOpen(true)
            break
        case genericTexts.actionsText.editPermissions:
            reopenAssessment(assessmentId)
            break
        case genericTexts.actionsText.export:
            exportAssessment(assessmentId)
            break
        case genericTexts.actionsText.import:
            startImportAssessment()
            break
        }
    }

    const handleTdClick = (column, assessmentId) => {
        if (column === 'domains') return
        const selectedAssessment = assessments.find(a => a.id === assessmentId * 1)
        if(selectedAssessment?.status !== assessmentStatusesEnum.cancelled) {
            setSelectedAssessmentId(assessmentId)
            editOrStartAssessment(assessmentId)
        }
    }

    const editOrStartAssessment = async (assessmentId) => {
        const selectedAssessment = tableData.find(a => a.id === assessmentId * 1)
        selectedAssessment?.status === assessmentStatusesEnum.pending ? setIsGeneralInfoPopupOpen(true) : setIsPopupOpen(true)
        loadAssessmentDetails(assessmentId)
    }

    
    const loadAssessmentDetails = async (assessmentId) => {

        // get assessment and questionnaire
        setIsLoadingAssessment(true)
        const assessment = await AssessmentApi.getAssessmentResults(assessmentId, currentOrgId)
        const questionnaire = assessment?.template_id ? await QuestionnaireApi.getQuestionnaireTemplateFull(assessment.template_id, currentOrgId) : null
        if (!assessment || assessment.error || !questionnaire || questionnaire.error) {
            setIsLoadingAssessment(false)
            return null
        }
        let currentActivity = await AssessmentApi.getActivityByAssessmentId(assessmentId)
        if (!currentActivity || currentActivity.error) {
            currentActivity = {}
        }
        const filteredQuestionnaire = getFilteredQuestionnaire(questionnaire)
        setSelectedQuestionnaire(filteredQuestionnaire)
        setSelectedAssessment(assessment)
        setSelectedActivity(currentActivity)
        const answers = getAssessmentAnswers(assessment, questionnaire.domains || [])
        setAnswers(answers)
        setIsLoadingAssessment(false)
        return assessment
    }

    const startAnswer = () => {
        setSelectedAssessmentId(firstVisitId)
        editOrStartAssessment(firstVisitId)
        // Use lines below to open info page before starting
        // setIsGeneralInfoPopupOpen(true)
        // loadAssessmentDetails(firstVisitId)
    }

    const closePopup = () => {
        setIsFirstVisit(false)
        setIsPopupOpen(false)
        setIsGeneralInfoPopupOpen(false)
        // wait for the popup will be closed with transition
        setTimeout(() => {
            setSelectedAssessment(null)
            setSelectedQuestionnaire(null)
            setAnswers([])
            setSelectedAssessmentId('')
        }, [500])
        updateAssessments()
    }

    const isTenant = () => location.pathname.includes('tenant')

    const submitAssessment = async (assessmentId) => {
        setIsLoading(true)
        setIsPopupOpen(false)
        const currentActivity = await AssessmentApi.getActivityByAssessmentId(assessmentId)
        if (!currentActivity || currentActivity.error) store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: submittedAssessment.error, notificationType: 'error' }))
        const submittedAssessment = await AssessmentApi.submitAssessment(assessmentId)
        if (!submittedAssessment || submittedAssessment.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: submittedAssessment.error, notificationType: 'error' }))
        } else {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.submittedSuccess(`${ASSESSMENT} ${assessmentId}`) }))
            await updateAssessments()
        }
        if (isTenant()) {
            store.dispatch(action(UPDATE_IS_TENANT_ASSESSMENT_SUBMIT, true))
            navigate('/tenant/dashboard')
        }
        else {
            navigate(`/${appModulesPaths[CYBER_READINESS_MODULE]}/${DASHBOARD_PATH}?id=${currentActivity.id}`)

        }
    }

    const reopenAssessment = async (assessmentId) => {
        const selectedAssessment = assessments.find(a => a.id === +assessmentId)
        const reopenedAssessment = await AssessmentApi.updateAssessment({ ...selectedAssessment, status: assessmentStatusesEnum.inProcess }, currentOrgId)
        if (!reopenedAssessment || reopenedAssessment.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: reopenedAssessment.error, notificationType: 'error' }))
        } else {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${ASSESSMENT} ${assessmentId} was reopened` }))
            await updateAssessments()
            if (isPopupOpen) loadAssessmentDetails(assessmentId)
        }
    }

    const submitAssessmentFromEdit = async () => {
        const savedAnswers = await saveAnswers()
        if (!savedAnswers) return
        await submitAssessment(selectedAssessmentId)
        closePopup()
    }

    const updateAssessments = async () => {
        const assessmentsList = await AssessmentApi.getAllAssessmentsByOrgId(currentOrgId)
        if (!assessmentsList || assessmentsList.error) {
            if (!isFirstVisit) store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: assessmentsList.error, notificationType: 'error' }))
            setIsLoading(false)
            return
        }
        setAssessments(assessmentsList)
    }
    const displayAssessment = () => {
        setIsGeneralInfoPopupOpen(false)
        setIsInfoOpenedFromClient(false)
        setIsPopupOpen(true)
    }

    const exportAssessment = async (assessmentId) => {
        AssessmentApi.exportAssessment(currentOrgId, assessmentId)
    }

    const startImportAssessment = async () => {
        setIsImportPopupOpen(true)
    }

    const importAssessmentConfirmClick = () => {
        const fileUploadControl = document.getElementById('fileUpload')
        fileUploadControl.click()
    }

    const importAssessment = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const importAssessmentResult = await AssessmentApi.uploadFile(currentOrgId, selectedAssessmentId, formData)
        if (!importAssessmentResult || importAssessmentResult.error || !importAssessmentResult?.assessment?.assessment_results) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `Import failed: ${importAssessmentResult?.error}`, notificationType: 'error' }))
        }
        else {
            const rowsCount = importAssessmentResult.assessment.assessment_results.length
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `Import completed successfully. ${rowsCount} answers imported.` }))
        }
        setIsImportPopupOpen(false)
        updateAssessments()
    }

    const assessmentTitleClick = () => {
        setIsInfoOpenedFromClient(true)
        setIsGeneralInfoPopupOpen(true)
    }

    useEffect(async () => {
        if (firstVisitId) {
            setIsFirstVisit(true)
        } else {
            setIsLoading(true)
        }
        await updateAssessments()
        setIsLoading(false)
    }, [])

    return (
        <div className={(isTenant() ? 'page-content-no-menu' : 'page-content') + ' assessment-management'}>
            {isFirstVisit ? <AssessmentFirst startAnswer={startAnswer} /> : isLoading ?
                <Loader /> :
                <>
                    <div className='page-header'>
                        <h1 id='assessment-management-title'>{ASSESSMENT}s Management</h1>
                    </div>
                    <div className='assessment-management-con'>
                        <SortableTable
                            columns={assessmentsColumns}
                            tableData={tableData}
                            menuClickHandler={menuClickHandler}
                            handleTdClick={handleTdClick}
                            tableId='assessments-table' />
                    </div>
                </>}
            {(!isGeneralInfoPopupOpen || isInfoOpenedFromClient) && <Popup
                isOpen={isPopupOpen}
                title=''
                onClose={closePopup}
                isDisabledClickOutside={true}
                customClass='questionnaire-popup'>
                {isLoadingAssessment ? <Loader /> :
                    (selectedQuestionnaire && selectedAssessment ?
                        <>
                            <TenantOnboardingAssessment />
                            <QuestionnaireClient
                                questionnaire={selectedQuestionnaire}
                                answers={answers}
                                isReview={selectedAssessment.status === assessmentStatusesEnum.submitted}
                                saveAnswers={saveAnswers}
                                submitAssessment={() => setIsSubmitPopupOpen(true)}
                                reopenAssessment={() => reopenAssessment(selectedAssessment.id)}
                                assessmentTitleClick={assessmentTitleClick} /> 
                        </> : <Alert message={`Nodata for this ${ASSESSMENT}`} alertType='neutral' />
                        
                    )}
            </Popup>}
            <Popup
                isOpen={isGeneralInfoPopupOpen}
                title='General Information'
                onClose={isInfoOpenedFromClient ? displayAssessment : closePopup}
                isDisabledClickOutside={true}
                customClass='extra-large assessment-general-info'>
                {isLoadingAssessment ? <Loader /> : 
                    selectedQuestionnaire ? <AssessmentGeneralInfo 
                        selectedQuestionnaire={selectedQuestionnaire}
                        selectedActivity={selectedActivity} 
                        isInfoOpenedFromClient={isInfoOpenedFromClient}
                        closePopup={closePopup} 
                        displayAssessment={displayAssessment} /> : 
                        <Alert message={`Nodata for this ${ASSESSMENT}`} alertType='neutral' />
                }
            </Popup>
            <Popup
                isOpen={isImportPopupOpen}
                onClose={() => setIsImportPopupOpen(false)} 
                title={<div><i className='icon-warning'></i> {genericTexts.defaultText.deletePopupTitle}</div>}
                isDisabledClickOutside={true}>
                <p> By importing the file, existing data will be overwritten. This action cannot be undone.</p>
                <div className='hidden-row'><InputRow
                    inputId='fileUpload'
                    title='Import Assessment'
                    inputType='file'
                    changeValueHandler={importAssessment} /></div>

                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={importAssessmentConfirmClick} id='got-it'>Got It</button>
                </div>
            </Popup>
            <Popup
                isOpen={isSubmitPopupOpen}
                onClose={() => setIsSubmitPopupOpen(false)}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>Are you sure you want to submit the assessment?</p>
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={() => isPopupOpen ? submitAssessmentFromEdit() : submitAssessment(selectedAssessmentId)} id='submit-assessment'>{genericTexts.defaultText.yes}</button>
                    <button onClick={() => setIsSubmitPopupOpen(false)} className='btn-cancel' id='submit-assessment-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
        </div>
    )
}

const Assessments = connect(mapStateToProps)(AssessmentsComponent)
export default Assessments