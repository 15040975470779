import React from 'react'
import './Settings.scss'
import ChangePassword from './ProfileSections/ChangePassword'
import UserDetails from './ProfileSections/UserDetails'

const Profile = ({ logOut = () => {} }) => {
    return (<div className='page-content profile'>
        <div className='page-header'>
            <h1 id='profile-page'>Profile</h1>
        </div>
        <div className='settings-con'>
            <UserDetails />
            <ChangePassword logOut={logOut} />
        </div>
    </div>)
}

export default Profile