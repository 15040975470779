import { AreaChartCustom, BarChartCustom, PieChartCustom, RadarChartCustom, WidgetBlock } from 'hub-web-lib'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import React, { useEffect, useState } from 'react'
import './RedTeam.scss'
import { getByCategoryChartData, getByImpactChartData, getBySeverityChartData, getBySeverityQuarterlyChartData, getCategoryByYearChartData, getRiskLevelChartData, getRiskLevelTrendChartData } from 'js/ui-helpers/red-team-dashboard'

const RedTeam = ({ metricsData, metricsProps, filter, isAnimationActive }) => {
    const [charts, setCharts] = useState({})

    useEffect(() => {
        const { riskLevelChartData, riskLevelChartDetails } = getRiskLevelChartData(metricsData, metricsProps, filter)
        const { byCategoryChartData, byCategoryChartDetails } = getByCategoryChartData(metricsData)
        const { byImpactChartData, byImpactChartDetails } = getByImpactChartData(metricsData, metricsProps, filter)
        const { bySeverityChartData, bySeverityChartDetails } = getBySeverityChartData(metricsData, metricsProps, filter)
        const { bySeverityQuarterlyChartData, bySeverityQuarterlyChartDetails } = getBySeverityQuarterlyChartData(metricsData, metricsProps, filter)
        const { riskLevelTrendChartData, riskLevelTrendChartDetails } = getRiskLevelTrendChartData(metricsData, metricsProps, filter)
        const { categoryByYearChartData, categoryByYearChartDetails } = getCategoryByYearChartData(metricsData)
        setCharts({
            riskLevelChartData, 
            riskLevelChartDetails,
            byCategoryChartData, 
            byCategoryChartDetails,
            byImpactChartData, 
            byImpactChartDetails,
            bySeverityChartData, 
            bySeverityChartDetails,
            bySeverityQuarterlyChartData, 
            bySeverityQuarterlyChartDetails,
            riskLevelTrendChartData,
            riskLevelTrendChartDetails,
            categoryByYearChartData,
            categoryByYearChartDetails
        })
    }, [])

    const findingsDonats = <>
        <div className='red-team-widgets-content-block'>
            <h4>By Category</h4>
            {charts?.byCategoryChartData?.length ? <>
                <PieChartCustom chartData={charts.byCategoryChartData} chartDetails={{ ...charts.byCategoryChartDetails, isAnimationActive }} />
            </> : <NoDataPlaceholder />}
        </div>
        <div className='red-team-widgets-content-block'>
            <h4>By Impact</h4>
            {charts?.byImpactChartData?.length ? <>
                <PieChartCustom chartData={charts.byImpactChartData} chartDetails={{ ...charts.byImpactChartDetails, isAnimationActive }} />
            </> : <NoDataPlaceholder />}
        </div>
    </>

    return (
        <>
            <div className='module-widgets-row radar'>
                <WidgetBlock title='Risk Level of Findings by Threats Category' content={charts?.riskLevelChartData?.length ?
                    <RadarChartCustom chartData={charts.riskLevelChartData} chartDetails={{ ...charts.riskLevelChartDetails, isAnimationActive }}  /> :
                    <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row grid-1-2'>
                <div className='red-team-donats'>
                    <WidgetBlock content={findingsDonats} />
                </div>
                <div className='red-team-bars'>
                    <WidgetBlock title='Findings by Severity' content={charts?.bySeverityChartData?.length ?
                        <BarChartCustom chartData={charts.bySeverityChartData} chartDetails={{ ...charts.bySeverityChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
                    <WidgetBlock title='Finding by Severity - Periods Comparison' content={charts.bySeverityQuarterlyChartData?.length ?
                        <BarChartCustom chartData={charts.bySeverityQuarterlyChartData} chartDetails={{ ...charts.bySeverityQuarterlyChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
                </div>
            </div>
            <div className='module-widgets-row'>
                <WidgetBlock title='Findings’ Risk Level Trends Over Time' content={charts?.riskLevelTrendChartData?.length ?
                    <AreaChartCustom chartData={charts.riskLevelTrendChartData} chartDetails={{ ...charts.riskLevelTrendChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
            <div className='module-widgets-row'>
                <WidgetBlock title='Findings’ Category by Years' content={charts?.categoryByYearChartData?.length ?
                    <BarChartCustom chartData={charts.categoryByYearChartData} chartDetails={{ ...charts.categoryByYearChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />} />
            </div>
        </>
                
    )
}

export default RedTeam