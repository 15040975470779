import React from 'react'
import './HubRouter.scss'

import { Routes, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_ERROR_CONFIG } from 'js/constants/action-types'
import { sideMenuItems, headerMenuItems, home360CenterMenu } from 'js/constants/main-menus'
import { Main, NotFound, UnderConstruction, helpers } from 'hub-web-lib'
import { getEnabledSettingItems } from 'js/ui-helpers/settings'

import DdosSimulation from 'components/Views/DdosSimulation/DdosSimulation'
import InternalThreats from 'components/Views/InternalThreats/InternalThreats'
import InternalThreatsAssets from 'components/Views/InternalThreats/InternalThreatsAssets/InternalThreatsAssets'
import Scheduler from 'components/Views/Scheduler/Scheduler'

import About from 'components/Views/About/About'
import UserManagement from 'components/Views/UserManagement/UserManagement'
import OrgManagement from 'components/Views/OrgManagement/OrgManagement'
import Profile from 'components/Views/Settings/Profile'

import PTAssets from 'components/Views/PT/Dashboards/PTAssets'
import PTReports from 'components/Views/PT/Dashboards/PTReports'
import PTFindings from 'components/Views/PT/Dashboards/PTFindings'

import AssetManagement from 'components/Views/PT/PTManagement/AssetManagement'
import Settings from 'components/Views/Settings/Settings'
import ReportManagement from 'components/Views/PT/PTManagement/ReportManagement'
import ReportInfo from 'components/Views/PT/PTManagement/ReportInfo'
import NewReport from 'components/Views/PT/PTManagement/NewReport'
import QAActivityManagement from 'components/Views/CyberReadiness/Activities/QAActivityManagement'
import QAActivityInfo from 'components/Views/CyberReadiness/Activities/ActivityInfo/QAActivityInfo'
import EasmActivityManagement from 'components/Views/AttackSurface/Activities/EasmActivityManagement'
import EasmActivityInfo from 'components/Views/AttackSurface/Activities/ActivityInfo/EasmActivityInfo'
import HomeDashboard from 'components/Views/Home/HomeDashboard'
import Assessments from 'components/Views/CyberReadiness/Assessments/Assessments'
import AssessmentInfo from 'components/Views/CyberReadiness/Assessments/AssessmentInfo/AssessmentInfo'
import TemplatesList from 'components/Views/CyberReadiness/QuestionnaireTemplates/TemplatesList'
import TemplateInfo from 'components/Views/CyberReadiness/QuestionnaireTemplates/TemplateInfo/TemplateInfo'
import RedTeamActivityManagement from 'components/Views/RedTeam/Activities/RedTeamActivityManagement'
import RedTeamActivityInfo from 'components/Views/RedTeam/Activities/ActivityInfo/RedTeamActivityInfo'
import PenTestActivityManagement from 'components/Views/PenTest/Activities/PenTestActivityManagement'
import PenTestActivityInfo from 'components/Views/PenTest/Activities/ActivityInfo/PenTestActivityInfo'
import DdosActivityManagement from 'components/Views/Ddos/Activities/DdosActivityManagement'
import DdosActivityInfo from 'components/Views/Ddos/Activities/ActivityInfo/DdosActivityInfo'
import { getUserRole } from 'js/ui-helpers/roles'
import { ACTIVITY_PATH, ASSESSMENTS_PATH, ATTACK_SURFACE_MODULE, CYBER_READINESS_MODULE, DASHBOARD_PATH, DDOS_RESILIENCE_MODULE, PEN_TEST_MODULE, QUESTIONNAIRES_PATH, RED_TEAM_MODULE, RISK_ASSESSMENT_MODULE, appModulesPaths } from 'js/constants/app-modules'
import { APPLICATION, INFRASTRUCTURE } from 'js/constants/enums/pen-test'
import RiskAssessmentActivityManagement from 'components/Views/RiskAssessment/Activities/RiskAssessmentActivityManagement'
import RiskAssessmentActivityInfo from 'components/Views/RiskAssessment/Activities/ActivityInfo/RiskAssessmentActivityInfo'
import DashboardActivityGeneric from 'components/Modules/Activity/DashboardActivityGeneric/DashboardActivityGeneric'
import TanentDashboard from 'components/Views/Tenant/TenantDashboard'

const mapStateToProps = state => {
    return {
        showErrorPage: state.errorConfig.showErrorPage,
        errorConfig: state.errorConfig,
        currentUser: state.currentUser,
        visibleModules: state.visibleModules

    }
}

const mapDispatchToProps = (dispatch) => {
    return { updateErrorConfig: val => dispatch(action(UPDATE_ERROR_CONFIG, val)) }
}

const existingLinks360 = [...helpers.getLinksFromMenu(sideMenuItems), ...helpers.getLinksFromMenu(headerMenuItems), ...helpers.getLinksFromMenu(home360CenterMenu)]

const HubRouterDetails = ({ showErrorPage, updateErrorConfig, errorConfig, currentUser, visibleModules, routerData }) => {

    const isAdmin = getUserRole(currentUser) === 1
    const isAnalyst = getUserRole(currentUser) === 2
    const isReporter = getUserRole(currentUser) === 3

    const visibleMods = getEnabledSettingItems(visibleModules)
    return (
        <Routes location={location} >
            <Route exact path='/' element={<Main showBreadCrumbs={false} showErrorPage={showErrorPage} updateErrorConfig={updateErrorConfig} errorConfig={errorConfig} />} >
                <Route index element={<HomeDashboard />} />
                <Route path='ddos-simulation' element={<DdosSimulation />} />
                <Route path='internal-threats' element={<InternalThreats />} />
                <Route path='internal-threats/:id' element={<InternalThreatsAssets />} />
                {visibleMods.pt && <><Route path='pt/dashboard' element={<PTAssets />} />
                    <Route path='pt/dashboard/:id' element={<PTReports />} />
                    <Route path='pt/dashboard/:id/:rid/:fid' element={<PTFindings />} />
                    <Route path='pt/assets' element={isAdmin ? <AssetManagement /> : <NotFound />} />
                    <Route path='pt/activities' element={isAdmin ? <ReportManagement /> : <NotFound />} />
                    <Route path='pt/activities/:rid' element={isAdmin ? <ReportInfo /> : <NotFound />} />
                    <Route path='pt/activities/new' element={isAdmin ? <NewReport /> : <NotFound />} />
                </>}
                <Route path='scheduler' element={<Scheduler />} />
                <Route path='admin/orgs' element={isAdmin ? <OrgManagement routerData={routerData} /> : <NotFound />} />
                <Route path='admin/users' element={isAdmin ? <UserManagement /> : <NotFound />} />
                <Route path={`tenant/${DASHBOARD_PATH}`} element={<TanentDashboard />} />
                <Route path={`tenant/${ASSESSMENTS_PATH}`} element={<Assessments />} />
                {visibleMods[CYBER_READINESS_MODULE] && <>
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${DASHBOARD_PATH}`} element={<DashboardActivityGeneric module={CYBER_READINESS_MODULE} />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <QAActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <QAActivityInfo /> : <NotFound />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${QUESTIONNAIRES_PATH}`} element={isAdmin || isAnalyst ? <TemplatesList /> : <NotFound />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${QUESTIONNAIRES_PATH}/:rid`} element={isAdmin || isAnalyst ? <TemplateInfo /> : <NotFound />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${ASSESSMENTS_PATH}`} element={isAdmin || isAnalyst || isReporter ? <Assessments /> : <NotFound />} />
                    <Route path={`${appModulesPaths[CYBER_READINESS_MODULE]}/${ASSESSMENTS_PATH}/:rid`} element={isAdmin || isAnalyst || isReporter ? <AssessmentInfo /> : <NotFound />} />
                </>}
                {visibleMods[ATTACK_SURFACE_MODULE] && <>
                    <Route path={`${appModulesPaths[ATTACK_SURFACE_MODULE]}/${DASHBOARD_PATH}`} element={<DashboardActivityGeneric module={ATTACK_SURFACE_MODULE} />} />
                    <Route path={`${appModulesPaths[ATTACK_SURFACE_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <EasmActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[ATTACK_SURFACE_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <EasmActivityInfo /> : <NotFound />} />
                </>}
                {visibleMods[RED_TEAM_MODULE] && <>
                    
                    <Route path={`${appModulesPaths[RED_TEAM_MODULE]}/${DASHBOARD_PATH}`} element={<DashboardActivityGeneric module={RED_TEAM_MODULE} />} />
                    <Route path={`${appModulesPaths[RED_TEAM_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <RedTeamActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[RED_TEAM_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <RedTeamActivityInfo /> : <NotFound />} />
                </>}
                {visibleMods[PEN_TEST_MODULE] && <>
                    <Route path={`${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${APPLICATION}`} element={<DashboardActivityGeneric module={PEN_TEST_MODULE} modulePropKey='test_type' modulePropValue={APPLICATION} />} />
                    <Route path={`${appModulesPaths[PEN_TEST_MODULE]}/${DASHBOARD_PATH}-${INFRASTRUCTURE}`} element={<DashboardActivityGeneric module={PEN_TEST_MODULE} modulePropKey='test_type' modulePropValue={INFRASTRUCTURE} />} />
                    <Route path={`${appModulesPaths[PEN_TEST_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <PenTestActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[PEN_TEST_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <PenTestActivityInfo /> : <NotFound />} />
                </>}

                {visibleMods[DDOS_RESILIENCE_MODULE] && <>
                    <Route path={`${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${DASHBOARD_PATH}`} element={<DashboardActivityGeneric module={DDOS_RESILIENCE_MODULE} />} />
                    <Route path={`${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <DdosActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <DdosActivityInfo /> : <NotFound />} />
                </>}
                {visibleMods[RISK_ASSESSMENT_MODULE] && <>
                    <Route path={`${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${DASHBOARD_PATH}`} element={<DashboardActivityGeneric module={RISK_ASSESSMENT_MODULE} />} />
                    <Route path={`${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${ACTIVITY_PATH}`} element={isAdmin || isAnalyst ? <RiskAssessmentActivityManagement /> : <NotFound />} />
                    <Route path={`${appModulesPaths[RISK_ASSESSMENT_MODULE]}/${ACTIVITY_PATH}/:rid`} element={isAdmin || isAnalyst ? <RiskAssessmentActivityInfo /> : <NotFound />} />
                </>}
                <Route path='profile' element={<Profile logOut={routerData.logOut} />} />
                <Route path='settings' element={<Settings />} />
                <Route path='admin/current-org' element={<Settings />} />
                {existingLinks360.map(item => (
                    <Route key={item.link} path={item.link} element={<UnderConstruction pageTitle={item.name} />} />
                ))}
                <Route path='about' element={<About />} />
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}

const HubRouter = connect(mapStateToProps, mapDispatchToProps)(HubRouterDetails)
export default HubRouter

