import React, { useState } from 'react'
import './TemplatePossibleAnswers.scss'
import TemplatePossibleAnswer from './TemplatePossibleAnswer'
import { Popup, genericTexts, helpers } from 'hub-web-lib'
import { questionnairePossibleAnswerModel } from 'js/constants/models/questionnaire-template'

const TemplatePossibleAnswers = ({
    question,
    isApproved,
    optionIconType,
    id,
    updatePossibleAnswerProp = () => {},
    updateProp = () => {}
}) => {

    const [optionToDeleteIndex, setOptionToDeleteIndex] = useState(-1)
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)

    const addOption = () => {
        const answers = question.possible_answers || []
        const updatedOptions = [...answers, {
            ...helpers.createNewInstance(questionnairePossibleAnswerModel),
            name: `Option ${answers.length + 1}`,
            order: answers?.length ?  answers.length + 1 : 1
        }]
        updateProp(updatedOptions, 'possible_answers')
    }
    const removeOptionHandler = (i) => {
        setOptionToDeleteIndex(i)
        setIsOpenDeletePopup(true)
    }

    const closeDeletePopup = () => {
        setOptionToDeleteIndex(-1)
        setIsOpenDeletePopup(false)
    }

    const removeOption = () => {
        const updatedOptions = [...(question.possible_answers || [])].filter((_, index) => optionToDeleteIndex !== index)
        updateProp(updatedOptions, 'possible_answers')
        closeDeletePopup()
    }

    const changeOption = (val, key, i) => {
        updatePossibleAnswerProp(val, i, key)
    }
    return (
        <>
            <ul className='question-options'>
                {!!(question.possible_answers?.length) && question.possible_answers.sort((a, b) => a.order - b.order).map((a, i) => 
                    <TemplatePossibleAnswer
                        key={i}
                        answer={a}
                        index={i}
                        optionIconType={optionIconType}
                        id={id}
                        changeOption={changeOption}
                        removeOption={removeOptionHandler}
                        isApproved={isApproved} />
                )}
                {!isApproved && <li>
                    <span className={`option-icon ${optionIconType}`}></span>                   
                    <button className='btn-link' id={`add-possible-answer-${id}`} onClick={addOption}>Add Option</button>
                </li>}
            </ul>

            <Popup
                onClose={closeDeletePopup}
                isOpen={isOpenDeletePopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>{genericTexts.defaultText.deletePopupText('answer')}</p>
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={removeOption} id='delete-posible-answer'>{genericTexts.defaultText.yes}</button>
                    <button onClick={closeDeletePopup} className='btn-cancel' id='delete-posible-answer-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
        </>
    )
}

export default TemplatePossibleAnswers
