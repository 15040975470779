import { ACCEPT, ACCESS_CONTROL, APPROVAL, AVOID, BY_CATEGORY, DENIED, HIGH, INFOMATIONAL, IN_PROCESS, LOW, LOW_PROP, MEDIUM, MITIGATE, PENDING, TRANSFER, VULNERABILITY_MANAGEMENT, BY_RISK, PIE, VERY_LOW } from './common-metrics'

const RISK_LEVEL = 'Risk Level'
const RISK_MATRIX = 'Risk Matrix'
const RISK_STATUS = 'Risk Status'
const RISK_BY_CATEGORY = 'Risk by Category'
const RISK_RESPONSE = 'Risk Response'
const DOMAIN_RISK_LEVEL = `Domain - ${RISK_LEVEL}`
const DOMAIN_RISK_BY_CATEGORY = `Domain - ${RISK_BY_CATEGORY}`
const DOMAIN_RESPONSE = 'Domain - Response'

const IMPACT = 'Impact'
const RESPONSE = 'Response'
const HARDENING_REVIEW_MACOS = 'Hardening Review - MacOS'
const HARDENING_REVIEW_WINDOWS = 'Hardening Review - Windows'
const ARCHITECTURE_REVIEW = 'Architecture Review'
const LIKELIHOOD = 'Likelihood'

const NETWORK_SECURITY = 'Network Security'
const MONITORING_AND_LOGGING = 'Monitoring and Logging'
const DATA_PROTECTION = 'Data Protection'

const riskAssessmentMetricsCategories = [
    RISK_LEVEL,
    RISK_MATRIX,
    RISK_STATUS,
    RISK_BY_CATEGORY,
    RISK_RESPONSE,
    DOMAIN_RISK_LEVEL,
    DOMAIN_RISK_BY_CATEGORY,
    DOMAIN_RESPONSE
]

const riskAssessmentMetricsWidgets = [
    PIE,
    LIKELIHOOD,
    IMPACT,
    RISK_STATUS,
    BY_CATEGORY,
    RESPONSE,
    HARDENING_REVIEW_MACOS,
    HARDENING_REVIEW_WINDOWS,
    ARCHITECTURE_REVIEW
]

const riskAssessmentMetricsKeys = [
    BY_RISK,
    LOW,
    MEDIUM,
    HIGH,
    IN_PROCESS,
    PENDING,
    APPROVAL,
    DENIED,
    VULNERABILITY_MANAGEMENT,
    NETWORK_SECURITY,
    ACCESS_CONTROL,
    MONITORING_AND_LOGGING,
    DATA_PROTECTION,
    AVOID, 
    MITIGATE,
    TRANSFER,
    ACCEPT,
    RISK_LEVEL,
]

const getMetricsLabelDotsByTemplateType = (labelDotsItems, templateType) => {
    const items = [...labelDotsItems]
    const informationalItemIndex = items.findIndex(i => i.status === LOW_PROP)
    items[informationalItemIndex].label = templateType === 2 ? VERY_LOW : INFOMATIONAL
    return items
}

export {
    riskAssessmentMetricsKeys,
    riskAssessmentMetricsCategories,
    riskAssessmentMetricsWidgets,
    getMetricsLabelDotsByTemplateType,
    LIKELIHOOD,
    IMPACT,
    RISK_LEVEL,
    RISK_MATRIX,
    RISK_STATUS,
    RISK_BY_CATEGORY,
    RISK_RESPONSE,
    RESPONSE,
    DOMAIN_RESPONSE,
    DOMAIN_RISK_BY_CATEGORY,
    DOMAIN_RISK_LEVEL
}