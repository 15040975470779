const CATEGORY = 'category'
const WIDGET = 'widget'
const KEY = 'key'
const metricsTextProps = [CATEGORY, WIDGET, KEY]

const PRIMARY = 'primary'
const WARNING = 'warning'
const MEDIUM_WARNING = 'medium-warning'
const ERROR = 'error'
const NEUTRAL = 'neutral'

const METRICS_TYPE = 'metrics'
const FINDINGS_TYPE = 'findings'

const IS_EDITABLE = 'is_editable'
const GENERAL = 'General'
const TECHNOLOGY = 'Technology'
const PEOPLE = 'People'
const PROCESSES = 'Processes'
const TOTAL = 'Total'

const OTHER = 'Other'
const LOW = 'Low'
const MEDIUM = 'Medium'
const HIGH = 'High'
const CRITICAL = 'Critical'
const OTHER_PROP = 'other'
const LOW_PROP = 'low'
const MEDIUM_PROP = 'medium'
const HIGH_PROP = 'high'
const CRITICAL_PROP = 'critical'
const VERY_HIGHT = 'Very High'
const VERY_LOW = 'Very Low'
const VERY_HIGHT_PROP = 'very_high'
const INFOMATIONAL = 'Informational'
const INFOMATIONAL_PROP = 'informational'
const NONE = 'None'
const NONE_PROP = 'none'

const FULLY_IMPLEMENTED = 'Fully Implemented'
const PARTIALLY_IMPLEMENTED = 'Partially Implemented'
const NOT_IMPLEMENTED = 'Not Implemented'
const FULLY = 'Fully'
const PARTIALLY = 'Partially'
const NOT = 'Not'
const FULLY_PROP = 'fully_implemented'
const PARTIALLY_PROP = 'partially_implemented'
const NOT_PROP = 'not_implemented'

const ACTIVE = 'Active'
const NEW_ALERTS = 'New Alerts'
const ACTIVE_ALERTS = 'Active Alerts'
const FINDINGS = 'Findings'
const FINDINGS_SEVERITY = 'Findings Severity'
const EXTERNAL_THREATS = 'External Threats'
const INTERNAL_THREATS = 'Internal Threats'
const READINESS_BY_CATEGORY = 'Readiness by Category'
const SCORING_BY_CATEGORY = 'Scoring By Category'
const SCORING_BY_DOMAIN = 'Scoring By Domain'
const LEVEL_OF_READINESS = 'Level of Readiness'
const CHART_VALUES = 'Chart Values'
const CHART_PERCENTAGE = 'Chart Percentage'
const IMPLEMENTATION_OF_SECURITY_CONTROL = 'Implementation of Security Control'
const YEARLY_MEAN_TIME = 'Yearly Mean Time'
const RISK_LEVEL_BY_CATEGORY= 'Risk Level By Category'
const OPEN = 'Open'
const ACKNOWLEDGE = 'Acknowledge'
const MEAN_TIME_TO_ACKNOWLEDGE = 'Mean Time to Acknowledge'
const MEAN_TIME_TO_RESOLVE = 'Mean Time to Resolve'
const BY_AGE = 'By Age'
const BY_SEVERITY = 'By Severity'
const BY_QUARTER = 'By Quarter'
const BY_TYPE = 'By Type'
const BY_MONTH = 'By Month'
const ALERTS_BY_SEVERITY = 'Alerts By Severity'
const RESOLVED = 'Resolved'
const CARDS = 'Cards'
const PIE = 'Pie'
const AREA = 'Area'
const BY_RISK = 'By Risk'
const FIXED = 'Fixed'
const MITIGATED = 'Mitigated'
const PARTIALLY_FIXED = 'Partially Fixed'
const NOT_FIXED = 'Not Fixed'
const NOT_RETESTED = 'Not Retested'

const RADAR = 'Radar'
const BY_CATEGORY = 'By Category'
const CHAIN_OF_THE_ATTACK = 'Chain of the Attack'
const BREACHING_THE_INTERNAL_PERIMETER = 'Breaching the Internal Perimeter'
const INTERNAL_ATTACK = 'Internal Attack'
const EXTERNAL_ATTACK = 'External Attack'
const RISK_LEVEL_TREND = 'Risk Level Trend'
const CATEGORY_BY_YEAR = 'Category By Year'
const CATEGORY_BY_SEVERITY = 'Category By Severity'
const BY_IMPACT = 'By Impact'
const APPLICATION_PT = 'Application PT'
const INFRASTRUCTURE_PT = 'Infrastructure PT'
const VULNERABILITY_MANAGEMENT = 'Vulnerability Management'
const ACCESS_CONTROL = 'Access Control'

const IN_PROCESS = 'In Process'
const PENDING = 'Pending'
const APPROVAL = 'Approval'
const DENIED = 'Denied'

const AVOID = 'Avoid'
const MITIGATE = 'Mitigate'
const TRANSFER = 'Transfer'
const ACCEPT = 'Accept'

const valuesPropsGeneric = [OTHER_PROP, INFOMATIONAL_PROP, LOW_PROP, MEDIUM_PROP, HIGH_PROP, CRITICAL_PROP]

const otherValueProps = {
    id: OTHER_PROP,
    name: OTHER,
    color: '#A3A1A1',
}

const metricsColumnsEdit = {
    key: 'edit',
    name: '',
    type: 'component',
    filterable: false,
    sortable: false
}

const metricsColumnInfoEdit = {
    key: 'edit',
    name: 'Editable',
    type: 'string'
}

const metricsColumnsText = [{
    key: CATEGORY,
    name: 'Category',
    type: 'string',
    width: 20
}, {
    key: WIDGET,
    name: 'Widget',
    type: 'string',
    width: 15
}, {
    key: KEY,
    name: 'Key',
    type: 'string',
    width: 15
}]

const metricsColumnsTextForm = [{
    key: 'category_select',
    name: 'Category',
    type: 'component',
    sortBy: CATEGORY
}, {
    key: 'widget_select',
    name: 'Widget',
    type: 'component',
    sortBy: WIDGET
}, {
    key: 'key_select',
    name: 'Key',
    type: 'component',
    sortBy: KEY
}]

export {
    CATEGORY,
    WIDGET,
    KEY,
    metricsTextProps,
    PRIMARY,
    WARNING,
    MEDIUM_WARNING,
    ERROR,
    NEUTRAL,
    METRICS_TYPE,
    FINDINGS_TYPE,
    IS_EDITABLE,
    GENERAL,
    TECHNOLOGY,
    PEOPLE,
    PROCESSES,
    TOTAL,
    OTHER,
    OTHER_PROP,
    LOW,
    LOW_PROP,
    MEDIUM,
    MEDIUM_PROP,
    HIGH,
    HIGH_PROP,
    VERY_HIGHT,
    VERY_HIGHT_PROP,
    VERY_LOW,
    CRITICAL,
    CRITICAL_PROP,
    valuesPropsGeneric,
    otherValueProps,

    FULLY_IMPLEMENTED,
    PARTIALLY_IMPLEMENTED,
    NOT_IMPLEMENTED,
    FULLY,
    PARTIALLY,
    NOT,
    FULLY_PROP,
    PARTIALLY_PROP,
    NOT_PROP,

    INFOMATIONAL,
    INFOMATIONAL_PROP,
    NONE,
    NONE_PROP,

    ACTIVE,
    NEW_ALERTS,
    ACTIVE_ALERTS,
    FINDINGS,
    FINDINGS_SEVERITY,
    EXTERNAL_THREATS,
    INTERNAL_THREATS,
    READINESS_BY_CATEGORY,
    SCORING_BY_CATEGORY,
    LEVEL_OF_READINESS,
    CHART_VALUES,
    CHART_PERCENTAGE,
    IMPLEMENTATION_OF_SECURITY_CONTROL,
    YEARLY_MEAN_TIME,
    RISK_LEVEL_BY_CATEGORY,
    OPEN,
    ACKNOWLEDGE,
    MEAN_TIME_TO_ACKNOWLEDGE,
    MEAN_TIME_TO_RESOLVE,
    BY_AGE,
    BY_SEVERITY,
    BY_QUARTER,
    BY_TYPE,
    BY_MONTH,
    CARDS,
    PIE,
    AREA,
    BY_RISK,
    FIXED,
    MITIGATED,
    PARTIALLY_FIXED,
    NOT_FIXED,
    NOT_RETESTED,
    ALERTS_BY_SEVERITY,
    RESOLVED,
    RADAR,
    BY_CATEGORY,
    CHAIN_OF_THE_ATTACK,
    BREACHING_THE_INTERNAL_PERIMETER,
    INTERNAL_ATTACK,
    EXTERNAL_ATTACK,
    RISK_LEVEL_TREND,
    CATEGORY_BY_YEAR,
    CATEGORY_BY_SEVERITY,
    BY_IMPACT,
    APPLICATION_PT,
    INFRASTRUCTURE_PT,
    SCORING_BY_DOMAIN,

    metricsColumnsEdit,
    metricsColumnInfoEdit,
    metricsColumnsText,
    metricsColumnsTextForm,
    VULNERABILITY_MANAGEMENT,
    ACCESS_CONTROL,

    IN_PROCESS,
    PENDING,
    APPROVAL,
    DENIED,
    AVOID, 
    MITIGATE,
    TRANSFER,
    ACCEPT
}