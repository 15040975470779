import { EllipsisText, ProgressBar } from 'hub-web-lib'
import { ASSESSMENT } from 'js/constants/vocabulary'
import React from 'react'
import './AssessmentQuestionsHeader.scss'

const AssessmentQuestionsHeader = ({ 
    category, 
    questions, 
    isButtonLoading,
    isReview
}) => {

    const answeredQuestionsCount = questions.filter(q => q.isAnswered).length

    return (
        <div className='questions-header'>
            <div className='questions-header-summary'>
                <div className='category-name'>
                    <span className='category-name-prefix'>{ASSESSMENT}:</span>
                    <EllipsisText text={category?.name} noBreak={true} width={450} />
                </div>
                <div className='category-progress'>
                    <p className='answered-questions'>{answeredQuestionsCount} of {questions.length} answered</p>
                    {!isReview && <button className={`btn-link ${isButtonLoading ? 'loader' : ''}`} id='save-answers-progress'>{isButtonLoading ? 'Saving Progress' : <><i className='icon-v'></i> Progress Saved</>}</button>}
                </div>
            </div>
            <div className='category-description-assessment'>
                {category?.description}
            </div>
            <ProgressBar percent={answeredQuestionsCount / questions.length * 100 } type='primary' isSimpleLine={true} />
        </div>
    )
}

export default AssessmentQuestionsHeader
