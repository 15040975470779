import React from 'react'
import './DdosSimulation.scss'
import { HomeMenu } from 'hub-web-lib'
import { WidgetBlock } from 'hub-web-lib'
import { MegaProgressItem } from 'hub-web-lib'
import { WidgetList } from 'hub-web-lib'
import { Accordion } from 'hub-web-lib'
import { ddossMenu } from 'js/constants/main-menus'
import { activitiesList, suggestionsList } from 'js/constants/lorem-ipsum'

export const DdosSimulation = () => {

    const securePercent = 72
    return (
        <div className='ddoss page-content'>
            <div className='dashboard'>
                <div className='dashboard-center'>
                    <div className='ddoss-center-progress'>
                        <h2 id='ddos-protection'>DDoS Protection </h2>
                        <MegaProgressItem circularItem={ { percent: securePercent } } outerContent={ { title: 'DDoS Simulation', date: new Date(2022, 4, 22, 10, 33, 30, 0), resultText: 'Result Impact on score:', percent: -2 } } size='large' />
                    </div>
                    <HomeMenu menuItems={ddossMenu}></HomeMenu>
                </div>
                <div className='dashboard-right'>

                    <WidgetBlock 
                        title='Critical Findings' 
                        icon={ { icon: 'settings', link: '/' } }
                        content={ <p className='large-value'>93</p> }
                        type='error'
                    />

                    <WidgetBlock 
                        title='Medium Findings' 
                        icon={ { icon: 'settings', link: '/' } }
                        content={ <p className='large-value'>132</p> }
                        type='warning'
                    />

                    <WidgetBlock 
                        title='Low Findings' 
                        icon={ { icon: 'settings', link: '/' } }
                        content={ <p className='large-value'>256</p> }
                        type='primary'
                    />

                </div>
            </div>
            <div className='bottom-widgets'>

                <WidgetBlock 
                    title='Activities List:' 
                    icon={ { icon: 'search', link: '/' } }
                    content={ <WidgetList list={activitiesList} count={ 3 } />}
                    size='small'
                />

                <WidgetBlock 
                    title='Top Suggestions'
                    content={ <Accordion list={suggestionsList} count={ 3 } voting={true} /> }
                    size='small'
                />
            </div>
        </div>
    )
}

export default DdosSimulation