import React from 'react'
import { Tabs, helpers, genericTexts } from 'hub-web-lib'
import { useState } from 'react'
import RiskAssessmentGeneralInformation from './UpdateActivityTabs/RiskAssessmentGeneralInformation'
import { ACTIVITY } from 'js/constants/vocabulary'
import { riskAssessmentModel } from 'js/constants/models/risk-assessment'
import RiskAssessmentActivityMetrics from './UpdateActivityTabs/RiskAssessmentActivityMetrics'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { emptyJsonValues, objectHasValuesForKeys } from 'middlewares/ApiHelpers'
import RiskAssessmentApi from 'services/RiskAssessmentApi'
import { getStepsErrors } from 'js/ui-helpers/risk-assessment-activity'
import { findDeletedMetrics, getNumerizedMetrics } from 'js/ui-helpers/metrics'
import { metricsTextProps, valuesPropsGeneric } from 'js/constants/metrics/common-metrics'
import { metricModel } from 'js/constants/models/metric'

const newActivityModel = helpers.createNewInstance(riskAssessmentModel)
delete newActivityModel.id
delete newActivityModel.org_id
const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(newActivityModel))

const newMetricModel = helpers.createNewInstance(metricModel)

const UpdateRiskAssessmentActivity = ({ 
    isNewActivity,
    currentUser,
    currentOrg,
    orgUsers,
    activityDetails = {},
    closeUpdateActivityPopup,   
    updateActivities,
    templateUrl,
    findingsUrl,
    templateType
}) => {
    const newActivity = {
        ...newActivityModel,
        org_id: currentOrg?.id,
        reported_by: currentOrg?.id === currentUser?.org_id ? currentUser?.id : ''
    }
    const [activityToUpdate, setActivityToUpdate] = useState(isNewActivity ? newActivity : activityDetails)
    const [activeTab, setActiveTab] = useState(0)
    const [customMetrics, setCustomMetrics] = useState([{ ...newMetricModel }])
    const providedMetrics = isNewActivity ? [] : helpers.createNewInstance(activityDetails.metrics || [])
    const [predefinedMetricsToEdit, setPredefinedMetricsToEdit] = useState(providedMetrics)
    const [errorMsg, setErrorMsg] = useState('')
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })
    const [isMetricsTabOpened, setIsMetricsTabOpened] = useState(false)
    const metrics = [...predefinedMetricsToEdit, ...customMetrics.filter(m => objectHasValuesForKeys(m, metricsTextProps))]
    const [activityFindings, setActivityFindings] = useState(isNewActivity ? [] : helpers.createNewInstance(activityDetails.findings || []))
    const stepsErrors = getStepsErrors(activityToUpdate, metrics)

    const hasFindings = !!activityFindings?.length
    const deletedMetrics = findDeletedMetrics(providedMetrics, predefinedMetricsToEdit)
    const deletedFindings = findDeletedMetrics(activityToUpdate.findings, activityFindings)

    const checkReadyToSubmit = () => {
        const errors = Object.values(stepsErrors).filter(e => e).map(e => e.message)
        if (errors.length) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: errors.join('. '), notificationType: 'error' }))
            if (stepsErrors[0]) {
                const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
                setFieldsErrorMsg(errorDetails)
            }
            stepsErrors[0] ? setActiveTab(0) : setActiveTab(1)
            return false
        }
        return true
    }

    const saveActivity = async () => {
        if (stepsErrors[0]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[0].message, notificationType: 'error' }))
            const errorDetails = { ...helpers.createNewInstance(fieldsErrorMsgDefault), ...stepsErrors[0] }
            setFieldsErrorMsg(errorDetails)
            setFieldsErrorMsg(errorDetails)
            setActiveTab(0)
            return false
        }
        const activity = { ...activityToUpdate, has_findings: hasFindings }
        delete activity.metrics
        delete activity.findings
        const activityUpdated = isNewActivity ? await RiskAssessmentApi.createActivity(activity) : await RiskAssessmentApi.updateActivity(activity)
        if (!activityUpdated || activityUpdated.error) {
            if (Array.isArray(activityUpdated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                activityUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                setErrorMsg(activityUpdated.message)
            }
            setActiveTab(0)
            return null
        }
        const activityId = isNewActivity ? activityUpdated.id : activityDetails.id
        return activityId
    }

    const saveMetrics = async (activityId) => {
        if (stepsErrors[1]) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: stepsErrors[1].message, notificationType: 'error' }))
            setActiveTab(1)
            return false
        }
        const metricsWithValues = getNumerizedMetrics(metrics, isNewActivity, valuesPropsGeneric)
        const metricsSaved = await RiskAssessmentApi.updateActivityMetrics(activityId, metricsWithValues)
        if (!metricsSaved?.message?.success) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Metrics saving failed. Please check the missed data', notificationType: 'error' }))
            setActiveTab(1)
            return false
        }
        if (deletedMetrics.length && !isNewActivity) await RiskAssessmentApi.deleteActivityMetricsList(activityId, deletedMetrics)
        return true
    }

    const saveFindings = async (activityId) => {
        if (hasFindings) await RiskAssessmentApi.updateActivityFindings(activityId, activityFindings)
        if (deletedFindings.length && !isNewActivity) await RiskAssessmentApi.deleteActivityFindingsList(activityId, deletedFindings)
        return true
    }

    const updateActivity = async () => {

        // check if all the steps are validated - if submit action
        const readyToSubmit = checkReadyToSubmit()
        if (!readyToSubmit) return

        // save activity
        const activityId = await saveActivity()
        if (!activityId) return

        // save metrics
        const metricsSaved = await saveMetrics(activityId)
        if (!metricsSaved) return

        // save findings
        const findingsSaved = await saveFindings(activityId)
        if (!findingsSaved) return
        
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: isNewActivity ? genericTexts.defaultText.createdSuccess(ACTIVITY) : genericTexts.defaultText.updatedSuccess(ACTIVITY) }))
        closeUpdateActivityPopup()
        await updateActivities()
    }


    const changeValueHandler = (key, updatedActivity) => {
        setActivityToUpdate(updatedActivity)
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })

    }
    const updateMetrics = (updatedMetrics, isPredefined = false) => {
        isPredefined ?
            setPredefinedMetricsToEdit(updatedMetrics)
            : setCustomMetrics(updatedMetrics)
    }

    const formTabs = [{
        label: 'General Information',
        contentComponent: RiskAssessmentGeneralInformation
    }, {
        label: 'Activity Metrics',
        contentComponent: RiskAssessmentActivityMetrics
    }]

    const changeMetricsTab = (isNext) => {
        let newTabIndex = isNext ? activeTab + 1 : activeTab - 1
        if (newTabIndex < 0) newTabIndex = 0
        if (newTabIndex > formTabs.length - 1) newTabIndex = formTabs.length - 1
        setActiveTab(newTabIndex)
        if (newTabIndex === 1) setIsMetricsTabOpened(true)
    }

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex)
        if (tabIndex === 1) setIsMetricsTabOpened(true)
    }

    const calculateCompletedSteps = () => {
        let steps = Object.keys(stepsErrors).filter(step => !stepsErrors[step]).map(step => step * 1)
        if (steps.includes(1) && isNewActivity && !isMetricsTabOpened) steps = steps.filter(s => s !== 1)
        return steps
    }

    const completedSteps = calculateCompletedSteps()

    const data = {
        currentOrg,
        orgUsers,
        changeValueHandler,
        activityToUpdate,
        customMetrics,
        updateMetrics,
        newMetricModel,
        predefinedMetricsToEdit,
        setFieldsErrorMsg,
        fieldsErrorMsg,
        errorMsg,
        templateUrl,
        findingsUrl,
        templateType,
        currentUser,
        hasFindings,
        setActivityFindings
    }

    return (
        <div className='update-activity-wrapper'>
            <Tabs
                layout='vertical'
                tabs={formTabs}
                activeIndex={activeTab}
                handleActiveTab={handleTabClick}
                isSteps={true}
                data={data}
                completedSteps={completedSteps} />
            <div className='form-buttons'>
                <div className='steps-buttons'>
                    {activeTab === 0 ? <button className='btn-submit' onClick={() => changeMetricsTab(true)} id='create-risk-assessment-activity-next-step'>Next</button> :
                        <>
                            <button className='btn-submit' onClick={updateActivity} id='save-risk-assessment-activity'>{genericTexts.defaultText.saveItem(ACTIVITY)}</button>
                            <button className='btn-link' onClick={() => changeMetricsTab(false)} id='create-risk-assessment-activity-previous-step'>Back</button>
                        </>}
                </div>
            </div>
        </div>
    )
}

export default UpdateRiskAssessmentActivity