import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './PT.scss'
import { Alert, Loader, BarChart, WidgetBlock, Tabs, MegaProgressItem, Accordion, helpers } from 'hub-web-lib'
import moment from 'moment'
import { findingSeverityTypes } from 'js/constants/enums/pt'
import { getScore, statusTypes, getPtData, mapRecommendations } from 'js/ui-helpers/pt'
import PTApi from 'services/PTApi'
import { connect } from 'react-redux'

const getCircularProgressBarDetails = helpers.getCircularProgressBarDetails
const transformToKebab = helpers.transformToKebab

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

export const PTReportsComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org

    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [currentAsset, setCurrentAsset] = useState({})
    const [assetReports, setAssetReports] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()

    const assetTitle = currentAsset?.system_name
    
    const openFindings = (findingStatus, tabIndex = activeTabIndex) => {
        if (!findingStatus) return
        const clickedActivity = assetReports[tabIndex]
        navigate(`${location.pathname}/${clickedActivity.id}/${transformToKebab(findingStatus)}-findings`)
    }

    const downloadPdf = async () => {
        const link = document.createElement('a')
        const clickedActivity = assetReports[activeTabIndex]
        const activityTitle = `${transformToKebab(assetTitle)}-${moment(clickedActivity.created_on).format('DD-MM-YY')}`
        link.href = clickedActivity.pdf_report_id
        link.setAttribute('download', `${activityTitle}.pdf`) 
        document.body.appendChild(link)
        link.click()
    }

    const handleActiveTab = (index) => {
        if (index !== activeTabIndex && ptAssetTabs[index]) {
            setActiveTabIndex(index)
        }
    }

    const updateReportsList = async () => {
        const assets = await PTApi.getAssets(currentOrgId, { asset_type: 1 })
        if (!assets || assets.error) return
        const assetId = (location?.pathname || '').split('/')[3]*1
        const asset = assets.find(a => a.id === assetId)
        let reports = await PTApi.getReportsByAssetId(asset?.id)
        if (!reports || reports.error) reports = []
        asset.reports = reports
        for await (const report of asset.reports) {
            // findings 
            let findings = await PTApi.getFindingsByReportId(report.id)
            if (!findings || findings.error) findings = { items: [] }
            report.findings = findings.items

            report.severity = {}
            findingSeverityTypes.forEach(severity => {
                report.severity[severity.name] = report.findings.filter(f => f.severity === severity.id)?.length || 0
            })
            report.score = getScore(report.severity)
            report.recommendations = mapRecommendations(report.main_recommendations)
        }
        setAssetReports(asset.reports)
        setCurrentAsset(asset)
        setIsLoading(false)
    }

    const reportsChart = getPtData(assetReports, { title: 'Activity Findings Summary', isShownScore: true, axisStep: 2, colorScheme: 'primary-reverse', }, 'created_on')

    useEffect( async () => {
        await updateReportsList()
    }, [])

    const ptAssetTabs = assetReports.map(report => ({
        label: <div className='tab-nav-content'>
            <div className='tab-nav-description'>
                <p className='title'>{moment(report.created_on).format('HH:mm DD MMMM')}</p>
            </div>
            <div className={`status ${getCircularProgressBarDetails(report.score)}`}>{report.score}%</div>
        </div>,
        contentComponent: () => (<div className='tab-content-activity'>
            <div className='tab-content-activity-header'>
                <h3 id='retest-report'>Retest Report</h3>
                {report.pdf_report_id && <button onClick={downloadPdf} id={`download-pt-report-${report.pdf_report_id}`}><i className='icon-download-cloud'></i>Download Report</button>}
            </div>
            <div className='tab-content-activity-row'>
                <div className='tab-content-activity-progress-bar'>
                    <MegaProgressItem 
                        circularItem={ { percent: report.score, title: 'Activity Score' } } 
                        size='large'
                        outerComponent={<ul className='scores'>
                            <h4 id={`activity-${report.id}-findings`}>Findings</h4>
                            {Object.entries(report.severity).map(([key, value]) => {
                                return (<li key={`finding-${key}`} onClick={() => openFindings(key)}>
                                    <h5 id={`activity-${report.id}-findings-${key}`}>{helpers.capitalizeString(key)}</h5>
                                    <span className={`status ${statusTypes[key]}`}>{value}</span>
                                </li>)
                            })}
                        </ul>} />
                </div>
                <div className='tab-content-activity-widgets'>
                    <WidgetBlock 
                        title='Main activity recommendations:'
                        content={ <Accordion list={report.recommendations} count={ 7 } voting={false} /> }
                    />
                </div>
            </div>
        </div>)
    }))

    return (
        <div className='pt page-content'>
            <div className='page-header'>
                <h1 id='pt-asset-activities'>{currentAsset?.system_name} Activities</h1>
            </div>
            {isLoading ? <Loader /> : assetReports.length ? <div className='pt-con'>
                <div className='pt-activity'>
                    <WidgetBlock 
                        title='Activities'
                        content={<Tabs
                            activeIndex={activeTabIndex}
                            tabs={ptAssetTabs}
                            handleActiveTab={handleActiveTab}
                            layout='vertical'
                        />}/>
                </div>
                <BarChart 
                    chartData={reportsChart} 
                    handleChartRowClick={(rowIndex) => openFindings('all', rowIndex)}
                    handleChartColumnClick={(columnIndex, rowIndex) => openFindings(reportsChart.columns[columnIndex].label, rowIndex)} />
            </div> : <Alert message={`No reports found for ${currentAsset?.system_name}`} alertType='warning' />}
        </div>
    )
}
const PTReports = connect(mapStateToProps)(PTReportsComponent)
export default PTReports