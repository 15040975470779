import React from 'react'
import { comingSoonModules } from 'js/constants/app-modules'

const getModuleVisibility = (id, appModulesVisibility) => {
    return (appModulesVisibility[id] || !Object.keys(appModulesVisibility).includes(id) && !comingSoonModules.includes(id)) ? {
        sectionId: 'navigation'
    } : {
        disabled: true,
        ribbon: <i className='icon-lock'></i>,
        sectionId: 'comingSoon'
    }
}

const getPermittedMenu = (userRole, menuItems, visibleModules) => {
    if (!menuItems) return []
    const permittedMenuItems = structuredClone(menuItems).filter((menuItem) => {
        if (isPermitted(userRole, menuItem)) {
            if (menuItem.subItems?.length) {
                menuItem.subItems = getPermittedMenu(userRole, menuItem.subItems)
            }
            return true
        }
        return false
    }).map(menuItem => (visibleModules ? { ...menuItem, ...getModuleVisibility(menuItem.id, visibleModules) } : menuItem))
    return permittedMenuItems
}

const isPermitted = (userRole, menuItem) => {
    return !menuItem.roles || menuItem.roles.includes(userRole)
}

const getUserRole = (user) => {
    if (!user?.role_id) return null
    return user.role_id[user.current_org] || user.role_id[user.org_id]
}

const getTenantProps = (currentUser, location, currentUserOrgs) => {
    const userIsTenant = currentUser?.is_tenant
    const isTenant = location.pathname.includes('tenant')
    const isHub = currentUser?.current_org === 1 && getUserRole(currentUser) === 1
    const isTenantMode = userIsTenant && isTenant && !isHub
    const demoOrg = (currentUserOrgs || []).find(org => org.name === 'Demo')
    const tenantOrg = (currentUserOrgs || []).find(org => org.name !== 'Demo' && org.name !== 'Hub Security')
    const isDemoMode = currentUser?.current_org === demoOrg?.id
    return { userIsTenant, isTenant, isTenantMode, demoOrg, tenantOrg, isDemoMode, isHub }
}

export {
    getPermittedMenu,
    isPermitted,
    getUserRole,
    getTenantProps
}