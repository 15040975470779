import { AccordionNested } from 'hub-web-lib'
import React from 'react'
import './AssessmentCategories.scss'

function AssessmentCategories({
    categories = [],
    activeIds = [],
    updateActiveIds = () => { },
}) {

    const list = (categories || [])
        .sort((a, b) => a.order - b.order)
        .map(c => {
            const questions = c.questions || []
            const subItems = questions
                .sort((a, b) => a.order - b.order)
                .map((q, i) => ({
                    id: q.id,
                    content: <p className={`content-question ${q.id === activeIds[1] ? 'active' : ''}`} title={q.text}>
                        <span className='content-question-indicator'>
                            {q.is_required && !q.isAnswered ? '*' : ''}
                            {q.isAnswered && <i className='icon-v'></i>}
                        </span>
                        {i + 1}. {q.text}
                    </p>
                }))
            const answeredQuestions = questions.filter(q => q.isAnswered)
            const isAllAnswered = answeredQuestions.length === questions.length
            const content = <div className='content-caterory' title={c.name}>
                <p>
                    <i className='icon-folder'></i> {c.name}
                </p>
                <span className={isAllAnswered ? 'all-completed' : ''}>
                    {isAllAnswered ? <i className='icon-v'></i> : `${answeredQuestions.length}/${questions.length}`}
                </span>
            </div>
            return {
                id: c.id,
                content,
                subItems
            }
        })

    return (
        <div className='questionnaire-categories-section'>
            <h4>Categories <span>({list.length})</span></h4>
            <AccordionNested
                list={list}
                listId='questionnaire-categories'
                defaultActiveItemIds={activeIds}
                updateActiveItemsIds={updateActiveIds}
            />
        </div>
    )
}

export default AssessmentCategories
