import { CheckBoxGroup, InputRow, RadioGroup, helpers } from 'hub-web-lib'
import debounce from 'lodash.debounce'
import React, { useEffect, useMemo } from 'react'
import './AssessmentQuestion.scss'

function AssessmentQuestion({ 
    question = {}, 
    questionNumber,
    answer,
    isActive,
    isLast,
    isReview,
    goNext = () => {},
    updateActiveQuestionId = () => {},
    saveAnswer = () => {}
}) {
    const answerType = question?.answer_type_id
    const id = `question-${question?.id}`
    const title = `${questionNumber}. ${question?.text}`
    const options = question?.possible_answers || []
    const optionsIds = options.map(o => o.id)

    const arrayAnswer = answer && Array.isArray(answer) ? answer : []
    const stringAnswer = answer && (typeof answer === 'string' || helpers.isNumber(answer))  ? answer : ''

    const defaultAnswer = {
        1: arrayAnswer,
        2: stringAnswer,
        3: stringAnswer,
        4: stringAnswer,
        5: stringAnswer
    }
    const currentAnswer = defaultAnswer[answerType]

    const goNextQuestion = () => {
        goNext(question?.id)
    }

    const activateQuestion = () => {
        // e.preventDefault()
        updateActiveQuestionId(question?.id)
    }

    const saveProgress = (val) => {
        saveAnswer(val, question?.id)
    }

    const isAnswered = (currentAnswer && (answerType === 1 ? currentAnswer.length : true)) || currentAnswer === 0
    const canGoNext = !isReview && isActive && isAnswered && !isLast

    
    const debouncedResults = useMemo(() => {
        return debounce(saveProgress, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    const questionForm = {
        1: <CheckBoxGroup 
            checkBoxGroupId={id}
            title={title}
            allItems={options}
            isInputRow={true}
            layout='vertical'
            checkedIds={currentAnswer}
            updateItems={debouncedResults}
            description={question?.description}
            disabledIds={isActive && !isReview ? [] : optionsIds} />,
        2: <RadioGroup 
            radioGroupId={id}
            title={title}
            allItems={options}
            checkedId={currentAnswer}
            updateItem={debouncedResults}
            description={question?.description}
            disabledIds={isActive && !isReview ? [] : optionsIds}/>,
        3: <InputRow
            inputId={id}
            title={title}
            inputTag='select'
            placeholder='Select Answer'
            options={options}
            layout='vertical'
            defaultValue={currentAnswer}
            changeValueHandler={debouncedResults}
            description={question?.description}
            disabled={!isActive || isReview} />,
        4: <InputRow
            inputId={id}
            title={title}
            inputTag='textarea'
            placeholder='Type Your Answer'
            layout='vertical'
            defaultValue={currentAnswer}
            changeValueHandler={debouncedResults}
            description={question?.description}
            disabled={!isActive || isReview} />,
        5: <InputRow
            inputId={id}
            title={title}
            inputType='number'
            placeholder='Type Your number'
            layout='vertical'
            defaultValue={currentAnswer}
            changeValueHandler={debouncedResults}
            description={question?.description}
            disabled={!isActive || isReview} />
    }

    return (
        <div className={`question-wrapper ${isActive || isReview ? 'active' : 'disabled'}`} id={`wraper-${id}`} onClick={isActive ? null : activateQuestion}>
            {question.is_required && <span className='error-msg'>*</span>}
            {questionForm[answerType]}
            <button 
                id={`next-${id}`} 
                title='next-question' 
                className='btn-submit' 
                onClick={goNextQuestion}
                disabled={!canGoNext}>Next <i className='icon-right'></i>
            </button>
        </div>
    )
}

export default AssessmentQuestion