import { ACTIVITY } from '../vocabulary'
import { DDOS_RESILIENCE, INCIDENT_RESPONSE, PHISHING_SIMULATION, RANSOMWARE_RESILIENCE } from '../app-modules'

const addMonthsToNow = (monthsCount) => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + monthsCount)
    return currentDate
}

const ddosActivityDemo = {
    id: 1,
    org_id: 1,
    start_date: addMonthsToNow(-2),
    end_date: addMonthsToNow(-1),
    is_complete: false,
    description: `${DDOS_RESILIENCE} ${ACTIVITY}`,
    created_by: 1,
    is_editable: true
}

const incidentResponseActivityDemo = {
    id: 1,
    org_id: 1,
    start_date: addMonthsToNow(1),
    end_date: addMonthsToNow(1),
    is_complete: false,
    description: `${INCIDENT_RESPONSE} ${ACTIVITY}`,
    created_by: 1,
    is_editable: true
}

const phishingSimulationActivityDemo = {
    id: 1,
    org_id: 1,
    start_date: addMonthsToNow(-4),
    end_date: addMonthsToNow(-4),
    is_complete: false,
    description: `${PHISHING_SIMULATION} ${ACTIVITY}`,
    created_by: 1,
    is_editable: true
}

const ransomwareResilienceActivityDemo = {
    id: 1,
    org_id: 1,
    start_date: addMonthsToNow(3),
    end_date: addMonthsToNow(4),
    is_complete: true,
    description: `${RANSOMWARE_RESILIENCE} ${ACTIVITY}`,
    created_by: 1,
    is_editable: true
}

const ddosMetricsDemo = [{
    id: 471,
    activity_id: 12,
    other: 0,
    informational: 3,
    low: 0,
    medium: 8,
    high: 1,
    critical: 0,
    category: 'Total',
    widget: 'Total',
    key: 'Total',
    is_editable: true
}]

const incidentResponseMetricsDemo = [{
    id: 471,
    activity_id: 12,
    other: 0,
    informational: 3,
    low: 0,
    medium: 4,
    high: 12,
    critical: 6,
    category: 'Total',
    widget: 'Total',
    key: 'Total',
    is_editable: true
}]

const phishingSimulationMetricsDemo = [{
    id: 471,
    activity_id: 12,
    other: 0,
    informational: 3,
    low: 0,
    medium: 6,
    high: 4,
    critical: 2,
    category: 'Total',
    widget: 'Total',
    key: 'Total',
    is_editable: true
}]

const ransomwareResilienceMetricsDemo = [{
    id: 471,
    activity_id: 12,
    other: 0,
    informational: 3,
    low: 0,
    medium: 5,
    high: 4,
    critical: 13,
    category: 'Total',
    widget: 'Total',
    key: 'Total',
    is_editable: true
}]

const easmMetricsPlaceholder = [
    {
        'widget': 'Active',
        'other': '429',
        'category': 'General',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Active',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 327
    },
    {
        'widget': 'Open',
        'other': '209',
        'category': 'General',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Open',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 328
    },
    {
        'widget': 'Acknowledge',
        'other': '220',
        'category': 'General',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Acknowledge',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 329
    },
    {
        'widget': 'Mean Time to Acknowledge',
        'other': '2,4',
        'category': 'General',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Mean Time to Acknowledge',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 330
    },
    {
        'widget': 'Mean Time to Resolve',
        'other': '0,0',
        'category': 'General',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Mean Time to Resolve',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 331
    },
    {
        'widget': 'By Severity',
        'other': '0',
        'category': 'Active',
        'low': 13,
        'high': 383,
        'is_editable': false,
        'key': 'By Severity',
        'activity_id': 1,
        'informational': 0,
        'medium': 33,
        'critical': 0,
        'id': 332
    },
    {
        'widget': 'By Type',
        'other': '377',
        'category': 'Active',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Email Security Issues',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 333
    },
    {
        'widget': 'By Type',
        'other': '35',
        'category': 'Active',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Exploitable Ports',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 334
    },
    {
        'widget': 'By Type',
        'other': '15',
        'category': 'Active',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Certificate Authority Issues',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 335
    },
    {
        'widget': 'By Type',
        'other': '2',
        'category': 'Active',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Mail Servers In Blacklist',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 336
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '0-7 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 337
    },
    {
        'widget': 'Open',
        'other': '0',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '0-7 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 338
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '8-14 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 339
    },
    {
        'widget': 'Open',
        'other': '1',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '8-14 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 340
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '15-31 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 341
    },
    {
        'widget': 'Open',
        'other': '0',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '15-31 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 342
    },
    {
        'widget': 'Acknowledge',
        'other': '220',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '31 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 343
    },
    {
        'widget': 'Open',
        'other': '208',
        'category': 'By Age',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '31 d',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 344
    },
    {
        'widget': 'By Month',
        'other': '364',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-05-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 345
    },
    {
        'widget': 'By Month',
        'other': '35',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-06-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 346
    },
    {
        'widget': 'By Month',
        'other': '2',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-07-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 347
    },
    {
        'widget': 'By Month',
        'other': '1',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-08-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 348
    },
    {
        'widget': 'By Month',
        'other': '25',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-09-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 349
    },
    {
        'widget': 'By Month',
        'other': '1',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-11-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 350
    },
    {
        'widget': 'By Month',
        'other': '1',
        'category': 'New Alerts',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2023-12-01 00:00:00',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 351
    },
    {
        'widget': 'Alerts By Severity',
        'other': '0',
        'category': 'Findings',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': 'Q4 22',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 352
    },
    {
        'widget': 'Alerts By Severity',
        'other': '0',
        'category': 'Findings',
        'low': 1,
        'high': 11,
        'is_editable': false,
        'key': 'Q3 23',
        'activity_id': 1,
        'informational': 0,
        'medium': 16,
        'critical': 0,
        'id': 353
    },
    {
        'widget': 'Alerts By Severity',
        'other': '0',
        'category': 'Findings',
        'low': 0,
        'high': 1,
        'is_editable': false,
        'key': 'Q4 23',
        'activity_id': 1,
        'informational': 0,
        'medium': 1,
        'critical': 0,
        'id': 354
    },
    {
        'widget': 'Acknowledge',
        'other': '2',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '5/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 355
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '1/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 356
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 357
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '3/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 358
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '4/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 359
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '6/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 360
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '7/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 361
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '8/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 362
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '9/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 363
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '10/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 364
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '11/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 365
    },
    {
        'widget': 'Acknowledge',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '12/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 366
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '1/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 367
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '2/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 368
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '3/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 369
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '4/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 370
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '5/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 371
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '6/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 372
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '7/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 373
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '8/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 374
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '9/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 375
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '10/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 376
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '11/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 377
    },
    {
        'widget': 'Resolved',
        'other': '0',
        'category': 'Yearly Mean Time',
        'low': 0,
        'high': 0,
        'is_editable': false,
        'key': '12/01/2023',
        'activity_id': 1,
        'informational': 0,
        'medium': 0,
        'critical': 0,
        'id': 378
    }
]

const qaMetricsPlaceholder = [
    {
        'id': 50,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Cyber Readiness',
        'key': 'Total',
        'not_implemented': 0,
        'partially_implemented': 0,
        'fully_implemented': 90,
        'is_editable': true
    },
    {
        'id': 51,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Scoring By Category',
        'key': 'Technology',
        'not_implemented': 0,
        'partially_implemented': 0,
        'fully_implemented': 65,
        'is_editable': true
    },
    {
        'id': 52,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Scoring By Category',
        'key': 'People',
        'not_implemented': 0,
        'partially_implemented': 0,
        'fully_implemented': 76,
        'is_editable': true
    },
    {
        'id': 53,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Scoring By Category',
        'key': 'Processes',
        'not_implemented': 0,
        'partially_implemented': 0,
        'fully_implemented': 80,
        'is_editable': true
    },
    {
        'id': 54,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Implementation of Security Control',
        'key': 'Chart Percentage',
        'not_implemented': 10,
        'partially_implemented': 40,
        'fully_implemented': 50,
        'is_editable': true
    },
    {
        'id': 55,
        'activity_id': 5,
        'category': 'General',
        'widget': 'Implementation of Security Control',
        'key': 'Chart Values',
        'not_implemented': 20,
        'partially_implemented': 60,
        'fully_implemented': 80,
        'is_editable': true
    },
    {
        'id': 56,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Level of Readiness',
        'key': 'Chart Values',
        'not_implemented': 14,
        'partially_implemented': 36,
        'fully_implemented': 50,
        'is_editable': true
    },
    {
        'id': 57,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Inventory and Control of Software Assets',
        'not_implemented': 0,
        'partially_implemented': 2,
        'fully_implemented': 2,
        'is_editable': true
    },
    {
        'id': 58,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Secure Configuration of Enterprise Assets and Software',
        'not_implemented': 2,
        'partially_implemented': 3,
        'fully_implemented': 7,
        'is_editable': true
    },
    {
        'id': 59,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Data Protection',
        'not_implemented': 4,
        'partially_implemented': 8,
        'fully_implemented': 2,
        'is_editable': true
    },
    {
        'id': 60,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Audit Log Management',
        'not_implemented': 0,
        'partially_implemented': 5,
        'fully_implemented': 8,
        'is_editable': true
    },
    {
        'id': 61,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Email and Web Browser Protections',
        'not_implemented': 1,
        'partially_implemented': 2,
        'fully_implemented': 6,
        'is_editable': true
    },
    {
        'id': 62,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Malware Defenses',
        'not_implemented': 0,
        'partially_implemented': 7,
        'fully_implemented': 7,
        'is_editable': true
    },
    {
        'id': 63,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Network Infrastructure Management',
        'not_implemented': 8,
        'partially_implemented': 3,
        'fully_implemented': 5,
        'is_editable': true
    },
    {
        'id': 64,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Network Monitoring and Defense',
        'not_implemented': 1,
        'partially_implemented': 9,
        'fully_implemented': 4,
        'is_editable': true
    },
    {
        'id': 65,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Application Software Security',
        'not_implemented': 5,
        'partially_implemented': 7,
        'fully_implemented': 0,
        'is_editable': true
    },
    {
        'id': 66,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Inventory and Control of Enterprise Assets',
        'not_implemented': 1,
        'partially_implemented': 9,
        'fully_implemented': 3,
        'is_editable': true
    },
    {
        'id': 67,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'SOC',
        'not_implemented': 9,
        'partially_implemented': 0,
        'fully_implemented': 1,
        'is_editable': true
    },
    {
        'id': 68,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'Wi-Fi Network',
        'not_implemented': 0,
        'partially_implemented': 8,
        'fully_implemented': 5,
        'is_editable': true
    },
    {
        'id': 69,
        'activity_id': 5,
        'category': 'Technology',
        'widget': 'Readiness by Category',
        'key': 'OT Infrastructures',
        'not_implemented': 0,
        'partially_implemented': 5,
        'fully_implemented': 7,
        'is_editable': true
    },
    {
        'id': 70,
        'activity_id': 5,
        'category': 'People',
        'widget': 'Level of Readiness',
        'key': 'Chart Values',
        'not_implemented': 0,
        'partially_implemented': 60,
        'fully_implemented': 40,
        'is_editable': true
    },
    {
        'id': 71,
        'activity_id': 5,
        'category': 'People',
        'widget': 'Readiness by Category',
        'key': 'Security Awareness and Skills Training',
        'not_implemented': 0,
        'partially_implemented': 2,
        'fully_implemented': 6,
        'is_editable': true
    },
    {
        'id': 72,
        'activity_id': 5,
        'category': 'People',
        'widget': 'Readiness by Category',
        'key': 'Service Provider Management',
        'not_implemented': 6,
        'partially_implemented': 7,
        'fully_implemented': 0,
        'is_editable': true
    },
    {
        'id': 73,
        'activity_id': 5,
        'category': 'People',
        'widget': 'Readiness by Category',
        'key': 'Account Management',
        'not_implemented': 5,
        'partially_implemented': 3,
        'fully_implemented': 3,
        'is_editable': true
    },
    {
        'id': 74,
        'activity_id': 5,
        'category': 'People',
        'widget': 'Readiness by Category',
        'key': 'Access Control Management',
        'not_implemented': 0,
        'partially_implemented': 5,
        'fully_implemented': 3,
        'is_editable': true
    },
    {
        'id': 75,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Level of Readiness',
        'key': 'Chart Values',
        'not_implemented': 7,
        'partially_implemented': 30,
        'fully_implemented': 70,
        'is_editable': true
    },
    {
        'id': 76,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Continuous Vulnerability Management',
        'not_implemented': 0,
        'partially_implemented': 1,
        'fully_implemented': 2,
        'is_editable': true
    },
    {
        'id': 77,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Data Recovery',
        'not_implemented': 1,
        'partially_implemented': 2,
        'fully_implemented': 3,
        'is_editable': true
    },
    {
        'id': 78,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Incident Response Management',
        'not_implemented': 3,
        'partially_implemented': 2,
        'fully_implemented': 6,
        'is_editable': true
    },
    {
        'id': 79,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Penetration Testing',
        'not_implemented': 1,
        'partially_implemented': 4,
        'fully_implemented': 2,
        'is_editable': true
    },
    {
        'id': 80,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Human resources (HR) security aspects',
        'not_implemented': 0,
        'partially_implemented': 3,
        'fully_implemented': 5,
        'is_editable': true
    },
    {
        'id': 81,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Physical security',
        'not_implemented': 0,
        'partially_implemented': 6,
        'fully_implemented': 6,
        'is_editable': true
    },
    {
        'id': 82,
        'activity_id': 5,
        'category': 'Processes',
        'widget': 'Readiness by Category',
        'key': 'Business Continuity Planning',
        'not_implemented': 0,
        'partially_implemented': 7,
        'fully_implemented': 0,
        'is_editable': true
    }
]

const redTeamMetricsPlaceholder = [
    {
        'id': 1,
        'activity_id': 1,
        'other': 0,
        'informational': 1,
        'low': 1,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'Chain of the Attack',
        'is_editable': true
    },
    {
        'id': 2,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 4,
        'high': 3,
        'critical': 1,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'Breaching the Internal Perimeter',
        'is_editable': true
    },
    {
        'id': 3,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 4,
        'critical': 1,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'Internal Attack',
        'is_editable': true
    },
    {
        'id': 4,
        'activity_id': 1,
        'other': 0,
        'informational': 4,
        'low': 0,
        'medium': 2,
        'high': 1,
        'critical': 0,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'External Attack',
        'is_editable': true
    },
    {
        'id': 5,
        'activity_id': 1,
        'other': 0,
        'informational': 5,
        'low': 3,
        'medium': 0,
        'high': 1,
        'critical': 1,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'Application PT',
        'is_editable': true
    },
    {
        'id': 6,
        'activity_id': 1,
        'other': 0,
        'informational': 2,
        'low': 2,
        'medium': 3,
        'high': 1,
        'critical': 5,
        'category': 'Risk Level By Category',
        'widget': 'Radar',
        'key': 'Infrastructure PT',
        'is_editable': true
    },
    {
        'id': 7,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Findings',
        'widget': 'By Category',
        'key': 'Chain of the Attack',
        'is_editable': true
    },
    {
        'id': 8,
        'activity_id': 1,
        'other': 8,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Findings',
        'widget': 'By Category',
        'key': 'Breaching the Internal Perimeter',
        'is_editable': true
    },
    {
        'id': 9,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Findings',
        'widget': 'By Category',
        'key': 'Internal Attack',
        'is_editable': true
    },
    {
        'id': 13,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 9,
        'high': 10,
        'critical': 8,
        'category': 'Findings',
        'widget': 'By Impact',
        'key': 'By Impact',
        'is_editable': false
    },
    {
        'id': 14,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 10,
        'high': 0,
        'critical': 5,
        'category': 'Findings',
        'widget': 'By Severity',
        'key': 'By Severity',
        'is_editable': false
    },
    {
        'id': 15,
        'activity_id': 1,
        'other': 0,
        'informational': 20,
        'low': 7,
        'medium': 10,
        'high': 15,
        'critical': 1,
        'category': 'Findings Severity',
        'widget': 'By Quarter',
        'key': 'Q1 22',
        'is_editable': true
    },
    {
        'id': 16,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 10,
        'high': 0,
        'critical': 5,
        'category': 'Findings Severity',
        'widget': 'By Quarter',
        'key': 'Q2 22',
        'is_editable': true
    },
    {
        'id': 17,
        'activity_id': 1,
        'other': 0,
        'informational': 16,
        'low': 14,
        'medium': 20,
        'high': 0,
        'critical': 0,
        'category': 'Findings Severity',
        'widget': 'By Quarter',
        'key': 'Q1 23',
        'is_editable': true
    },
    {
        'id': 18,
        'activity_id': 1,
        'other': 0,
        'informational': 12,
        'low': 10,
        'medium': 19,
        'high': 3,
        'critical': 0,
        'category': 'Findings Severity',
        'widget': 'By Quarter',
        'key': 'Q2 23',
        'is_editable': true
    },
    {
        'id': 19,
        'activity_id': 1,
        'other': 0,
        'informational': 7,
        'low': 10,
        'medium': 10,
        'high': 0,
        'critical': 0,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q3 22',
        'is_editable': true
    },
    {
        'id': 20,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 7,
        'medium': 8,
        'high': 0,
        'critical': 1,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q4 22',
        'is_editable': true
    },
    {
        'id': 21,
        'activity_id': 1,
        'other': 0,
        'informational': 15,
        'low': 8,
        'medium': 5,
        'high': 5,
        'critical': 0,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q1 23',
        'is_editable': true
    },
    {
        'id': 22,
        'activity_id': 1,
        'other': 0,
        'informational': 13,
        'low': 9,
        'medium': 3,
        'high': 2,
        'critical': 2,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q2 23',
        'is_editable': true
    },
    {
        'id': 23,
        'activity_id': 1,
        'other': 20,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Chain of the Attack',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 24,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Chain of the Attack',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 25,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Chain of the Attack',
        'key': '2023',
        'is_editable': true
    },
    {
        'id': 26,
        'activity_id': 1,
        'other': 15,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Internal Attack',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 27,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Internal Attack',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 28,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Internal Attack',
        'key': '2023',
        'is_editable': true
    },
    {
        'id': 29,
        'activity_id': 1,
        'other': 9,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'External Attack',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 30,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'External Attack',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 31,
        'activity_id': 1,
        'other': 3,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'External Attack',
        'key': '2023',
        'is_editable': true
    },
    {
        'id': 32,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Application PT',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 33,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Application PT',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 34,
        'activity_id': 1,
        'other': 15,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Application PT',
        'key': '2023',
        'is_editable': true
    },
    {
        'id': 35,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Infrastructure PT',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 36,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Infrastructure PT',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 37,
        'activity_id': 1,
        'other': 7,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Infrastructure PT',
        'key': '2023',
        'is_editable': true
    },
    {
        'id': 38,
        'activity_id': 1,
        'other': 8,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Breaching the Internal Perimeter',
        'key': '2021',
        'is_editable': true
    },
    {
        'id': 39,
        'activity_id': 1,
        'other': 14,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Breaching the Internal Perimeter',
        'key': '2022',
        'is_editable': true
    },
    {
        'id': 40,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Category By Year',
        'widget': 'Breaching the Internal Perimeter',
        'key': '2023',
        'is_editable': true
    }
]

const penTestMetricsPlaceholder = [
    {
        'id': 1,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 10,
        'medium': 8,
        'high': 5,
        'critical': 3,
        'category': 'Vulnerabilities Totals by Severity',
        'widget': 'Cards',
        'key': 'By Severity',
        'is_editable': true
    },
    {
        'id': 3,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 2,
        'medium': 2,
        'high': 3,
        'critical': 1,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Area',
        'key': 'Network Discovery',
        'is_editable': true
    },
    {
        'id': 4,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 1,
        'medium': 2,
        'high': 2,
        'critical': 0,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Area',
        'key': 'Application Security Testing',
        'is_editable': true
    },
    {
        'id': 5,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 3,
        'medium': 1,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Area',
        'key': 'DR Configuration',
        'is_editable': true
    },
    {
        'id': 6,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 4,
        'medium': 3,
        'high': 0,
        'critical': 2,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Area',
        'key': 'Operating System Testing',
        'is_editable': true
    },
    {
        'id': 7,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 2,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Area',
        'key': 'Misconfigurations',
        'is_editable': true
    },
    {
        'id': 8,
        'activity_id': 1,
        'other': 8,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Top 5 Vulnerabilities Categories',
        'widget': 'By Category',
        'key': 'Network Discovery',
        'is_editable': true
    },
    {
        'id': 9,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Top 5 Vulnerabilities Categories',
        'widget': 'By Category',
        'key': 'Application Security Testing',
        'is_editable': true
    },
    {
        'id': 10,
        'activity_id': 1,
        'other': 4,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Top 5 Vulnerabilities Categories',
        'widget': 'By Category',
        'key': 'DR Configuration',
        'is_editable': true
    },
    {
        'id': 11,
        'activity_id': 1,
        'other': 9,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Top 5 Vulnerabilities Categories',
        'widget': 'By Category',
        'key': 'Operating System Testing',
        'is_editable': true
    },
    {
        'id': 12,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Top 5 Vulnerabilities Categories',
        'widget': 'By Category',
        'key': 'Misconfigurations',
        'is_editable': true
    },
    {
        'id': 13,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities by Status',
        'widget': 'Open',
        'key': 'Open',
        'is_editable': true
    },
    {
        'id': 14,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities by Status',
        'widget': 'Resolved',
        'key': 'Resolved',
        'is_editable': true
    },
    {
        'id': 15,
        'activity_id': 1,
        'other': 8,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities by Status',
        'widget': 'Acknowledge',
        'key': 'Acknowledge',
        'is_editable': true
    },
    {
        'id': 16,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 16,
        'high': 7,
        'critical': 1,
        'category': 'Vulnerabilities by Severity',
        'widget': 'By Quarter',
        'key': 'Q1 22',
        'is_editable': true
    },
    {
        'id': 17,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 12,
        'medium': 12,
        'high': 6,
        'critical': 3,
        'category': 'Vulnerabilities by Severity',
        'widget': 'By Quarter',
        'key': 'Q2 22',
        'is_editable': true
    },
    {
        'id': 18,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 15,
        'high': 7,
        'critical': 1,
        'category': 'Vulnerabilities by Severity',
        'widget': 'By Quarter',
        'key': 'Q1 23',
        'is_editable': true
    },
    {
        'id': 19,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 10,
        'medium': 10,
        'high': 5,
        'critical': 3,
        'category': 'Vulnerabilities by Severity',
        'widget': 'By Quarter',
        'key': 'Q2 23',
        'is_editable': true
    },
    {
        'id': 20,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 16,
        'high': 7,
        'critical': 1,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q1 22',
        'is_editable': true
    },
    {
        'id': 21,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 2,
        'high': 0,
        'critical': 0,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q2 22',
        'is_editable': true
    },
    {
        'id': 22,
        'activity_id': 1,
        'other': 0,
        'informational': 10,
        'low': 5,
        'medium': 15,
        'high': 7,
        'critical': 1,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q1 23',
        'is_editable': true
    },
    {
        'id': 23,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 2,
        'high': 0,
        'critical': 0,
        'category': 'Risk Level Trend',
        'widget': 'By Quarter',
        'key': 'Q2 23',
        'is_editable': true
    },
    {
        'id': 24,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 2,
        'medium': 2,
        'high': 3,
        'critical': 1,
        'category': 'Vulnerabilities Category by Severity',
        'widget': 'Category By Severity',
        'key': 'Network Discovery',
        'is_editable': true
    },
    {
        'id': 25,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 1,
        'medium': 2,
        'high': 2,
        'critical': 0,
        'category': 'Vulnerabilities Category by Severity',
        'widget': 'Category By Severity',
        'key': 'Application Security Testing',
        'is_editable': true
    },
    {
        'id': 26,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 3,
        'medium': 1,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities Category by Severity',
        'widget': 'Category By Severity',
        'key': 'DR Configuration',
        'is_editable': true
    },
    {
        'id': 27,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 4,
        'medium': 3,
        'high': 0,
        'critical': 2,
        'category': 'Vulnerabilities Category by Severity',
        'widget': 'Category By Severity',
        'key': 'Operating System Testing',
        'is_editable': true
    },
    {
        'id': 28,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 2,
        'high': 0,
        'critical': 0,
        'category': 'Vulnerabilities Category by Severity',
        'widget': 'Category By Severity',
        'key': 'Misconfigurations',
        'is_editable': true
    },
    {
        'id': 2,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 6,
        'high': 3,
        'critical': 4,
        'category': 'Vulnerabilities by Risk Level',
        'widget': 'Pie',
        'key': 'By Risk',
        'is_editable': true
    }
]

const ddosMetricsPlaceholder = [
    {
        'id': 50,
        'activity_id': 3,
        'other': 0,
        'none': 1,
        'low': 1,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Physical Layer (Layer 1)',
        'is_editable': false
    },
    {
        'id': 51,
        'activity_id': 3,
        'other': 0,
        'none': 0,
        'low': 0,
        'medium': 4,
        'high': 3,
        'critical': 1,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Data Link Layer (Layer 2)',
        'is_editable': false
    },
    {
        'id': 52,
        'activity_id': 3,
        'other': 0,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 4,
        'critical': 1,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Network Layer (Layer 3)',
        'is_editable': false
    },
    {
        'id': 53,
        'activity_id': 3,
        'other': 0,
        'none': 4,
        'low': 0,
        'medium': 2,
        'high': 1,
        'critical': 0,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Transport Layer (Layer 4)',
        'is_editable': false
    },
    {
        'id': 54,
        'activity_id': 3,
        'other': 0,
        'none': 5,
        'low': 3,
        'medium': 0,
        'high': 1,
        'critical': 1,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Session Layer (Layer 5)',
        'is_editable': false
    },
    {
        'id': 55,
        'activity_id': 3,
        'other': 0,
        'none': 2,
        'low': 2,
        'medium': 3,
        'high': 1,
        'critical': 5,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Presentation Layer (Layer 6)',
        'is_editable': false
    },
    {
        'id': 56,
        'activity_id': 3,
        'other': 0,
        'none': 2,
        'low': 2,
        'medium': 3,
        'high': 1,
        'critical': 5,
        'recommendations': null,
        'category': 'Exposure By Layer',
        'widget': 'Cylinder',
        'key': 'Application Layer (Layer 7)',
        'is_editable': false
    },
    {
        'id': 57,
        'activity_id': 3,
        'other': 2,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Mitigation',
        'key': 'Mitigated',
        'is_editable': false
    },
    {
        'id': 58,
        'activity_id': 3,
        'other': 8,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Mitigation',
        'key': 'Partially Mitigated',
        'is_editable': false
    },
    {
        'id': 59,
        'activity_id': 3,
        'other': 8,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Mitigation',
        'key': 'Unmitigated',
        'is_editable': false
    },
    {
        'id': 60,
        'activity_id': 3,
        'other': 1,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Vectors',
        'key': 'ICMP FLood',
        'is_editable': true
    },
    {
        'id': 61,
        'activity_id': 3,
        'other': 2,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Vectors',
        'key': 'TCP Flood',
        'is_editable': true
    },
    {
        'id': 62,
        'activity_id': 3,
        'other': 1,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Vectors',
        'key': 'Slow POST',
        'is_editable': true
    },
    // {
    //     'id': 63,
    //     'activity_id': 3,
    //     'other': 3,
    //     'none': 0,
    //     'low': 0,
    //     'medium': 0,
    //     'high': 0,
    //     'critical': 0,
    //     'recommendations': null,
    //     'category': 'Findings',
    //     'widget': 'By Attack Vectors',
    //     'key': 'SSL Renegotiation',
    //     'is_editable': true
    // },
    // {
    //     'id': 64,
    //     'activity_id': 3,
    //     'other': 1,
    //     'none': 0,
    //     'low': 0,
    //     'medium': 0,
    //     'high': 0,
    //     'critical': 0,
    //     'recommendations': null,
    //     'category': 'Findings',
    //     'widget': 'By Attack Vectors',
    //     'key': 'TCP Syn Flood',
    //     'is_editable': true
    // },
    // {
    //     'id': 65,
    //     'activity_id': 3,
    //     'other': 0,
    //     'none': 0,
    //     'low': 0,
    //     'medium': 0,
    //     'high': 0,
    //     'critical': 0,
    //     'recommendations': null,
    //     'category': 'Findings',
    //     'widget': 'By Attack Vectors',
    //     'key': 'Get Flood to Non-Existing Endpoint',
    //     'is_editable': true
    // },
    // {
    //     'id': 66,
    //     'activity_id': 3,
    //     'other': 1,
    //     'none': 0,
    //     'low': 0,
    //     'medium': 0,
    //     'high': 0,
    //     'critical': 0,
    //     'recommendations': null,
    //     'category': 'Findings',
    //     'widget': 'By Attack Vectors',
    //     'key': 'LOGIN Flood',
    //     'is_editable': true
    // },
    // {
    //     'id': 67,
    //     'activity_id': 3,
    //     'other': 3,
    //     'none': 0,
    //     'low': 0,
    //     'medium': 0,
    //     'high': 0,
    //     'critical': 0,
    //     'recommendations': null,
    //     'category': 'Findings',
    //     'widget': 'By Attack Vectors',
    //     'key': 'Get Flood',
    //     'is_editable': true
    // },
    {
        'id': 68,
        'activity_id': 3,
        'other': 2,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Types',
        'key': 'Infrastructure',
        'is_editable': false
    },
    {
        'id': 69,
        'activity_id': 3,
        'other': 8,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Types',
        'key': 'Volumetric',
        'is_editable': false
    },
    {
        'id': 70,
        'activity_id': 3,
        'other': 5,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Findings',
        'widget': 'By Attack Types',
        'key': 'Application',
        'is_editable': false
    },
    {
        'id': 71,
        'activity_id': 3,
        'other': 0,
        'none': 0,
        'low': 10,
        'medium': 10,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Exposure Trends Over Time',
        'widget': 'By Quarter',
        'key': 'Q4 22',
        'is_editable': true
    },
    {
        'id': 72,
        'activity_id': 3,
        'other': 0,
        'none': 0,
        'low': 7,
        'medium': 8,
        'high': 0,
        'critical': 1,
        'recommendations': null,
        'category': 'Exposure Trends Over Time',
        'widget': 'By Quarter',
        'key': 'Q3 23',
        'is_editable': true
    },
    {
        'id': 73,
        'activity_id': 3,
        'other': 0,
        'none': 0,
        'low': 8,
        'medium': 5,
        'high': 5,
        'critical': 0,
        'recommendations': null,
        'category': 'Exposure Trends Over Time',
        'widget': 'By Quarter',
        'key': 'Q4 23',
        'is_editable': true
    },
    {
        'id': 74,
        'activity_id': 3,
        'other': 5,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'ICMP FLood',
        'is_editable': true
    },
    {
        'id': 75,
        'activity_id': 3,
        'other': 9,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'TCP Flood',
        'is_editable': true
    },
    {
        'id': 76,
        'activity_id': 3,
        'other': 6,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'Slow POST',
        'is_editable': true
    },
    {
        'id': 77,
        'activity_id': 3,
        'other': 7,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'SSL Renegotiation',
        'is_editable': true
    },
    {
        'id': 78,
        'activity_id': 3,
        'other': 4,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'TCP Syn Flood',
        'is_editable': true
    },
    {
        'id': 79,
        'activity_id': 3,
        'other': 9,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'Get Flood to Non-Existing Endpoint',
        'is_editable': true
    },
    {
        'id': 80,
        'activity_id': 3,
        'other': 6,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'LOGIN Flood',
        'is_editable': true
    },
    {
        'id': 81,
        'activity_id': 3,
        'other': 8,
        'none': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'recommendations': null,
        'category': 'Attack Vectors Prioritized',
        'widget': 'By # of Vulnerable Targets ',
        'key': 'Get Flood',
        'is_editable': true
    }
]

const riskAssessmentMetricsPlaceholder = [
    {
        'id': 1,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 9,
        'medium': 8,
        'high': 7,
        'critical': 2,
        'category': 'Risk Level',
        'widget': 'Pie',
        'key': 'By Risk',
        'is_editable': false
    },
    {
        'id': 2,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 2,
        'medium': 4,
        'high': 5,
        'critical': 0,
        'category': 'Risk Matrix',
        'widget': 'Likelihood',
        'key': 'Very likely',
        'is_editable': true
    },
    {
        'id': 3,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 3,
        'medium': 1,
        'high': 1,
        'critical': 0,
        'category': 'Risk Matrix',
        'widget': 'Likelihood',
        'key': 'Likely',
        'is_editable': true
    },
    {
        'id': 4,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 4,
        'medium': 3,
        'high': 1,
        'critical': 0,
        'category': 'Risk Matrix',
        'widget': 'Likelihood',
        'key': 'Occasional',
        'is_editable': true
    },
    {
        'id': 5,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 7,
        'medium': 3,
        'high': 1,
        'critical': 0,
        'category': 'Risk Matrix',
        'widget': 'Likelihood',
        'key': 'Rare',
        'is_editable': true
    },
    {
        'id': 22,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - MacOS',
        'key': 'Vulnerability Management',
        'is_editable': true
    },
    {
        'id': 26,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - MacOS',
        'key': 'Data Protection',
        'is_editable': true
    },
    {
        'id': 27,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - Windows',
        'key': 'Vulnerability Management',
        'is_editable': true
    },
    {
        'id': 34,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - MacOS',
        'key': 'Avoid',
        'is_editable': true
    },
    {
        'id': 38,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - Windows',
        'key': 'Avoid',
        'is_editable': true
    },
    {
        'id': 6,
        'activity_id': 1,
        'other': 4,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Status',
        'widget': 'Risk Status',
        'key': 'In Process',
        'is_editable': true
    },
    {
        'id': 7,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Status',
        'widget': 'Risk Status',
        'key': 'Pending',
        'is_editable': true
    },
    {
        'id': 8,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Status',
        'widget': 'Risk Status',
        'key': 'Approval',
        'is_editable': true
    },
    {
        'id': 9,
        'activity_id': 1,
        'other': 8,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Status',
        'widget': 'Risk Status',
        'key': 'Denied',
        'is_editable': true
    },
    {
        'id': 10,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk by Category',
        'widget': 'By Category',
        'key': 'Vulnerability Management',
        'is_editable': true
    },
    {
        'id': 11,
        'activity_id': 1,
        'other': 11,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk by Category',
        'widget': 'By Category',
        'key': 'Network Security',
        'is_editable': true
    },
    {
        'id': 12,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk by Category',
        'widget': 'By Category',
        'key': 'Access Control',
        'is_editable': true
    },
    {
        'id': 13,
        'activity_id': 1,
        'other': 9,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk by Category',
        'widget': 'By Category',
        'key': 'Monitoring and Logging',
        'is_editable': true
    },
    {
        'id': 14,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk by Category',
        'widget': 'By Category',
        'key': 'Data Protection',
        'is_editable': true
    },
    {
        'id': 15,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Response',
        'widget': 'Response',
        'key': 'Avoid',
        'is_editable': true
    },
    {
        'id': 16,
        'activity_id': 1,
        'other': 11,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Response',
        'widget': 'Response',
        'key': 'Mitigate',
        'is_editable': true
    },
    {
        'id': 17,
        'activity_id': 1,
        'other': 5,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Response',
        'widget': 'Response',
        'key': 'Transfer',
        'is_editable': true
    },
    {
        'id': 18,
        'activity_id': 1,
        'other': 10,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Risk Response',
        'widget': 'Response',
        'key': 'Accept',
        'is_editable': true
    },
    {
        'id': 19,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 1,
        'medium': 1,
        'high': 1,
        'critical': 0,
        'category': 'Domain - Risk Level',
        'widget': 'Hardening Review - MacOS',
        'key': 'Risk Level',
        'is_editable': true
    },
    {
        'id': 20,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 1,
        'medium': 1,
        'high': 1,
        'critical': 0,
        'category': 'Domain - Risk Level',
        'widget': 'Hardening Review - Windows',
        'key': 'Risk Level',
        'is_editable': true
    },
    {
        'id': 21,
        'activity_id': 1,
        'other': 0,
        'informational': 0,
        'low': 7,
        'medium': 6,
        'high': 5,
        'critical': 0,
        'category': 'Domain - Risk Level',
        'widget': 'Architecture Review',
        'key': 'Risk Level',
        'is_editable': true
    },
    {
        'id': 23,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - MacOS',
        'key': 'Network Security',
        'is_editable': true
    },
    {
        'id': 24,
        'activity_id': 1,
        'other': 1,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - MacOS',
        'key': 'Access Control',
        'is_editable': true
    },
    {
        'id': 25,
        'activity_id': 1,
        'other': 1,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - MacOS',
        'key': 'Monitoring and Logging',
        'is_editable': true
    },
    {
        'id': 28,
        'activity_id': 1,
        'other': 4,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - Windows',
        'key': 'Network Security',
        'is_editable': true
    },
    {
        'id': 29,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - Windows',
        'key': 'Access Control',
        'is_editable': true
    },
    {
        'id': 30,
        'activity_id': 1,
        'other': 3,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - Windows',
        'key': 'Monitoring and Logging',
        'is_editable': true
    },
    {
        'id': 31,
        'activity_id': 1,
        'other': 1,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Hardening Review - Windows',
        'key': 'Data Protection',
        'is_editable': true
    },
    {
        'id': 32,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Architecture Review',
        'key': 'Network Security',
        'is_editable': true
    },
    {
        'id': 33,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Risk by Category',
        'widget': 'Architecture Review',
        'key': 'Monitoring and Logging',
        'is_editable': true
    },
    {
        'id': 35,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - MacOS',
        'key': 'Mitigate',
        'is_editable': true
    },
    {
        'id': 36,
        'activity_id': 1,
        'other': 1,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - MacOS',
        'key': 'Transfer',
        'is_editable': true
    },
    {
        'id': 37,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - MacOS',
        'key': 'Accept',
        'is_editable': true
    },
    {
        'id': 39,
        'activity_id': 1,
        'other': 4,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - Windows',
        'key': 'Mitigate',
        'is_editable': true
    },
    {
        'id': 40,
        'activity_id': 1,
        'other': 2,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - Windows',
        'key': 'Transfer',
        'is_editable': true
    },
    {
        'id': 41,
        'activity_id': 1,
        'other': 3,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Hardening Review - Windows',
        'key': 'Accept',
        'is_editable': true
    },
    {
        'id': 42,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Architecture Review',
        'key': 'Avoid',
        'is_editable': true
    },
    {
        'id': 43,
        'activity_id': 1,
        'other': 6,
        'informational': 0,
        'low': 0,
        'medium': 0,
        'high': 0,
        'critical': 0,
        'category': 'Domain - Response',
        'widget': 'Architecture Review',
        'key': 'Mitigate',
        'is_editable': true
    }
]

const START_FILLING_TEXT = 'Start filling out the activities to unlock the dashboard'

export {
    ddosActivityDemo,
    incidentResponseActivityDemo,
    phishingSimulationActivityDemo,
    ransomwareResilienceActivityDemo,
    ddosMetricsDemo,
    incidentResponseMetricsDemo,
    phishingSimulationMetricsDemo,
    ransomwareResilienceMetricsDemo,
    START_FILLING_TEXT,
    easmMetricsPlaceholder,
    qaMetricsPlaceholder,
    redTeamMetricsPlaceholder,
    penTestMetricsPlaceholder,
    ddosMetricsPlaceholder,
    riskAssessmentMetricsPlaceholder
}