import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './Layout.scss'
import HubRouter from 'components/Layout/HubRouter/HubRouter'
import UserApi from 'services/UserApi'
import { sideMenuItems, headerMenuItems, sideMenuSections, tenantSubmenu, settingsMenuItem } from 'js/constants/main-menus'
import { helpers, Loader, Header, SideMenu, Notification, Popup } from 'hub-web-lib'
import { Login } from 'components/Views/Login/Login'
import { connect } from 'react-redux'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_CURRENT_USER, UPDATE_CURRENT_USER_ORGS, UPDATE_DASHBOARD_SETTINGS, UPDATE_NOTIFICATION_CONTENT, UPDATE_QUESTIONNAIRE_FEATURES, UPDATE_RISK_MATRIX_SETTINGS, UPDATE_SETTINGS, UPDATE_VISIBLE_MODULES, UPDATE_DDOS_FEATURES } from 'js/constants/action-types'
import { loginModes } from 'js/constants/enums/user'
import avatar from 'assets/avatar.png'
import ResetPassword from 'components/Views/Login/ResetPassword'
import { getPermittedMenu,  getTenantProps,  getUserRole } from 'js/ui-helpers/roles'
import SettingsApi from 'services/SettingsApi'
import OrgSelector from 'components/Views/Settings/SettingsSections/OrgSelector'
import OrgApi from 'services/OrgApi'
import { dashboardSettingsDefault } from 'js/constants/dashboard-settings'
import { getDocumentTitle } from 'js/constants/app-modules'
import TermsCondtions from 'components/Views/Login/TermsCondtions'
const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        notificationContent: state.notificationContent,
        visibleModules: state.visibleModules,
        currentUserOrgs: state.currentUserOrgs
    }
}


const LayoutComponent = ({ currentUser, notificationContent, visibleModules, currentUserOrgs }) => {
    const location = useLocation()
    const [loginErrorMsg, setLoginErrorMsg] = useState('')
    const [loginMode, setLoginMode] = useState(loginModes.login)
    const [loginParameter, setLoginParameter] = useState('')
    const [currentUserLogged, setCurrentUserLogged] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [levelsItems, setLevelsItems] = useState(null)
    const [userSideMenu, setUserSideMenu] = useState([])
    const [isTermsPopup, setIsTermsPopup] = useState(false)
    const [searchParams] = useSearchParams()
    const [currentOrgLogo, setCurrentOrgLogo] = useState()
    const [isModeSet, setIsModeSet] = useState(false)
    const isResetPassword = location.pathname.includes('reset-password')
    const resetPasswordToken = isResetPassword ? searchParams.get('token') : ''
    const { userIsTenant, isTenant, isTenantMode, demoOrg, tenantOrg, isDemoMode, isHub } = getTenantProps(currentUser, location, currentUserOrgs)
    const clearNotification = () => store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, null))
    const navigate = useNavigate()

    document.title = getDocumentTitle()

    const logOut = () => {
        UserApi.userLogout()
        UserApi.clearCurrentUserCookies()
        setCurrentUserLogged('')
    }

    const logIn = async (userCredentials) => {
        const loginResult = await UserApi.setUserTokenCookies(userCredentials)
        if (loginResult.message) {
            setLoginErrorMsg(loginResult.message)
            return
        }
        if (loginResult.command) {
            if (loginResult.command === 'get-otp') setLoginMode(loginModes.getOtp)
            if (loginResult.command === 'set-otp') setLoginMode(loginModes.setupOtp)
            setLoginParameter(loginResult.parameter)
            setLoginErrorMsg('')
            return
        }
        const currentUserResult = await updateCurrentUser()
        setLoginErrorMsg(currentUserResult.message)
        if (currentUserResult.user) {
            // On successful login - reset the login mode
            setLoginMode(loginModes.login)
        }
        if (!currentUserResult.user.tc_approve) {
            setIsTermsPopup(true)
        }
    }

    const updateCurrentUser = async () => {
        const currentUserResult = await UserApi.setCurrentUserCookies()
        if (currentUserResult.user && !currentUserResult.user.tc_approve) {
            setIsTermsPopup(true)
        }
      
        if (!currentUserResult.user) {
            setIsLoading(false)
            logOut()
            return null
        }
        setCurrentUserLogged(currentUserResult.user)
        await updateCurrentUserOrgs(currentUserResult.user) 
        return currentUserResult
    }

    const updateCurrentUserOrgs = async (user = currentUser) => {
        if (!user) return
        const orgs = await OrgApi.getUserOrgs(user)
        if (!orgs || orgs.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: orgs.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_CURRENT_USER_ORGS, orgs))
    }

    const updateLevelsItems = (menu = userSideMenu) => {
        if (isTenantMode) {
            setLevelsItems(tenantSubmenu)
        } else {
            let path = location.pathname
            if (path[0] === '/') path = path.split('/')[1]
            const menuItems = menu.find(item => (item.link || '').includes(path)) || headerMenuItems.find(item => (item.link || '').includes(path))
            setLevelsItems(menuItems?.subItems?.length ? menuItems.subItems : null)
        }
    }

    const setUpSettings = async () => {
        if (!currentUser?.current_org) return []
        const settings = await SettingsApi.getAllSettings(currentUser.current_org)
        if (!settings || settings.error) return []
        settings.forEach(s => s.value = JSON.parse(s.value))
        store.dispatch(action(UPDATE_SETTINGS, settings))
        const visibleModules = settings.filter(s => s.category_id === 1)
        store.dispatch(action(UPDATE_VISIBLE_MODULES, visibleModules))
        const questionnareFeaturesList = settings.filter(s => s.category_id === 2)
        store.dispatch(action(UPDATE_QUESTIONNAIRE_FEATURES, questionnareFeaturesList))
        const dashboardSettings = settings.filter(v => v.category_id === 3).sort((a, b) => a.id - b.id)
        store.dispatch(action(UPDATE_DASHBOARD_SETTINGS,
            dashboardSettings?.length ? dashboardSettings : helpers.createNewInstance(dashboardSettingsDefault).map(s => ({ ...s, org_id: currentUser.current_org })))
        )
        const riskMatrixTemplate = settings.find(v => v.category_id === 4)
        store.dispatch(action(UPDATE_RISK_MATRIX_SETTINGS, riskMatrixTemplate))
        const ddosFeaturesList = settings.filter(s => s.category_id === 5)
        store.dispatch(action(UPDATE_DDOS_FEATURES, ddosFeaturesList))
        const currentOrgObj = await OrgApi.getOrg(currentUser.current_org)
        setCurrentOrgLogo(currentOrgObj.company_logo_url)
        return visibleModules
    }
    
    useEffect(async () => {
        setIsLoading(true)
        await updateCurrentUser()
        setIsLoading(false)
    }, [])

    const setSideMenu = (user, modules = visibleModules) => {
        if (!modules?.length) return
        setCurrentUserLogged(user)
        const visibleMod = {}
        modules.map(v => visibleMod[v.key] = v.value)
        const menu = getPermittedMenu(getUserRole(user), sideMenuItems, visibleMod)
        setUserSideMenu(menu)
        updateLevelsItems(menu)
    }

    const switchToDemoMode = async () => {
        if (demoOrg) await updateCurrentOrgId(demoOrg.id)
        navigate('/')
    }

    const switchToTenantMode = async () => {
        if (tenantOrg) await updateCurrentOrgId(tenantOrg.id)
        if(!isTenant) navigate('/tenant/dashboard')
    }

    const goToLogin = (path, mode) => {
        if(mode === loginModes.resetPassword) {
            if(currentUserLogged) logOut()
            setLoginMode(mode)
        }
        navigate(path ? path: '/')
    }

    const updateCurrentOrgId = async (orgId) => {
        const updatedUser = await UserApi.updateUserCurrentOrg(currentUser.id, orgId)
        if (!updatedUser || updatedUser.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: updatedUser?.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_CURRENT_USER, { ...currentUser, current_org: orgId }))
    }

    useEffect(async () => {
        if (!isModeSet || !helpers.isSameObject(currentUser, currentUserLogged)) {
            if (currentUser && currentUserOrgs?.length && !isResetPassword) {
                setIsLoading(true)
                const modules = await setUpSettings()
                setSideMenu(currentUser, modules)
                if (userIsTenant && !isDemoMode && !isHub) {
                    switchToTenantMode()
                } else if (isTenant) {
                    switchToDemoMode()
                }
                setIsLoading(false)
                setIsModeSet(true)
            }
        }
    }, [currentUser, currentUserOrgs, location.pathname])

    useEffect(() => {
        if (visibleModules && visibleModules.length) {
            setSideMenu(currentUser)
        }
    }, [visibleModules])

    const currentUserDetails = { name: currentUserLogged?.name || currentUserLogged?.user_name || '', avatar: currentUserLogged?.avatar_url || avatar }
    
    const getHeaderFullMenu = () => {
        let menu = [...headerMenuItems]
        const selectorIndex = menu.findIndex(i => i.id === 'orgSelectorHeader')
        if (selectorIndex !== -1) menu[selectorIndex].customElement = <OrgSelector isContentHidden={true} />
        if (isTenantMode && !isHub) {
            if (demoOrg) {
                menu = [{
                    id: 'tryDemoModeMode',
                    customElement: <button className='btn-submit' onClick={switchToDemoMode} id='try-demo-mode'>Try Demo Mode</button>
                }, 
                ...headerMenuItems
                ]
            }
        } else if (userIsTenant && !isHub) {
            if (demoOrg) {
                menu = [{
                    id: 'exitDemoMode',
                    customElement: <button className='btn-submit' onClick={switchToTenantMode} id='try-demo-mode'>Exit Demo Mode</button>
                }, 
                ...headerMenuItems
                ]
            }
        } else {
            menu = [
                settingsMenuItem, 
                ...headerMenuItems
            ]
        }
        return menu
    }

    const headerFullMenu = getHeaderFullMenu()
    const routerData = {
        updateCurrentUserOrgs,
        logOut
    }

    useEffect(() => {
        updateLevelsItems()
        clearNotification()
    }, [location])
    return (
        isLoading ? <Loader /> : <>{isResetPassword ? <>
            <Header noMenu={true} />
            <ResetPassword userToken={resetPasswordToken} onGoToLogin={goToLogin} />
        </> :
            (currentUserLogged ? <>
                <Header leftMenuItems={levelsItems} menuItems={headerFullMenu} currentUser={currentUserDetails} logOut={logOut} notificationNumber={0} isLinkableLogo={true} />
                {isTenantMode || <SideMenu menuItems={userSideMenu} menuSections={sideMenuSections} isShownLevels={false} logo={currentOrgLogo} />}
                <HubRouter routerData={routerData} />
            </> : <>
                <Header noMenu={true} />
                <Login logIn={logIn} errorMsg={loginErrorMsg} mode={loginMode} parameter={loginParameter} />
            </>)}<Notification notificationContent={notificationContent} clearNotification={clearNotification} />
        <Popup
            isOpen={isTermsPopup}
            title=''
            onClose={() => { logOut(); setIsTermsPopup(false) }}
            isDisabledClickOutside={true}
            customClass='questionnaire-popup'>
            {isLoading? <Loader/> : <>
                <TermsCondtions currentUserLogged={currentUserLogged} setIsTermsPopup={setIsTermsPopup}/>
            </>
            }
        </Popup>        
        </>
    )
}

const Layout = connect(mapStateToProps)(LayoutComponent)

export default Layout 