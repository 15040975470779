import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './PT.scss'
import { Alert, CheckBoxGroup, genericTexts, helpers, InputList, InputRow, Loader, Popup } from 'hub-web-lib'
import { ACTIVITY, FINDING } from 'js/constants/vocabulary'
import { reportModel, findingModel } from 'js/constants/models/pt'
import { connect } from 'react-redux'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { numericJsonValues, emptyJsonValues } from 'middlewares/ApiHelpers'
import PTApi from 'services/PTApi'
import OrgApi from 'services/OrgApi'
import UserApi from 'services/UserApi'
import { findingSeverityTypes } from 'js/constants/enums/pt'
import { statusTypes } from 'js/ui-helpers/pt'

const newReportModel = helpers.createNewInstance(reportModel)
delete newReportModel.id
delete newReportModel.org_id
const fieldsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(newReportModel))

const newFindingModel = helpers.createNewInstance(findingModel)
delete newFindingModel.id
delete newFindingModel.reports
delete newFindingModel.org_id
delete newFindingModel.created_on
const findingsErrorMsgDefault = emptyJsonValues(helpers.createNewInstance(newFindingModel))

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

export const NewReportComponent = ({ currentUser }) => {
    const currentUserOrgId = currentUser?.org_id
    const [isLoading, setIsLoading] = useState(true)
    const [orgs, setOrgs] = useState([])
    const [logicalAssets, setLogicalAssets] = useState([])
    const [technicalAssets, setTechnicalAssets] = useState([])
    const [reports, setReports] = useState([])
    const [users, setUsers] = useState([])
    const [fieldsErrorMsg, setFieldsErrorMsg] = useState({ ...fieldsErrorMsgDefault })
    const [errorMsg, setErrorMsg] = useState('')
    const [newReport, setNewReport] = useState({ ...newReportModel })
    const [findingToEdit, setFindingToEdit] = useState(null)
    const [findingToDeleteId, setFindingToDeleteId] = useState('')
    const [isNewFinding, setIsNewFinding] = useState(true)
    const [findingFieldsErrorMsg, setFindingFieldsErrorMsg] = useState({ ...findingsErrorMsgDefault })
    const [findingErrorMsg, setFindingErrorMsg] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const navigate = useNavigate()

    const getData = async () => {
        let orgs = await OrgApi.getUserOrgs(currentUser)
        if (!orgs || orgs.error) {
            orgs = []
        }
        setOrgs(orgs)
        setNewReport({ ...newReportModel, org_id: currentUserOrgId })
    }

    const getOrgData = async (updatedOrgId) => {
        let messages = []
        const lAssets = await PTApi.getAssets(updatedOrgId, { asset_type: 1 })
        if (!lAssets || lAssets.error) {
            messages.push('Cannot get logical assets for selected organization')
        } else {
            setLogicalAssets(lAssets)
            if (!lAssets.length) messages.push('No logical assets created for selected organization')
        }
        const tAssets = await PTApi.getAssets(updatedOrgId, { asset_type: 2 })
        if (!tAssets || tAssets.error) {
            messages.push('Cannot get technical assets for selected organization')
        } else {
            setTechnicalAssets(tAssets)
            if (!lAssets.length) messages.push('No technical assets created for selected organization')
        }
        const existingReports = await PTApi.getReports(updatedOrgId)
        if (existingReports && !existingReports.error) setReports(existingReports)
        const users = await UserApi.getOrgUsers(updatedOrgId)
        if (!users || users.error) {
            messages.push('Cannot get users for selected organization')
        } else {
            setUsers(users)
            if (!users.length) messages.push('No users created for selected organization')
        }
        if (!orgs?.length) {
            messages.push('No organizations found')
        }
        setNewReport({ ...newReport, user_ids: [], technical_assets: [], logical_asset: '' })
        setFieldsErrorMsg({ ...fieldsErrorMsgDefault })
        if (messages.length) {
            if (messages.length === 1) {
                setAlertMessage(messages[0])
            } else {
                setAlertMessage(<>{messages.map((m, i) => <span key={i}>{m}<br /></span>)}</>)
            }
        } else {
            setAlertMessage('')
        }
    }

    const validateReport = () => {
        let error = false
        const updatedFieldsErrorMsg = helpers.createNewInstance(fieldsErrorMsg)
        if (!newReport.pdf_report_id ) {
            updatedFieldsErrorMsg.pdf_report_id = 'PDF id is requred'
            error = true
        }
        const existingPdf = reports.find(report => report.pdf_report_id === newReport.pdf_report_id * 1)
        if (existingPdf) {
            updatedFieldsErrorMsg.pdf_report_id = `PDF id already appears in report ${existingPdf.id}`
            error = true
        } 
        if (!newReport.logical_asset) {
            updatedFieldsErrorMsg.logical_asset = 'Logical Asset is requred'
            error = true
        }
        if (!newReport.findings.length) {
            updatedFieldsErrorMsg.findings = 'Activity should contain findings'
            error = true
        }
        if (!newReport.main_recommendations.length) {
            updatedFieldsErrorMsg.main_recommendations = 'Activity should contain recommendations'
            error = true
        }
        setFieldsErrorMsg(updatedFieldsErrorMsg)
        return error
    }

    const changeValueHandler = (key, val, isAuto) => {
        if (isAuto) return
        const updatedReport = { ...newReport, [key]: val }
        setNewReport(updatedReport)
        setFieldsErrorMsg({ ...fieldsErrorMsg, [key]: '' })
    }

    const addNewReport = async () => {
        const invalid = validateReport()
        if (invalid) return
        const numerizedReport = prepareReportForPayload()
        const reportUpdated = await PTApi.createReport(numerizedReport)
        if (!reportUpdated || reportUpdated.error) {
            
            if (Array.isArray(reportUpdated.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                reportUpdated.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFieldsErrorMsg(errorDetails)
            } else {
                setErrorMsg(reportUpdated.message)
            }
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: genericTexts.defaultText.createdSuccess(ACTIVITY) }))
        setTimeout(() => {
            navigate('/pt/activities')
        }, 1000)
    }

    const prepareReportForPayload = () => {
        const numerizedReport = numericJsonValues(helpers.createNewInstance(newReport))
        numerizedReport.finding_ids = numerizedReport.findings.map(f => f.id)
        numerizedReport.asset_ids = [numerizedReport.logical_asset, ...numerizedReport.technical_assets]
        numerizedReport.main_recommendations = numerizedReport.main_recommendations.filter(r => r)
        delete numerizedReport.findings
        delete numerizedReport.logical_asset
        delete numerizedReport.technical_assets
        return numerizedReport
    }
    
    const openEditFindingsPopup = (findingId) => {
        const existingFinding = newReport.findings.find(f => f.id === findingId)
        setFindingToEdit(existingFinding || { ...newFindingModel })
        setIsNewFinding(!existingFinding)
    }

    const changeValueHandlerFinding = (key, val) => {
        setFindingToEdit({ ...findingToEdit, [key]: val })
        setFindingFieldsErrorMsg({ ...findingFieldsErrorMsg, [key]: '' })
    }

    const closeEditFindingsPopup = () => {
        setFindingToEdit(null)
        setFindingFieldsErrorMsg({ ...findingsErrorMsgDefault })
        setIsNewFinding(true)
    }

    const updateFinding = async () => {
        const invalid = validateFinding()
        if (invalid) return
        const isNew = !findingToEdit.id
        const updatedFinding = prepareFindingForPayload(findingToEdit)
        const response = await updateCreateFindingAction(isNew, updatedFinding)
        if (!response || response.error) {
            if (Array.isArray(response.details)) {
                const errorDetails = helpers.createNewInstance(fieldsErrorMsgDefault)
                response.details.forEach(err => {
                    errorDetails[err.loc[1]] = err.msg
                })
                setFindingFieldsErrorMsg(errorDetails)
            } else {
                const message = typeof response.message === 'string' ? response.message : response.message.join(', ')
                setFindingErrorMsg(message)
            }
            return
        }
        updateReportFindings(isNew, response)
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: getSuccessMessage(isNew) }))
        closeEditFindingsPopup()
    }

    const prepareFindingForPayload = (finding) => {
        const updatedFinding = numericJsonValues(helpers.createNewInstance(finding))
        updatedFinding.recommendations = updatedFinding.recommendations.filter(r => r)
        updatedFinding.technical_details = updatedFinding.technical_details.filter(d => d)
        return updatedFinding
    }

    const updateCreateFindingAction = async (isNew, updatedFinding) => {
        let updated
        if (isNew) {
            updated = await PTApi.createFinding({ ...updatedFinding, org_id: newReport.org_id })
        } else {
            updated = await PTApi.updateFinding(updatedFinding, updatedFinding.id)
        }
        return updated
    }

    const updateReportFindings = (isNew, newFinding) => {
        const updatedFindings = [...newReport.findings]
        if (isNew) {
            const created = newFinding.finding
            created.recommendations = created.recommendations ? created.recommendations.filter(r => r) : []
            created.technical_details = created.technical_details ? created.technical_details.filter(d => d) : []
            updatedFindings.push(created)
        } else {
            const index = newReport.findings.findIndex(f => f.id * 1 === findingToEdit.id * 1)
            findingToEdit.recommendations = findingToEdit.recommendations ? findingToEdit.recommendations.filter(r => r) : []
            findingToEdit.technical_details = findingToEdit.technical_details ? findingToEdit.technical_details.filter(d => d) : []
            updatedFindings[index] = findingToEdit
        }
        changeValueHandler('findings', updatedFindings)
    }

    const getSuccessMessage = (isNew) => {
        return isNew ? genericTexts.defaultText.createdSuccess(FINDING) : genericTexts.defaultText.updatedSuccess(FINDING)
    }

    const openDeleteFindingPopup = (findingId) => {
        setFindingToDeleteId(findingId)
        setIsOpenDeletePopup(true)
    }

    const deleteFinding = async () => {
        const deletedFinding = await PTApi.deleteFinding(findingToDeleteId)
        if (!deletedFinding || deletedFinding.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(FINDING), notificationType: 'error' }))
        } else {
            const updatedFindings = [...newReport.findings.filter(f => f.id !== findingToDeleteId * 1)]
            changeValueHandler('findings', updatedFindings)
        }
        setIsOpenDeletePopup(false)
        setFindingToDeleteId('')
    }

    const validateFinding = () => {
        let error = false
        const updatedFindingsErrorMsg = helpers.createNewInstance(findingFieldsErrorMsg)
        if (!findingToEdit.title) {
            updatedFindingsErrorMsg.title = 'Title is required'
            error = true
        }
        if (!findingToEdit.severity) {
            updatedFindingsErrorMsg.severity = 'Severity is required'
            error = true
        }
        if (!findingToEdit.description) {
            updatedFindingsErrorMsg.description = 'Description is required'
            error = true
        }
        if (!findingToEdit.risk_description) {
            updatedFindingsErrorMsg.risk_description = 'Risk Description is required'
            error = true
        }
        if (error) setFindingFieldsErrorMsg(updatedFindingsErrorMsg)
        return error
    }

    useEffect(() => {
        if (newReport.org_id) getOrgData(newReport.org_id, orgs)
    }, [newReport.org_id])

    useEffect( async () => {
        if (currentUserOrgId) {
            await getData()
            setIsLoading(false)
        }
    }, [currentUserOrgId])

    return (
        <div className='page-content'>
            <div className='page-header'>
                <h1 id='new-pt-activity'>{genericTexts.defaultText.addNewPopupTitle(ACTIVITY)}</h1>
            </div>
            {isLoading ? <Loader /> : <div className='new-report-con'>
                {alertMessage && <Alert message={alertMessage} alertType='warning' />}
                <div className='new-report-form form'>
                            
                    <fieldset>
                        <InputRow 
                            inputId='org_id' 
                            title='Organization'
                            inputTag='select'
                            placeholder='Select'
                            options={orgs}
                            changeValueHandler={(val) => changeValueHandler('org_id', val)} 
                            defaultValue={ newReport.org_id }
                            errorMsg={fieldsErrorMsg.org_id} />
                                        
                        <InputRow 
                            inputId='pdf_report_id' 
                            title='PDF Report ID' 
                            changeValueHandler={(val) => changeValueHandler('pdf_report_id', val)} 
                            defaultValue={ newReport.pdf_report_id || '' }
                            errorMsg={fieldsErrorMsg.pdf_report_id} />

                        
                        <InputRow 
                            inputId='logical_asset' 
                            title='Logical Asset' 
                            inputTag='select'
                            placeholder='Select'
                            options={logicalAssets.map(a => ({ ...a, name: a.system_name }))}
                            changeValueHandler={(val) => changeValueHandler('logical_asset', val)} 
                            defaultValue={ newReport.logical_asset }
                            errorMsg={fieldsErrorMsg.logical_asset} />
                                        
                        <InputRow
                            inputId='date'
                            title='Date'
                            inputType='dateTime'
                            defaultValue={newReport.date ? new Date(newReport.date) : ''}
                            changeValueHandler={(val, isAuto) => changeValueHandler('date', val, isAuto)}
                            errorMsg={fieldsErrorMsg.date} />
                                        
                        <InputRow
                            inputId='is_completed'
                            title='Completed'
                            inputType='checkbox'
                            defaultValue={newReport.is_completed}
                            changeValueHandler={val => changeValueHandler('is_completed', val)}
                            errorMsg={fieldsErrorMsg.is_completed} />
                    </fieldset>

                    <fieldset className='findings-list'>
                        <h4 id='new-activity-findings'>Findings</h4>
                        <ul>
                            {newReport.findings.map((finding, i) => {
                                const severityTitle = findingSeverityTypes.find(t => t.id === finding.severity)?.name || ''
                                return (<li key={`finding-${i + 1}`}>
                                    <p>{finding.title}</p>
                                    <div>
                                        <span className={`status ${statusTypes[severityTitle]}`}>{severityTitle}</span>
                                        <button className='btn-icon' onClick={() => openEditFindingsPopup(finding.id)} id={`edit-finding-${i + 1}`}><i className='icon-edit'></i></button>
                                        <button className='btn-icon' onClick={() => openDeleteFindingPopup(finding.id)} id={`delete-finding-${i + 1}`}><i className='icon-delete'></i></button>
                                    </div>
                                </li>) })}
                            <li><button className='btn-icon btn-add' onClick={() => openEditFindingsPopup('new')} id='add-finding'><i className='icon-plus'></i></button></li>
                        </ul>
                        <p className='error-msg' id='new-activity-findings-error'>{fieldsErrorMsg.findings}</p>
                    </fieldset>
                    
                    <fieldset>
                        <h4 id='new-activity-technical-assets'>Technical Assets</h4>
                        <CheckBoxGroup 
                            title='Select All'
                            checkBoxGroupId='technical-assets'
                            allItems={technicalAssets.map(a => ({ id: a.id, name: `${a.full_url}:${a.port}` }))}
                            checkedIds={newReport.technical_assets}
                            updateItems={(val) => changeValueHandler('technical_assets', val)} 
                            columns={1} />
                    </fieldset>
                    
                    <fieldset>
                        <h4 id='new-activity-participants'>Participants</h4>
                        <CheckBoxGroup 
                            title='Select All'
                            checkBoxGroupId='participants'
                            allItems={users.map(u => ({ id: u.id, name: u.name || u.user_name }))}
                            checkedIds={newReport.user_ids}
                            updateItems={(list) => changeValueHandler('user_ids', list)} />
                        <p className='error-msg' id='new-activity-participants-error'>{fieldsErrorMsg.user_ids}</p>
                    </fieldset>

                    <fieldset>
                        <InputList
                            details={{ inputId: 'main_recommendations', title: 'Main Recommendations' }}
                            list={Array.isArray(newReport.main_recommendations) ? newReport.main_recommendations : []}
                            canDeleteFirst={true}
                            updateInputList={(val) => changeValueHandler('main_recommendations', val)} />
                        <p className='error-msg' id='new-activity-recommendations-error'>{fieldsErrorMsg.main_recommendations}</p>
                    </fieldset>

                    <p className='error-msg' id='new-activity-error'>{errorMsg}</p>

                    <div className='form-buttons'>
                        <button className='btn-submit' onClick={addNewReport} id='add-new-pt-report-submit'>
                            {genericTexts.defaultText.createNewButton(ACTIVITY)}
                        </button>
                    </div>

                </div>

                <Popup
                    onClose={closeEditFindingsPopup}
                    isOpen={!!(findingToEdit)}
                    title={genericTexts.defaultText.addNewPopupTitle(FINDING, !isNewFinding)}
                    isDisabledClickOutside={true}
                    customClass='large'>
                    {!!(findingToEdit) && <>
                        <fieldset>
                            <InputRow 
                                inputId='title' 
                                title='Title' 
                                inputTag='textarea'
                                changeValueHandler={(val) => changeValueHandlerFinding('title', val)} 
                                defaultValue={ findingToEdit.title || '' }
                                errorMsg={findingFieldsErrorMsg.title} />

                            <InputRow 
                                inputId='severity' 
                                title='Severity' 
                                inputTag='select'
                                placeholder='Select'
                                options={findingSeverityTypes.map(t => ({ ...t, name: helpers.capitalizeString(t.name) }))}
                                changeValueHandler={(val) => changeValueHandlerFinding('severity', val)} 
                                defaultValue={ findingToEdit.severity || '' }
                                errorMsg={findingFieldsErrorMsg.severity} />

                        </fieldset>

                        <fieldset>
                            <InputRow 
                                inputId='description' 
                                title='Description'
                                inputTag='textarea'
                                changeValueHandler={(val) => setFindingToEdit({ ...findingToEdit, description: val })} 
                                defaultValue={ findingToEdit.description || '' }
                                errorMsg={findingFieldsErrorMsg.description} />

                            <InputRow 
                                inputId='risk_description' 
                                title='Risk Description'
                                inputTag='textarea'
                                changeValueHandler={(val) => setFindingToEdit({ ...findingToEdit, risk_description: val })} 
                                defaultValue={ findingToEdit.risk_description || '' }
                                errorMsg={findingFieldsErrorMsg.risk_description} />

                        </fieldset>

                        <fieldset>
                            <h4 id='new-activity-finding-scoring'>Scoring</h4>
                            <div className='scoring'>
                                <InputRow 
                                    inputId='impact' 
                                    inputType='number'
                                    placeholder='Impact'
                                    changeValueHandler={(val) => setFindingToEdit({ ...findingToEdit, scoring: { ...findingToEdit.scoring, ['Impact']: val } })} 
                                    defaultValue={ findingToEdit.scoring['Impact'] || '' }
                                    errorMsg={findingFieldsErrorMsg.scoring['Impact']} />

                                <InputRow 
                                    inputId='likelihood'  
                                    inputType='number'
                                    placeholder='Likelihood'
                                    changeValueHandler={(val) => setFindingToEdit({ ...findingToEdit, scoring: { ...findingToEdit.scoring,  ['Likelihood']: val } })} 
                                    defaultValue={ findingToEdit.scoring['Likelihood'] || '' }
                                    errorMsg={findingFieldsErrorMsg.scoring['Likelihood']} />

                                <InputRow 
                                    inputId='riskLevel' 
                                    inputType='number'
                                    placeholder='Risk Level' 
                                    changeValueHandler={(val) => setFindingToEdit({ ...findingToEdit, scoring: { ...findingToEdit.scoring,  ['Risk Level']: val } })} 
                                    defaultValue={ findingToEdit.scoring['Risk Level'] || '' }
                                    errorMsg={findingFieldsErrorMsg.scoring['Risk Level']} />
                            </div>
                        </fieldset>

                        <fieldset>
                            <InputList
                                details={{ inputId: 'recommendations', title: 'Recommendations' }}
                                list={Array.isArray(findingToEdit.recommendations) ? findingToEdit.recommendations : []}
                                canDeleteFirst={true}
                                updateInputList={(val) => setFindingToEdit({ ...findingToEdit, recommendations: val })} />
                            <p className='error-msg' id='new-activity-finding-recommendations-error'>{findingFieldsErrorMsg.recommendations}</p>

                            <InputList
                                details={{ inputId: 'technical_details', title: 'Technical Details' }}
                                list={Array.isArray(findingToEdit.technical_details) ? findingToEdit.technical_details : []}
                                canDeleteFirst={true}
                                updateInputList={(val) => setFindingToEdit({ ...findingToEdit, technical_details: val })} />
                            <p className='error-msg' id='new-activity-technical-details-error'>{findingFieldsErrorMsg.technical_details}</p>
                        </fieldset>

                        <p className='error-msg' id='new-activity-finding-error'>{findingErrorMsg}</p>

                        <div className='popup-buttons'>
                            <button className='btn-submit' onClick={updateFinding} id='create-new-finding'>{genericTexts.defaultText.createNewButton(FINDING, !isNewFinding)}</button>
                            <button onClick={closeEditFindingsPopup} className='btn-cancel' id='create-new-finding-cancel'>{genericTexts.defaultText.cancel}</button>
                        </div>
                    </>}
                </Popup>

                <Popup
                    onClose={() => setIsOpenDeletePopup(false)}
                    isOpen={isOpenDeletePopup}
                    title={genericTexts.defaultText.deletePopupTitle}>
                    <p>{genericTexts.defaultText.deletePopupText(FINDING)}</p>
                    <div className='popup-buttons'>
                        <button className='btn-submit' onClick={() => deleteFinding()} id='delete-finding'>{genericTexts.defaultText.yes}</button>
                        <button onClick={() => setIsOpenDeletePopup(false)} className='btn-cancel' id='delete-finding-cancel'>{genericTexts.defaultText.no}</button>
                    </div>
                </Popup>

            </div>}
            

        </div>     
    )
}

const NewReport = connect(mapStateToProps)(NewReportComponent)

export default NewReport