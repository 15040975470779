import React, { useEffect, useRef, useState } from 'react'
import { InputRow } from 'hub-web-lib'
import './Login.scss'
import UserApi from 'services/UserApi'
import { getErrorMessage } from 'middlewares/ApiHelpers'
import Otp from './Otp/Otp'
import OtpSetup from './Otp/OtpSetup'
import { loginModes } from 'js/constants/enums/user'
import ReCAPTCHA from 'react-google-recaptcha'
import SettingsApi from 'services/SettingsApi'

const userCredentialsDefault = {
    username: '',
    password: ''
}

export const Login = ({ logIn = () => { }, errorMsg = '', mode, parameter }) => {
    const [userCredentials, setUserCredentials] = useState(userCredentialsDefault)
    const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState('')
    const [isForgotPassword, setIsForgotPassword] = useState(mode === loginModes.resetPassword)
    const [isPasswordLinkSent, setIsPasswordLinkSent] = useState(false)
    const [isPasswordLinkSending, setIsPasswordLinkSending] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [isLoadingButton, setIsLoadingButton] = useState(false)
    const [envLogo, setEnvLogo] = useState('')
    const mounted = useRef(false)
    const loginWithCredentials = async (userCredentials) => {
        setIsLoadingButton(true)
        const recaptcha = recaptchaRef?.current
        await logIn(userCredentials)
        if (recaptcha) recaptcha.reset()
        if (!mounted.current) return
        setIsLoadingButton(false)
    }

    const switchForgotPasswordMode = (isOn) => {
        setIsForgotPassword(isOn)
        setIsPasswordLinkSent(false)
        setChangePasswordErrorMsg('')
    }

    const sendNewPassword = async (isResend) => {
        setChangePasswordErrorMsg('')
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)) {
            setChangePasswordErrorMsg('Email is not Valid')
            return
        }
        setIsPasswordLinkSending(true)
        const recaptcha = recaptchaRef?.current
        const isPasswordSent = await UserApi.sendNewUserPassword(userEmail, isResend, userCredentials.captcha_value)
        if (!isPasswordSent?.data?.error) {
            setIsPasswordLinkSent(true)
        } else {
            setChangePasswordErrorMsg(getErrorMessage({ response: isPasswordSent }))
            !isResend && setIsPasswordLinkSent(false)
        }
        if (recaptcha) recaptcha.reset()
        setIsPasswordLinkSending(false)
    }

    const changeUserMail = (val) => {
        setUserEmail(val)
        setChangePasswordErrorMsg('')
    }

    const onSubmitOtp = (otp) => {
        const updatedUserCredentials = { ...userCredentials, client_secret: otp }
        setUserCredentials(updatedUserCredentials)
        logIn(updatedUserCredentials)
    }

    const brandingTitle = process.env.REACT_APP_SITE_BRAND_NAME
    const brandingLogo = envLogo ? <img src={envLogo} /> : ''
     

    const getEnvLogo = async () => {
        const logoUrl = await SettingsApi.orgLogo()
        setEnvLogo(logoUrl)
    }
    useEffect(async () => {
        mounted.current = true
        getEnvLogo()
        return () => {
            mounted.current = false
        }
        
    }, [])

    const [captchaData, setCaptchaData] = useState({ required: false })

    useEffect(() => {
        let curLoginMode = mode
        if (curLoginMode === loginModes.login && isForgotPassword) {
            curLoginMode = isPasswordLinkSent ? loginModes.afterResetPassword : loginModes.resetPassword
        }
        const isNeedCaptcha = curLoginMode === loginModes.resetPassword || curLoginMode === loginModes.afterResetPassword
        if (!mounted.current) return
        UserApi.getCaptchaData(isNeedCaptcha).then(
            data => setCaptchaData(data)
        )
    }, [mode, isForgotPassword, isPasswordLinkSent, isLoadingButton])

    const onChangeCaptcha = (captchaValue) => {
        setUserCredentials({ ...userCredentials, captcha_value: captchaValue })
    }

    const recaptchaRef = React.createRef()

    const captchaControl = captchaData.required ? (
        <div className='captcha-container'>
            <ReCAPTCHA ref={recaptchaRef} sitekey={captchaData.site_key} theme="dark" onChange={onChangeCaptcha} />
        </div>
    ) : null

    let loginControl
    if (isForgotPassword && isPasswordLinkSent) {
        loginControl = (<>
            <div className='login-header'>
                <span><i className='icon-v'></i></span>
                <h2 id='reset-password-link'>{isPasswordLinkSending ? 'Sending Reset Password Link...' : 'Reset Password Link Sent'}</h2>
            </div>
            <p>We&apos;ve {isPasswordLinkSending ? 'sending' : 'sent'} you an email to reset your password.</p>

            <p>To create your new password, click the link in the email and enter a new one - easy.</p>

            <p>Didn&apos;t receive the email? Click below to resend the email</p>
            <div className='form'>
                <p className='error-msg' id='reset-passsword-error'>{changePasswordErrorMsg}</p>
                {captchaControl}
                <div className='form-buttons'>
                    <button className='btn-set' onClick={() => switchForgotPasswordMode(false)} id='back-to-login-sent'>Back to Login</button>
                    <button onClick={() => sendNewPassword(true)} className={'btn-set'} id='resend-link'>Resend Link</button>
                </div>
            </div>
        </>)
    }
    else if (isForgotPassword && !isPasswordLinkSent) {
        loginControl = (<>
            <div className='login-header'>
                <h2 id='reset-your-password'>Reset Your Password</h2>
            </div>

            <div className='form'>
                <InputRow
                    inputId='email'
                    defaultValue={userEmail}
                    placeholder='Email'
                    layout='vertical'
                    inputType='email'
                    changeValueHandler={changeUserMail}
                    handleSubmit={() => sendNewPassword(false)}
                    isEllipsis={false} />
                <p className='error-msg' id='reset-passsword-error'>{changePasswordErrorMsg}</p>
                {captchaControl}
                <button onClick={() => sendNewPassword(false)} className={'btn-submit'} disabled={!userEmail} id='reset-pasword'>Reset Password</button>
                <button className='btn-link' onClick={() => switchForgotPasswordMode(false)} id='back-to-login'>Back to Login</button>
            </div>
        </>)
    }
    else if (mode === loginModes.setupOtp) {
        loginControl = (<OtpSetup submitOtp={onSubmitOtp} secretKey={parameter} errorMsg={errorMsg} />)
    }
    else if (mode === loginModes.getOtp) {
        loginControl = (<Otp submitOtp={onSubmitOtp} errorMsg={errorMsg} />)
    }
    else {
        loginControl = (<>
            <div className='login-header'>
                <h2 id='login'>Login</h2>
            </div>
            <InputRow
                inputId='userName'
                defaultValue={userCredentials.username}
                placeholder='Username/email address'
                layout='vertical'
                changeValueHandler={val => setUserCredentials({ ...userCredentials, username: val })}
                isEllipsis={false} />
            <InputRow
                inputId='userPassword'
                defaultValue={userCredentials.password}
                placeholder='Password'
                inputType='password'
                layout='vertical'
                changeValueHandler={val => setUserCredentials({ ...userCredentials, password: val })}
                handleSubmit={() => logIn(userCredentials)}
                isEllipsis={false} />
            <p className='error-msg' id='login-error'>{errorMsg}</p>
            {captchaControl}
            <button onClick={() => loginWithCredentials(userCredentials)} className={`btn-submit ${isLoadingButton ? 'loader' : ''}`} disabled={!(userCredentials.username && userCredentials.password)} id='login'>Login</button>
            <button className='btn-link' onClick={() => switchForgotPasswordMode(true)} id='forgot-password'>Forgot Password</button>
        </>)
    }

    return (
        <div className={`login ${isPasswordLinkSent ? 'no-background' : ''}`}>
            {brandingLogo && <div className='branding-logo'>{brandingLogo}</div>}
            <div className='login-container'>
                {brandingTitle && <div className='branding-title'>{brandingTitle}</div>}
                {loginControl}
            </div>
        </div>
    )
}

export default Login