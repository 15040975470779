import axios from 'axios'
import { dispatchRequest } from 'middlewares/ApiHelpers'

const OrgApi = {

    getOrg: async (orgId) => {
        return await dispatchRequest(axios.get(`/orgs/${orgId}`)).then(res => res.data)
    },

    createOrg: async (orgToEdit) => {
        if (orgToEdit?.new_user && !orgToEdit.new_user.avatar_url) delete orgToEdit.new_user.avatar_url
        return await dispatchRequest(axios.post('/orgs/create', orgToEdit)).then(res => res.data)
    },

    disableOrg: async (orgId) => {
        return await dispatchRequest(axios.post(`/orgs/${orgId}/deactivate`)).then(res => res.data)
    },

    enableOrg: async (orgId) => {
        return await dispatchRequest(axios.post(`/orgs/${orgId}/activate`)).then(res => res.data)
    },

    getUserOrgs: async (user, activeOnly) => {
        const isHubAdmin = user?.org_id === 1 && user?.is_admin
        return dispatchRequest(axios.get(`/orgs/all${(isHubAdmin || activeOnly) ? '' : '_active'}`)).then(res => res.data)
    },
    getChildOrgs: async (orgId) => {
        return dispatchRequest(axios.get(`/orgs/get_child_orgs/${orgId}`)).then(res => res.data)
    },
    editOrg: async (orgDict) => {
        return dispatchRequest(axios.put(`/orgs/${orgDict.id}/edit_org`, orgDict)).then(res => res.data)
    },
    uploadOrgLogo: async (org_id, file, isDefault) => {
        const params = { default_company_logo: isDefault }
        return dispatchRequest(axios.post(`/files/org/${org_id}/company-logo`, file, { params })).then(res => res.data)
    }

}
export default OrgApi
