import React from 'react'
import './TermsCondtions.scss'
import UserApi from 'services/UserApi'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'


const currentUrl = window.location.protocol + '//' + window.location.host + '/'


const TermsCondtions = ({ currentUserLogged, setIsTermsPopup = () => { } }) => {
    const acceptTerms = async (currentUserLogged) => {
        const updatedUser = await UserApi.updateUserTerms(currentUserLogged.id)
        if (!updatedUser || updatedUser.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: updatedUser.message, notificationType: 'error' }))
            return
        }
        setIsTermsPopup(false)
    }
    return (
        <div className='terms-container'>
            <div className='terms-header'>
                <h1 id='terms-title'>HUB Guard Platform</h1>
                <h2 id='terms-secondary-title'>Terms of Use</h2>
            </div>

            <div className='terms-body'>
                <p>
                    The HUB Guard Platform website suite (the &ldquo;Platform&rdquo;) located at {currentUrl} (the &ldquo;Site&rdquo;).
                    The solution is a copyrighted work belonging to HUB Cyber Security Ltd. (the &ldquo;Company&rdquo;). Certain features of the
                    Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in
                    connection with such features.
                </p>
                <p>All such additional terms, guidelines, and rules are incorporated by
                    reference into these terms.</p>
                <p>
                    These terms of use describe the legally binding terms and conditions that
                    oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE EXPLICITLY AGREEING TO COMPLY WITH THESE
                    TERMS and you represent that you have the authority and legal capacity to enter into these terms. YOU SHOULD
                    BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ANY OF THE PROVISIONS OF THESE TERMS,
                    DO NOT LOG INTO AND/OR USE THE SITE.
                </p>
                <ol>
                    <li className='titles bold'> Definitions</li>
                    <p>For purposes of the agreement each of the terms listed below shall be deemed to
                        have the meaning attributed to it:</p>
                    <ol className='sub-items'>
                        <li className='dots'>Agreement &ndash; these terms of use, also referred to as these
                            terms.</li>
                        <li className='dots'>User &ndash; the party / legal entity who uses the Site.</li>
                        <li className='dots'>Office hours - 09:00 to 18:00 GMT+3 during Israeli business
                            days.</li>
                        <li className='dots'>Parties - Company and User together.</li>
                        <li className='dots'>Party - either Company or User.</li>
                        <li className='dots'>Regulation &ndash; any governmental dictate applicable to these
                            terms of use including without limitation, import, export and privacy.</li>
                        <li className='dots'>Software &ndash; Company&rsquo;s computer executable programs
                            located on the Site.</li>
                        <li className='dots'>Support request &ndash; User&#39;s request for support from
                            Company.</li>
                    </ol>
                    <p>The above definitions are in addition to any other definitions located in these terms and conditions.</p>
                    <li className='titles'>
                        <h4> Access to the Site</h4>
                        <ol className='sub-items'>
                            <li className='dots'>
                                <h4>Subject to these terms.</h4>
                                <p> Company grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.</p>
                            </li>

                            <li className='dots'><h4>Certain Restrictions.</h4>
                                <br />The rights approved to you in these terms are subject to the following restrictions:
                                <ol>
                                    <li className='squares'>you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; and</li>
                                    <li className='squares'>you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; and</li>
                                    <li className='squares'>you shall not access the Site in order to build a similar or competitive website; and</li>
                                    <li className='squares'>you shall not interfere or disrupt in any way the operation of the Site; and</li>
                                    <li className='squares'>except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these terms. All copyright and other proprietary notices on the Site must be retained on all copies thereof.</li>
                                </ol>
                            </li>
                            The Company reserves the right to change, suspend, or terminate the Site with or without prior notice to you. You agree that the Company will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part thereof.
                            No support or maintenance. You agree that the Company will have no obligation to provide you with any support in connection with the Site.
                        </ol>
                    </li>
                    <li className='titles'>
                        <h4>General</h4>
                        <ol className='sub-items'>
                            <li className='dots'>
                                These terms are subject to occasional revision, and if Company makes any substantial changes, Company may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on the Site. You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us with is not valid, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Any changes to these terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on the Site. These changes will be effective immediately for new users of the Site. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                            </li>
                            <li className='dots'>
                                <span className='bold'>Force Majeure Events</span>
                                <br />
                                Company shall not be liable to you for any failure to perform its any of its obligations or duties hereunder if such failure is caused by acts of nature, war, hostilities, government sanction or any acts of any government authority, strike, visa and other immigration-or travel-related delays, interruption of electricity or communication, labor dispute, other labor trouble, shortage of labor or market shortage of materials, fire, epidemics (including COVID-19), acts of terrorism, riots, malicious acts of damage, failure of the public electricity supply, public calamity, flood or any event beyond the Company’s reasonable control (“force majeure events”), provided that it uses reasonable efforts, under the circumstances, to resume performance as soon as reasonably practicable.
                            </li>
                            <li className='dots'>
                                <span className='bold'>Electronic Communications</span>
                                <br />
                                The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal obligation that such communications would satisfy if it were in a hard copy format.
                            </li>
                            <li className='dots'>
                                <span className='bold'>Entire Terms</span>
                                <br />
                                These terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these terms shall not operate as a waiver of such right or provision. The section titles in these terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”. If any provision of these terms is held to be invalid or unenforceable, the other provisions of these terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other. These terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The Company may freely assign these terms. The terms and conditions set forth in these terms shall be binding upon assignees.

                            </li>
                            <li className='dots'>
                                <span className='bold'>Your Privacy</span>
                                <br />
                                For a better experience while using the Platform, you may be required to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you. Additional information is included in Appendix I – The Platform: Privacy Policy.
                            </li>
                        </ol>

                    </li>
                    <li className='titles'>
                        <h4>Intellectual Property Rights</h4>
                        <p>
                            Company shall own all intellectual property rights related to the Platform, the Site and software. You acknowledge and agree that the software, and any related know-how, methodologies, equipment, processes, updates, upgrades, modifications, enhancements and derivative works and, including without limitation all patent, copyright, trade secret, trademarks, trade names, service marks and other proprietary rights therein (“proprietary rights”), are and shall remain the sole and exclusive property of the Company. You have, and shall have, no right, title or interest in and to the software with respect to proprietary rights, other than the rights expressly set forth in this document.

                            Therefore, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Company, its subsidiaries or suppliers. Note that these terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights. Company, its subsidiaries or suppliers reserve all rights not granted in these terms.
                        </p>
                    </li>
                    <li className='titles'>
                        <h4>Use by Authorized Personnel</h4>
                        <p>
                            You may allow authorized personnel to use the Site solely on your behalf and for internal operations. You are fully responsible for ensuring that these authorized personnel comply with the terms and are solely liable to the Company for any breach of the same by such authorized personnel.
                        </p>
                    </li>
                    <li className='titles'>
                        <h4>User Content</h4>
                        <ol className='sub-items'>
                            <li className='dots'>
                                <h4>User Content</h4>
                                <p>“User content” means any and all information and content that User submits to the Site. You are exclusively responsible for your user content. You bear all risks associated with use of your user content. You hereby certify that your user content does not violate our acceptable use policy. You may not represent or imply to others that your user content is in any way provided, sponsored or endorsed by Company. Because you alone are responsible for your user content, you may expose yourself to liability. Company is not obliged to backup any user content that you post; also, your user content may be deleted at any time without prior notice to you. You are solely responsible for making your own backup copies of your user content if you desire.</p>
                                <p>You hereby grant to Company an irreversible, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your user content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your user content in the Site. You hereby irreversibly waive any claims and assertions of moral rights or attribution with respect to your user content.</p>
                            </li>
                            <li className='dots'>
                                <h4>Acceptable Use Policy</h4>
                                <p>Intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</p>
                                <p>In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain squares, pyramid schemes, or any other form of duplicative or unsolicited messages; (iii) use the Site to harvest, collect, gather or assemble information or data regarding other users without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site, whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to the Site.</p>
                                <p>We reserve the right to review any user content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the acceptable use policy or any other provision of these terms or otherwise create liability for us or any other person. Such action may include removing or modifying your user content, terminating your account, and/or reporting you to law enforcement authorities.</p>
                                <p>If you provide Company with any feedback or suggestions regarding the Site, you hereby assign to Company all rights in such feedback and agree that Company shall have the right to use and fully exploit such feedback and related information in any manner it believes appropriate. Company will treat any feedback you provide to Company as non-confidential and non-proprietary.</p>
                                <p>You agree to indemnify and hold Company and its officers, employees, and agents harmless, including costs and attorneys’ fees, from any claim or demand made by any third-party due to or arising out of (a) your use of the Site, (b) your violation of the acceptable use policy, (c) your violation of applicable laws or regulations or (d) your user content. Company reserves the right to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of the Company. Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
                            </li>

                        </ol>
                    </li>
                    <li className='titles'>
                        <h4>Third-Party Links & Ads; Other Users</h4>
                        <ol className='sub-items'>
                            <li className='dots'>
                                <h4>Third-Party Links & Ads. </h4>
                                <p>The Site may contain links to third-party websites and services, and/or display advertisements for third parties. Such third-party links & ads are not under the control of Company, and Company is not responsible for any third-party links & ads. Company provides access to these third-party links & ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to third-party links & ads. You use all third-party links & ads at your own risk and should apply a suitable level of caution and discretion in doing so. When you click on any of the third-party links & ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.</p>
                            </li>
                            <li className='dots'>
                                <h4>Other Users.</h4>
                                <p>You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any other User, we are under no obligation to become involved.
                                    You hereby release and forever discharge the Company and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site.</p>
                            </li>
                            <li className='dots'>
                                <h4>Cookies and Web Beacons.</h4>
                                <p> Like any other website, the Platform uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
                            </li>
                        </ol>
                    </li>

                    <li className='titles'>
                        <h4>Limitation and Exclusion of Liability; Indemnification</h4>
                        <p>
                            To the fullest extent under applicable law, in no event will the Company, its officers, directors, employees, agents, suppliers and/or its licensors be liable for any indirect, incidental, exemplary, special, punitive or consequential damages, including but not limited to damages for business interruptions, business information, goodwill, use, data or other losses (even if Company has been advised of the possibility of such damages), resulting from or in connection with the Platform and the Site or the use or inability to use it, foreseeable or unforeseeable.
                            Without derogating from any other provision hereof, Company&apos;s total liability towards you for any loss or damage, whether caused by breach of contract or tort (including breach of warranty, any negligence, strict liability in tort or breach of statutory duty) or arising in any other way, will be limited to the actual consideration paid by you to the Company during a period of 6 (six) months preceding the relevant damage occurrence.
                            You agree to indemnify and hold Company and its directors, officers, employees, representatives, agents, co-branders and other partners, harmless from any claim or demand, including reasonable attorneys fees, made by any third party due to or arising out of your violation of this document.
                        </p>
                    </li>
                    <li className='titles'>
                        <h4>Limitation and Exclusion of Liability; Indemnification</h4>
                        <ol className='sub-items'>
                            <li>
                                To the fullest extent under applicable law, in no event will the Company, its officers, directors, employees, agents, suppliers and/or its licensors be liable for any indirect, incidental, exemplary, special, punitive or consequential damages, including but not limited to damages for business interruptions, business information, goodwill, use, data or other losses (even if Company has been advised of the possibility of such damages), resulting from or in connection with the Platform and the Site or the use or inability to use it, foreseeable or unforeseeable.
                            </li>
                            <li>
                                Without derogating from any other provision hereof, Company&apos;s total liability towards you for any loss or damage, whether caused by breach of contract or tort (including breach of warranty, any negligence, strict liability in tort or breach of statutory duty) or arising in any other way, will be limited to the actual consideration paid by you to the Company during a period of 6 (six) months preceding the relevant damage occurrence.
                            </li>
                            <li>
                                You agree to indemnify and hold Company and its, directors, officers, employees, representatives, agents, co-branders and other partners, harmless from any claim or demand, including reasonable attorneys fees, made by any third party due to or arising out of your violation of this document.
                            </li>
                        </ol>
                    </li>

                    <li className='titles'>
                        <h4>Term and Termination</h4>
                        <p>

                            Subject to this section, these terms will remain in full force and effect while you use the Site. We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these terms. Upon termination of your rights under these terms, your account and right to access and use the Site will terminate immediately. You understand that any termination of your account may involve deletion of your user content associated with your account from our live databases. Company will not have any liability whatsoever to you for any termination of your rights under these terms.
                        </p>
                    </li>
                    <li className='titles'>
                        <h4>Copyright Policy</h4>
                        <p>Company respects the intellectual property of others and asks that users of the Site do the same. In connection with its Site, Company has adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of the online Site who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of the Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information must be provided to Company:</p>
                        <ol className='sub-items'>
                            <li className='dots'>
                                your physical or electronic signature;
                            </li>
                            <li className='dots'>
                                identification of the copyrighted work(s) that you claim to have been infringed;
                            </li>
                            <li className='dots'>
                                identification of the material on the Site that you claim is infringing and that you request us to remove;
                            </li>
                            <li className='dots'>
                                sufficient information to permit us to locate such material;
                            </li>
                            <li className='dots'>
                                your address, telephone number, and e-mail address;
                            </li>
                            <li className='dots'>
                                a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
                            </li>
                            <li className='dots'>
                                a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.
                            </li>
                        </ol>
                    </li>
                    <li className='titles'>
                        <h4>Availability</h4>
                        The Site&apos;s availability and functionality depend on various factors, such as communication networks, software, hardware, and the Company&apos;s Site providers and contractors. The Company does not warrant or guarantee that the Site will operate and/or be available at all times without disruption or interruption, or that it will be immune from unauthorized access error-free.
                    </li>
                    <li className='titles'>
                        <h4>Changes to the Site</h4>
                        Company reserves the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue, temporarily or permanently, the Site (or any part thereof) without notice, at any time. In addition, you hereby acknowledge that the content available through the Site may be changed, modified, edited, or extended in terms of content and form or removed at any time without any notice to you. You agree that the Company shall not be liable to you or to any third party for any modification, suspension, error, malfunction, or discontinuance of the Site (or any part thereof).
                    </li>
                    <li className='titles'>
                        <h4>Survival</h4>
                        The sections dealing with access to the Site, intellectual property rights, user content, limited warranty and disclaimer, limitation and exclusion of liability; indemnification, term and termination, copyright policy, survival and governing law, jurisdiction & dispute resolution shall survive termination or expiration of these terms.                    </li>
                    <li className='titles'>
                        <h4>Governing Law & Jurisdiction and Dispute Resolution</h4>
                        <ol className='sub-items'>
                            The use of the Site and the adjudication of these terms shall be governed by the laws of the State of Israel without giving effect to its provisions regarding conflict of laws. Exclusive jurisdiction over and venue of any suit arising out of or relating to the use of the Site and the adjudication of these terms will be in the relevant courts located in Tel Aviv, Israel and each of the parties hereto consents to the personal jurisdiction of, and venue in, those courts.
                            All claims and disputes in connection with the terms or the use of any product or service provided by the Company that cannot be resolved informally shall be resolved by binding arbitration on an individual basis. Unless otherwise agreed to, it shall apply to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the terms. All arbitration proceedings shall be held in English.
                            Before either party may seek arbitration, the party must first send to the other party a written notice of dispute describing the nature and basis of the claim or dispute, and the requested relief. If you and the Company do not resolve the claim or dispute within thirty (30) days after the notice is received, either party may begin an arbitration proceeding.
                            All aspects of the arbitration proceeding shall be strictly confidential. The parties agree to maintain confidentiality unless otherwise required by law.
                        </ol>
                    </li>

                    <li className='titles'>
                        <h4>Contact</h4>
                        If you have any questions (or comments) concerning the terms or the Site, you are welcome to send us an email to the following address, and we will make an effort to reply within a reasonable timeframe: info@HUBSecurity.io. By contacting us, you represent that you are free and authorized to do so and that you will not knowingly provide HUB Security with information that infringes upon third parties’ rights, including any intellectual property rights nor will you provide us with any non-authorized information. You further acknowledge that notwithstanding anything herein to the contrary, any and all rights, including intellectual property rights in such information provided, shall belong exclusively to HUB Security, and HUB Security may use or refrain from using any such information at its sole discretion.
                    </li>
                    <li className='titles'>
                        <h4>Information About Us</h4>
                        The details of the Company are as follows: HUB Security Ltd. 30 Hamasger ST., Tel Aviv, Israel

                    </li>

                </ol>
                <div className='terms-header'>
                    <h2 id='appendix-title'>Appendix I - Privacy Policy</h2>

                </div>
                <div className='terms-body'>
                    <p>
                        HUB Cyber Security Ltd. operates the {currentUrl}  website, which provides the HUB Guard Platform to perform online self assessments.
                        This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of personal information if anyone decided to use our service, the HUB Guard Platform website.
                        If you choose to use our service, then you agree to the collection and use of information in relation with this policy. The personal information that we collect is used for providing and improving the service. We will not use or share your information with anyone except as described in this privacy policy.
                        The terms used in this privacy policy have the same meanings as in our terms and conditions, which is accessible at {currentUrl}, unless otherwise defined in this privacy policy.
                    </p>

                    <ol>
                        <li className='titles'> <h4>Information Collection and Use</h4>
                            For a better experience while using our service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.
                        </li>
                        <li className='titles'> <h4>Log Data</h4>
                            We want to inform you that whenever you visit our service, we collect information that your browser sends to us that is called log data. This log data may include information such as your computer’s internet protocol (“IP”) address, browser version, pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                        </li>
                        <li className='titles'> <h4>Cookies</h4>
                            Cookies are files with a small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.
                            Our website uses these “cookies” to collect information and to improve our service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our service.
                            For more general information on cookies, please read <a
                                href="https://www.google.com/url?q=https://www.cookieconsent.com/what-are-cookies/&amp;sa=D&amp;source=editors&amp;ust=1704201661102298&amp;usg=AOvVaw0bnUPiCiz5slOUVJgzp6rW">&ldquo;What
                                Are Cookies&ldquo;.</a>
                        </li>
                        <li className='titles'> <h4>Service Providers</h4>
                            We may employ third-parties due to the following reasons:
                            To facilitate our service; To provide the service on our behalf; to perform service-related services; or to assist us in analyzing how our service is used.
                            We hereby inform our users that these third parties have access to your personal information, to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                        </li>
                        <li className='titles'> <h4>Security</h4>
                            We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                        </li>
                        <li className='titles'> <h4>Links to Other Sites</h4>
                            Our service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the privacy policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.                        </li>
                        <li className='titles'> <h4>Changes to This Privacy Policy</h4>
                            We may update our privacy policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new privacy policy on this page. These changes are effective immediately, after they are posted on this page.                        </li>
                    </ol>
                </div>
            </div>
            <div className='footer'>
                All right reserved to HUB Cyber Security Ltd. © 2023
                <button className='btn-submit' onClick={() => acceptTerms(currentUserLogged)} id='accept-terms'> Accept Terms & Conditions</button>

            </div>
        </div>
    )
}

export default TermsCondtions