import React, { useState } from 'react'
import { InputRow, LabelDots, Loader, Popup, SortableTable, SplitButton, genericTexts } from 'hub-web-lib'
import { METRICS_TYPE, FINDINGS_TYPE, valuesPropsGeneric } from 'js/constants/metrics/common-metrics'
import { penTestMetricsCategories, penTestMetricsWidgets, penTestMetricsKeys } from 'js/constants/metrics/pen-test-metrics'
import PenTestApi from 'services/PenTestApi'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { connect } from 'react-redux'
import UserApi from 'services/UserApi'
import 'scss/UpdateActivity/MetricsTable.scss'
import { getMetricId, getTextFields, refactorImportedMetrics, getMetricsConsts, preparePredefinedMetrics, getChangedMetrics, getRequiredFieldError, getMetricsLabelDots, checkImportingErrors, getActivityMetricsValuesProps, getValuesFieldsDynamic, getActivityInfoMetricsColumnsForm } from 'js/ui-helpers/metrics'
import { DOWNLOAD_CLOUD, DOWNLOAD_FINDINGS_TEMPLATE, DOWNLOAD_METRICS_TEMPLATE, IMPORT_FINDINGS, IMPORT_METRICS, UPLOAD_CLOUD, metricsButtonsList } from 'js/constants/enums/common-activity'
import ImportTemplateButtons from 'components/Modules/Activity/ImportPlaceholder/ImportTemplateButtons'
import Cloud from 'assets/illustrations/cloud.svg'
import { PEN_TEST_MODULE } from 'js/constants/app-modules'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        dashboardSettings: state.dashboardSettings
    }
}

const PenTestActivityMetricsComponent = ({ data, currentUser, dashboardSettings }) => {
    const currentOrgId = currentUser?.current_org
    const {
        newMetricModel,
        customMetrics,
        predefinedMetricsToEdit,
        updateMetrics,
        templateUrl,
        findingsUrl,
        activityToUpdate,
        hasFindings,
        setActivityFindings
    } = data

    const penTestValuesProps = getActivityMetricsValuesProps(dashboardSettings, PEN_TEST_MODULE)

    const metricsConsts = getMetricsConsts(
        penTestMetricsCategories, 
        penTestMetricsWidgets, 
        penTestMetricsKeys, 
        penTestValuesProps
    )
    
    const labelDotsItems = getMetricsLabelDots(metricsConsts)

    const [custom, setCustom] = useState(customMetrics)
    const [predefined, setPredefined] = useState(preparePredefinedMetrics(predefinedMetricsToEdit, metricsConsts, true))
    const [metricsFile, setMetricsFile] = useState(null)
    const [sheetName, setSheetName] = useState('')
    const [isOpenedMetricsPopUp, setIsOpenedMetricsPopUp] = useState(false)
    const [isOpenedSheetPopUp, setIsOpenedSheetPopUp] = useState(false)
    const [isImportingMetrics, setIsImportingMetrics] = useState(false)
    const [importingErrors, setImportingErrors] = useState([])
    const [isOpenedMetricsErrorsPopup, setIsOpenedMetricsErrorsPopup] = useState(false)
    const isPenTestWarningMessageDisabled = UserApi.getUserProp('isPenTestWarningMessageDisabled', currentUser?.id)
    const [isWarningDisabled, setIsWarningDisabled] = useState(JSON.parse(isPenTestWarningMessageDisabled))
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [fileType, setFileType] = useState('')
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 1)
    const changeFile = (file) => {
        setMetricsFile(file)
        setIsOpenedSheetPopUp(true)
        setIsOpenedMetricsPopUp(false)
        setIsOpenedMetricsErrorsPopup(false)
    }

    const importMetricsClick = () => {
        UserApi.setUserPropCookie('isPenTestWarningMessageDisabled', isWarningDisabled, currentUser?.id, true)
        const importMetricsRow = document.getElementById('fileUpload')
        importMetricsRow.click()

    }

    const checkIfCookiesExists = (type) => {
        setFileType(type)
        isWarningDisabled ? importMetricsClick(type) : setIsOpenedMetricsPopUp(true)
    }

    const closeSheetPopUp = () => {
        setIsOpenedSheetPopUp(false)
        setSheetName('')
        setFileType('')
    }
    
    const uploadNewMetrics = async (type = fileType) => {
        if (!metricsFile) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Please choose a file', notificationType: 'error' }))
            return
        }
        const isMetricsFile = type === METRICS_TYPE
        setIsImportingMetrics(true)
        setIsButtonLoading(true)
        const formData = new FormData()
        formData.append('file', metricsFile)
        const importedMetricsData = isMetricsFile ? await PenTestApi.postMetricsFile(currentOrgId, formData, sheetName) : await PenTestApi.postFindingsFile(currentOrgId, formData, activityToUpdate?.test_type)
        if (!importedMetricsData || importedMetricsData.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `Import failed: ${importedMetricsData.error}`, notificationType: 'error' }))
            setIsButtonLoading(false)
            setIsOpenedSheetPopUp(false)
            setIsImportingMetrics(false)
            return
        }
        const updatedMetrics = refactorImportedMetrics(isMetricsFile ? (importedMetricsData.pentest_metrics?.data_entities || []) : (importedMetricsData?.metrics || []), valuesPropsGeneric)
        setPredefined(updatedMetrics)
        updateMetrics(updatedMetrics, true)
        setActivityFindings(importedMetricsData?.findings || [])
        checkImportingErrors(importedMetricsData?.pentest_metrics, updatedMetrics, setImportingErrors, setIsOpenedMetricsErrorsPopup)
        setIsOpenedSheetPopUp(false)
        setIsButtonLoading(false)
        setIsImportingMetrics(false)
        setFileType('')
    }

    const downloadTemplate = (type) => {
        const url = type === METRICS_TYPE ? templateUrl : findingsUrl
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer')
        } else {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Template not found', notificationType: 'error' }))
        }
    }

    const handleSplitButtonClick = (val) => {
        switch (val) {
        case 1: {
            downloadTemplate(METRICS_TYPE)
            break
        }
        case 2: {
            downloadTemplate(FINDINGS_TYPE)
            break
        }
        case 3: {
            checkIfCookiesExists(METRICS_TYPE)
            break
        }
        case 4: {
            checkIfCookiesExists(FINDINGS_TYPE)
            break
        }
        }
    }

    const templateButtons = [ { func: () => downloadTemplate(METRICS_TYPE), title: DOWNLOAD_METRICS_TEMPLATE, icon: DOWNLOAD_CLOUD, id: 'download-ddos-metrics' },
        { func: () => downloadTemplate(FINDINGS_TYPE), title: DOWNLOAD_FINDINGS_TEMPLATE, icon: DOWNLOAD_CLOUD, id: 'download-ddos-findings' },
        { func: () => checkIfCookiesExists(METRICS_TYPE), title: IMPORT_METRICS, icon: UPLOAD_CLOUD, id: 'import-ddos-metrics' },
        { func: () => checkIfCookiesExists(FINDINGS_TYPE), title: IMPORT_FINDINGS, icon: UPLOAD_CLOUD, id: 'import-ddos-findings' }]

    const changeMetricValue = (key, i, val, isPredefined) => {
        let updatedMetrics = getMetrics(isPredefined)
        updatedMetrics = getChangedMetrics(updatedMetrics, key, i, val, metricsConsts, true)
        setMetrics(updatedMetrics, isPredefined)
        updateMetrics(updatedMetrics, isPredefined)
    }

    const addNewMetric = () => {
        const updatedMetrics = [...custom, { ...newMetricModel }]
        setCustom(updatedMetrics)
    }

    const deleteMetric = (index, isPredefined) => {
        const updatedMetrics = (getMetrics(isPredefined)).filter((_, i) => i !== index)
        setMetrics(updatedMetrics, isPredefined)
        updateMetrics(updatedMetrics, isPredefined)
    }

    const setMetrics = (updatedMetrics, isPredefined) => {
        isPredefined ? setPredefined(updatedMetrics) : setCustom(updatedMetrics)
    }

    const getMetrics = (isPredefined) => {
        return isPredefined ? [...predefined] : [...custom]
    }

    const checkRequiredField = (val, isPredefined, i) => {
        const metrics = getMetrics(isPredefined)
        return getRequiredFieldError(val, i, isPredefined, metrics.length) 
    }

    const metricsFunctions = {
        changeMetricValue,
        checkRequiredField,
        deleteMetric
    }

    const getMetricsTableData = (isPredefined) => {
        const metrics = getMetrics(isPredefined)
        const data = metrics.map((metric, i) => {
            const id = getMetricId(metric, i, isPredefined, predefined)
            const metricProps = {
                metric, 
                i,
                metricsLength: metrics.length,
                id, 
                isPredefined,
            }
            const valuesFields = getValuesFieldsDynamic(metricProps, metricsConsts, metricsFunctions, hasFindings)
            const textFields = getTextFields(metricProps, metricsConsts, metricsFunctions, true, hasFindings)
            return {
                id,
                ...metric,
                ...textFields,
                ...valuesFields,
            }
        })
        return data
    }

    const customMetricsTableData = getMetricsTableData(false)
    const predefinedMetricsTableData = getMetricsTableData(true)
    const metricsTableColumns = getActivityInfoMetricsColumnsForm(penTestValuesProps)
    metricsTableColumns[3].name = <span className='absolute-th'>Values</span>

    return (
        <div className='metrics-tables metrics-tables-pen-test'>
            <div className='metrics-tables-header'>
                <div>
                    <h3 id='pen-test-activity-metrics'>Activity Metrics</h3>
                </div>
                {predefined.length ? <div className='metrics-tables-header-buttons'>
                    <SplitButton buttonsList={metricsButtonsList} selectedButtonId={1} splitButtonId='lets-split' splitButtonClass='btn-set' handleButtonClick={handleSplitButtonClick} />
                </div>: <></>}
            </div>
            {predefined.length ? <><fieldset>
                <LabelDots list={labelDotsItems} />
            </fieldset>
            {!hasFindings && <fieldset className='editable-table metrics-tables-custom'>

                <SortableTable
                    columns={metricsTableColumns}
                    tableData={customMetricsTableData}
                    itemsPerPage={0}
                    noFilter={true}
                    tableId='pen-test-activity-metrics-custom-table' />
                <div className='editable-table-bottom'>
                    <button className='btn-link' onClick={addNewMetric} id='add-custom-metric'><i className='icon-plus'></i> Custom Metrics</button>
                </div>
            </fieldset>}
            <fieldset className='editable-table metrics-tables-predefined'>
                {isImportingMetrics ? <Loader /> : <SortableTable
                    columns={metricsTableColumns}
                    tableData={predefinedMetricsTableData}
                    itemsPerPage={0}
                    tableId='pen-test-activity-metrics-predefined-table' />}
            </fieldset>
            </>:  
                <ImportTemplateButtons templateButtons={templateButtons} img={Cloud}/>
            }
            <Popup
                isOpen={isOpenedMetricsPopUp}
                onClose={() => setIsOpenedMetricsPopUp(false)} title={<div><i className='icon-warning'></i> {genericTexts.defaultText.deletePopupTitle}</div>}
                isDisabledClickOutside={true}>
                <p> By importing the file, existing data will be overwritten. This action cannot be undone.</p>
                <div className='hidden-row'><InputRow
                    inputId='fileUpload'
                    title='Import Metrics'
                    inputType='file'
                    changeValueHandler={changeFile}
                    defaultValue={metricsFile} /></div>
                <InputRow inputId='checkbox' title='Don’t show again' inputType='checkbox' defaultValue={isWarningDisabled} changeValueHandler={setIsWarningDisabled} />

                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={importMetricsClick} id='got-it'>Got It</button>
                </div>
            </Popup>
            <Popup
                isOpen={isOpenedMetricsErrorsPopup}
                onClose={() => setIsOpenedMetricsErrorsPopup(false)} title={<div><i className='icon-warning'></i> Import Warnings</div>}
                customClass='import-errors'>
                <p>Import completed with warnings. You can fix your file and upload again or fill the missed fields</p>
                {importingErrors.map((error, i) => <p key={i} className='error-msg' id={`pen-test-import-error-${i}`}>{error}</p>)}
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={() => setIsOpenedMetricsErrorsPopup(false)} id='import-warning-ok'>OK</button>
                </div>
            </Popup>

            <Popup
                isOpen={isOpenedSheetPopUp}
                onClose={closeSheetPopUp} title={<div className='popup-cloud-icon'><i className='icon-upload-cloud'></i></div>}>
                <div className='sheet-name-popup'>
                    <div className='sheet-file-name'><span>{metricsFile?.name || ''}</span></div>
                    {fileType === METRICS_TYPE && <InputRow
                        inputId='textInput'
                        placeholder='Enter Sheet name'
                        title='Sheet Name'
                        defaultValue={sheetName}
                        changeValueHandler={setSheetName} />}
                </div>
                <div className='popup-buttons'>
                    <button className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} onClick={() => uploadNewMetrics(fileType)} id='upload-metrics'>Upload</button>
                </div>
            </Popup>

        </div>)
}


const PenTestActivityMetrics = connect(mapStateToProps)(PenTestActivityMetricsComponent)

export default PenTestActivityMetrics

