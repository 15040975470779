import React, { useState } from 'react'
import AssessmentDomain from '../Widgets/AssessmentDomain'
import './AssessmentClient.scss'
import 'components/Modules/Questionnaire/Questionnaire.scss'
import { Tabs } from 'hub-web-lib'
import AssessmentSummary from '../Widgets/AssessmentSummary'
import { listAllQuestions, mapDomainCategories } from 'js/ui-helpers/questionnaire'

function QuestionnaireClient({ 
    questionnaire, 
    answers, 
    isReview, 
    isViewOnly,
    saveAnswers = () => {},
    submitAssessment = () => {},
    reopenAssessment = () => {},
    closeViewAssessment = () => {},
    assessmentTitleClick,
}) {

    const [activeTab, setActiveTab] = useState(0)

    const domains = questionnaire.domains.map(d => ({ ...d, categories: mapDomainCategories(answers, d) }))
    const allQuestions = listAllQuestions(domains)

    const questionnaireTabs = domains.map(domain => ({
        label: domain.name,
        contentComponent: () => <></>
    }))

    const goNextDomain = () => {
        if (activeTab < questionnaireTabs.length - 1) {
            setActiveTab(activeTab + 1)
        } else {
            const firstUnansweredDomainIndex = domains
                .findIndex(d => (d.categories || [])
                    .find(c => (c.questions || [])
                        .find(q => !q.isAnswered && q.is_required)
                    )
                )
            if (firstUnansweredDomainIndex !== -1) setActiveTab(firstUnansweredDomainIndex)
        }
    }

    const data = {
        answers,
        isReview,
        isViewOnly,
        saveAnswers,
        goNextDomain,
        allQuestions
    }

    return (
        <div className={`questionnaire-client ${isViewOnly ? 'has-back-state' : ''}`}>
            {isViewOnly && <button className='btn-icon' id='questionnaire-go-back' onClick={closeViewAssessment}><i className='icon-arrow-left1'></i></button>}
            <Tabs 
                tabs={questionnaireTabs} 
                activeIndex={activeTab}
                handleActiveTab={setActiveTab}
                tabsTitle={{ title: questionnaire.name, inline: true, titleClick: assessmentTitleClick }} />
            {domains.map((domain, i) => <AssessmentDomain key={domain.id} data={{ ...data, domain, isShown: i === activeTab }} /> )}
            {!isViewOnly && <AssessmentSummary 
                domains={domains} 
                allQuestions={allQuestions} 
                submitAssessment={submitAssessment} 
                isReview={isReview} 
                reopenAssessment={reopenAssessment} />}
            
        </div>
    )
}

export default QuestionnaireClient