import { helpers } from 'hub-web-lib/dist/index-export'

const appName = 'HUBGuard'
const companyName = 'HUB Security'

const CYBER_READINESS_MODULE = 'cyberReadiness'
const ATTACK_SURFACE_MODULE = 'attackSurface'
const RED_TEAM_MODULE = 'redTeam'
const PEN_TEST_MODULE = 'penTest'
const DDOS_RESILIENCE_MODULE = 'ddosResilience'
const RISK_ASSESSMENT_MODULE = 'riskAssessment'
const PHISHING_SIMULATION_MODULE = 'phishingSimulation'
const RANSOMWARE_RESILIENCE_MODULE = 'ransomwareResilience'
const INCIDENT_RESPONSE_MODULE = 'incidentResponse'
const SSDLC_MODULE = 'SSDLC'

const CYBER_READINESS = 'Cyber Readiness'
const ATTACK_SURFACE = 'Attack Surface'
const RED_TEAM = 'Red Team'
const PEN_TEST = 'Pen Test'
const DDOS_RESILIENCE = 'DDoS Resilience'
const RISK_ASSESSMENT = 'Risk Assessment'
const INCIDENT_RESPONSE = 'Incident Response'
const PHISHING_SIMULATION = 'Phishing Simulation'
const RANSOMWARE_RESILIENCE = 'Ransomware Resilience'
const PT = 'PT'
const SSDLC = 'SSDLC'

const ACTIVITIES = 'Activities'
const ASSESSMENTS = 'Assessments'
const QUESTIONNAIRES = 'Questionnaires'
const DASHBOARD = 'Dashboard'
const DASHBOARD_PATH = 'dashboard'
const ASSESSMENTS_PATH = 'assessments'
const QUESTIONNAIRES_PATH = 'questionnaires'
const ACTIVITY_PATH = 'activities'

const appModulesNames = {
    [CYBER_READINESS_MODULE]: CYBER_READINESS,
    [ATTACK_SURFACE_MODULE]: ATTACK_SURFACE,
    [RED_TEAM_MODULE]: RED_TEAM,
    [PEN_TEST_MODULE]: PEN_TEST,
    [DDOS_RESILIENCE_MODULE]: DDOS_RESILIENCE,
    [RISK_ASSESSMENT_MODULE]: RISK_ASSESSMENT,
    [PHISHING_SIMULATION_MODULE]: PHISHING_SIMULATION,
    [RANSOMWARE_RESILIENCE_MODULE]: RANSOMWARE_RESILIENCE,
    [INCIDENT_RESPONSE_MODULE]: INCIDENT_RESPONSE,
    [SSDLC_MODULE]: SSDLC
}

const getAppModulePath = () => {
    const paths = {}
    Object.keys(appModulesNames).forEach(m => {
        paths[m] = helpers.transformToKebab(appModulesNames[m])
    })
    return paths
}

const appModulesPaths = getAppModulePath()

const comingSoonModules = [
    PHISHING_SIMULATION_MODULE,
    RANSOMWARE_RESILIENCE, 
    INCIDENT_RESPONSE_MODULE

]

const disabledModulesDefault = [RANSOMWARE_RESILIENCE_MODULE, PHISHING_SIMULATION_MODULE]

const getDocumentTitle = () => {
    const pathArray = location.pathname.split('/')
    let pathTitle = pathArray.map(p => helpers.transformStringToSentance(p)).join(' ')
    if (location.pathname === '/') pathTitle = `Main ${DASHBOARD}`
    const title = `${appName} ${pathTitle ? `| ${pathTitle}` : ''} | ${companyName}`
    return title
}

export {

    CYBER_READINESS,
    ATTACK_SURFACE,
    RED_TEAM,
    PEN_TEST,
    DDOS_RESILIENCE,
    RISK_ASSESSMENT,
    INCIDENT_RESPONSE,
    PHISHING_SIMULATION,
    RANSOMWARE_RESILIENCE,
    SSDLC,

    CYBER_READINESS_MODULE,
    ATTACK_SURFACE_MODULE,
    RED_TEAM_MODULE,
    PEN_TEST_MODULE,
    DDOS_RESILIENCE_MODULE,
    RISK_ASSESSMENT_MODULE,
    INCIDENT_RESPONSE_MODULE,
    PHISHING_SIMULATION_MODULE,
    RANSOMWARE_RESILIENCE_MODULE,
    SSDLC_MODULE,

    PT,

    ACTIVITIES,
    ACTIVITY_PATH,
    ASSESSMENTS,
    ASSESSMENTS_PATH,
    QUESTIONNAIRES,
    QUESTIONNAIRES_PATH, 
    DASHBOARD,
    DASHBOARD_PATH,

    appModulesNames,
    appModulesPaths,
    comingSoonModules,
    disabledModulesDefault,
    getDocumentTitle,
}