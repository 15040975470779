const checkDateIsValid = (date) => {
    return date instanceof Date && !isNaN(date)
}

const dateToValidValue = (date, returnNull) => {
    const newDate = new Date(date)
    if (checkDateIsValid(newDate)) return newDate
    return returnNull ? null : new Date()
}

export {
    checkDateIsValid,
    dateToValidValue
}