import React from 'react'
import './TemplateQuestion.scss'
import { InputRow, helpers } from 'hub-web-lib'
import { questionnaireAnswerTypes } from 'js/constants/enums/questionnaire'
import TemplatePossibleAnswers from './TemplatePossibleAnswers'
import { questionTextDefault } from 'js/ui-helpers/questionnaire'

const TemplateQuestion = ({ data = {} }) => {

    const {
        domainId,
        isApproved,
        question,
        activeCategoryId,
        isShown,
        updateQuestionProp = () => { },
        updateQuestionPossibleAnswerProp = () => { },
        deleteQuestion = () => { },
        duplicateQuestion = () => { }
    } = data

    const updateProp = (val, key) => {
        updateQuestionProp(val, activeCategoryId, question.id, key)
    }

    const updatePossibleAnswerProp = (val, index, key) => {
        updateQuestionPossibleAnswerProp(val, activeCategoryId, question.id, index, key)
    }

    const optionsWithContent = questionnaireAnswerTypes.map(t => ({
        ...t,
        content: <><i className={`icon-${t.icon}`}></i> {t.name}</>
    }))

    const questionTextBlur = () => {
        if (!question.text) updateProp(`${questionTextDefault} ${question.id}`, 'text')
    }

    const selectedType = questionnaireAnswerTypes.find(t => t.id === question.answer_type_id)
    const id = `${domainId}-${activeCategoryId}-${question.id}`
    const optionIconType = question.answer_type_id === 1 ? 'square' : 'round'

    return (
        <div className='template-question-wrapper' id={`question-${id}`}>
            <div className='question-details'>
                {isShown && <InputRow
                    inputId={`question-text-${id}`}
                    inputTag='textarea'
                    autoHeight={true}
                    defaultValue={question.text}
                    disabled={isApproved}
                    handleBlur={questionTextBlur}
                    changeValueHandler={(val) => updateProp(val, 'text')} />}
                <InputRow
                    inputId={`question-type-${id}`}
                    inputTag='select'
                    placeholder='Select'
                    options={optionsWithContent}
                    defaultValue={question.answer_type_id}
                    disabled={isApproved}
                    changeValueHandler={(val) => updateProp(val, 'answer_type_id')} />

                <InputRow inputId={`question-weight-${id}`}
                    placeholder='0-100'
                    inputType='number'
                    max='100'
                    defaultValue={helpers.isNumber(question?.weight) ? question.weight : ''}
                    disabled={isApproved}
                    changeValueHandler={(val) => updateProp(val, 'weight')} />
            </div>
            {selectedType?.hasOptions && <TemplatePossibleAnswers
                question={question}
                optionIconType={optionIconType}
                id={id}
                updatePossibleAnswerProp={updatePossibleAnswerProp}
                updateProp={updateProp}
                isApproved={isApproved} />
            }

            {(question.description && isApproved || !isApproved) && isShown && <div className='question-description'>
                <InputRow
                    inputId={`question-description-${id}`}
                    inputTag='textarea'
                    placeholder='+ Add Description'
                    autoHeight={true}
                    defaultValue={question.description}
                    disabled={isApproved}
                    changeValueHandler={(val) => updateProp(val, 'description')} />
            </div>}

            <div className='question-buttons'>
                {!isApproved && <div className='question-buttons-actions'>
                    <button className='btn-icon' id={`copy-question-${id}`} onClick={() => duplicateQuestion(activeCategoryId, question.id)}><i className='icon-setting'></i></button>
                    <button className='btn-icon' id={`delete-question-${id}`} onClick={() => deleteQuestion(activeCategoryId, question.id)}><i className='icon-delete'></i></button>
                </div>}
                <div className='question-buttons-switchers'>
                    <InputRow
                        title='Required'
                        inputId={`question-required-${id}`}
                        inputType='checkbox'
                        isCompact={true}
                        defaultValue={question.is_required}
                        disabled={isApproved}
                        changeValueHandler={(val) => updateProp(val, 'is_required')} />
                    <InputRow
                        title='Hidden'
                        inputId={`question-hidden-${id}`}
                        inputType='checkbox'
                        isCompact={true}
                        defaultValue={question.is_hidden}
                        disabled={isApproved}
                        changeValueHandler={(val) => updateProp(val, 'is_hidden')} />
                    {/* <InputRow
                        title='Cyber Defense'
                        inputId={`question-cyber-defense-${id}`}
                        inputType='checkbox'
                        isCompact={true}
                        defaultValue={question.is_cyber_defense}
                        disabled={isApproved}
                        changeValueHandler={(val) => updateProp(val, 'is_cyber_defense')} /> */}
                </div>
            </div>
        </div>
    )
}

export default TemplateQuestion