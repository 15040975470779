import React from 'react'
import './TemplatePossibleAnswer.scss'
import { InputRow, helpers } from 'hub-web-lib'

const TemplatePossibleAnswer = ({ 
    answer, 
    isApproved,
    index, 
    optionIconType,
    id, 
    changeOption = () => {}, 
    removeOption = () => {}
}) => {
    const optionNameBlur = () => {
        if (!answer.name) changeOption(`Option ${index + 1}`, 'name', index)
    }
    const blurField = (id) => {
        const nameField = document.getElementById(id)
        nameField.blur()
    }
    return (
        <li>
            <span className={`option-icon ${optionIconType}`}></span> 
            <div className='option-content'>
                <div className='option-details'>    
                    <InputRow
                        inputId={`option-name-${index}-${id}`}
                        defaultValue={answer.name || ''}
                        changeValueHandler={(val) => changeOption(val, 'name', index)}
                        handleSubmit={() => blurField(`option-name-${index}-${id}`)}
                        handleBlur={optionNameBlur}
                        disabled={isApproved} />                        
                    <InputRow
                        title='Score'
                        inputId={`option-score-${index}-${id}`}
                        defaultValue={helpers.isNumber(answer.score) ? answer.score : ''}
                        inputType='number'
                        placeholder='0-100'
                        changeValueHandler={(val) => changeOption(val, 'score', index)}
                        handleSubmit={() => blurField(`option-score-${index}-${id}`)}
                        max='100'
                        isDecimalNumber={true}
                        disabled={isApproved} />
                </div>
                {!isApproved && <div className='option-buttons'>
                    <button className='btn-icon' id={`delete-option-${index}-${id}`} onClick={() => removeOption(index)}><i className='icon-x'></i></button>
                </div>}
                
            </div>
        </li>
    )
}

export default TemplatePossibleAnswer
