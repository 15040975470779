import React, { useState, useEffect } from 'react'
import UserApi from 'services/UserApi'
import { connect } from 'react-redux'
import { helpers, InfoCard, InputRow } from 'hub-web-lib'
import { ORG } from 'js/constants/vocabulary'
import { industriesList, licenseTypes, orgTypes, sizes } from 'js/constants/enums/org'
import './OrgSelector.scss'
import { getUserRole } from 'js/ui-helpers/roles'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_CURRENT_USER, UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

const orgInfoDefault = {
    id: {
        title: `${ORG} ID`
    },
    name: {
        title: 'Name'
    },
    domain: {
        title: 'Domain'
    },
    industry: {
        title: 'Industry'
    },
    country: {
        title: 'Country'
    },
    type: {
        title: 'Type'
    },
    size: {
        title: 'Size'
    },
    license: {
        title: 'License'
    },
}

const OrgSelectorComponent = ({ currentUser, isContentHidden, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const [isLoading, setIsLoading] = useState([])
    const [cardData, setCardData] = useState({ ...orgInfoDefault })
    const orgs = currentUserOrgs.filter(o => o.status === 1)

    const getCardData = (org) => {
        let data = { ...orgInfoDefault }
        if (!org) return data
        data.id.value = org.id || 0
        data.name.value = org.name || '-'
        data.domain.value = org.domain || '-'
        data.industry.value = helpers.capitalizeString(industriesList.find(i => i.id === org.industry_id)?.name || '-')
        data.country.value = org.country || '-'
        data.license.value = helpers.capitalizeString(licenseTypes.find(i => i.id === org.license_id)?.name || '-')
        data.type.value = helpers.capitalizeString(orgTypes.find(i => i.id === org.org_type)?.name || '-')
        data.size.value = helpers.capitalizeString(sizes.find(i => i.id === org.size)?.name || '-')

        return data
    }

    const updateCurrentOrgId = async (orgId) => {
        const updatedUser = await UserApi.updateUserCurrentOrg(currentUser.id, orgId)
        if (!updatedUser || updatedUser.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {  message: updatedUser.message, notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_CURRENT_USER, { ...currentUser, current_org: orgId }))
    }

    useEffect( () => {
        if (currentUserOrgs.length) {
            const currentOrg = orgs.find(o => o.id === currentOrgId * 1)
            setCardData(getCardData(currentOrg))
        }
        setIsLoading(false)
    }, [currentUserOrgs])

    return (
        isLoading ? <div className='loader'></div> : 
            <>
                <section className={`org-selector ${isContentHidden ? 'hidden-content' : ''}`}>
                    {!isContentHidden && orgs.length > 1 && <h3 id='switch-organization'>Switch Organization</h3>}
                    <InputRow 
                        inputId='org_id' 
                        title='Organization'
                        inputTag='select'
                        placeholder='Select'
                        isCompact={isContentHidden}
                        options={orgs.sort((a, b) => a.id - b.id)}
                        changeValueHandler={(val) => updateCurrentOrgId(val)}
                        defaultValue={ currentOrgId }
                        disabled={orgs.length === 1} />
                </section>
                {!isContentHidden && getUserRole(currentUser) === 1 && <section>
                    <InfoCard cardData={cardData} />
                </section>}
            </>)
}

const OrgSelector = connect(mapStateToProps)(OrgSelectorComponent)

export default OrgSelector