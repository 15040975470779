import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, InfoCard, Loader, SortableTable, genericTexts, helpers } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import PenTestApi from 'services/PenTestApi'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { action } from 'js/actions'
import store from 'js/store'
import { connect } from 'react-redux'
import 'scss/UpdateActivity/ActivityInfo.scss'
import { getActivityInfoMetricsColumns, getActivityInfoMetricsTableData, getActivityMetricsValuesProps } from 'js/ui-helpers/metrics'
import { getActivityInfoCardData, getSelectedActivityTitle } from 'js/ui-helpers/activity'
import { activityCardInfoDefault } from 'js/constants/enums/activity'
import BackButton from 'components/Assets/BackButton/BackButton'
import { PEN_TEST_MODULE } from 'js/constants/app-modules'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        dashboardSettings: state.dashboardSettings,
        currentUserOrgs: state.currentUserOrgs
    }
}

const PenTestActivityInfoComponent = ({ currentUser, dashboardSettings, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [currentActivityInfo, setCurrentActivityInfo] = useState({})
    const currentActivityTitle = getSelectedActivityTitle(currentActivityInfo)
    const penTestValuesProps = getActivityMetricsValuesProps(dashboardSettings, PEN_TEST_MODULE)

    const getActivityInfo = async () => {
        let info = location?.state?.activity
        if (info) {
            setCurrentActivityInfo(info)
            return
        }
        const activityId = (location?.pathname || '').split('/')[3]*1
        const activity = await PenTestApi.getActivity(activityId)
        if (!activity || activity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(ACTIVITY), notificationType: 'error' }))
            return
        }  
        activity.metrics = await PenTestApi.getActivityMetrics(activityId)
        if (!activity.metrics || activity.metrics.error) activity.metrics = []
        const org = currentUserOrgs.find(o => o.id === currentOrgId) || { id: currentOrgId }
        activity.organization = org?.name || currentOrgId
        setCurrentActivityInfo(activity)
    }

    const getCardData = () => {
        const data = getActivityInfoCardData({ ...activityCardInfoDefault, test_type: { title: 'Test Type' } }, currentActivityInfo)
        data.test_type.value = helpers.capitalizeString(currentActivityInfo.test_type)
        return data
    }

    const cardData = getCardData()
    const metricsTableData = getActivityInfoMetricsTableData(currentActivityInfo, penTestValuesProps)
    const metricsTableColumns = getActivityInfoMetricsColumns(penTestValuesProps)


    useEffect( async () => {
        await getActivityInfo()
        setIsLoading(false)
    }, [])

    return (
        <div className='page-content pen-test-info activity-info'>
            <div className='page-header'>
                <BackButton />
                <h1 id='pen-test-activity-info'>{ACTIVITY} {currentActivityTitle} Info</h1>
            </div>
            {isLoading ? <Loader /> : currentActivityInfo.id ? <div className='report-info-con info-card-con'>
                <InfoCard cardData={cardData} />
                <h2 id='pen-test-mertics'>Metrics</h2>
                <SortableTable columns={metricsTableColumns} tableData={metricsTableData} itemsPerPage={0} tableId='pen-test-activity-info-metrics-table' />
            </div> : <Alert message={`No data for this ${ACTIVITY}`} />}
        </div>
    )
}
const PenTestActivityInfo = connect(mapStateToProps)(PenTestActivityInfoComponent)
export default PenTestActivityInfo