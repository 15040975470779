const requiredFieldMsg = 'This field is required'

const userFriendlyErrors = {
    'ensure this value has at least 1 characters': requiredFieldMsg,
    'value is not a valid integer': requiredFieldMsg,
    'none is not an allowed value': requiredFieldMsg,
    'ensure this value is greater than 0': requiredFieldMsg
}

export {
    userFriendlyErrors
}