import React, { useState, useEffect } from 'react'
import { genericTexts, Popup, Loader, SortableTable, RadioGroup } from 'hub-web-lib'
import { ACTIVITY } from 'js/constants/vocabulary'
import { ACTIVITY_PATH, DDOS_RESILIENCE, DDOS_RESILIENCE_MODULE, appModulesPaths } from 'js/constants/app-modules'
import UpdateDdosActivity from './UpdateActivity/UpdateDdosActivity'
import { connect } from 'react-redux'
import { activityColumns } from 'js/constants/table-columns'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { action } from 'js/actions'
import store from 'js/store'
import DdosApi from 'services/DdosApi'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import UserApi from 'services/UserApi'
import { getUserRole } from 'js/ui-helpers/roles'
import { getActivityTableData, getSelectedActivity } from 'js/ui-helpers/activity'
import 'scss/ActivityManagement.scss'
import { ARCHIVED, allOption, nonCompleteNotification, starArchive, getMultiCheckActions, ARCHIVED_PROP, STARRED_PROP } from 'js/constants/enums/activity'
import { ACTIVE } from 'js/constants/metrics/common-metrics'
import { refactorFindings } from 'js/ui-helpers/metrics'
const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        currentUserOrgs: state.currentUserOrgs
    }
}

const DdosActivityManagementComponent = ({ currentUser, currentUserOrgs }) => {
    const currentOrgId = currentUser?.current_org
    const [isOpenActivityPopup, setIsOpenActivityPopup] = useState(false)
    const [isNewActivity, setIsNewActivity] = useState(false)
    const [currentOrg, setCurrentOrg] = useState(null)
    const [orgUsers, setOrgUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [activities, setActivities] = useState([])
    const [selectedActivityId, setSelectedActivityId] = useState('')
    const [selectedActivityIds, setSelectedActivityIds] = useState([])
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const [templateUrl, setTemplateUrl] = useState('')
    const [findingsUrl, setFindingsUrl] = useState('')
    const [isShowArchived, setIsShowArchived] = useState(false)
    const [filterType, setFilterType] = useState('all')
    const [starArray, setStarArray] = useState()
    const [searchParams] = useSearchParams()
    const providedId = searchParams.get('id')
    const { selectedActivity, selectedActivitiesCount, activitiesText, wasText, deleteActivitiesText } = getSelectedActivity(activities, selectedActivityId, selectedActivityIds)
    const activityColumnsUser = currentUser?.is_admin ? activityColumns : [...activityColumns].filter(c => c.key !== 'organization')
    const navigate = useNavigate()
    const multiCheckActions = getMultiCheckActions(isShowArchived)
    
    const openUpdateActivityPopup = () => {
        setIsNewActivity(true)
        setIsOpenActivityPopup(true)
    }

    const closeUpdateActivityPopup = () => {
        setIsNewActivity(false)
        setIsOpenActivityPopup(false)
        setSelectedActivityId('')
    }

    const getPermittedUsersList = (users) => {
        return [...users.filter(user => getUserRole(user) < 4)
            .sort((a, b) => a.id * 1 - b.id * 1)
            .map(user => ({ id: user.id, name: user.name || user.user_name }))]
    }

    const getOrgsAndMetrics = async () => {
        const org = currentUserOrgs.find(o => o.id === currentOrgId) || { id: currentOrgId }
        const users = await UserApi.getOrgUsers(currentOrgId)
        setCurrentOrg(org && !org.error ? org : { id: currentOrgId })
        setOrgUsers(users && !users.error ? getPermittedUsersList(users) : [])
        return org
    }

    const getTemplateAndFindingsUrl = async () => {
        const template = await DdosApi.downloadMetricsTemplate()
        const findings = await DdosApi.downloadFindingsTemplate()
        setTemplateUrl(template?.url || '')
        setFindingsUrl(findings?.url || '')
    }

    const deleteActivity = async () => {
        setIsOpenDeletePopup(false)
        const deletedActiviry = await DdosApi.deleteActivity(selectedActivityId)
        if (!deletedActiviry || deletedActiviry.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedError(ACTIVITY), notificationType: 'error' }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.deletedSuccess(ACTIVITY) }))
        await updateActivities()
    }

    const deleteActivities = async () => {
        setIsOpenDeletePopup(false)
        let error = false
        for await (const id of selectedActivityIds) {
            const deletedActivity = await DdosApi.deleteActivity(id)
            if (!deletedActivity || deletedActivity.error) error = true
        }
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${activitiesText} ${wasText} deleted` }))
        setSelectedActivityIds([])
        await updateActivities()
    }

    const updateActivityStatus = async (activityId, actionType) => {
        const { selectedActivity } = getSelectedActivity(activities, activityId)
        const updatedActivity = await DdosApi.updateActivity({ ...selectedActivity, [actionType]: !selectedActivity[actionType] })
        if (!updatedActivity || updatedActivity.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: updatedActivity?.message || genericTexts.defaultText.genericErrorMsg, notificationType: 'error' }))
            return false
        }
        await updateActivities()
        return true
    }   

    const updateActivitiesStatus = async (activityIds) => {
        let error = false
        for await (const id of activityIds) {
            const { selectedActivity } = getSelectedActivity(activities, id)
            const updatedActivity = await DdosApi.updateActivity({ ...selectedActivity, [ARCHIVED_PROP]: !selectedActivity[ARCHIVED_PROP] })
            if (!updatedActivity || updatedActivity.error) error = true
        }
        const { activitiesText, wasText } = getSelectedActivity(activities, '', activityIds)
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${activitiesText} ${wasText} ${isShowArchived ? 'unarchived' : 'archived'}` }))
        setSelectedActivityIds([])
        await updateActivities()
    }

    const updateActivities = async () => {

        const activities = await DdosApi.getAllActivities(currentOrgId)
        if (!activities || activities.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: activities.message, notificationType: 'error' }))
            return
        }
        for await (const activity of activities) {
            activity.metrics = []
            const metrics = await DdosApi.getActivityMetrics(activity.id)
            if (metrics && !metrics.error) activity.metrics = metrics
            activity.findings = []
            if (activity.has_findings) {
                const findings = await DdosApi.getActivityFindings(activity.id)
                if (findings && !findings.error) activity.findings = refactorFindings(findings)
            }
        }
        setActivities(activities)
        const tempStarArray = {}
        activities.map(a => tempStarArray[a.id] = a.starred)
        setStarArray(tempStarArray)    
    }

    const menuClickHandler = async (action, activityId) => {
        setSelectedActivityId(activityId)
        const { selectedActivity } = getSelectedActivity(activities, activityId)
        switch (action) {
        case genericTexts.actionsText.info:
            navigate(`/${appModulesPaths[DDOS_RESILIENCE_MODULE]}/${ACTIVITY_PATH}/${activityId}`, { state: { activity: selectedActivity } })
            break
        case genericTexts.actionsText.edit:
            setIsOpenActivityPopup(true)
            setIsNewActivity(false)
            break
        case genericTexts.actionsText.archive:
            updateActivityStatus(activityId, ARCHIVED_PROP)
            break
        case genericTexts.actionsText.delete:
            setIsOpenDeletePopup(true)
            break
        }
    }

    const multiCheckClickHandler = (action, activityIds) => {
        setSelectedActivityIds(activityIds)
        switch (action) {
        case genericTexts.actionsText.archive:
            updateActivitiesStatus(activityIds)
            break
        case genericTexts.actionsText.delete:
            setIsOpenDeletePopup(true)
            break
        }
    }

    const openEditPopup = () => {
        if (!providedId) return
        const { selectedActivity } = getSelectedActivity(activities, providedId)
        if (selectedActivity) {
            if (!selectedActivity.is_complete) store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: nonCompleteNotification, notificationType: 'warning' }))
            setSelectedActivityId(providedId)
            setIsOpenActivityPopup(true)
            setIsNewActivity(false)
        }
    }


    const handleTdClick = (val, activityId) => {
        if (val === 'star') {
            const updatedStarArray = { ...starArray } 
            updatedStarArray[activityId] = !updatedStarArray[activityId] 
            setStarArray(updatedStarArray)
            updateActivityStatus(activityId, STARRED_PROP)
            return
        }
        setSelectedActivityId(activityId)
        setIsOpenActivityPopup(true)
        setIsNewActivity(false)
    }
    
    useEffect(async () => {
        await getOrgsAndMetrics()
        await updateActivities()
        getTemplateAndFindingsUrl()
        openEditPopup()
        setIsLoading(false)
    }, [])
    
    const tableData = getActivityTableData(activities, starArray)
    const filterTableData = () => {
        if (isShowArchived) return tableData.filter(item => item.archived)
        return tableData.filter(item => (filterType === allOption.id ? true : item.starred) && !item.archived)
    }

    const filteredTableData = filterTableData()
    return (
        <div className='ddos page-content'>
            <div className='page-header'>
                <h1 id='ddos-activities-management'>{DDOS_RESILIENCE} Activities Management</h1>
                <button id='add-new-ddos-activity' onClick={openUpdateActivityPopup} >{genericTexts.defaultText.addNewButton(ACTIVITY)}</button>
            </div>
            {!!tableData?.length && !selectedActivitiesCount && <div className={`filter-switcher ${filteredTableData.length ? 'has-content' : ''}`}>
                {!isShowArchived && <RadioGroup radioGroupId='filter-switcher-group' checkedId={filterType} isSwitcher={true} updateItem={setFilterType} allItems={ [allOption, starArchive] }/>}
                <div className='archive-button'> 
                    <button className='btn-set' onClick={() => setIsShowArchived(!isShowArchived)}>Show {isShowArchived ? ACTIVE : ARCHIVED}</button>
                </div>
            </div>}
            {isLoading ? <Loader /> : <div className='report-management-con'>
                <SortableTable
                    columns={activityColumnsUser}
                    tableData={filteredTableData}
                    defaultSort={{ key: 'id' }}
                    menuClickHandler={menuClickHandler}
                    handleTdClick={handleTdClick}
                    tableId='ddos-activities-table'
                    multiCheckActions={multiCheckActions}
                    multiCheckClickHandler={multiCheckClickHandler}
                    updateMultiCheckIds={setSelectedActivityIds}
                    multiCheckIds={selectedActivityIds} />
            </div>}
            {isOpenActivityPopup && <Popup
                onClose={closeUpdateActivityPopup}
                isOpen={isOpenActivityPopup}
                title={isNewActivity ? genericTexts.defaultText.addNewPopupTitle(ACTIVITY) : genericTexts.actionsText.editText(ACTIVITY, true)}
                isDisabledClickOutside={true}
                customClass='update-activity update-activity-ddos'>
                <UpdateDdosActivity
                    isNewActivity={isNewActivity}
                    currentUser={currentUser}
                    currentOrg={currentOrg}
                    orgUsers={orgUsers}
                    activityDetails={selectedActivity}
                    closeUpdateActivityPopup={closeUpdateActivityPopup}
                    updateActivities={updateActivities}
                    templateUrl={templateUrl} 
                    findingsUrl={findingsUrl}

                />
            </Popup>}
            <Popup
                onClose={() => setIsOpenDeletePopup(false)}
                isOpen={isOpenDeletePopup}
                title={genericTexts.defaultText.deletePopupTitle}>
                <p>{deleteActivitiesText}</p>
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={selectedActivitiesCount ? deleteActivities : deleteActivity} id='delete-qa-activity'>{genericTexts.defaultText.yes}</button>
                    <button onClick={() => setIsOpenDeletePopup(false)} className='btn-cancel' id='delete-qa-activity-cancel'>{genericTexts.defaultText.no}</button>
                </div>
            </Popup>
        </div>

    )
}


const DdosActivityManagement = connect(mapStateToProps)(DdosActivityManagementComponent)

export default DdosActivityManagement