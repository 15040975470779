
const ASSET = 'Asset'
const ACTIVITY = 'Activity'
const USER = 'User'
const ORG = 'Organization'
const FINDING = 'Finding'
const PARTICIPANT = 'Participant'
const METRIC = 'Metric'
const QUESTIONNAIRE = 'Questionnaire'
const ASSESSMENT = 'Assessment'
const TEMPLATE = 'Template'
const DOMAIN = 'Domain'
const CATEGORY = 'Category'
const QUESTION = 'Question'
const ARCHIVE = 'Archive'
export {
    ASSET,
    ACTIVITY,
    USER,
    ORG,
    FINDING,
    PARTICIPANT,
    METRIC,
    QUESTIONNAIRE,
    ASSESSMENT,
    TEMPLATE,
    DOMAIN,
    CATEGORY,
    QUESTION,
    ARCHIVE
}