import { helpers } from 'hub-web-lib'
import { 
    findingSeverityTypes, 
    findingSeverityTypesFull,
    allSeverityStatuses,
    MAJOR, 
    MINOR, 
    EXTREME
} from 'js/constants/enums/pt'
import moment from 'moment'
import { dateToValidValue } from './validators'
import PTApi from 'services/PTApi'

const getStatusValuesObject = (a, b, c) => {
    return {
        [EXTREME]: a,
        [MAJOR]: b,
        [MINOR]: c
    }
}

const statusTypes = getStatusValuesObject('error', 'warning', 'primary')

const getAssetsWidgetList = (assetsList) => {
    return [ 
        { title: 'Name', contentRight: 'Target', customClass: 'date' }, 
        ...assetsList.map(asset => ({ 
            title: asset.system_name, 
            contentRight: asset.domain,
            id: asset.id
        }))]
}

const getNotifications = (notificationsList) => {
    const notifications = notificationsList.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
    return notifications.slice(0, 4).map(n => {
        const status = (findingSeverityTypes.find(t => t.id === n.severity) || findingSeverityTypes[0]).name
        return { 
            id: n.id, 
            message: n.title, 
            status: helpers.capitalizeString(status),
            statusType: statusTypes[status], 
            date: n.created_on
        }
    })
}

const getPtData = (list, details, nameKey) => {
    const columns = allSeverityStatuses.map(status => ({ label: status }))
    const chartData = {
        details,
        columns,
        rows: list.map(item => {
            const label = dateToValidValue(item[nameKey], true) ? moment(item[nameKey]).format('HH:mm DD MMMM') : item[nameKey]
            return { label, ...item.severity, percent: item.score }
        })
    }
    return chartData
}

const getScore = (severity) => {
    const ratios = [0, 20, 40, 60, 80]
    const severityArray = Object.entries(severity).map(([key, value]) => {
        const id = findingSeverityTypes.find(t => t.name === key)?.id
        const index = id ? id + 1 : findingSeverityTypesFull.find(t => t.name === key)?.id
        return {
            name: key,
            value,
            index,
            ratio: ratios[index - 1]
        }
    })
    if (!severityArray.filter(s => s.value).length) return 0
    let average = 0
    let ratioArray = [0]
    severityArray.forEach(s => {
        if (s.value && s.ratio) ratioArray.push(s.ratio)
        average += s.value * s.index
    })
    average = average / severityArray.map(s => s.value).reduce((a, b) => a + b) * 20
    const rating = Math.max(...ratioArray) + average / 100 * 30 - 10
    return Math.max(Math.round(rating), 0)
}

const mapRecommendations = (recommendations) => {
    if (typeof recommendations === 'string') recommendations = JSON.parse(recommendations)
    return recommendations.map((r, i) => {
        // NOTE: the structure should be array of strings, if not - will be stringified
        const recommendation = typeof r === 'string' ? r : JSON.stringify(r)
        const wordsArray = recommendation.split(' ')
        const header = wordsArray.length > 6 ? wordsArray.slice(0, 6).join(' ') + '...' : recommendation
        return { 
            id: i + 1, 
            header,
            description: recommendation
        }
    })
}

const getReportInfo = async (report) => {
    // findings 
    let findings = await PTApi.getFindingsByReportId(report.id)
    if (!findings || findings.error) findings = { items: [] }
    report.findings = findings.items

    // assets 
    let assets = await PTApi.getAssetsByReportId(report.id)
    if (!assets || assets.error) assets = []
    report.assets = assets
    const logicalAsset = assets.find(a => a.asset_type === 1)
    report.system_name = logicalAsset?.system_name

    // participants 
    const participants = await PTApi.getUsersByReportId(report.id)
    report.participants = participants

    report.severity = {}
    const fullSeverity = {}
    findingSeverityTypes.forEach(severity => {
        const findingsCount = report.findings.filter(f => f.severity === severity.id)?.length || 0
        if (findingsCount) report.severity[severity.name] = findingsCount
        fullSeverity[severity.name] = findingsCount
    })
    report.score = getScore(fullSeverity)
    return report
}

export {
    getAssetsWidgetList,
    getNotifications,
    getPtData,
    getScore,
    statusTypes,
    mapRecommendations,
    getReportInfo
}