import React, { useState } from 'react'
import { InputRow, LabelDots, Loader, Popup, SortableTable, genericTexts, helpers } from 'hub-web-lib'
import { OTHER_PROP, valuesPropsGeneric } from 'js/constants/metrics/common-metrics'
import { easmMetricsCategories, easmMetricsWidgets, easmMetricsKeys } from 'js/constants/metrics/easm-metrics'
import EasmApi from 'services/EasmApi'
import store from 'js/store'
import { action } from 'js/actions'
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import { connect } from 'react-redux'
import UserApi from 'services/UserApi'
import moment from 'moment'
import ImportTemplateButtons from 'components/Modules/Activity/ImportPlaceholder/ImportTemplateButtons'
import 'scss/UpdateActivity/MetricsTable.scss'
import { getLastSecondOfCurrentDay, getYearAgoDate } from 'middlewares/ApiHelpers'
import { checkImportingErrors, getActivityInfoMetricsColumnsForm, getActivityMetricsValuesProps, getChangedMetrics, getMetricId, getMetricsConsts, getMetricsLabelDots, getRequiredFieldError, getTextFields, getValuesFieldsDynamic, preparePredefinedMetrics, refactorImportedMetrics } from 'js/ui-helpers/metrics'
import Cloud from 'assets/illustrations/cloud.svg'
import { DOWNLOAD_CLOUD, IMPORT_METRICS, NETFILTER, SYNC_FROM_CLOUD, UPLOAD_CLOUD } from 'js/constants/enums/common-activity'
import { ATTACK_SURFACE_MODULE } from 'js/constants/app-modules'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        dashboardSettings: state.dashboardSettings
    }
}

const dateTimeDetails = {
    showTimeSelect: false,
    dateFormat: 'yyyy/MM/dd',
    timeFormat: 'HH',
    maxDate: getLastSecondOfCurrentDay(),
    minDate: getYearAgoDate()
}

const EasmActivityMetricsComponent = ({ data, currentUser, dashboardSettings }) => {
    const currentOrgId = currentUser?.current_org
    const {
        newMetricModel,
        customMetrics,
        predefinedMetricsToEdit,
        updateMetrics,
        templateUrl,
        hasFindings,
        setActivityFindings
    } = data

    const easmValuesProps = getActivityMetricsValuesProps(dashboardSettings, ATTACK_SURFACE_MODULE)

    const metricsConsts = getMetricsConsts(
        easmMetricsCategories, 
        easmMetricsWidgets, 
        easmMetricsKeys, 
        easmValuesProps
    )
    
    const labelDotsItems = getMetricsLabelDots(metricsConsts)

    const [custom, setCustom] = useState(customMetrics)
    const [predefined, setPredefined] = useState(preparePredefinedMetrics(predefinedMetricsToEdit, metricsConsts))
    const [metricsFile, setMetricsFile] = useState(null)
    const [sheetName, setSheetName] = useState('')
    const [isOpenedMetricsPopUp, setIsOpenedMetricsPopUp] = useState(false)
    const [isOpenedSheetPopUp, setIsOpenedSheetPopUp] = useState(false)
    const [isOpenedCloudSync, setIsOpenedCloudSync] = useState(false)
    const [isImportingMetrics, setIsImportingMetrics] = useState(false)
    const [importingErrors, setImportingErrors] = useState([])
    const [isOpenedMetricsErrorsPopup, setIsOpenedMetricsErrorsPopup] = useState(false)
    const isEasmWarningMessageDisabled = UserApi.getUserProp('isEasmWarningMessageDisabled', currentUser?.id)
    const [isWarningDisabled, setIsWarningDisabled] = useState(JSON.parse(isEasmWarningMessageDisabled))
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 1)
    const [fromDate, setFromDate] = useState(currentDate)
    const [toDate, setToDate] = useState(new Date())
    const changeFile = (file) => {
        setMetricsFile(file)
        setIsOpenedSheetPopUp(true)
        setIsOpenedMetricsPopUp(false)
        setIsOpenedMetricsErrorsPopup(false)
    }

    const importMetricsClick = () => {
        UserApi.setUserPropCookie('isEasmWarningMessageDisabled', isWarningDisabled, currentUser?.id, true)
        const importMetricsRow = document.getElementById('fileUpload')
        importMetricsRow.click()

    }

    const checkIfCookiesExists = () => {
        isWarningDisabled ? importMetricsClick() : setIsOpenedMetricsPopUp(true)
    }

    const closeSheetPopUp = () => {
        setIsOpenedSheetPopUp(false)
        setSheetName('')
    }
    const importCyberData = async () => {
        setIsButtonLoading(true)
        setIsImportingMetrics(true)
        const formatFromDate = moment(fromDate).format('YYYY-MM-DD')
        const formatToDate = moment(toDate).format('YYYY-MM-DD')
        const data = await EasmApi.importMetricsFromCyberInt(formatFromDate, formatToDate)
        if (!data || data.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: data.error, notificationType: 'error' }))
            setIsOpenedCloudSync(false)
            setIsButtonLoading(false)
            setIsImportingMetrics(false)
            return
        }
        setPredefined(data.metrics)
        updateMetrics(data.metrics, true)
        setActivityFindings(data.findings || [])
        setIsOpenedCloudSync(false)
        setIsButtonLoading(false)
        setIsImportingMetrics(false)

    }
    const closeCloudSync = () => {
        setIsOpenedCloudSync(false)
    }
    const uploadNewMetrics = async () => {
        if (!metricsFile) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Please choose a file', notificationType: 'error' }))
            return
        }
        setIsImportingMetrics(true)
        setIsButtonLoading(true)
        const formData = new FormData()
        formData.append('file', metricsFile)
        const importedMetricsData = await EasmApi.postMetricsFile(currentOrgId, formData, sheetName)
        if (!importedMetricsData || importedMetricsData.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `Import failed: ${importedMetricsData.error}`, notificationType: 'error' }))
            setIsButtonLoading(false)
            setIsOpenedSheetPopUp(false)
            setIsImportingMetrics(false)
            return
        }
        const updatedMetrics = refactorImportedMetrics(importedMetricsData?.easm_metrics?.data_entities || [], valuesPropsGeneric, true)
        setPredefined(updatedMetrics)
        updateMetrics(updatedMetrics, true)
        checkImportingErrors(importedMetricsData?.easm_metrics, updatedMetrics, setImportingErrors, setIsOpenedMetricsErrorsPopup)
        setIsOpenedSheetPopUp(false)
        setIsButtonLoading(false)
        setIsImportingMetrics(false)
    }

    const changeMetricValue = (key, i, val, isPredefined) => {
        let updatedMetrics = getMetrics(isPredefined)
        updatedMetrics = getChangedMetrics(updatedMetrics, key, i, val, metricsConsts)
        setMetrics(updatedMetrics, isPredefined)
        updateMetrics(updatedMetrics, isPredefined)
    }

    const addNewMetric = () => {
        const updatedMetrics = [...custom, { ...newMetricModel }]
        setCustom(updatedMetrics)
    }

    const deleteMetric = (index, isPredefined) => {
        const updatedMetrics = (getMetrics(isPredefined)).filter((_, i) => i !== index)
        setMetrics(updatedMetrics, isPredefined)
        updateMetrics(updatedMetrics, isPredefined)
    }

    const setMetrics = (updatedMetrics, isPredefined) => {
        isPredefined ? setPredefined(updatedMetrics) : setCustom(updatedMetrics)
    }

    const getMetrics = (isPredefined) => {
        return isPredefined ? [...predefined] : [...custom]
    }

    const checkRequiredField = (val, isPredefined, i) => {
        const metrics = getMetrics(isPredefined)
        return getRequiredFieldError(val, i, isPredefined, metrics.length) 
    }

    const metricsFunctions = {
        changeMetricValue,
        checkRequiredField,
        deleteMetric
    }

    const getMetricsTableData = (isPredefined) => {
        const metrics = getMetrics(isPredefined)
        const data = metrics.map((metric, i) => {
            const id = getMetricId(metric, i, isPredefined, predefined)
            const hasOtherError = metric[OTHER_PROP] && `${metric[OTHER_PROP]}`.split(',').map(v => !helpers.isNumber(v)).includes(true) ? 'Must be a number' : ''
            const metricProps = {
                metric,
                i,
                metricsLength: metrics.length,
                id,
                isPredefined,
            }
            const valuesFields = getValuesFieldsDynamic(metricProps, metricsConsts, metricsFunctions, hasFindings)
            const textFields = getTextFields(metricProps, metricsConsts, metricsFunctions, false, hasFindings)
            return {
                id,
                ...metric,
                ...textFields,
                ...valuesFields,
                other_value: <InputRow
                    inputId={`${OTHER_PROP}-${id}`}
                    changeValueHandler={(val) => changeMetricValue(OTHER_PROP, i, val, isPredefined)}
                    defaultValue={metric[OTHER_PROP]}
                    errorMsg={hasOtherError}
                    disabled={hasFindings}
                    selectOnFocus={true} />,
            }
        })
        return data
    }

    const downloadTemplate = () => {
        if (templateUrl) {
            window.open(templateUrl, '_blank', 'noopener,noreferrer')
        } else {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: 'Template not found', notificationType: 'error' }))
        }
    }
    const templateButtons = [ { func: downloadTemplate, title: 'Download Template', icon: DOWNLOAD_CLOUD, id: 'download-easm-metrics' },
        { func: checkIfCookiesExists, title: IMPORT_METRICS, icon: UPLOAD_CLOUD, id: 'import-easm-metrics' },
        { func: () => setIsOpenedCloudSync(true), title: SYNC_FROM_CLOUD, icon: NETFILTER, id: 'Sync From Cloud' }]

    const customMetricsTableData = getMetricsTableData(false)
    const predefinedMetricsTableData = getMetricsTableData(true)
    const metricsTableColumns = getActivityInfoMetricsColumnsForm(easmValuesProps)
    metricsTableColumns[3].name = <span className='absolute-th'>Values</span>
    return (
        <div className='metrics-tables metrics-tables-easm'>
            <div className='metrics-tables-header'>
                <div>
                    <h3 id='easm-activity-metrics'>Activity Metrics</h3>
                </div>
                {!! predefined.length && <div className='metrics-tables-header-buttons'>
                    {templateUrl && <a className='btn-link' href={templateUrl} target='_blank' rel='noreferrer' id='download-easm-metrics-template'><i className='icon-download-cloud'></i> Download Template</a>}
                    <button className='btn-link' onClick={checkIfCookiesExists} id='import-easm-metrics'>
                        <i className='icon-upload-cloud'></i> <p className='import-text'>{IMPORT_METRICS}</p></button>
                    <button className='btn-link' onClick={() => setIsOpenedCloudSync(true)} id='sync-cloud-metrics'>
                        <i className='icon-netfilter'></i> <p className='import-text'>{SYNC_FROM_CLOUD}</p></button>
                </div>} 
            </div>
            {predefined.length ?
                <>
                    <fieldset>
                        <LabelDots list={labelDotsItems} />
                    </fieldset>
                    {!hasFindings && <fieldset className='editable-table metrics-tables-custom'>

                        <SortableTable
                            columns={metricsTableColumns}
                            tableData={customMetricsTableData}
                            itemsPerPage={0}
                            noFilter={true}
                            tableId='easm-activity-metrics-custom-table' />
                        <div className='editable-table-bottom'>
                            <button className='btn-link' onClick={addNewMetric} id='add-custom-metric'><i className='icon-plus'></i> Custom Metrics</button>
                        </div>
                    </fieldset>}
                    <fieldset className='editable-table metrics-tables-predefined'>
                        {isImportingMetrics ? <Loader /> : <SortableTable
                            columns={metricsTableColumns}
                            tableData={predefinedMetricsTableData}
                            itemsPerPage={0}
                            tableId='easm-activity-metrics-predefined-table' />}
                    </fieldset>
                </> :  
                <ImportTemplateButtons templateButtons={templateButtons} img={Cloud} />
            }
            <Popup
                isOpen={isOpenedMetricsPopUp}
                onClose={() => setIsOpenedMetricsPopUp(false)} title={<div><i className='icon-warning'></i> {genericTexts.defaultText.deletePopupTitle}</div>}
                isDisabledClickOutside={true}>
                <p> By importing the file, existing data will be overwritten. This action cannot be undone.</p>
                <div className='hidden-row'><InputRow
                    inputId='fileUpload'
                    title='Import Metrics'
                    inputType='file'
                    changeValueHandler={changeFile}
                    defaultValue={metricsFile} /></div>
                <InputRow inputId='checkbox' title='Don’t show again' inputType='checkbox' defaultValue={isWarningDisabled} changeValueHandler={setIsWarningDisabled} />

                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={importMetricsClick} id='got-it'>Got It</button>
                </div>
            </Popup>
            <Popup
                isOpen={isOpenedMetricsErrorsPopup}
                onClose={() => setIsOpenedMetricsErrorsPopup(false)} title={<div><i className='icon-warning'></i> Import Warnings</div>}
                customClass='import-errors'>
                <p>Import completed with warnings. You can fix your file and upload again or fill the missed fields</p>
                {importingErrors.map((error, i) => <p key={i} className='error-msg' id={`easm-import-error-${i}`}>{error}</p>)}
                <div className='popup-buttons'>
                    <button className='btn-submit' onClick={() => setIsOpenedMetricsErrorsPopup(false)} id='import-warning-ok'>OK</button>
                </div>
            </Popup>
            <Popup
                isOpen={isOpenedSheetPopUp}
                onClose={closeSheetPopUp} title={<div className='popup-cloud-icon'><i className='icon-upload-cloud'></i></div>}>
                <div className='sheet-name-popup'>
                    <div className='sheet-file-name'><span>{metricsFile?.name || ''}</span></div>
                    <InputRow
                        inputId='textInput'
                        placeholder='Enter Sheet name'
                        title='Sheet Name'
                        defaultValue={sheetName}
                        changeValueHandler={setSheetName} />
                </div>
                <div className='popup-buttons'>
                    <button className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} onClick={uploadNewMetrics} id='upload-metrics'>Upload</button>
                </div>
            </Popup>
            <Popup
                isOpen={isOpenedCloudSync}
                onClose={closeCloudSync} title={'Choose Date Range'}>
                <div className='sheet-name-popup'>
                    <InputRow dateTimeDetails={dateTimeDetails} inputId='datePickerFrom' title='From Date' inputType='dateTime' changeValueHandler={setFromDate} defaultValue={fromDate} />
                    <InputRow dateTimeDetails={dateTimeDetails} inputId='datePickerTo' title='To Date' inputType='dateTime' changeValueHandler={setToDate} defaultValue={toDate} />
                </div>
                <div className='popup-buttons'>
                    <button className={`btn-submit ${isButtonLoading ? 'loader' : ''}`} onClick={importCyberData} id='upload-metrics'>Sync</button>
                </div>
            </Popup>
        </div>)
}


const EasmActivityMetrics = connect(mapStateToProps)(EasmActivityMetricsComponent)

export default EasmActivityMetrics

